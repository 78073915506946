import Loader from "@/components/ui/loader";

import type { ContentLoaderProps } from "./content-loader.types";

import { ContentLoaderWrapper } from "./content-loader.styles";

const ContentLoader = ({ height, noBorderRadius }: ContentLoaderProps) => {
    return (
        <ContentLoaderWrapper height={height} noBorderRadius={noBorderRadius}>
            <Loader />
        </ContentLoaderWrapper>
    );
};

export default ContentLoader;
