import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import moment from "moment";

import { useSessionDetailsContext } from "@/context/session-details/session-details";

import { CalendarIcon24, ClockIcon24, UsersIcon24 } from "@/components/icons";

import { Client } from "@/enums/clients";

import useQueryParams from "@/utils/use-query-params";

import { getTimeDifference } from "../components/timeline-chart-with-firing/utils";
import {
    BulletPoint,
    DateDiv,
    DurationDiv,
    NameAndIconDiv,
    TimeDiv,
    TraineeNameSpan,
    UserAndSessionInfoWrapper,
} from "../session-detail.styles";

export const useSessionInfo = () => {
    const { state }: { state: Record<string, string> } = useLocation();
    const intl = useIntl();
    const params: any = useQueryParams();
    const { customerName } = useSessionDetailsContext();

    const sessionPeriodFrom = params.get("from");
    const sessionPeriodTo = params.get("to");
    const name = params.get("name");
    const simulationName = params.get("simulationName");
    const traineeName = params.get("traineeName");
    const logoUrl = params.get("logoUrl");

    const sessionDuration = getTimeDifference(
        new Date(sessionPeriodFrom),
        new Date(sessionPeriodTo),
    );

    const getUsers = () => {
        const cacheUsers = localStorage.getItem("users"); //why do we need to get the users from localStorage??

        if (cacheUsers !== null) {
            const parseUsers = JSON.parse(cacheUsers);
            const usersSplitArray = parseUsers.trim().split(",");

            const usersArray = usersSplitArray.map((user: any) => {
                const splitUser = user.replace("=>", " - ");
                const [role, userName] = splitUser.split(" - ");
                const capitalizedRole = role.charAt(0).toUpperCase() + role.slice(1);
                const finalUser = `${capitalizedRole} - ${userName}`;

                return finalUser;
            });

            return usersArray;
        }

        return [state?.traineeName || traineeName]; // Return the single user in an array
    };

    const users = getUsers();

    let title = useMemo(() => {
        const sessionTitle = name || intl.formatMessage({ id: "session-detail:title" });

        return `${state?.simulationName || simulationName || ""} \u2022 ${sessionTitle}`;
    }, [state?.simulationName, simulationName, name, intl]);

    if (customerName == Client.MLRS) {
        title = `${"TCT - MLRS"} \u2022 ${"Detachment Commanders Course"}`;
    }

    const getSessionSubtitleDetails = useMemo(() => {
        const sessionDate = moment(sessionPeriodFrom || sessionPeriodTo).format("DD/MM/YYYY");
        const fromTime = moment(sessionPeriodFrom).format("HH:mm");

        return (
            <UserAndSessionInfoWrapper>
                {users?.map((user: any, index: number) => {
                    return (
                        <NameAndIconDiv key={`${index}-${user}`}>
                            <UsersIcon24 fill="#999999" />
                            <TraineeNameSpan>{user}</TraineeNameSpan>
                        </NameAndIconDiv>
                    );
                })}
                <DateDiv>
                    <CalendarIcon24 />
                    {sessionDate}
                </DateDiv>

                <TimeDiv>
                    <ClockIcon24 />
                    {fromTime}
                </TimeDiv>

                <BulletPoint>{`\u2022`}</BulletPoint>

                <DurationDiv>{sessionDuration}</DurationDiv>
            </UserAndSessionInfoWrapper>
        );
    }, [sessionPeriodFrom, sessionPeriodTo, state?.traineeName, traineeName, sessionDuration]);

    return { getSessionSubtitleDetails, logoUrl, title, state };
};
