export enum ContextFilterPresets {
    Last7Days = "last7days",
    Last14Days = "last14days",
    Last30Days = "last30days",
    Last12Months = "last12months",
    Lifetime = "lifetime",
}

export const ContextFilterPresetValues: { [key: string]: number } = {
    [ContextFilterPresets.Last7Days]: -6,
    [ContextFilterPresets.Last14Days]: -13,
    [ContextFilterPresets.Last30Days]: -29,
    [ContextFilterPresets.Last12Months]: -364,
    [ContextFilterPresets.Lifetime]: 0,
};

export const ProgressFilters: { [key: string]: string } = {
    Last7Days: "last7days",
    Month: "month",
    Year: "year",
    Lifetime: "lifetime",
};

export const ProgressFilterValues: { [key: string]: number } = {
    [ProgressFilters.Last7Days]: -6,
    [ProgressFilters.Lifetime]: 0,
};
