import React, { useCallback } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import type { DropdownMenuProps } from "./dropdown-menu.types";

import { DropdownMenuWrapperDiv, MenuItem } from "./dropdown-menu.styles";

const DropdownMenu = <T extends number | string | null>({
    isOpen,
    items,
    top,
    right,
    clickedItem,
    handleOptionClick,
    setIsOpen,
}: DropdownMenuProps<T>) => {
    const onCloseMenu = useCallback(() => {
        if (!isOpen) return;

        setIsOpen(null);
    }, [isOpen]);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: onCloseMenu,
    });

    return (
        <>
            {isOpen && (
                <DropdownMenuWrapperDiv ref={clickOutsideRef} top={top} right={right}>
                    {items.map((item: string, index) => {
                        return (
                            <MenuItem
                                key={index}
                                id={item}
                                isSelected={clickedItem === item}
                                onClick={() => handleOptionClick(item)}
                            >
                                {item}
                            </MenuItem>
                        );
                    })}
                </DropdownMenuWrapperDiv>
            )}
        </>
    );
};

export default DropdownMenu;
