import { useEffect, useState } from "react";

import { cloneDeep } from "lodash";

import { type RangeBarData, type ChartData, type RangeBarDataSet } from "@/types/timeline-chart";

import { ChartName } from "@/enums/session-details";

import { options } from "./chart-config";
import { convertDataToApex, createEmptyData, getMaxValue } from "./utils/utils";

import { CustomAreaChart } from "./timeline-chart-flight.styles";

const BUFFER_VALUE = 1;

type CombinedChartProps = {
    chartStateByType: {
        [k: string]: boolean | undefined;
    };
    areaChartData: ChartData[];
    rangeBarData: RangeBarData[];
};

const getAreaSeriesData = (chartData: ChartData[]) => {
    const finalData = chartData.map((chart: ChartData, index: number) => {
        return {
            name: chart.name,
            label: chart.radioLabel,
            data: convertDataToApex(chart.data) || [],
            yAxis: index,
            color: chart.color,
            unit: chart.unit,
        };
    });

    // to create chart with empty-data to show the annotation when all the area charts are toggled off
    finalData.push({
        name: "emptydata",
        label: "emptydata",
        data: createEmptyData(chartData[0].data),
        yAxis: 1,
        color: "transparent",
        unit: "",
    });

    return finalData;
};

const getAreaOptionsData = (RangeBarData: RangeBarData[], chartData: ChartData[]) => {
    const optionsCopy = cloneDeep(options);
    //To show the annotation in areachart (Lookout Procedure, Blind spot check etc)
    const xAxisData = RangeBarData?.[0]?.data?.map((point: RangeBarDataSet) => {
        return {
            x: new Date(point.StartTime).getTime(),
            x2: new Date(point.EndTime).getTime(),
            fillColor: "#13536F",
            opacity: 0.3,
            strokeDashArray: 0,
            borderColor: "none",
        };
    });

    if (xAxisData?.length !== 0) {
        optionsCopy.annotations.xaxis = xAxisData;
    }

    //to set yaxis for area chart
    const yAxisData = chartData?.map((chart: ChartData) => {
        return {
            title: {
                name: chart.name,
            },
            min: 0,
            max: getMaxValue(chart.data) + BUFFER_VALUE,
            show: false,
        };
    });
    optionsCopy.yaxis = yAxisData;

    return optionsCopy;
};

const getToolTipState = (obj: any, ignoreKey = ChartName.LOOKOUT): boolean => {
    let enableTooltip = false;

    for (const key in obj) {
        if (key !== ignoreKey && obj[key] === true) {
            enableTooltip = true;
            break;
        }
    }

    return enableTooltip;
};

const CombinedChart = ({ chartStateByType, areaChartData, rangeBarData }: CombinedChartProps) => {
    const areaSeriesData = getAreaSeriesData(areaChartData);
    const areaOptions = getAreaOptionsData(rangeBarData, areaChartData);

    const [seriesData, setSeriesData] = useState(areaSeriesData);
    const [optionsData, setOptionsData] = useState(areaOptions);

    useEffect(() => {
        //to disable area chart which is toggled off
        let filteredSeries = [...areaSeriesData];
        filteredSeries = filteredSeries.map((series: any) => {
            if (chartStateByType[series.name] === false) {
                series.data = [];
            }

            return series;
        });

        //when range bar chart is disabled, we are removing the annotations
        let annotations = cloneDeep(areaOptions.annotations);

        if (chartStateByType[ChartName.LOOKOUT] === false) {
            annotations = {
                yaxis: [],
                xaxis: [],
                points: [],
                images: [],
            };
        }

        //to hide the area chart tooltip when only annotation is selected
        const enableToolTip = getToolTipState(chartStateByType);
        setOptionsData((prevOptions: any) => ({
            ...prevOptions,
            tooltip: {
                enabled: enableToolTip,
            },
            annotations,
        }));

        setSeriesData(filteredSeries);
    }, [chartStateByType]);

    return (
        <>
            <CustomAreaChart options={optionsData} series={seriesData} type="area" height={230} />
        </>
    );
};

export default CombinedChart;
