import client from "@/client";

import type { Option } from "../../pages/users/components/users-filter";

export const getUsers = async (
    skipToken: string | null | undefined,
    searchInputValue: string | null | undefined,
    roles: Option[] | undefined,
    status: string | undefined,
) => {
    const roleParam = roles?.map((role) => `roles=${role.id}`).join("&");
    const { data } = await client.get(
        `v1/User?pageSize=7${skipToken ? `&skipToken=${skipToken}` : ""}${searchInputValue ? `&searchString=${searchInputValue}` : ""}${roleParam ? `&${roleParam}` : ""}${status ? `&status=${status}` : ""}`,
    );

    return data;
};

export const updateUserRole = async ({ userId, newRole }: { userId: string; newRole: string }) => {
    const { data } = await client.put(`v1/User/Role?userId=${userId}&newRole=${newRole}`);

    return data;
};

export const updateUserStatus = async ({
    userId,
    isActive,
}: {
    userId: string;
    isActive: boolean;
}) => {
    const { data } = await client.put(`v1/User/account?userId=${userId}&isActive=${isActive}`);

    return data;
};

export const resendUserInvitation = async (userId: string) => {
    const { data } = await client.put(`v1/User/invitation/${userId}`);

    return data;
};

//IF THERE ARE MORE ITEMS TO LOAD
//skipToken: null
//hasNextPage: false

//IF THERE AREN'T ANY MORE ITEMS TO LOAD
//skipToken: "AQABAAAAAgAAALI49FlS_KxNtpgYrLUm5-OLTh2nvsuvRJ"
//hasNextPage: true

//PARAMETERS
// pageSize: number;
// skipToken: string;
// searchString: string;
// roles; string[];
