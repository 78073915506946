import styled, { css } from "styled-components";

import base from "@/themes/base/base";
import { desktopLargeMedia } from "@/themes/breakpoints";

export const MainContainer = styled.div<{ hasSessions: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;

    ${({ hasSessions }) =>
        !hasSessions &&
        css`
            align-items: center;
            justify-content: center;
            height: calc(100vh - var(--heat-header-height) - var(--sessions-page-header-height));
        `}
`;

export const SessionItemRefContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SessionsSidebar = styled.div`
    width: 33%;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 4rem 0;
`;

export const EmptyStateContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    background-color: ${base.colors.neutral.grey[800]};
    align-items: center;
    border-radius: 8px;
    min-height: 22rem;
    width: 45.5rem;

    ${desktopLargeMedia} {
        width: 64.5rem;
    }
`;

export const DateDisplayFixedContainer = styled.div`
    position: fixed;
    top: var(--sessions-page-date-div-height);
    background-color: ${base.colors.neutral.grey[900]};
    z-index: 1;
    height: 2.625rem;
    width: 81.3%;

    ${desktopLargeMedia} {
        display: none;
    }
`;

export const DateDisplayWrapperDiv = styled.div`
    ${base.typography.largeMedium};
    display: flex;
    align-items: center;
    gap: 0.25rem;
    position: sticky;
    padding-bottom: 1.5rem;
    top: var(--sessions-page-date-div-height);
    transition: top 0.5s ease-in-out;
    z-index: 2;
`;

export const GroupedSessionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
