import { useCallback, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage } from "react-intl";

import { CalendarIcon20, ChevronRightSquareCornersIcon20 } from "@/components/icons";

import { ContextFilterPresetValues } from "@/enums/overview";

import {
    ContextFilterPopUp,
    Toggle,
    Preset,
    PresetsContainer,
    PresetsList,
    Link,
    FilterContainer,
} from "./benefits.styles";

const ContextFilter = ({
    selectedPreset,
    onSelect,
}: {
    selectedPreset: string;
    onSelect: (selectedPreset: string) => void;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleList = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handlePresetSelection = useCallback(
        (filter: string) => {
            setIsOpen(false);
            onSelect(filter);
        },
        [selectedPreset],
    );

    const onClose = useCallback(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    }, [isOpen]);

    const clickOutsideRef = useDetectClickOutside({ onTriggered: onClose });

    return (
        <FilterContainer ref={clickOutsideRef}>
            <Toggle onClick={handleToggleList} data-test-id="benefits-dropdown">
                <CalendarIcon20 fill="#f2f2f2" />
                {selectedPreset && <FormattedMessage id={`overview:${selectedPreset}`} />}
                <ChevronRightSquareCornersIcon20 />
            </Toggle>
            {isOpen && (
                <ContextFilterPopUp width={"auto"}>
                    <PresetsContainer>
                        <PresetsList>
                            {Object.keys(ContextFilterPresetValues).map((k: string) => (
                                <Preset key={`preset-${k}`}>
                                    <Link
                                        selected={k === selectedPreset}
                                        onClick={() => handlePresetSelection(k)}
                                        data-test-id={`bene-${k}`.toLowerCase()}
                                    >
                                        <FormattedMessage id={`overview:${k}`} />
                                    </Link>
                                </Preset>
                            ))}
                        </PresetsList>
                    </PresetsContainer>
                </ContextFilterPopUp>
            )}
        </FilterContainer>
    );
};

export default ContextFilter;
