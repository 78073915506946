import type { IconProps } from "@/types/icon";

const DownloadIcon24 = ({
    width = 24,
    height = 25,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 24 25`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path
            d="M12.5 16L7.5 11L8.9 9.55L11.5 12.15V4H13.5V12.15L16.1 9.55L17.5 11L12.5 16ZM6.5 20C5.95 20 5.47917 19.8042 5.0875 19.4125C4.69583 19.0208 4.5 18.55 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.3042 19.0208 19.9125 19.4125C19.5208 19.8042 19.05 20 18.5 20H6.5Z"
            fill={fill}
        />
    </svg>
);

export default DownloadIcon24;
