export interface EmptyStateProps {
    title?: string;
    description?: string;
    emptyStateType: EmptyStateType;
    minHeight?: string;
    svgWidth?: number;
    svgHeight?: number;
}

export enum EmptyStateType {
    Sessions = "sessions",
    Charts = "charts",
    Error = "error",
}

export interface SecondaryWrapperStylingProps {
    minHeight?: string;
}
