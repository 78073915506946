import base from "@/themes/base/base";

import Timeline from "@/components/icons/timeline-tooltip.svg";

import { calculateCognitiveLabel, getTimeDifference } from "./utils/utils";

export const series: any = [];

export const options: any = {
    chart: {
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
        animations: {
            enabled: false,
        },
    },

    dataLabels: {
        enabled: false,
    },

    legend: {
        show: false, // Hide the legend
    },

    stroke: {
        curve: "smooth",
        width: 2,
    },

    grid: {
        strokeDashArray: 8,
        borderColor: "#53555A",
    },

    xaxis: {
        type: "datetime",
        axisTicks: {
            show: false,
        },
        labels: {
            show: false, // Hide the x-axis labels
        },
        axisBorder: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
    },

    tooltip: {
        enabled: true,
        x: {
            show: false,
        },
        y: {
            show: false,
        },
        custom: ({ seriesIndex, dataPointIndex, w }: any) => {
            const startDateTime = new Date(w.globals.seriesX[seriesIndex][0]);
            const currentDateTime = new Date(w.globals.seriesX[seriesIndex][dataPointIndex]);
            const seriesData = w.globals.initialSeries.filter(
                (chart: any) => chart.name !== "emptydata",
            );

            const seriesTooltip: any[] = seriesData.map((series: any) => {
                const { name, label, color, unit } = series;
                const data = series.data[dataPointIndex] ? series?.data[dataPointIndex][1] : "";

                //if cognitive load, show high, med, low based on data value
                const getDataLabel = (data: any) => {
                    if (name.toLowerCase() === "cognitiveload") {
                        const label = calculateCognitiveLabel(data);

                        return `${data} (${label})`;
                    }

                    return data;
                };

                return (
                    `<span class=${data === "" ? "hidden" : ""}>` +
                    `<svg width="20" height="20" viewBox="0 0 20 20" style="margin-right:4px;">` +
                    `<circle cx="12" cy="12" r="6" fill=${color} stroke="white" stroke-width="2"/></svg>` +
                    `<span class='value-label'>` +
                    label +
                    `:</span>` +
                    getDataLabel(data) +
                    unit +
                    "</span>"
                );
            });

            return (
                '<div class="custom-tooltip">' +
                `<span><img src=${Timeline} width="22px" class="timeline"/>` +
                getTimeDifference(startDateTime, currentDateTime) +
                "</span>" +
                seriesTooltip.join("") +
                "</div>"
            );
        },
    },

    yaxis: [],

    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.4,
            opacityTo: 0,
            gradientToColors: base.colors.neutral.grey[800],
        },
    },

    annotations: {
        xaxis: [],
    },
};
