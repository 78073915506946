export const mobileMedia = "@media (min-width: 360px) and (max-width: 767px)";
export const tabletPortraitMedia = "@media (min-width: 768px) and (max-width: 1023px)";
export const tabletLandscapeMedia = "@media (min-width: 1024px) and (max-width: 1279px)";
export const desktopSmallMedia = "@media (min-width: 1280px) and (max-width: 1439px)";
export const desktopMediumMedia = "@media (min-width: 1440px) and (max-width: 1919px)";
export const desktopLargeMedia = "@media (min-width: 1920px)";

//to eventually add
//export const fromTabletPortraitToDesktopSmallMedia = '@media (min-width: 768px) and (max-width: 1439px)';
//export const fromTabletPortraitToDesktopMediumMedia = '@media (min-width: 768px) and (max-width: 1919px)';
//export const fromTabletLandscapeToDesktopMediumMedia = '@media (min-width: 1024px) and (max-width: 1919px)';
export const lowerThanDesktopLargeMedia = "@media (max-width: 1919px)";

// Breakpoints
export const mobileWidth = "360px";
export const tabletPortraitWidth = "768px";
export const tabletLandscapeWidth = "1024px";
export const desktopSmallWidth = "1280px";
export const desktopMediumWidth = "1440px";
export const desktopLargeWidth = "1920px";
