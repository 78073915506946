import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { getWelcomeSurvey } from "@/api/user";

import { PrimaryButton } from "@/pages/shared/shared.styles";

import { SuccessIcon128 } from "@/components/icons";
import { VRIntlProviderComponent } from "@/components/providers/intl-provider";

import { PageLayout, StyledHeatLogo } from "../onboarding-styles";
import type { WelcomeSurveyData } from "../survey/survey.types";

import { ContentWrapper, Description, SuccessContainer } from "./success.styles";

const localeFn = (target: string) => import(`./../locale/${target.toLowerCase()}.json`);

const SurveySuccess = () => {
    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const [successPageButton, setSuccessPageButton] = useState<any>();

    const { data: welcomeSurveyData } = useQuery<WelcomeSurveyData>(
        ["getWelcomeSurvey"],
        () => getWelcomeSurvey(),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
        },
    );

    useEffect(() => {
        setSuccessPageButton(welcomeSurveyData?.successPageButton);
    }, [welcomeSurveyData]);

    const redirectToOverview = async () => {
        /* this invalidates & refetches updated user data from api call getUser,
        so that when first time user gets redirected to Overview page,
        UserBadge component displays the updated data */
        await queryClient.invalidateQueries("get-user");
        await queryClient.refetchQueries("get-user");

        navigate(successPageButton ? `${successPageButton.redirectLink}` : `/overview`);
    };

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="privacy-page" fallback={null}>
            <PageLayout>
                <SuccessContainer minHeight>
                    <StyledHeatLogo />

                    <ContentWrapper>
                        <SuccessIcon128 />
                        <Description>
                            <FormattedMessage id={"success:desc"} />
                        </Description>
                        <PrimaryButton onClick={redirectToOverview} fullWidth>
                            {successPageButton ? (
                                successPageButton?.text
                            ) : (
                                <FormattedMessage id={"success:goto-overview"} />
                            )}
                        </PrimaryButton>
                    </ContentWrapper>
                </SuccessContainer>
            </PageLayout>
        </VRIntlProviderComponent>
    );
};

export default SurveySuccess;
