import type { IconProps } from "@/types/icon";

const SortDashesIcon32 = ({
    width = 32,
    height = 32,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        {...remainingProps}
    >
        <path
            d="M4 24V21.3333H12V24H4ZM4 17.3333V14.6667H20V17.3333H4ZM4 10.6667V8H28V10.6667H4Z"
            fill={fill}
        />
    </svg>
);

export default SortDashesIcon32;
