import type { ChangeEvent } from "react";
import type {
    InfiniteData,
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "react-query";

import type { Option } from "../users-filter";

export interface OptionsBarProps {
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    refetchUsersData: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<InfiniteData<any>, unknown>>;
    searchInputValue: string | null;
    onRoleChange: (roles: Option[]) => void;
    onStatusChange: (status: string) => void;
}

export enum AddUsersEmailErrorAPIResponse {
    InvalidEmail = "RegularExpressionValidator",
    EmailAlreadyExists = "EmailAlreadyExists",
    MaxiSizeReached = "MaxSizeReached",
    DuplicateEmails = "DuplicatedItems",
}
