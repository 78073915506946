import { useCallback, useEffect, useState } from "react";

import { useModal } from "@/context/modal/modal";
import { useSessionDetailsContext } from "@/context/session-details/session-details";

import base from "@/themes/base/base";

import ContentLoader from "@/components/content-loader/content-loader";
import CircuitScoringModalContent from "@/components/ui/modal-with-context/session-details-page/circuit-scoring/circuit-scoring-modal-content";

import { useSessionInfo } from "../../hooks/useSessionInfo";

import ProgressBarAndCircuitScoring from "./components/progress-bar-and-circuit-scoring/progress-bar-and-circuit-scoring";

import { circuitParameters, circuitScore } from "./mock-data/data";

import type {
    CircuitScoreDataSet,
    HandleCircuitClickProps,
    LinearProgressBarDataSet,
    ProgressBarDataSet,
} from "./scoring.types";

const MODAL_HEIGHT_PERCENTAGE = 0.874;
const MODAL_NON_SCROLLABLE_CONTENT_HEIGHT = 170;

export const CircuitScoring = () => {
    const { openModal, setWidth, setHeight, isModalOpen } = useModal();

    const {
        isFilterOpen,
        setFilterOptions,
        setSelectedIndex,
        setSelectedFilterOption,
        setIsFilterOpen,
    } = useSessionDetailsContext();

    const { getSessionSubtitleDetails, logoUrl } = useSessionInfo();

    const [scrollableContainerHeight, setScrollableContainerHeight] = useState(
        () => window.innerHeight * 0.874 - 170,
    );

    //TODO: when we have BE integration, use useQuery's variable
    const isLoading = false;

    const circuitScoreData: ProgressBarDataSet = (
        circuitScore as unknown as CircuitScoreDataSet[]
    ).filter((dataset: any) => dataset.chartType === "ProgressBar")[0]
        .datasets[0] as ProgressBarDataSet;

    const linearProgressBarData: LinearProgressBarDataSet[] = (
        circuitScore as unknown as CircuitScoreDataSet[]
    ).filter((dataset: any) => dataset.chartType === "LinearProgressBar")[0]
        .datasets as LinearProgressBarDataSet[];

    //when modal closes, close filters button, reset selectedFilterOption & reset selectedIndex
    useEffect(() => {
        if (!isModalOpen) {
            setIsFilterOpen(false);
            setSelectedFilterOption(null);
            setSelectedIndex(null);
        }
    }, [isModalOpen]);

    //to update the ComponentsScrollableWrapper height, when screen height changes
    useEffect(() => {
        const handleScrollableContainerResize = () => {
            const updatedHeight =
                window.innerHeight * MODAL_HEIGHT_PERCENTAGE - MODAL_NON_SCROLLABLE_CONTENT_HEIGHT;
            setScrollableContainerHeight(updatedHeight);
        };

        window.addEventListener("resize", handleScrollableContainerResize);

        return () => window.removeEventListener("resize", handleScrollableContainerResize);
    }, []);

    //to update the DOM element's height, since styled components are not receiving the updated height
    useEffect(() => {
        const componentsScrollableWrapper = document.getElementById(
            "components-scrollable-wrapper",
        );

        if (componentsScrollableWrapper) {
            componentsScrollableWrapper.style.height = `${scrollableContainerHeight}px`;
        }
    }, [scrollableContainerHeight]);

    //to populate filterOptions
    useEffect(() => {
        setFilterOptions(linearProgressBarData);
    }, [linearProgressBarData]);

    //to close filters button when clicking outside
    const handleClickOutsideFilters = useCallback(
        (event: any) => {
            if (isFilterOpen && !event.target.closest("#modal-filters-popup-id")) {
                setIsFilterOpen(false);
            }
        },
        [isFilterOpen],
    );

    //to close filters button when clicking outside
    useEffect(() => {
        if (isFilterOpen) {
            document.addEventListener("click", handleClickOutsideFilters);
        }

        return () => {
            document.removeEventListener("click", handleClickOutsideFilters);
        };
    }, [isFilterOpen, handleClickOutsideFilters]);

    const handleCircuitClickModalOpen = useCallback(
        ({ circuit, isReportsButtonClicked }: HandleCircuitClickProps) => {
            setWidth("77.25682295%"); //for 1104px modal width, in breakpoint 1440px
            setHeight("87.4%");

            //if reports button is clicked, open circuit 1 by default
            if (isReportsButtonClicked) {
                setSelectedFilterOption(linearProgressBarData[0]);
                setSelectedIndex(0);
            }

            openModal({
                content: (
                    <CircuitScoringModalContent
                        scrollableContainerHeight={scrollableContainerHeight}
                        logoUrl={logoUrl}
                        getSessionSubtitleDetails={getSessionSubtitleDetails}
                        circuitScoreData={circuitScoreData}
                        circuitParameters={circuitParameters}
                        handleCircuitModalOpen={() =>
                            handleCircuitClickModalOpen({ circuit, isReportsButtonClicked })
                        }
                    />
                ),
                backgroundColor: `${base.colors.neutral.grey[900]}`,
                borderRadius: "0.25rem",
                padding: "1.5rem 1.5rem 0 1.5rem",
                boxShadow: "0px 0px 4px 1px rgba(255, 255, 255, 0.25)",
            });
        },
        [
            setWidth,
            setHeight,
            logoUrl,
            getSessionSubtitleDetails,
            openModal,
            scrollableContainerHeight,
            circuitScoreData,
            linearProgressBarData,
        ],
    );

    if (isLoading) {
        return <ContentLoader height="21.1875rem" />;
    }

    return (
        <ProgressBarAndCircuitScoring
            circuitScoreData={circuitScoreData}
            linearProgressBarData={linearProgressBarData}
            handleCircuitModalOpen={handleCircuitClickModalOpen}
        />
    );
};
