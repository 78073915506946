import React from "react";
import { FormattedMessage } from "react-intl";

import { useModal } from "@/context/modal/modal";

import {
    AddUserErrorMessage,
    ButtonsWrapper,
    Description,
    ModalContentWrapperDiv,
    NotAddedUser,
    NotAddedUsersWrapper,
    StyledCancelButton,
    StyledConfirmButton,
    Title,
} from "../../modal-contents.styles";

import AddUserModalContent from "../add-user/add-user-modal-content";
import type { AddMultipleUsersSuccessfulAPIResponse } from "../add-user/add-user-modal-content";

interface ConfirmationModalProps {
    title: string;
    titleIntlValues?: {};
    description?: string;
    descriptionIntlValues?: {};
    isAddUserConfirmationModal?: boolean;
    addMultipleUsersSuccessfulAPIResponse?: AddMultipleUsersSuccessfulAPIResponse;
}

const ConfirmationModalContent = ({
    title,
    titleIntlValues,
    description,
    descriptionIntlValues,
    isAddUserConfirmationModal = false,
    addMultipleUsersSuccessfulAPIResponse,
}: ConfirmationModalProps) => {
    const { closeModal, openModal } = useModal();

    const handleAddMoreUsersClick = () => {
        openModal({
            content: (
                <AddUserModalContent
                    title="users:users-table:modal-contents:add-user:title"
                    description="users:users-table:modal-contents:add-user:description"
                />
            ),
        });
    };

    const handleAddUsersConfirmationModalClose = () => {
        closeModal();
    };

    const someUsersNotAdded =
        addMultipleUsersSuccessfulAPIResponse &&
        addMultipleUsersSuccessfulAPIResponse?.notCreatedUsers.length > 0;
    const usersNotAdded = addMultipleUsersSuccessfulAPIResponse?.notCreatedUsers;

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id={title} values={titleIntlValues} />
            </Title>
            {description && (
                <Description>
                    <FormattedMessage id={description} values={descriptionIntlValues} />
                </Description>
            )}
            {!isAddUserConfirmationModal && (
                <ButtonsWrapper>
                    <StyledConfirmButton onClick={closeModal} fullWidth>
                        <FormattedMessage id="users:users-table:modal-contents:confirmation-modal:primary-button" />
                    </StyledConfirmButton>
                </ButtonsWrapper>
            )}

            {isAddUserConfirmationModal && (
                <>
                    {someUsersNotAdded && (
                        <>
                            <AddUserErrorMessage>
                                <FormattedMessage id="users:users-table:modal-contents:add-user:confirmation-modal:multiple-users-error" />
                            </AddUserErrorMessage>

                            <NotAddedUsersWrapper>
                                {someUsersNotAdded &&
                                    usersNotAdded?.map((user, index) => {
                                        return (
                                            <React.Fragment key={`${user.emailAddress}${index}`}>
                                                <NotAddedUser>{user.emailAddress}</NotAddedUser>
                                                {index < usersNotAdded.length - 1 && (
                                                    <NotAddedUser>,&nbsp;</NotAddedUser>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                            </NotAddedUsersWrapper>
                        </>
                    )}

                    <ButtonsWrapper>
                        <StyledConfirmButton onClick={handleAddMoreUsersClick} fullWidth>
                            <FormattedMessage id="users:users-table:modal-contents:add-user:confirmation-modal:primary-button" />
                        </StyledConfirmButton>
                        <StyledCancelButton
                            onClick={handleAddUsersConfirmationModalClose}
                            fullWidth
                        >
                            <FormattedMessage id="users:users-table:modal-contents:confirmation-modal:primary-button" />
                        </StyledCancelButton>
                    </ButtonsWrapper>
                </>
            )}
        </ModalContentWrapperDiv>
    );
};

export default ConfirmationModalContent;
