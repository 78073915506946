import type { IconProps } from "@/types/icon";

const ChevronRightSquareCornersIcon24 = ({
    fill = "#F2F2F2",
    width = 24,
    height = 24,
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill={fill}
        {...remainingProps}
    >
        <path
            d="M8.5 17.4273L9.5677 18.5L15.5 12.5L9.5617 6.5L8.5 7.57273L13.3766 12.5L8.5 17.4273Z"
            fill={fill}
        />
    </svg>
);

export default ChevronRightSquareCornersIcon24;
