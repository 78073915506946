import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { useMsal } from "@azure/msal-react";

import { useFeatureToggles } from "@/context/feature-toggles";
import { useModal } from "@/context/modal/modal";
import { useUser } from "@/context/user";

import { PrimaryButton, SecondaryButton } from "@/pages/shared/shared.styles";

import { VRIntlProviderComponent } from "@/components/providers/intl-provider";
import DeleteAccountAdminModal from "@/components/ui/modal-with-context/modal-contents/delete-account-confirmation/delete-account-admin-modal-content";
import DeleteAccountModal from "@/components/ui/modal-with-context/modal-contents/delete-account-confirmation/delete-account-modal-content";
import Page, { PageLoader } from "@/components/ui/page";

import { Features } from "@/enums/features";
import { Roles } from "@/enums/user";

import { Container, Title, Wrapper } from "./settings.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const SettingsPage = () => {
    const { instance } = useMsal();
    const { state: user } = useUser();
    const { isFeatureActive } = useFeatureToggles();
    const { openModal, setWidth, setMinHeight } = useModal();

    const handleChangePassword = useCallback(() => {
        instance.loginRedirect({
            authority: process.env.REACT_APP_MSAL_PASSWORD_CHANGE_AUTHORITY,
            scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE],
        });
    }, [instance]);

    const handleDeleteAccount = () => {
        setWidth("540px");
        setMinHeight("352px");
        openModal(
            //each client decides which roles are allowed to delete accounts
            user.role === Roles.Admin || isFeatureActive(Features.BackofficeDeleteAccount)
                ? {
                      content: (
                          <DeleteAccountAdminModal title="account-delete:modal-contents:admin:description" />
                      ),
                  }
                : {
                      content: (
                          <DeleteAccountModal
                              title="account-delete:modal-contents:title"
                              description="account-delete:modal-contents:description"
                          />
                      ),
                  },
        );
    };

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="settings" fallback={<PageLoader />}>
            <Page title="Settings">
                <Wrapper>
                    <Container>
                        <Title>
                            <FormattedMessage id={"settings:change-password:title"} />
                        </Title>
                        <PrimaryButton
                            onClick={handleChangePassword}
                            data-test-id="change-password"
                            fullWidth
                        >
                            <FormattedMessage id={"settings:change-password"} />
                        </PrimaryButton>
                    </Container>

                    <Container>
                        <Title>
                            <FormattedMessage id={"settings:delete-account:title"} />
                        </Title>
                        <SecondaryButton
                            onClick={handleDeleteAccount}
                            data-test-id="delete-account"
                            fullWidth
                        >
                            <FormattedMessage id={"settings:delete-account"} />
                        </SecondaryButton>
                    </Container>
                </Wrapper>
            </Page>
        </VRIntlProviderComponent>
    );
};

export default SettingsPage;
