import { useQuery } from "react-query";

import { getImprovementScore } from "@/api/promo";

import {
    IconsWrapper,
    PromoWrapper,
    StyledAmpersandText,
    StyledHEATLogoImg,
    StyledMainText,
    StyledScoreText,
    StyledSecondaryText,
    StyledVBSLogoImg,
    StyledVRAILogoImg,
    VRAILogoWrapper,
} from "./promo.styles";

const Promo = () => {
    const options = {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: true,
    };

    const { data } = useQuery([getImprovementScore], getImprovementScore, options);

    const HEATLogo = data?.heatLogoUrl;
    const SIMLogo = data?.simLogoUrl;
    const VRAILogo = data?.vraiLogoUrl;

    const mainText = data?.longDescription;
    const improvementScore = data?.dataset?.length > 0 ? data?.dataset?.[0].result : "0";
    const unit = data?.dataset?.length > 0 ? data?.dataset?.[0].unit : "%";
    const secondaryText = data?.shortDescription;

    return (
        <PromoWrapper>
            <IconsWrapper>
                <StyledVBSLogoImg src={SIMLogo} />
                <StyledAmpersandText>&</StyledAmpersandText>
                <StyledHEATLogoImg src={HEATLogo} />
            </IconsWrapper>
            {mainText && <StyledMainText>{mainText}</StyledMainText>}
            {improvementScore && (
                <StyledScoreText>
                    {improvementScore}
                    {unit}
                </StyledScoreText>
            )}
            {secondaryText && <StyledSecondaryText>{secondaryText}</StyledSecondaryText>}
            <VRAILogoWrapper>
                <StyledVRAILogoImg src={VRAILogo} />
            </VRAILogoWrapper>
        </PromoWrapper>
    );
};

export default Promo;
