import { css } from "styled-components";

export default css`
    :root {
        //mobile first approach
        --heat-header-height: 4.25rem; // 68px
        --navigation-width: 100%;

        --sessions-page-header-height: 3.5rem; // 56px
        --sessions-page-date-div-height: calc(
            var(--heat-header-height) + var(--sessions-page-header-height)
        );

        @media (min-width: 768px) {
            --heat-header-height: 5rem; // 80px
            --navigation-width: 14.25rem; // 228px

            --sessions-page-header-height: 5.5rem; // 88px
        }

        @media (min-width: 1024px) {
        }

        @media (min-width: 1280px) {
            --navigation-width: 14rem; // 224px
        }

        @media (min-width: 1440px) {
            --navigation-width: 18rem; // 288px
        }

        @media (min-width: 1920px) {
            --heat-header-height: 6.1875rem; // 99px

            --sessions-page-header-height: 5.5rem; // 88px
        }
    }
`;
