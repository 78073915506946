import styled from "styled-components";

import base from "@/themes/base/base";

export const Main = styled.div`
    position: relative;
    width: 100%;
`;

export const ButtonScenarioOverview = styled.button`
    font-size: 0.95rem;
    position: absolute;
    top: -2.9rem;
    right: 0;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: ${({ theme }) => theme.colors.legacy.gray[200]};
    padding: 0.5rem 1rem;
    border-radius: 4.25rem;
    border: 0;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
        background-color: ${({ theme }) => theme.colors.legacy.gray[150]};
    }
`;

export const LoaderContainer = styled.div`
    display: flex;
    height: 10rem;
    justify-content: center;
    align-items: center;
`;

export const Divider = styled.div`
    background-color: ${base.colors.neutral.grey[900]};
    width: 0.0625rem;
    height: 4rem;
`;

export const Title = styled.span`
    ${base.typography.extraLargeMedium};

    display: flex;
    gap: 0.4rem;
    align-items: center;
`;

export const SubTitle = styled.span`
    ${base.typography.baseRegular};

    color: #999;
    text-align: center;
`;

export const UserAndSessionInfoWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

export const NameAndIconDiv = styled.div`
    display: flex;
    gap: 0.25rem;
`;

export const DateDiv = styled.div`
    display: flex;
    gap: 0.25rem;
`;

export const TraineeNameSpan = styled.span``;

export const TimeDiv = styled.div`
    display: flex;
    gap: 0.25rem;
`;

export const BulletPoint = styled.span``;

export const DurationDiv = styled.span``;

//Custom Radio styles
export const Label = styled.label`
    ${base.typography.smallMedium};

    display: flex;
    align-items: self-end;
    gap: 0.125rem;
    cursor: pointer;
    user-select: none;

    &.disabled {
        opacity: 0.3;
        cursor: auto;
    }
`;

export const TitleAndRadioWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const RadioWrapper = styled.div`
    display: flex;
    gap: 0.75rem;

    .no-lookout {
        visibility: hidden;
    }

    .unchecked {
        font-weight: 400;
    }
`;

export const Input = styled.input`
    display: none;
`;
