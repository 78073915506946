import type { IconProps } from "@/types/icon";

const ExpandDiagonalIcon24 = ({
    width = 24,
    height = 24,
    fill = "#f2f2f2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path d="M5 19V13H7V17H11V19H5ZM17 11V7H13V5H19V11H17Z" fill={fill} />
    </svg>
);

export default ExpandDiagonalIcon24;
