import React from "react";

import { createRoot } from "react-dom/client";

import App from "@/app";

import "./index.css";
import "./styles/main.css";

const container = document.getElementById("root"); //to get the root element

if (container) {
    const root = createRoot(container); //create a root using createRoot

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );
}
