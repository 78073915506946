import { useCallback, useEffect, useRef } from "react";
import type { RefObject } from "react";

import { useModal } from "@/context/modal/modal";

import { CrossIcon32 } from "@/components/icons";
import { VRIntlProviderComponent } from "@/components/providers/intl-provider";

import {
    CloseContainer,
    ModalBackdrop,
    ModalContent,
    CloseIconContainer,
    Content,
} from "./modal-with-context.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

interface ModalProps {
    focusRef?: RefObject<HTMLInputElement>;
}
interface ModalWithContextProps {
    focusRef?: RefObject<HTMLInputElement>;
}

const Modal = ({ focusRef }: ModalProps) => {
    const {
        isModalOpen,
        closeModal,
        modalContent,
        width,
        height,
        minWidth,
        minHeight,
        backgroundColor,
        borderRadius,
        padding,
        boxShadow,
    } = useModal();

    const modalRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutsideModal = useCallback(
        (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                closeModal();
            }
        },
        [closeModal, modalRef],
    );

    const handleEscKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                closeModal();
            }
        },
        [closeModal],
    );

    useEffect(() => {
        if (isModalOpen) {
            document.addEventListener("mousedown", handleClickOutsideModal);
            document.addEventListener("keydown", handleEscKeyPress);
            document.body.style.overflow = "hidden"; //to prevent scroll on the background page

            if (focusRef && focusRef.current) {
                focusRef.current.focus();
            }
        } else {
            document.removeEventListener("mousedown", handleClickOutsideModal);
            document.removeEventListener("keydown", handleEscKeyPress);
            document.body.style.overflow = "auto";
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideModal);
            document.removeEventListener("keydown", handleEscKeyPress);
            document.body.style.overflow = "auto";
        };
    }, [isModalOpen]);

    if (!isModalOpen) return null;

    return (
        <ModalBackdrop>
            <ModalContent
                ref={modalRef}
                width={width}
                height={height}
                minWidth={minWidth}
                minHeight={minHeight}
                backgroundColor={backgroundColor}
                borderRadius={borderRadius}
                padding={padding}
                boxShadow={boxShadow}
            >
                <CloseContainer>
                    <CloseIconContainer onClick={closeModal}>
                        <CrossIcon32 />
                    </CloseIconContainer>
                </CloseContainer>
                <Content>{modalContent}</Content>
            </ModalContent>
        </ModalBackdrop>
    );
};

const ModalWithContext = ({ focusRef }: ModalWithContextProps) => {
    return (
        <VRIntlProviderComponent localeFn={localeFn} id="modal-with-context" fallback={null}>
            <Modal focusRef={focusRef} />
        </VRIntlProviderComponent>
    );
};

export default ModalWithContext;
