import type { IconProps } from "@/types/icon";

const UsersIcon = ({ width = 22, height = 14, fill = "#F2F2F2", ...remainingProps }: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 22 14`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99 3C9.99 4.66 8.66 6 7 6C5.34 6 4 4.66 4 3C4 1.34 5.34 0 7 0C8.66 0 9.99 1.34 9.99 3ZM17.99 3C17.99 4.66 16.66 6 15 6C13.34 6 12 4.66 12 3C12 1.34 13.34 0 15 0C16.66 0 17.99 1.34 17.99 3ZM7 8C4.67 8 0 9.17 0 11.5V14H14V11.5C14 9.17 9.33 8 7 8ZM14.03 8.05C14.38 8.02 14.71 8 15 8C17.33 8 22 9.17 22 11.5V14H16V11.5C16 10.02 15.19 8.89 14.03 8.05Z"
            fill={fill}
        />
    </svg>
);

export default UsersIcon;
