import { useEffect, useState } from "react";

import { cloneDeep } from "lodash";

import {
    type ChartData,
    type ChartState,
    type RangeBarData,
    type RangeBarDataSet,
} from "@/types/timeline-chart";

import { areaOptions, scatterOptions } from "./chart-config";
import { CustomAreaChart, ScatterChart } from "./styles";
import {
    convertDataToApex,
    createEmptyData,
    createYawData,
    getMaxAxis,
    getMaxValue,
    getMinAxis,
    transformAnnotationData,
} from "./utils";

const BUFFER_VALUE = 0.1;

type CombinedChartProps = {
    chartStateByType: {
        [k: string]: boolean | undefined;
    };
    areaChartData: ChartData[];
    scatterChartData: ChartData[];
    rangeBarData: RangeBarData[];
};

const getAreaSeriesData = (chartData: ChartData[]) => {
    const finalData = chartData.map((chart: ChartData, index: number) => {
        return {
            name: chart.name,
            label: chart.radioLabel,
            data: convertDataToApex(chart.data) || [],
            yAxis: index,
            color: chart.color,
            unit: chart.unit,
            yawData: createYawData(chart.data) || [],
        };
    });

    //to create chart with empty-data to show the annotation when all the area chart is toggled off
    finalData.push({
        name: "emptydata",
        label: "emptydata",
        data: createEmptyData(chartData[0].data),
        yAxis: 1,
        color: "transparent",
        unit: "",
        yawData: [],
    });

    return finalData;
};

const getAreaOptionsData = (annotationData: RangeBarData[], chartData: ChartData[]) => {
    const optionsCopy = cloneDeep(areaOptions);

    const transformedAnnotationData = transformAnnotationData(annotationData);

    //To show the annotation in areachart example: Blindspot, Siren, Harshbreaking
    const xAxisAnnotations = transformedAnnotationData?.flatMap((dataSet: any) => {
        return dataSet?.data?.map((point: RangeBarDataSet) => {
            return {
                x: new Date(point.StartTime).getTime(),
                x2: new Date(point.EndTime).getTime(),
                id: dataSet.name,
                fillColor: dataSet.color,
                opacity: 0.3,
                strokeDashArray: 0,
                borderColor: "none",
            };
        });
    });

    //to set yaxis for area chart
    const yAxisData = chartData?.map((chart: ChartData) => {
        return {
            title: {
                name: chart.name,
            },
            min: 0,
            max: getMaxValue(chart.data) + BUFFER_VALUE,
            show: false,
        };
    });

    optionsCopy.yaxis = cloneDeep(yAxisData);

    if (xAxisAnnotations?.length !== 0) {
        optionsCopy.annotations.xaxis = cloneDeep(xAxisAnnotations);
    }

    return optionsCopy;
};

//set scatter chart axis to area chart data
const getScatterOptionsData = (chartData: ChartData[]) => {
    const finalOptions = cloneDeep(scatterOptions);
    const chartAxisData = chartData.flatMap((chart: ChartData) => chart.data);

    finalOptions.xaxis.min = getMinAxis(chartAxisData);
    finalOptions.xaxis.max = getMaxAxis(chartAxisData);

    return finalOptions;
};

//create series data from API
const getScatterSeriesData = (scatterData: ChartData[], chartData: ChartData[]) => {
    const chartAxisData = chartData.flatMap((chart: ChartData) => chart.data);
    const minAxis = getMinAxis(chartAxisData);

    const finalData = scatterData?.map((chart: ChartData) => {
        return {
            data: convertDataToApex(chart?.data) || [],
            color: chart?.color,
            name: chart.name,
            radioLabel: chart.radioLabel,
            startTime: new Date(minAxis),
        };
    });

    return finalData;
};

//filter series data on radio toggle
const filterSeriesData = (data: any, chartStateByType: ChartState) => {
    let filteredData = cloneDeep(data);
    filteredData = filteredData.map((series: any) => {
        if (chartStateByType[series.name] === false) {
            series.data = [];
        }

        return series;
    });

    return filteredData;
};

//filter annotations data on radio toggle
const filterOptionsData = (areaOptions: any, chartStateByType: ChartState) => {
    const filteredOptions = cloneDeep(areaOptions);
    let annotations = filteredOptions?.annotations?.xaxis;

    annotations = annotations.filter(
        (annotation: any) => chartStateByType[annotation.id] !== false,
    );
    filteredOptions.annotations.xaxis = annotations;

    return filteredOptions;
};

const CombinedChartFiring = ({
    chartStateByType,
    areaChartData,
    rangeBarData,
    scatterChartData,
}: CombinedChartProps) => {
    const areaSeries = getAreaSeriesData(areaChartData);
    const areaOptions = getAreaOptionsData(rangeBarData, areaChartData);

    const scatterOptions = getScatterOptionsData(areaChartData);
    const scatterSeriesData = getScatterSeriesData(scatterChartData, areaChartData);

    const [areaSeriesData, setAreaSeriesData] = useState(areaSeries);
    const [scatterSeries, setScatterSeries] = useState(scatterSeriesData);
    const [areaOptionsData, setAreaOptionsData] = useState(areaOptions);

    useEffect(() => {
        //to disable area chart which is toggled off
        setAreaSeriesData(filterSeriesData(areaSeries, chartStateByType));
        setScatterSeries(filterSeriesData(scatterSeriesData, chartStateByType));
        setAreaOptionsData(filterOptionsData(areaOptions, chartStateByType));
    }, [chartStateByType]);

    return (
        <>
            <ScatterChart
                options={scatterOptions}
                series={scatterSeries}
                type="scatter"
                height={40}
            />

            <CustomAreaChart
                options={areaOptionsData}
                series={areaSeriesData}
                type="area"
                height={230}
            />
        </>
    );
};

export default CombinedChartFiring;
