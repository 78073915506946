import { useCallback } from "react";

import { useSessionDetailsContext } from "@/context/session-details/session-details";

import CircuitTopStats from "@/pages/session-detail/components/circuit-top-stats";
import { EyeTracking } from "@/pages/session-detail/components/eye-tracking";
import ProgressBarAndCircuitScoring from "@/pages/session-detail/components/scoring/components/progress-bar-and-circuit-scoring/progress-bar-and-circuit-scoring";
import {
    ComponentsScrollableWrapper,
    ModalContentWrapper,
    SemiCircularGaugeAndCircuitScoringWrapper,
} from "@/pages/session-detail/components/scoring/scoring.styles";
import type {
    CircuitParameter,
    HandleCircuitClickProps,
    LinearProgressBarDataSet,
    ProgressBarDataSet,
} from "@/pages/session-detail/components/scoring/scoring.types";
import { SemiCircularGauge } from "@/pages/session-detail/components/semi-circular-gauge";

import {
    ArrowIconWrapper,
    PopUp,
    PopUpListItem,
    Toggle,
} from "@/components/filters/filters.styles";
import { ChevronDownSquareCornersIcon24, DirectionArrowTopRightIcon24 } from "@/components/icons";
import PageHeader from "@/components/ui/page-header/page-header";

import { DropdownFilterVariant } from "@/types/filters";

import {
    FlyingCircuitAndTimelineChartWrapper,
    RightContentWrapperDiv,
    StyledFlyingCircuit,
    StyledTimelineChartFlight,
} from "../../modal-contents/modal-contents.styles";

interface CircuitScoringModalContentTypes {
    scrollableContainerHeight: number;
    logoUrl: string;
    getSessionSubtitleDetails: JSX.Element;
    circuitScoreData: ProgressBarDataSet;
    circuitParameters: LinearProgressBarDataSet[];
    handleCircuitModalOpen: ({ circuit, isReportsButtonClicked }: HandleCircuitClickProps) => void;
}

const CircuitScoringModalContent = ({
    scrollableContainerHeight,
    logoUrl,
    getSessionSubtitleDetails,
    circuitScoreData,
    circuitParameters,
    handleCircuitModalOpen,
}: CircuitScoringModalContentTypes) => {
    const {
        isFilterOpen,
        selectedFilterOption,
        setSelectedIndex,
        filterOptions,
        selectedIndex,
        setIsFilterOpen,
        setSelectedFilterOption,
    } = useSessionDetailsContext();

    //toggle filters button
    const handleFilterButtonToggle = useCallback(
        (event: any) => {
            event.stopPropagation();

            setIsFilterOpen(!isFilterOpen);
        },
        [isFilterOpen],
    );

    //handles filter option click
    const handleFilterOptionClick = (event: any, index: number, option: CircuitParameter) => {
        event.stopPropagation();
        setSelectedIndex(index);
        setSelectedFilterOption(option);
        setIsFilterOpen(false); //to close filters when option is selected
    };

    return (
        <ModalContentWrapper>
            <PageHeader
                title={selectedFilterOption?.label || "Circuit"}
                logoUrl={logoUrl}
                subtitleDetailText={getSessionSubtitleDetails}
                rightContent={
                    <RightContentWrapperDiv>
                        <Toggle
                            isOpen={isFilterOpen}
                            onClick={handleFilterButtonToggle}
                            variant={DropdownFilterVariant.Primary}
                        >
                            <DirectionArrowTopRightIcon24 />
                            {selectedFilterOption?.label}
                            <ArrowIconWrapper isOpen={isFilterOpen}>
                                <ChevronDownSquareCornersIcon24 />
                            </ArrowIconWrapper>
                        </Toggle>
                        {isFilterOpen && (
                            <PopUp
                                id="modal-filters-popup-id"
                                variant={DropdownFilterVariant.Primary}
                                popUpWidth="16.125rem"
                            >
                                {filterOptions?.map((option: CircuitParameter, index: number) => {
                                    return (
                                        <PopUpListItem
                                            variant={DropdownFilterVariant.Primary}
                                            isSelected={selectedIndex === index}
                                            key={option.label}
                                            onClick={(event) => {
                                                handleFilterOptionClick(event, index, option);
                                            }}
                                        >
                                            {option.label}
                                        </PopUpListItem>
                                    );
                                })}
                            </PopUp>
                        )}
                    </RightContentWrapperDiv>
                }
            />
            <ComponentsScrollableWrapper
                //id is needed to calculate the scrollable container's height
                id="components-scrollable-wrapper"
                scrollableContainerHeight={scrollableContainerHeight}
            >
                <SemiCircularGaugeAndCircuitScoringWrapper>
                    <SemiCircularGauge />
                    <ProgressBarAndCircuitScoring
                        isIndividualCircuit
                        circuitScoreData={circuitScoreData}
                        circuitParameters={circuitParameters}
                        handleCircuitModalOpen={handleCircuitModalOpen}
                        isInsideModal
                    />
                </SemiCircularGaugeAndCircuitScoringWrapper>
                <CircuitTopStats />
                <FlyingCircuitAndTimelineChartWrapper>
                    <StyledFlyingCircuit />
                    <StyledTimelineChartFlight />
                </FlyingCircuitAndTimelineChartWrapper>
                <EyeTracking />
            </ComponentsScrollableWrapper>
        </ModalContentWrapper>
    );
};

export default CircuitScoringModalContent;
