//Pilot data 2 for 3D visualization
export const data4 = [
    { lat: 0.929210045511249, lon: -0.0789108927312713, alt: 40.0000044054247 },
    { lat: 0.929210045511249, lon: -0.0789108927312713, alt: 40.0000044054247 },
    { lat: 0.929210045511249, lon: -0.0789108927312713, alt: 40.0000044054247 },
    { lat: 0.929210045511249, lon: -0.0789108927312713, alt: 40.0000044054247 },
    { lat: 0.929210045852488, lon: -0.0789108922365409, alt: 39.8460554954518 },
    { lat: 0.929210045884842, lon: -0.0789108921897204, alt: 39.6814981982761 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045884951, lon: -0.0789108921902102, alt: 39.8266146545732 },
    { lat: 0.929210045885312, lon: -0.0789108921893775, alt: 39.8677832737389 },
    { lat: 0.92921004588529, lon: -0.0789108921894483, alt: 39.8765377239594 },
    { lat: 0.929210045885108, lon: -0.0789108921896846, alt: 39.8755372829049 },
    { lat: 0.929210046022351, lon: -0.0789108919906494, alt: 39.8785174898503 },
    { lat: 0.929210046345079, lon: -0.0789108915225833, alt: 39.8791161822321 },
    { lat: 0.929210046650951, lon: -0.0789108910789085, alt: 39.879184801804 },
    { lat: 0.929210046988676, lon: -0.0789108905890026, alt: 39.8796054022297 },
    { lat: 0.929210047405391, lon: -0.0789108899845105, alt: 39.8805082299508 },
    { lat: 0.929210048230925, lon: -0.0789108887871404, alt: 39.8807375238371 },
    { lat: 0.929210053937178, lon: -0.078910880512329, alt: 39.8810940393951 },
    { lat: 0.929210073665431, lon: -0.0789108519042461, alt: 39.8813031163243 },
    { lat: 0.929210105647682, lon: -0.0789108055293448, alt: 39.880754671055 },
    { lat: 0.929210140319135, lon: -0.0789107552483561, alt: 39.880754277879 },
    { lat: 0.929210172986881, lon: -0.0789107079021254, alt: 39.8807497438159 },
    { lat: 0.929210202558335, lon: -0.0789106650683148, alt: 39.8808356163762 },
    { lat: 0.929210230175347, lon: -0.0789106250454219, alt: 39.8808953387214 },
    { lat: 0.929210256188769, lon: -0.0789105873297812, alt: 39.8807617300923 },
    { lat: 0.929210281059453, lon: -0.0789105512679593, alt: 39.8808075260495 },
    { lat: 0.929210307071883, lon: -0.078910513550915, alt: 39.8810205834443 },
    { lat: 0.92921033001218, lon: -0.0789104802840487, alt: 39.8842176346678 },
    { lat: 0.929210330391631, lon: -0.0789104797334774, alt: 39.8814338606362 },
    { lat: 0.929210330749365, lon: -0.0789104792144057, alt: 39.8800223091975 },
    { lat: 0.929210331090687, lon: -0.0789104787191138, alt: 39.8801295988825 },
    { lat: 0.929210331448799, lon: -0.0789104781994235, alt: 39.8799710578694 },
    { lat: 0.929210331800624, lon: -0.0789104776889177, alt: 39.8800019400231 },
    { lat: 0.929210332419608, lon: -0.0789104767903525, alt: 39.8831297248108 },
    { lat: 0.92921033388239, lon: -0.0789104746661642, alt: 39.8880704393599 },
    { lat: 0.92921033593978, lon: -0.0789104716796464, alt: 39.8914028434302 },
    { lat: 0.929210337975124, lon: -0.0789104687240795, alt: 39.8909824876089 },
    { lat: 0.929210340144902, lon: -0.0789104655707696, alt: 39.891268843375 },
    { lat: 0.92921034836101, lon: -0.0789104536475567, alt: 39.8905018850082 },
    { lat: 0.929210481881345, lon: -0.0789102601520049, alt: 39.8862023891411 },
    { lat: 0.929210845984409, lon: -0.0789097385300657, alt: 39.887192196968 },
    { lat: 0.929211377252134, lon: -0.0789089876757077, alt: 39.8874278463077 },
    { lat: 0.929211907703296, lon: -0.0789082497018339, alt: 39.8886813039873 },
    { lat: 0.929212421432963, lon: -0.0789074928909247, alt: 39.8843836744379 },
    { lat: 0.929212901668355, lon: -0.0789067857691341, alt: 39.8840879123063 },
    { lat: 0.929213386825542, lon: -0.0789060828228423, alt: 39.883836963727 },
    { lat: 0.929213833273267, lon: -0.0789054467848391, alt: 39.883531455117 },
    { lat: 0.929214277541967, lon: -0.0789048262125935, alt: 39.883478101172 },
    { lat: 0.929214704166676, lon: -0.0789042335237248, alt: 39.8834330830804 },
    { lat: 0.929215104961224, lon: -0.0789036799119217, alt: 39.884058278695 },
    { lat: 0.929215498634794, lon: -0.0789031439861092, alt: 39.8865637179804 },
    { lat: 0.929215757362264, lon: -0.078902761634424, alt: 39.8932660716874 },
    { lat: 0.929215812788855, lon: -0.0789026828686192, alt: 39.8838805373031 },
    { lat: 0.929215813181864, lon: -0.0789026823111309, alt: 39.879654270221 },
    { lat: 0.929215813584276, lon: -0.0789026817405213, alt: 39.8806607679827 },
    { lat: 0.929215814018061, lon: -0.0789026811254452, alt: 39.8810902842687 },
    { lat: 0.929215814494663, lon: -0.0789026804496838, alt: 39.8812777291084 },
    { lat: 0.929215814980761, lon: -0.0789026797604535, alt: 39.8812828214929 },
    { lat: 0.929215815447612, lon: -0.0789026790984909, alt: 39.8810408510602 },
    { lat: 0.92921581593972, lon: -0.0789026784006571, alt: 39.8820230665321 },
    { lat: 0.929215816398624, lon: -0.0789026777498904, alt: 39.8805492746754 },
    { lat: 0.929215816814573, lon: -0.0789026771601013, alt: 39.8808249549575 },
    { lat: 0.929215817270103, lon: -0.078902676514123, alt: 39.8810435966515 },
    { lat: 0.929215817694416, lon: -0.0789026759124771, alt: 39.8809670206621 },
    { lat: 0.929215818154716, lon: -0.0789026752597802, alt: 39.8812054985318 },
    { lat: 0.929215818626012, lon: -0.0789026745914955, alt: 39.8809230087785 },
    { lat: 0.929215819120387, lon: -0.0789026738902695, alt: 39.8824432009358 },
    { lat: 0.92921581958617, lon: -0.0789026732295688, alt: 39.8806702044101 },
    { lat: 0.929215819996864, lon: -0.0789026726471802, alt: 39.8805688537835 },
    { lat: 0.929215820405884, lon: -0.0789026720672004, alt: 39.8808916562662 },
    { lat: 0.929215820851249, lon: -0.0789026714356933, alt: 39.88111122356 },
    { lat: 0.929215821314881, lon: -0.0789026707783, alt: 39.8812235636541 },
    { lat: 0.92921582179319, lon: -0.0789026701001055, alt: 39.8812210533035 },
    { lat: 0.929215822319478, lon: -0.078902669353627, alt: 39.8825469082578 },
    { lat: 0.929215822744117, lon: -0.078902668751457, alt: 39.8807382106807 },
    { lat: 0.929215823175242, lon: -0.0789026681401291, alt: 39.8808815661998 },
    { lat: 0.929215823597245, lon: -0.0789026675417461, alt: 39.881019425483 },
    { lat: 0.929215824046424, lon: -0.0789026669048278, alt: 39.8811395132818 },
    { lat: 0.92921582450612, lon: -0.0789026662530053, alt: 39.8812069480824 },
    { lat: 0.929215824960814, lon: -0.0789026656082695, alt: 39.8814134911417 },
    { lat: 0.929215825444176, lon: -0.0789026649228308, alt: 39.8813912083138 },
    { lat: 0.929215825894059, lon: -0.0789026642849103, alt: 39.8810412430097 },
    { lat: 0.929215826340544, lon: -0.0789026636518111, alt: 39.8810720090543 },
    { lat: 0.929215826774618, lon: -0.0789026630363095, alt: 39.8811235326778 },
    { lat: 0.929215827226363, lon: -0.0789026623957568, alt: 39.8811563614448 },
    { lat: 0.92921582768067, lon: -0.078902661751588, alt: 39.8811635533446 },
    { lat: 0.929215828118027, lon: -0.0789026611314375, alt: 39.8811530572351 },
    { lat: 0.929215828602788, lon: -0.0789026604439724, alt: 39.8818488927172 },
    { lat: 0.929215829071804, lon: -0.0789026597787434, alt: 39.8822102532943 },
    { lat: 0.929215829526785, lon: -0.078902659133418, alt: 39.8822204485508 },
    { lat: 0.929215829989472, lon: -0.0789026584770448, alt: 39.8810989994758 },
    { lat: 0.929215830374553, lon: -0.0789026579309758, alt: 39.8807517851822 },
    { lat: 0.929215830808605, lon: -0.0789026573154725, alt: 39.8810890129857 },
    { lat: 0.92921583127384, lon: -0.0789026566557235, alt: 39.8812005007372 },
    { lat: 0.929215831760465, lon: -0.0789026559656173, alt: 39.8820721623616 },
    { lat: 0.929215832191447, lon: -0.0789026553544732, alt: 39.8809788154115 },
    { lat: 0.929215832694959, lon: -0.0789026546403386, alt: 39.8818533209658 },
    { lat: 0.929215833146256, lon: -0.0789026540002397, alt: 39.8814684259852 },
    { lat: 0.929215833567626, lon: -0.078902653402617, alt: 39.8805542096732 },
    { lat: 0.929215833987117, lon: -0.0789026528077661, alt: 39.8808807359305 },
    { lat: 0.929215834431172, lon: -0.0789026521780968, alt: 39.8811383901132 },
    { lat: 0.92921583487638, lon: -0.0789026515468011, alt: 39.8812866907991 },
    { lat: 0.92921583535386, lon: -0.078902650869735, alt: 39.8815757949267 },
    { lat: 0.929215835826881, lon: -0.078902650198975, alt: 39.8810690100989 },
    { lat: 0.929215836259635, lon: -0.0789026495853434, alt: 39.881140883401 },
    { lat: 0.929215836705667, lon: -0.0789026489528823, alt: 39.8811720663937 },
    { lat: 0.929215837159408, lon: -0.0789026483094842, alt: 39.8811942432164 },
    { lat: 0.929215837634234, lon: -0.0789026476360447, alt: 39.8825055233805 },
    { lat: 0.929215838106466, lon: -0.0789026469662273, alt: 39.8806413760984 },
    { lat: 0.929215838524668, lon: -0.078902646373204, alt: 39.8808654495215 },
    { lat: 0.929215838990959, lon: -0.0789026457120217, alt: 39.8811200390778 },
    { lat: 0.929215848590561, lon: -0.0789026321059758, alt: 39.8823794267253 },
    { lat: 0.929215921186948, lon: -0.0789025291808169, alt: 39.8838655459316 },
    { lat: 0.929216133408737, lon: -0.0789022274839574, alt: 39.8860631001174 },
    { lat: 0.929216538141134, lon: -0.0789016563341657, alt: 39.8867352570151 },
    { lat: 0.929217081017046, lon: -0.0789008906477922, alt: 39.8873549437478 },
    { lat: 0.929217716198566, lon: -0.0788999904346612, alt: 39.8874035167887 },
    { lat: 0.929218364880213, lon: -0.078899075721852, alt: 39.8937621175622 },
    { lat: 0.929219003488975, lon: -0.0788981845060936, alt: 39.8915708011493 },
    { lat: 0.929219556791593, lon: -0.0788974286609485, alt: 39.884319375981 },
    { lat: 0.929220099289862, lon: -0.0788966649447844, alt: 39.8860163613632 },
    { lat: 0.92922060819597, lon: -0.0788960404410189, alt: 39.8872735342112 },
    { lat: 0.929221120865724, lon: -0.0788956132822959, alt: 39.8893589390166 },
    { lat: 0.929221596582105, lon: -0.0788954256424633, alt: 39.8847301029416 },
    { lat: 0.929222034088153, lon: -0.0788953957140466, alt: 39.8858304691238 },
    { lat: 0.929222384886482, lon: -0.0788955339353165, alt: 39.8857662947476 },
    { lat: 0.929222663842837, lon: -0.0788957964858359, alt: 39.8855085041221 },
    { lat: 0.92922288613161, lon: -0.0788961713195251, alt: 39.8834736149719 },
    { lat: 0.929223111852508, lon: -0.0788966663872381, alt: 39.8883310784236 },
    { lat: 0.92922332913614, lon: -0.0788970928170098, alt: 39.885750776654 },
    { lat: 0.929223488404734, lon: -0.078897414345416, alt: 39.8903882940487 },
    { lat: 0.929223512715111, lon: -0.0788974617391358, alt: 39.868132000997 },
    { lat: 0.929223512990952, lon: -0.0788974622803331, alt: 39.8788418077495 },
    { lat: 0.929223513283647, lon: -0.078897462854588, alt: 39.879978167446 },
    { lat: 0.929223519387902, lon: -0.0788974748342373, alt: 39.882335781179 },
    { lat: 0.929223595723032, lon: -0.0788976247174716, alt: 39.8850655405445 },
    { lat: 0.929223851328171, lon: -0.0788981293482616, alt: 39.8885909639443 },
    { lat: 0.929224327516895, lon: -0.078899081313937, alt: 39.8928746901 },
    { lat: 0.92922500247793, lon: -0.0789004658759069, alt: 39.8928976661987 },
    { lat: 0.929225871811966, lon: -0.0789022995680567, alt: 39.8996147660704 },
    { lat: 0.929227025798704, lon: -0.0789047007112911, alt: 39.9104168187743 },
    { lat: 0.929228646463395, lon: -0.0789079238069186, alt: 39.9222992336223 },
    { lat: 0.929230571409846, lon: -0.0789116869443224, alt: 39.935278822397 },
    { lat: 0.929232913540018, lon: -0.0789162621319615, alt: 39.9530323060594 },
    { lat: 0.929235628649121, lon: -0.0789214894204087, alt: 39.969623583727 },
    { lat: 0.929238586926901, lon: -0.0789271030061528, alt: 39.9874223731821 },
    { lat: 0.929241994599369, lon: -0.0789335862726222, alt: 40.0068267631412 },
    { lat: 0.929245554145842, lon: -0.0789403998884026, alt: 40.0283044880223 },
    { lat: 0.929249551622657, lon: -0.0789480519819533, alt: 40.0477061372331 },
    { lat: 0.929253729187543, lon: -0.0789560478260738, alt: 40.0751152552035 },
    { lat: 0.929258308145192, lon: -0.0789648353485289, alt: 40.1082689109759 },
    { lat: 0.929263006503444, lon: -0.0789739502185834, alt: 40.5075771776533 },
    { lat: 0.929267898745951, lon: -0.0789835437169194, alt: 44.4312514535365 },
    { lat: 0.929273083461272, lon: -0.0789938164063466, alt: 48.2952460905443 },
    { lat: 0.92927836747677, lon: -0.0790043996277792, alt: 52.8150885106693 },
    { lat: 0.929283817944383, lon: -0.0790155121285839, alt: 59.8167733873478 },
    { lat: 0.929289285429963, lon: -0.0790268570092236, alt: 65.899314462211 },
    { lat: 0.929295040419497, lon: -0.0790390309250022, alt: 77.4717823413409 },
    { lat: 0.9293006505576, lon: -0.0790510648749074, alt: 90.5281919996068 },
    { lat: 0.929306555297361, lon: -0.0790638504543857, alt: 99.6740909311792 },
    { lat: 0.929312442467332, lon: -0.0790767091198022, alt: 105.088530639707 },
    { lat: 0.929318528515601, lon: -0.0790901242788239, alt: 109.811341294729 },
    { lat: 0.929324547759407, lon: -0.0791034933769517, alt: 118.951483248512 },
    { lat: 0.929330739647659, lon: -0.0791172095271283, alt: 132.221480071278 },
    { lat: 0.929336934491008, lon: -0.0791308441587782, alt: 148.892379420986 },
    { lat: 0.929342858499474, lon: -0.0791437995654962, alt: 164.163245185055 },
    { lat: 0.929349014430735, lon: -0.0791571686462733, alt: 177.105954328465 },
    { lat: 0.929355219801666, lon: -0.0791705287251869, alt: 189.248208438301 },
    { lat: 0.929361482531229, lon: -0.0791838645818123, alt: 201.36711293372 },
    { lat: 0.929367604635383, lon: -0.0791966964212773, alt: 214.720479876702 },
    { lat: 0.929374005937811, lon: -0.0792098866544031, alt: 228.852938307035 },
    { lat: 0.929380541767634, lon: -0.079223096083129, alt: 242.948556492428 },
    { lat: 0.929386905928514, lon: -0.0792356877972225, alt: 259.290315094607 },
    { lat: 0.929393572222638, lon: -0.0792486153535836, alt: 276.720154768384 },
    { lat: 0.9294002370903, lon: -0.0792612769011374, alt: 292.155994248455 },
    { lat: 0.929407184315127, lon: -0.0792742226003345, alt: 309.179318742433 },
    { lat: 0.929413900346807, lon: -0.079286522193737, alt: 327.335392616386 },
    { lat: 0.92942096540143, lon: -0.0792992833541363, alt: 346.830095654939 },
    { lat: 0.929427794596828, lon: -0.0793114968810891, alt: 364.935799953891 },
    { lat: 0.929434972278634, lon: -0.0793242153930279, alt: 382.939279926232 },
    { lat: 0.92944217573473, lon: -0.0793368676892107, alt: 400.214737043636 },
    { lat: 0.929449264233823, lon: -0.0793491745582159, alt: 416.775991890939 },
    { lat: 0.929456758112319, lon: -0.0793617174955861, alt: 433.92082894914 },
    { lat: 0.929464235839019, lon: -0.0793734088838859, alt: 451.495619173986 },
    { lat: 0.929472279640365, lon: -0.0793848187167486, alt: 471.842521109679 },
    { lat: 0.929480554573418, lon: -0.0793951942004398, alt: 495.265919355214 },
    { lat: 0.929489293080184, lon: -0.0794047983326514, alt: 521.646077225829 },
    { lat: 0.929498135338312, lon: -0.0794131679844788, alt: 548.397536971908 },
    { lat: 0.92950756987422, lon: -0.0794204666943035, alt: 576.758528548655 },
    { lat: 0.929516941037336, lon: -0.0794260323334913, alt: 605.992805188147 },
    { lat: 0.929526802042407, lon: -0.0794302895431222, alt: 635.090153079798 },
    { lat: 0.929536461374308, lon: -0.0794327842722087, alt: 664.375925184177 },
    { lat: 0.929546453349737, lon: -0.0794336168953825, alt: 695.457442977814 },
    { lat: 0.929556139309306, lon: -0.0794327175252988, alt: 725.445822514722 },
    { lat: 0.929565896415023, lon: -0.0794300634937199, alt: 754.406868751488 },
    { lat: 0.929575170101387, lon: -0.0794259208822386, alt: 778.075886442303 },
    { lat: 0.929584317275975, lon: -0.0794198338413442, alt: 801.942655148532 },
    { lat: 0.929592847144214, lon: -0.0794119819121549, alt: 825.590954592122 },
    { lat: 0.929600667044183, lon: -0.0794024818393528, alt: 848.195454854607 },
    { lat: 0.929607857818341, lon: -0.079391161420775, alt: 869.380936488283 },
    { lat: 0.929614033593505, lon: -0.0793784411835077, alt: 888.97006127039 },
    { lat: 0.929619135771446, lon: -0.0793645518152093, alt: 905.643811752933 },
    { lat: 0.929623226875708, lon: -0.0793494547586175, alt: 916.851615667171 },
    { lat: 0.929625782030975, lon: -0.0793341121897271, alt: 926.484351666773 },
    { lat: 0.929626822845003, lon: -0.0793177695019647, alt: 935.074945461853 },
    { lat: 0.929626189638673, lon: -0.0793019440383733, alt: 940.922482510785 },
    { lat: 0.929623915324416, lon: -0.0792860627926209, alt: 941.901953432574 },
    { lat: 0.929620076713059, lon: -0.0792713440462832, alt: 938.788495289613 },
    { lat: 0.929614779360139, lon: -0.0792574684294334, alt: 930.949745725953 },
    { lat: 0.929608705738714, lon: -0.0792449864144165, alt: 920.568857028308 },
    { lat: 0.9296021185334, lon: -0.0792328756676903, alt: 913.193108490396 },
    { lat: 0.929595450287062, lon: -0.0792205443426177, alt: 904.661625152984 },
    { lat: 0.929589034816192, lon: -0.0792082661452845, alt: 895.11477671506 },
    { lat: 0.929582787123871, lon: -0.0791958108473904, alt: 885.459123296623 },
    { lat: 0.929576746176574, lon: -0.0791833493655065, alt: 877.683361933358 },
    { lat: 0.929570738511721, lon: -0.0791705396845086, alt: 872.493603864418 },
    { lat: 0.929565016580344, lon: -0.0791579403242245, alt: 864.748062281097 },
    { lat: 0.929559389898494, lon: -0.0791450980971466, alt: 855.955374010103 },
    { lat: 0.929553794313316, lon: -0.0791318616888083, alt: 848.78670626882 },
    { lat: 0.929548506073085, lon: -0.0791190594801404, alt: 845.012298197792 },
    { lat: 0.929543150614794, lon: -0.0791059235525316, alt: 841.678906466319 },
    { lat: 0.929538082944762, lon: -0.0790933849917406, alt: 838.371212669081 },
    { lat: 0.929532812843044, lon: -0.0790802499763874, alt: 835.136846840357 },
    { lat: 0.929527890926227, lon: -0.0790679192084304, alt: 835.070449962012 },
    { lat: 0.929522854717084, lon: -0.0790552732625056, alt: 838.753920858604 },
    { lat: 0.929518116225966, lon: -0.0790433280681913, alt: 841.969238932415 },
    { lat: 0.929513236288713, lon: -0.0790310238421285, alt: 843.164304667206 },
    { lat: 0.929508363169671, lon: -0.0790188148067605, alt: 844.038352654116 },
    { lat: 0.929503558267699, lon: -0.0790067799398821, alt: 846.109764963206 },
    { lat: 0.929498579024149, lon: -0.0789942781665667, alt: 846.767222236416 },
    { lat: 0.929493728301323, lon: -0.0789820730466569, alt: 847.77067117879 },
    { lat: 0.92948893190482, lon: -0.0789699782679485, alt: 849.210119646353 },
    { lat: 0.929483914575834, lon: -0.0789573027344206, alt: 852.990710178209 },
    { lat: 0.929479079023649, lon: -0.0789452040639932, alt: 856.432618852103 },
    { lat: 0.929473903600871, lon: -0.078932657822027, alt: 858.936707916258 },
    { lat: 0.92946879545857, lon: -0.0789207461998817, alt: 862.746341055665 },
    { lat: 0.929463678536912, lon: -0.078909242974851, alt: 866.433312598386 },
    { lat: 0.929458237166912, lon: -0.0788974292117741, alt: 869.588893489856 },
    { lat: 0.929452913774505, lon: -0.0788861561467966, alt: 872.126460625589 },
    { lat: 0.9294473596121, lon: -0.0788745862358891, alt: 874.770382344826 },
    { lat: 0.929441867326632, lon: -0.0788632658259621, alt: 879.850470084055 },
    { lat: 0.929436248299262, lon: -0.0788517303356519, alt: 886.64349236841 },
    { lat: 0.929430821789534, lon: -0.0788406194146917, alt: 894.41033036894 },
    { lat: 0.929425401230068, lon: -0.0788295476425845, alt: 902.581801641275 },
    { lat: 0.929419770618575, lon: -0.0788180895085288, alt: 911.65097519492 },
    { lat: 0.929414406223268, lon: -0.0788072303217909, alt: 919.882883472696 },
    { lat: 0.929409050799221, lon: -0.0787964517027838, alt: 928.269045835726 },
    { lat: 0.929403597070808, lon: -0.0787855101584775, alt: 934.646530777386 },
    { lat: 0.929398256572365, lon: -0.0787748194029564, alt: 938.457734737772 },
    { lat: 0.929393107567685, lon: -0.0787644315563126, alt: 940.534481356098 },
    { lat: 0.929387885197411, lon: -0.0787537463978978, alt: 941.515824877544 },
    { lat: 0.929382761692135, lon: -0.07874307514086, alt: 940.777127817312 },
    { lat: 0.92937779998694, lon: -0.078732504056391, alt: 938.889912516651 },
    { lat: 0.929372906638864, lon: -0.0787218480636643, alt: 936.972803628206 },
    { lat: 0.929368015329994, lon: -0.0787109811675502, alt: 935.122694678699 },
    { lat: 0.929363003297366, lon: -0.0786996666755466, alt: 935.079874327591 },
    { lat: 0.929358203387009, lon: -0.0786886399169027, alt: 937.089896850686 },
    { lat: 0.929353266731863, lon: -0.0786770755387133, alt: 940.59533967541 },
    { lat: 0.929348521154776, lon: -0.0786657252859831, alt: 945.955729690787 },
    { lat: 0.92934366331686, lon: -0.0786538888885047, alt: 951.933032695427 },
    { lat: 0.929339034569179, lon: -0.078642451634836, alt: 957.417575213974 },
    { lat: 0.929334261686163, lon: -0.0786305214688386, alt: 963.232745698709 },
    { lat: 0.929329502118953, lon: -0.078618505682437, alt: 969.08277639849 },
    { lat: 0.929324831933001, lon: -0.0786066680162622, alt: 973.891908488001 },
    { lat: 0.929320128965814, lon: -0.0785947738131106, alt: 978.591033298921 },
    { lat: 0.92931523464291, lon: -0.0785824498673609, alt: 984.686535298383 },
    { lat: 0.929310349827165, lon: -0.0785701637240065, alt: 991.132968389383 },
    { lat: 0.929305589812822, lon: -0.0785581521457548, alt: 997.413513814498 },
    { lat: 0.929300660947164, lon: -0.0785456803543655, alt: 1003.94826697704 },
    { lat: 0.929295871692325, lon: -0.0785335415978235, alt: 1010.39082050322 },
    { lat: 0.92929091625878, lon: -0.0785209737723241, alt: 1017.12068250583 },
    { lat: 0.929286111947832, lon: -0.0785087942763065, alt: 1023.87986030093 },
    { lat: 0.929281136615545, lon: -0.0784961944743814, alt: 1031.48898412675 },
    { lat: 0.929276113514737, lon: -0.078483492503158, alt: 1040.00434024138 },
    { lat: 0.929271259836588, lon: -0.0784711757558076, alt: 1048.8149854522 },
    { lat: 0.929266285560544, lon: -0.0784584955865238, alt: 1058.41372089211 },
    { lat: 0.929261484561694, lon: -0.0784462141643548, alt: 1068.29043072718 },
    { lat: 0.929256523604313, lon: -0.078433488430553, alt: 1079.01523957087 },
    { lat: 0.929251705408896, lon: -0.0784211056239206, alt: 1089.79950885774 },
    { lat: 0.92924677919643, lon: -0.0784084383492818, alt: 1101.07252001153 },
    { lat: 0.92924188059734, lon: -0.0783958435118501, alt: 1112.08704259394 },
    { lat: 0.929237090266627, lon: -0.0783835327993847, alt: 1120.94179374587 },
    { lat: 0.929232184477439, lon: -0.0783709388093789, alt: 1128.77777100619 },
    { lat: 0.929227370704175, lon: -0.0783586148480762, alt: 1135.21751747175 },
    { lat: 0.929222547814952, lon: -0.0783462828692398, alt: 1140.24422096664 },
    { lat: 0.929217917410188, lon: -0.0783344372303912, alt: 1143.52059583811 },
    { lat: 0.929213200037013, lon: -0.0783223520402717, alt: 1144.98260180891 },
    { lat: 0.929208498411198, lon: -0.0783102796494123, alt: 1144.30245887614 },
    { lat: 0.929203786982248, lon: -0.0782981425373741, alt: 1141.42186940992 },
    { lat: 0.929199140049001, lon: -0.0782861208291588, alt: 1136.81702827336 },
    { lat: 0.929194441497447, lon: -0.0782739367086434, alt: 1130.76482359296 },
    { lat: 0.929189829387349, lon: -0.0782621781232666, alt: 1126.09992376181 },
    { lat: 0.929184831678144, lon: -0.0782502507743739, alt: 1122.09532581552 },
    { lat: 0.929179517450284, lon: -0.0782389190838322, alt: 1118.12116794025 },
    { lat: 0.929173799644862, lon: -0.0782281718674801, alt: 1112.82139202802 },
    { lat: 0.929167716372139, lon: -0.0782180448494837, alt: 1102.70364492141 },
    { lat: 0.929161063070341, lon: -0.0782084463321207, alt: 1086.90682276984 },
    { lat: 0.929154150620285, lon: -0.0782000643504438, alt: 1070.54213158095 },
    { lat: 0.929146568232948, lon: -0.0781924081025889, alt: 1052.74868885865 },
    { lat: 0.929138939010551, lon: -0.0781860862723966, alt: 1035.21335832161 },
    { lat: 0.929130722752239, lon: -0.0781805928356874, alt: 1015.69896446897 },
    { lat: 0.92912251878006, lon: -0.0781763702210529, alt: 996.351779808965 },
    { lat: 0.929114049160249, lon: -0.0781732349855364, alt: 976.961397870144 },
    { lat: 0.929105405883478, lon: -0.0781712381314182, alt: 958.053344392096 },
    { lat: 0.929096513678291, lon: -0.0781703118751412, alt: 937.610533202576 },
    { lat: 0.929087843231836, lon: -0.0781705288082266, alt: 916.306183631917 },
    { lat: 0.929078948668016, lon: -0.0781720570593377, alt: 895.823101013428 },
    { lat: 0.929070069543189, lon: -0.0781748987841388, alt: 877.54364331692 },
    { lat: 0.929061539614387, lon: -0.0781788044430929, alt: 860.834175159352 },
    { lat: 0.92905290732809, lon: -0.0781839541815994, alt: 844.836013243685 },
    { lat: 0.929044512072572, lon: -0.0781902417392502, alt: 831.028976880577 },
    { lat: 0.929036765896512, lon: -0.0781973962818898, alt: 820.830125241777 },
    { lat: 0.929029199368261, lon: -0.0782058235703939, alt: 812.479716037398 },
    { lat: 0.929022060409874, lon: -0.0782152630255419, alt: 804.928549349595 },
    { lat: 0.929015664300413, lon: -0.0782251843692477, alt: 797.74980148876 },
    { lat: 0.929009562323478, lon: -0.0782362186144416, alt: 789.082000053273 },
    { lat: 0.929004155137146, lon: -0.0782476511097508, alt: 779.310169958362 },
    { lat: 0.928999389318736, lon: -0.0782594460160459, alt: 768.346119280818 },
    { lat: 0.928995030290039, lon: -0.0782721369597965, alt: 754.684792129025 },
    { lat: 0.928991243864188, lon: -0.078285345985442, alt: 738.798452963755 },
    { lat: 0.928988125542006, lon: -0.0782986335663669, alt: 721.440824800146 },
    { lat: 0.928985497322213, lon: -0.0783127304316865, alt: 701.703079375636 },
    { lat: 0.928983491391655, lon: -0.078327175138925, alt: 681.415822845598 },
    { lat: 0.928982129970706, lon: -0.0783414446924913, alt: 660.682022230383 },
    { lat: 0.928981351494793, lon: -0.0783563234978902, alt: 639.584260901559 },
    { lat: 0.928981236394216, lon: -0.0783708834165268, alt: 621.447398867332 },
    { lat: 0.928981784147406, lon: -0.0783859066433382, alt: 603.787845118077 },
    { lat: 0.928983064711, lon: -0.0784004788513498, alt: 588.543046785135 },
    { lat: 0.928985001251595, lon: -0.0784140978120966, alt: 576.246026777694 },
    { lat: 0.928987624334814, lon: -0.0784275153721541, alt: 563.090236281976 },
    { lat: 0.928990706828079, lon: -0.0784397968663686, alt: 548.779991455559 },
    { lat: 0.928994427430608, lon: -0.0784517094419135, alt: 532.751270677251 },
    { lat: 0.92899852001111, lon: -0.0784624656787477, alt: 516.336712292293 },
    { lat: 0.929003168004269, lon: -0.0784730096539312, alt: 497.624167644409 },
    { lat: 0.929007785184859, lon: -0.0784827661502303, alt: 479.724575637076 },
    { lat: 0.929012530307351, lon: -0.0784925360394, alt: 462.248804097585 },
    { lat: 0.929017137996668, lon: -0.0785019608079213, alt: 445.506314198845 },
    { lat: 0.929021822533178, lon: -0.0785114590754697, alt: 429.041442399335 },
    { lat: 0.929026482508587, lon: -0.0785206388712975, alt: 413.636190272889 },
    { lat: 0.929031276422047, lon: -0.0785297663305178, alt: 398.678627698253 },
    { lat: 0.929035956202219, lon: -0.0785384286025453, alt: 383.999393868943 },
    { lat: 0.929040868355104, lon: -0.0785473503353052, alt: 368.989988630487 },
    { lat: 0.929045645303351, lon: -0.0785559684873279, alt: 356.258377682045 },
    { lat: 0.929050632186999, lon: -0.0785650223678956, alt: 344.230130758985 },
    { lat: 0.929055595104865, lon: -0.0785741595677398, alt: 332.925535076464 },
    { lat: 0.929060396716865, lon: -0.0785831522089393, alt: 321.653264997411 },
    { lat: 0.92906535563535, lon: -0.078592585072876, alt: 311.65693268106 },
    { lat: 0.929070291918716, lon: -0.0786021088487849, alt: 302.827487616439 },
    { lat: 0.929075009541983, lon: -0.0786112976525452, alt: 294.752461721419 },
    { lat: 0.929079931032709, lon: -0.0786208256431513, alt: 287.041364033981 },
    { lat: 0.929084663657528, lon: -0.0786298930392725, alt: 279.056080703931 },
    { lat: 0.929089573609812, lon: -0.0786392003445175, alt: 270.553925508672 },
    { lat: 0.929094501064574, lon: -0.0786484478025987, alt: 261.943390279293 },
    { lat: 0.929099296933392, lon: -0.0786573839045939, alt: 254.070481247444 },
    { lat: 0.929104241636937, lon: -0.0786665953178938, alt: 246.733734134629 },
    { lat: 0.929108967075675, lon: -0.0786754621338795, alt: 239.611386446008 },
    { lat: 0.929113780137327, lon: -0.0786846029378965, alt: 231.595513243469 },
    { lat: 0.929118502282713, lon: -0.0786936984813917, alt: 223.275547823757 },
    { lat: 0.929123298907123, lon: -0.0787030771290034, alt: 214.984629309755 },
    { lat: 0.929128111593949, lon: -0.0787126719818143, alt: 209.877715041942 },
    { lat: 0.929132858330632, lon: -0.0787223417656952, alt: 209.829793527595 },
    { lat: 0.929137896382372, lon: -0.0787327974215201, alt: 215.916486488969 },
    { lat: 0.929143038930481, lon: -0.078743585518415, alt: 228.884629541054 },
    { lat: 0.929148118331454, lon: -0.0787543117938234, alt: 248.198051333454 },
    { lat: 0.929153143816761, lon: -0.0787649533276649, alt: 272.097334287286 },
    { lat: 0.929158433630299, lon: -0.0787761427958103, alt: 299.453592619877 },
    { lat: 0.92916376377441, lon: -0.078787341444096, alt: 323.704108793813 },
    { lat: 0.929169397649352, lon: -0.0787990291804504, alt: 345.718728272659 },
    { lat: 0.929174994102129, lon: -0.0788104557191864, alt: 366.47647991605 },
    { lat: 0.929181032701891, lon: -0.0788225993983591, alt: 385.345448686007 },
    { lat: 0.929187088283195, lon: -0.0788346230309256, alt: 398.721969065188 },
    { lat: 0.929193623128371, lon: -0.078847425975642, alt: 405.943948328121 },
    { lat: 0.92920019917622, lon: -0.0788601353810457, alt: 407.734334465962 },
    { lat: 0.929207238145309, lon: -0.0788735977600354, alt: 410.424652081396 },
    { lat: 0.929214354609152, lon: -0.0788871423929187, alt: 417.366974286032 },
    { lat: 0.929221897044441, lon: -0.078901440569357, alt: 428.60062961807 },
    { lat: 0.929229292783642, lon: -0.0789153965454423, alt: 442.451618860659 },
    { lat: 0.929237099104132, lon: -0.0789300732441397, alt: 459.77750319291 },
    { lat: 0.929245047827729, lon: -0.0789449820837833, alt: 480.235510505402 },
    { lat: 0.929252795365459, lon: -0.0789594967525698, alt: 502.737419451709 },
    { lat: 0.929260855393622, lon: -0.0789746007418866, alt: 528.350448012765 },
    { lat: 0.929268947784416, lon: -0.0789897852891304, alt: 555.667147184008 },
    { lat: 0.929276688616722, lon: -0.0790044887310656, alt: 582.833282901409 },
    { lat: 0.929284457431172, lon: -0.0790198888523591, alt: 611.049751288838 },
    { lat: 0.929291770318708, lon: -0.0790351159544411, alt: 638.194768237166 },
    { lat: 0.929299078137783, lon: -0.0790511291509366, alt: 665.789857466079 },
    { lat: 0.929306042182709, lon: -0.0790672150009161, alt: 692.487000712635 },
    { lat: 0.929313044066948, lon: -0.0790841605967362, alt: 719.112862725788 },
    { lat: 0.929319548433708, lon: -0.0791005157525739, alt: 742.472944989665 },
    { lat: 0.929326147060504, lon: -0.0791175234093169, alt: 764.490275725511 },
    { lat: 0.929332493775527, lon: -0.0791340975099586, alt: 784.459952894036 },
    { lat: 0.929339003483047, lon: -0.0791511776025764, alt: 803.74369622039 },
    { lat: 0.929345529130338, lon: -0.079168146332419, alt: 821.473536384248 },
    { lat: 0.929352057120549, lon: -0.0791850916232163, alt: 837.769420495521 },
    { lat: 0.929358371320368, lon: -0.0792015733031904, alt: 852.163498881517 },
    { lat: 0.929364860490582, lon: -0.0792186266166296, alt: 865.682001340418 },
    { lat: 0.929371334479007, lon: -0.0792357862102144, alt: 878.327308675145 },
    { lat: 0.92937755499443, lon: -0.0792524391804538, alt: 889.791790478977 },
    { lat: 0.929383997361234, lon: -0.0792698837043173, alt: 900.194305805855 },
    { lat: 0.929390157680748, lon: -0.0792867328864293, alt: 906.67761654803 },
    { lat: 0.929396490930605, lon: -0.0793041976106264, alt: 910.978017605991 },
    { lat: 0.929402521899849, lon: -0.0793209789516308, alt: 912.796768869786 },
    { lat: 0.929408849749625, lon: -0.0793380423671293, alt: 911.322873952525 },
    { lat: 0.929415633953919, lon: -0.0793544387960318, alt: 906.529446490353 },
    { lat: 0.929422774790486, lon: -0.0793692832548762, alt: 898.83260381579 },
    { lat: 0.92943098502735, lon: -0.079383176857545, alt: 894.203155457848 },
    { lat: 0.929439632067363, lon: -0.0793952202828483, alt: 890.805229987747 },
    { lat: 0.92944877964757, lon: -0.0794059656420543, alt: 884.121556580313 },
    { lat: 0.929458672133283, lon: -0.0794157212534393, alt: 872.807431390561 },
    { lat: 0.929468903689565, lon: -0.0794242055349341, alt: 854.671329607877 },
    { lat: 0.929479378261833, lon: -0.0794314444180157, alt: 829.224555277554 },
    { lat: 0.929490404447189, lon: -0.0794373894270816, alt: 799.855048070944 },
    { lat: 0.929501443420902, lon: -0.0794410792711274, alt: 781.425329760145 },
    { lat: 0.929513015254819, lon: -0.0794427907879553, alt: 771.935667082629 },
    { lat: 0.929524329010018, lon: -0.0794426854870272, alt: 766.866291562773 },
    { lat: 0.929535798350362, lon: -0.079440817939209, alt: 765.486681634267 },
    { lat: 0.929547064620186, lon: -0.0794371945254264, alt: 768.271049446467 },
    { lat: 0.929557743485734, lon: -0.0794321852689572, alt: 771.92801929136 },
    { lat: 0.929568511848189, lon: -0.079425600327135, alt: 774.118496984552 },
    { lat: 0.929578554633712, lon: -0.0794180162086279, alt: 771.820351783649 },
    { lat: 0.929588710702139, lon: -0.0794086388588605, alt: 764.489130167948 },
    { lat: 0.92959796520726, lon: -0.0793978181997519, alt: 761.043518768795 },
    { lat: 0.929606477547067, lon: -0.0793853042233972, alt: 763.774628837424 },
    { lat: 0.929614142655377, lon: -0.0793716324117202, alt: 768.077347800395 },
    { lat: 0.92962127179916, lon: -0.0793564090538527, alt: 771.477113792933 },
    { lat: 0.929627345874764, lon: -0.0793407638691382, alt: 772.087790504299 },
    { lat: 0.929632685486741, lon: -0.079323426306084, alt: 771.270161923781 },
    { lat: 0.92963682835425, lon: -0.0793051345553003, alt: 772.77033079693 },
    { lat: 0.929639611085542, lon: -0.0792867935669591, alt: 777.188988918981 },
    { lat: 0.929641241979965, lon: -0.0792673130931871, alt: 783.427758952326 },
    { lat: 0.929641592850373, lon: -0.0792484077341141, alt: 790.246203649682 },
    { lat: 0.929640770719153, lon: -0.0792290325420687, alt: 794.670922290281 },
    { lat: 0.929638757367929, lon: -0.0792105945236013, alt: 798.198461211459 },
    { lat: 0.929635453857368, lon: -0.0791919271713084, alt: 800.388458686677 },
    { lat: 0.929631148228738, lon: -0.0791744939103381, alt: 800.400912840814 },
    { lat: 0.92962559226984, lon: -0.079157260460866, alt: 798.291036519315 },
    { lat: 0.929619235244852, lon: -0.0791416747344264, alt: 795.077861105814 },
    { lat: 0.929611620634204, lon: -0.0791266990344757, alt: 791.52453598377 },
    { lat: 0.929603230584718, lon: -0.0791135716109821, alt: 790.222904272868 },
    { lat: 0.929594358244527, lon: -0.0791014995457051, alt: 785.15583369048 },
    { lat: 0.92958537741497, lon: -0.0790894598468429, alt: 777.733718608149 },
    { lat: 0.929576214533405, lon: -0.0790769513387993, alt: 770.320714222512 },
    { lat: 0.929567442872645, lon: -0.0790647234370501, alt: 766.235931084256 },
    { lat: 0.929558595229052, lon: -0.0790520919084498, alt: 766.432312458735 },
    { lat: 0.929549918831682, lon: -0.079039333715663, alt: 768.415907675184 },
    { lat: 0.929541660020773, lon: -0.0790264660467468, alt: 768.632615952846 },
    { lat: 0.92953381711989, lon: -0.079013388600096, alt: 767.008442370364 },
    { lat: 0.929526050816506, lon: -0.0789994671673541, alt: 764.357909539547 },
    { lat: 0.929518783076078, lon: -0.0789853027551888, alt: 766.578917477268 },
    { lat: 0.929511762378349, lon: -0.0789707276076136, alt: 772.550651808225 },
    { lat: 0.929505203709685, lon: -0.0789565904540255, alt: 778.237880294158 },
    { lat: 0.929498542830586, lon: -0.0789419598577471, alt: 783.346431765775 },
    { lat: 0.929492088804598, lon: -0.0789277818264195, alt: 790.092206292383 },
    { lat: 0.929485838001076, lon: -0.0789140863390234, alt: 795.993946714475 },
    { lat: 0.929479477946111, lon: -0.07890019242637, alt: 802.231372142151 },
    { lat: 0.929473195006416, lon: -0.0788865204748267, alt: 808.605794416798 },
    { lat: 0.929467210049329, lon: -0.0788735528640048, alt: 814.09983466454 },
    { lat: 0.929461083212333, lon: -0.0788603489896261, alt: 821.715451123008 },
    { lat: 0.929455059339721, lon: -0.0788474345973692, alt: 833.037773170933 },
    { lat: 0.929449401934561, lon: -0.0788353527741473, alt: 844.610897412212 },
    { lat: 0.929443817606158, lon: -0.0788234517643, alt: 855.146193290726 },
    { lat: 0.92943818389479, lon: -0.0788114434604435, alt: 864.151390862323 },
    { lat: 0.929432850341404, lon: -0.0788000456231083, alt: 871.132416663989 },
    { lat: 0.929427634779981, lon: -0.0787888431756281, alt: 876.036862343846 },
    { lat: 0.929422340211885, lon: -0.0787773822574091, alt: 879.093022496168 },
    { lat: 0.92941734155072, lon: -0.0787664527350135, alt: 880.598707860238 },
    { lat: 0.92941222086916, lon: -0.0787551214964077, alt: 881.59407301271 },
    { lat: 0.929407228479598, lon: -0.0787439250357499, alt: 879.387370736393 },
    { lat: 0.92940232141149, lon: -0.0787328376423559, alt: 874.83140767043 },
    { lat: 0.929397489580272, lon: -0.0787218902779352, alt: 870.359538094158 },
    { lat: 0.929392559852359, lon: -0.0787106638459812, alt: 865.608489160342 },
    { lat: 0.929387503986644, lon: -0.0786990944823979, alt: 860.668193249446 },
    { lat: 0.92938237717545, lon: -0.0786873399640595, alt: 857.106022407625 },
    { lat: 0.929377359045792, lon: -0.0786757877861658, alt: 855.224706675731 },
    { lat: 0.929372110416642, lon: -0.0786636201725276, alt: 853.847059562518 },
    { lat: 0.929367028265431, lon: -0.0786517404566239, alt: 854.502697486509 },
    { lat: 0.929361731009473, lon: -0.0786392445957378, alt: 858.188415061884 },
    { lat: 0.929356442322965, lon: -0.0786266616579208, alt: 862.600495939994 },
    { lat: 0.929351286791907, lon: -0.078614303071989, alt: 866.049398804917 },
    { lat: 0.92934607476924, lon: -0.0786017183014963, alt: 868.849258873066 },
    { lat: 0.929340726889435, lon: -0.0785887016430503, alt: 871.145204963794 },
    { lat: 0.929335334376728, lon: -0.0785754559478452, alt: 873.326234565523 },
    { lat: 0.929330116799838, lon: -0.0785625186634138, alt: 876.131871019704 },
    { lat: 0.929324738759698, lon: -0.0785490651806151, alt: 879.640897608274 },
    { lat: 0.929319341128581, lon: -0.0785354297107897, alt: 883.451673261394 },
    { lat: 0.929314128323632, lon: -0.078522128053471, alt: 886.996148451131 },
    { lat: 0.92930882019561, lon: -0.0785084595044834, alt: 890.509777205323 },
    { lat: 0.929303213042639, lon: -0.0784944209849683, alt: 894.259771597876 },
    { lat: 0.929297302569813, lon: -0.0784810498862415, alt: 896.818792700259 },
    { lat: 0.929290856401389, lon: -0.0784683129354696, alt: 896.412022565369 },
    { lat: 0.929283769472778, lon: -0.0784565144773576, alt: 892.987478428689 },
    { lat: 0.929275771521428, lon: -0.0784454342787131, alt: 883.802140328024 },
    { lat: 0.929267074299784, lon: -0.0784355796414308, alt: 869.250504853948 },
    { lat: 0.929257754457354, lon: -0.0784271197209905, alt: 850.747295674425 },
    { lat: 0.929248055561192, lon: -0.0784205372397776, alt: 833.873449527187 },
    { lat: 0.929237684483774, lon: -0.0784156509522196, alt: 818.221103836612 },
    { lat: 0.929227407577135, lon: -0.0784128110726179, alt: 805.337403685072 },
    { lat: 0.929216703896128, lon: -0.0784117866198006, alt: 793.383155667902 },
    { lat: 0.929206273391398, lon: -0.0784124044679936, alt: 778.846947294644 },
    { lat: 0.929195894307947, lon: -0.0784146565674185, alt: 762.257593123678 },
    { lat: 0.929185720241005, lon: -0.0784185176187155, alt: 743.933603789104 },
    { lat: 0.929176154291154, lon: -0.0784236587953298, alt: 723.144193266864 },
    { lat: 0.929166750827199, lon: -0.0784301535202703, alt: 697.050608834824 },
    { lat: 0.929157922035373, lon: -0.0784378015790653, alt: 668.961032397735 },
    { lat: 0.929149699698843, lon: -0.0784464991691066, alt: 640.118442514094 },
    { lat: 0.929141865572273, lon: -0.0784565625362497, alt: 611.9364855335 },
    { lat: 0.929134632122102, lon: -0.0784676419799759, alt: 584.008013236189 },
    { lat: 0.92912819961609, lon: -0.0784793308356977, alt: 557.696038456693 },
    { lat: 0.929122210021275, lon: -0.0784922516714567, alt: 531.585243694607 },
    { lat: 0.929117099750379, lon: -0.0785054466178966, alt: 507.86538743431 },
    { lat: 0.929112814334074, lon: -0.0785187917221717, alt: 486.127802848397 },
    { lat: 0.92910913121371, lon: -0.0785330379391636, alt: 464.911698405912 },
    { lat: 0.929106283984395, lon: -0.0785473992584171, alt: 444.311009067059 },
    { lat: 0.929104331256992, lon: -0.0785614134224857, alt: 424.067897520822 },
    { lat: 0.929103210904193, lon: -0.0785755226847468, alt: 404.336181778798 },
    { lat: 0.929102883093017, lon: -0.0785899193287284, alt: 383.029958986589 },
    { lat: 0.929103339986993, lon: -0.0786035029235848, alt: 361.886413224219 },
    { lat: 0.929104552796189, lon: -0.0786172043428954, alt: 338.39977196984 },
    { lat: 0.929106448897553, lon: -0.0786300907840215, alt: 315.169978080796 },
    { lat: 0.929109072939648, lon: -0.0786428078398988, alt: 291.379560271912 },
    { lat: 0.929112101395093, lon: -0.0786544617525514, alt: 269.136943863744 },
    { lat: 0.929115563597135, lon: -0.078665929351537, alt: 248.325711005657 },
    { lat: 0.929119329974754, lon: -0.0786771731172156, alt: 229.129258450006 },
    { lat: 0.929123065090229, lon: -0.0786877198327131, alt: 212.919356630308 },
    { lat: 0.929126888110712, lon: -0.0786981714248028, alt: 198.908976529217 },
    { lat: 0.929130814772429, lon: -0.0787085937790068, alt: 185.781701890127 },
    { lat: 0.929134608247577, lon: -0.0787182911712004, alt: 174.322841488981 },
    { lat: 0.929138586713852, lon: -0.0787280230492441, alt: 164.146403077445 },
    { lat: 0.929142491915853, lon: -0.0787371892816497, alt: 153.743757715002 },
    { lat: 0.929146546478402, lon: -0.0787465825711202, alt: 142.71309343674 },
    { lat: 0.929150625387014, lon: -0.078756181097314, alt: 131.880622567641 },
    { lat: 0.929154772023254, lon: -0.0787661551401211, alt: 124.019232880774 },
    { lat: 0.929159301674391, lon: -0.0787768898173668, alt: 121.573583512351 },
    { lat: 0.92916392511632, lon: -0.0787875460937527, alt: 123.433227356287 },
    { lat: 0.929168836332063, lon: -0.0787981705260214, alt: 129.790166438109 },
    { lat: 0.92917416812759, lon: -0.0788087053901495, alt: 135.706474880454 },
    { lat: 0.929179469042477, lon: -0.0788184607105366, alt: 136.899138701199 },
    { lat: 0.929184716742739, lon: -0.0788277713425784, alt: 133.121555085197 },
    { lat: 0.92919003513004, lon: -0.078837161427699, alt: 127.146454927821 },
    { lat: 0.929195011866714, lon: -0.0788459897944753, alt: 121.60198424359 },
    { lat: 0.929200054680324, lon: -0.0788550121544968, alt: 113.087030647117 },
    { lat: 0.929204836906993, lon: -0.0788636816920262, alt: 101.804659539555 },
    { lat: 0.929209569390021, lon: -0.0788724109247517, alt: 87.9757524131989 },
    { lat: 0.929214302408055, lon: -0.0788813060318044, alt: 74.7550060727931 },
    { lat: 0.929219201401978, lon: -0.0788905872102761, alt: 63.2268574773938 },
    { lat: 0.929224173817031, lon: -0.0789000489666254, alt: 55.0017510429867 },
    { lat: 0.929228967319961, lon: -0.0789093234428223, alt: 50.4641304833816 },
    { lat: 0.92923387017709, lon: -0.0789189530792357, alt: 47.3954151255024 },
    { lat: 0.929238557187006, lon: -0.0789281563254817, alt: 48.6750621143528 },
    { lat: 0.929243325324106, lon: -0.0789374935091945, alt: 49.0577171803151 },
    { lat: 0.929247795394368, lon: -0.0789462329307878, alt: 46.4164641151904 },
    { lat: 0.929252291563442, lon: -0.0789550175218367, alt: 42.1456853303794 },
    { lat: 0.929256576686067, lon: -0.0789633993972915, alt: 40.1644263196847 },
    { lat: 0.929260851860618, lon: -0.0789717675151668, alt: 40.1039681043481 },
    { lat: 0.929264922627612, lon: -0.0789796540139977, alt: 40.0828995001129 },
    { lat: 0.929268777889355, lon: -0.0789870900113878, alt: 40.0694206303222 },
    { lat: 0.929272662755011, lon: -0.0789945164659843, alt: 40.0584375763017 },
    { lat: 0.929276304706609, lon: -0.0790014296551285, alt: 40.0527857590605 },
    { lat: 0.929279831416537, lon: -0.0790080531214871, alt: 40.0296137194805 },
    { lat: 0.929283239790137, lon: -0.0790144498734473, alt: 40.0153354740382 },
    { lat: 0.929286371430996, lon: -0.0790202520289578, alt: 40.0017090075158 },
    { lat: 0.929289376035308, lon: -0.0790258161424402, alt: 39.9853264088855 },
    { lat: 0.9292920143754, lon: -0.079030688525949, alt: 39.970685436984 },
    { lat: 0.929294447806086, lon: -0.0790352004688311, alt: 39.9576703021072 },
    { lat: 0.9292965589742, lon: -0.0790392084380847, alt: 39.9448540827899 },
    { lat: 0.929298384708087, lon: -0.0790427702062014, alt: 39.92600497001 },
    { lat: 0.929299885708509, lon: -0.0790457529955981, alt: 39.9211362922012 },
    { lat: 0.92930114132975, lon: -0.0790482672048771, alt: 39.9158588208414 },
    { lat: 0.92930215925374, lon: -0.0790503194827165, alt: 39.9114817518943 },
    { lat: 0.92930296663895, lon: -0.0790518501329156, alt: 39.8983378809304 },
    { lat: 0.929303710077399, lon: -0.079052771202563, alt: 39.9001188073503 },
    { lat: 0.929304353273992, lon: -0.0790531906446915, alt: 39.8958738058746 },
    { lat: 0.929304838397968, lon: -0.0790532551062331, alt: 39.8948003222326 },
    { lat: 0.929305123441504, lon: -0.0790531226988834, alt: 39.8891158720361 },
    { lat: 0.929305339291889, lon: -0.0790529748795047, alt: 39.8854405961151 },
    { lat: 0.929305561589163, lon: -0.079052811981976, alt: 39.8863866952889 },
    { lat: 0.929305835366618, lon: -0.0790526028682988, alt: 39.8884833286248 },
    { lat: 0.929306215829295, lon: -0.0790522289367082, alt: 39.8953436608367 },
    { lat: 0.92930666655955, lon: -0.0790514988393848, alt: 39.8980212000738 },
    { lat: 0.929307317696183, lon: -0.0790506008608737, alt: 39.8893954317492 },
    { lat: 0.92930797045048, lon: -0.0790498035748612, alt: 39.8984556414876 },
    { lat: 0.929308386448078, lon: -0.07904919935285, alt: 39.8859155749666 },
    { lat: 0.929308686164078, lon: -0.0790487619934133, alt: 39.8842144689524 },
    { lat: 0.929308981748838, lon: -0.079048331978965, alt: 39.8852374703276 },
    { lat: 0.929309180114412, lon: -0.079048045300258, alt: 39.8851439386731 },
    { lat: 0.929309180114412, lon: -0.079048045300258, alt: 39.8851439386731 },
    { lat: 0.929309180114412, lon: -0.079048045300258, alt: 39.8851439386731 },
    { lat: 0.929309180114412, lon: -0.079048045300258, alt: 39.8851439386731 },
    { lat: 0.929309180114412, lon: -0.079048045300258, alt: 39.8851439386731 },
];

//Pilot 8, Scenario 1 for 3D visualization.csv
export const data2 = [
    { lat: 0.929216202935712, lon: -0.0789034261758106, alt: 39.7850966462474 },
    { lat: 0.929216202997505, lon: -0.0789034260857962, alt: 39.8807101713984 },
    { lat: 0.929216203003353, lon: -0.0789034260773148, alt: 39.8714508918216 },
    { lat: 0.929216203003519, lon: -0.0789034260770732, alt: 39.8699418038593 },
    { lat: 0.929216203072253, lon: -0.0789034259774028, alt: 39.8712980676688 },
    { lat: 0.929216203245326, lon: -0.0789034257264377, alt: 39.8715767736725 },
    { lat: 0.929216203445847, lon: -0.0789034254356722, alt: 39.8723986625687 },
    { lat: 0.929216203910282, lon: -0.0789034247611079, alt: 39.875314195119 },
    { lat: 0.929216205320683, lon: -0.0789034227093512, alt: 39.8827196029122 },
    { lat: 0.929216207867935, lon: -0.0789034190025896, alt: 39.8886480678905 },
    { lat: 0.929216211293662, lon: -0.0789034140177748, alt: 39.8924125547779 },
    { lat: 0.929216215163031, lon: -0.0789034083887342, alt: 39.893278898362 },
    { lat: 0.929216218718586, lon: -0.0789034032144841, alt: 39.8921378061397 },
    { lat: 0.929216222091956, lon: -0.0789033983056483, alt: 39.8912658069929 },
    { lat: 0.929216225345819, lon: -0.0789033935720188, alt: 39.8908297309974 },
    { lat: 0.929216228189007, lon: -0.0789033894410265, alt: 39.8880692357615 },
    { lat: 0.929216230031414, lon: -0.0789033867675039, alt: 39.8819008306561 },
    { lat: 0.92921623086679, lon: -0.0789033855560006, alt: 39.8761913211031 },
    { lat: 0.929216231290433, lon: -0.0789033849416188, alt: 39.874163429726 },
    { lat: 0.929216231595921, lon: -0.0789033844985919, alt: 39.8732219789889 },
    { lat: 0.929216231852217, lon: -0.0789033841269068, alt: 39.8728222903612 },
    { lat: 0.929216232090544, lon: -0.0789033837812791, alt: 39.8727006560351 },
    { lat: 0.929216232327169, lon: -0.0789033834381207, alt: 39.8727352831888 },
    { lat: 0.929216232581163, lon: -0.0789033830697747, alt: 39.8729031563462 },
    { lat: 0.929216232848732, lon: -0.0789033826817396, alt: 39.873182596254 },
    { lat: 0.929216233151061, lon: -0.0789033822432964, alt: 39.8735255660973 },
    { lat: 0.929216233484762, lon: -0.0789033817593572, alt: 39.8738440387904 },
    { lat: 0.929216233837952, lon: -0.0789033812471507, alt: 39.8740606267841 },
    { lat: 0.929216234199189, lon: -0.0789033807232796, alt: 39.8741381908006 },
    { lat: 0.929216234576531, lon: -0.078903380176055, alt: 39.874114586088 },
    { lat: 0.929216234935138, lon: -0.078903379655994, alt: 39.8740531486748 },
    { lat: 0.92921623528689, lon: -0.0789033791458741, alt: 39.8740079893392 },
    { lat: 0.929216235640436, lon: -0.0789033786331517, alt: 39.8739710786449 },
    { lat: 0.929216235993821, lon: -0.0789033781206639, alt: 39.8739602990581 },
    { lat: 0.929216236336705, lon: -0.0789033776234067, alt: 39.8739481011907 },
    { lat: 0.929216236678525, lon: -0.0789033771276912, alt: 39.8739547007832 },
    { lat: 0.929216237030502, lon: -0.0789033766172465, alt: 39.8739706978746 },
    { lat: 0.929216237381113, lon: -0.0789033761087828, alt: 39.8739706470951 },
    { lat: 0.929216237733011, lon: -0.0789033755984509, alt: 39.8739821142337 },
    { lat: 0.929216238077039, lon: -0.078903375099538, alt: 39.8739809130449 },
    { lat: 0.929216238434494, lon: -0.0789033745811471, alt: 39.8739940835313 },
    { lat: 0.929216238787907, lon: -0.0789033740686213, alt: 39.8740078068366 },
    { lat: 0.929216239134023, lon: -0.078903373566674, alt: 39.8740143457131 },
    { lat: 0.929216239485881, lon: -0.0789033730564043, alt: 39.8740149996518 },
    { lat: 0.929216239837577, lon: -0.0789033725463682, alt: 39.8740041518129 },
    { lat: 0.929216240188403, lon: -0.078903372037593, alt: 39.8740038739666 },
    { lat: 0.929216240533047, lon: -0.0789033715377806, alt: 39.8740008811413 },
    { lat: 0.92921624088313, lon: -0.0789033710300819, alt: 39.8740033489141 },
    { lat: 0.929216241232806, lon: -0.078903370522975, alt: 39.8740005487137 },
    { lat: 0.92921624157908, lon: -0.0789033700208029, alt: 39.8740114475087 },
    { lat: 0.929216241929201, lon: -0.0789033695130472, alt: 39.874029826894 },
    { lat: 0.929216242281925, lon: -0.078903369001517, alt: 39.8740347399007 },
    { lat: 0.929216242634848, lon: -0.0789033684897026, alt: 39.8740452488779 },
    { lat: 0.929216242984239, lon: -0.0789033679830086, alt: 39.8740422762106 },
    { lat: 0.929216243329636, lon: -0.0789033674821087, alt: 39.8740347094708 },
    { lat: 0.929216243681438, lon: -0.078903366971918, alt: 39.8740333470626 },
    { lat: 0.92921624403221, lon: -0.0789033664632177, alt: 39.8740266845099 },
    { lat: 0.929216244383762, lon: -0.0789033659533906, alt: 39.8740330714557 },
    { lat: 0.929216244728135, lon: -0.0789033654539742, alt: 39.874034531294 },
    { lat: 0.929216245081281, lon: -0.0789033649418347, alt: 39.8740581939175 },
    { lat: 0.929216245428564, lon: -0.0789033644381944, alt: 39.8740646245408 },
    { lat: 0.929216245775239, lon: -0.0789033639354348, alt: 39.8740720114845 },
    { lat: 0.92921624612935, lon: -0.0789033634218952, alt: 39.8740669479177 },
    { lat: 0.929216246483856, lon: -0.0789033629077824, alt: 39.8740613392395 },
    { lat: 0.929216246829181, lon: -0.0789033624069855, alt: 39.8740594790499 },
    { lat: 0.929216247173522, lon: -0.0789033619076123, alt: 39.8740579885024 },
    { lat: 0.929216247523116, lon: -0.078903361400626, alt: 39.8740575797136 },
    { lat: 0.929216247866238, lon: -0.0789033609030264, alt: 39.8740698003772 },
    { lat: 0.929216248210598, lon: -0.0789033604036307, alt: 39.874085079742 },
    { lat: 0.929216248560961, lon: -0.0789033598955262, alt: 39.8740942862434 },
    { lat: 0.929216248917573, lon: -0.0789033593783637, alt: 39.8740990911018 },
    { lat: 0.929216249262924, lon: -0.0789033588775261, alt: 39.874091086061 },
    { lat: 0.929216249612576, lon: -0.0789033583704521, alt: 39.8740890719529 },
    { lat: 0.929216249963287, lon: -0.0789033578618425, alt: 39.8740848070653 },
    { lat: 0.929216250322275, lon: -0.0789033573412277, alt: 39.874085419582 },
    { lat: 0.929216250665706, lon: -0.0789033568431789, alt: 39.8740854534333 },
    { lat: 0.929216251012996, lon: -0.0789033563395325, alt: 39.8741031457187 },
    { lat: 0.929216251363027, lon: -0.0789033558319097, alt: 39.874112836925 },
    { lat: 0.929216251723239, lon: -0.0789033553095193, alt: 39.8741236331226 },
    { lat: 0.929216252074048, lon: -0.0789033548007678, alt: 39.8741243819971 },
    { lat: 0.929216252425078, lon: -0.0789033542916967, alt: 39.8741155239108 },
    { lat: 0.929216252768884, lon: -0.0789033537931007, alt: 39.8741139885892 },
    { lat: 0.929216253118822, lon: -0.0789033532856145, alt: 39.8741127806673 },
    { lat: 0.929216253467835, lon: -0.0789033527794663, alt: 39.8741095770744 },
    { lat: 0.929216253818099, lon: -0.0789033522715088, alt: 39.8741172597135 },
    { lat: 0.929216254160529, lon: -0.0789033517749127, alt: 39.8741344988328 },
    { lat: 0.929216254513445, lon: -0.0789033512631081, alt: 39.8741452253283 },
    { lat: 0.929216254865357, lon: -0.0789033507527552, alt: 39.8741491717801 },
    { lat: 0.929216255214015, lon: -0.0789033502471247, alt: 39.8741493996398 },
    { lat: 0.929216255560841, lon: -0.0789033497441483, alt: 39.8741424905046 },
    { lat: 0.929216255911532, lon: -0.0789033492355719, alt: 39.8741414793563 },
    { lat: 0.929216256266288, lon: -0.0789033487211004, alt: 39.8741390024001 },
    { lat: 0.929216256610604, lon: -0.0789033482217649, alt: 39.8741384625916 },
    { lat: 0.929216256959843, lon: -0.0789033477152907, alt: 39.8741466639278 },
    { lat: 0.929216257314767, lon: -0.0789033472005696, alt: 39.8741630408047 },
    { lat: 0.929216257664351, lon: -0.0789033466935938, alt: 39.8741752106981 },
    { lat: 0.929216258009594, lon: -0.0789033461929154, alt: 39.8741773213603 },
    { lat: 0.929216258363629, lon: -0.0789033456794823, alt: 39.8741739371774 },
    { lat: 0.929216258717331, lon: -0.0789033451665366, alt: 39.8741692687319 },
    { lat: 0.929216259061874, lon: -0.0789033446668707, alt: 39.8741671799133 },
    { lat: 0.929216259408807, lon: -0.0789033441637395, alt: 39.8741664337576 },
    { lat: 0.929216259756366, lon: -0.0789033436596988, alt: 39.87416547949 },
    { lat: 0.929216260107542, lon: -0.0789033431504176, alt: 39.8741808836467 },
    { lat: 0.929216260450175, lon: -0.0789033426535235, alt: 39.8741945328948 },
    { lat: 0.929216260796788, lon: -0.0789033421508606, alt: 39.874203232729 },
    { lat: 0.929216261148471, lon: -0.0789033416408445, alt: 39.8742049133096 },
    { lat: 0.929216261502582, lon: -0.0789033411273075, alt: 39.8741975371714 },
    { lat: 0.929216261847725, lon: -0.0789033406267734, alt: 39.8741938369156 },
    { lat: 0.929216262193476, lon: -0.0789033401253615, alt: 39.8741954853977 },
    { lat: 0.92921626254319, lon: -0.0789033396181994, alt: 39.8741896059977 },
    { lat: 0.929216262894132, lon: -0.0789033391092523, alt: 39.8741971147835 },
    { lat: 0.929216263238905, lon: -0.0789033386092551, alt: 39.874210246045 },
    { lat: 0.929216263585628, lon: -0.0789033381064312, alt: 39.8742257339216 },
    { lat: 0.929216263937455, lon: -0.0789033375962032, alt: 39.8742288945133 },
    { lat: 0.92921626429244, lon: -0.0789033370813946, alt: 39.8742341609105 },
    { lat: 0.929216264645529, lon: -0.0789033365693355, alt: 39.8742236527547 },
    { lat: 0.929216264990969, lon: -0.07890333606837, alt: 39.8742200186909 },
    { lat: 0.929216265347996, lon: -0.0789033355505992, alt: 39.8742205699735 },
    { lat: 0.929216265714103, lon: -0.0789033350196606, alt: 39.8742228365021 },
    { lat: 0.929216266058389, lon: -0.078903334520368, alt: 39.8742228121241 },
    { lat: 0.929216266403155, lon: -0.0789033340203803, alt: 39.8742421902193 },
    { lat: 0.929216266750367, lon: -0.078903333516845, alt: 39.8742490528028 },
    { lat: 0.929216267106853, lon: -0.078903332999861, alt: 39.8742643802517 },
    { lat: 0.929216267792345, lon: -0.0789033320055012, alt: 39.8752347441885 },
    { lat: 0.92921628534013, lon: -0.0789033065685986, alt: 39.8770254910686 },
    { lat: 0.929216387138766, lon: -0.0789031591735281, alt: 39.8786876112581 },
    { lat: 0.929216654047887, lon: -0.0789027729696264, alt: 39.880118381466 },
    { lat: 0.929217132318997, lon: -0.0789020866102035, alt: 39.8807488512684 },
    { lat: 0.929217817556598, lon: -0.0789011168155919, alt: 39.8806447803254 },
    { lat: 0.929218626916101, lon: -0.0788999895679332, alt: 39.8800119821388 },
    { lat: 0.929219509647539, lon: -0.0788987902723866, alt: 39.8789879636696 },
    { lat: 0.929220450013695, lon: -0.0788977683009272, alt: 39.8779682815203 },
    { lat: 0.929221455602975, lon: -0.0788971604250285, alt: 39.8775470108428 },
    { lat: 0.92922248040316, lon: -0.0788970000219379, alt: 39.8775110417422 },
    { lat: 0.929223403055088, lon: -0.0788972879496897, alt: 39.8771200380455 },
    { lat: 0.929224179989854, lon: -0.0788979697988384, alt: 39.8768402652746 },
    { lat: 0.92922481290685, lon: -0.0788989056490339, alt: 39.8767511832015 },
    { lat: 0.929225283022476, lon: -0.0788999799655818, alt: 39.8770509906162 },
    { lat: 0.929225682606857, lon: -0.0789010978812045, alt: 39.8771626345257 },
    { lat: 0.929226078919557, lon: -0.0789022193607877, alt: 39.8771360954139 },
    { lat: 0.929226493826659, lon: -0.0789032991839891, alt: 39.8770777602571 },
    { lat: 0.929226926601094, lon: -0.0789043084663748, alt: 39.8770333464252 },
    { lat: 0.929227376683915, lon: -0.0789052704398827, alt: 39.8769913570736 },
    { lat: 0.929227836744866, lon: -0.0789062159928084, alt: 39.8769902114695 },
    { lat: 0.92922826198478, lon: -0.0789071126152774, alt: 39.8832229381249 },
    { lat: 0.929228581641316, lon: -0.0789079051976989, alt: 39.8780976934018 },
    { lat: 0.929228859616335, lon: -0.0789086435775005, alt: 39.8773454286093 },
    { lat: 0.929229126780907, lon: -0.0789093172295136, alt: 39.8768452610502 },
    { lat: 0.929229398646145, lon: -0.0789099450330112, alt: 39.8766557490276 },
    { lat: 0.929229678294383, lon: -0.0789105409470956, alt: 39.8766446274542 },
    { lat: 0.929229930714297, lon: -0.0789110407327328, alt: 39.8906321657197 },
    { lat: 0.929230000257071, lon: -0.0789111745292408, alt: 39.8799349629425 },
    { lat: 0.929230000584155, lon: -0.0789111751843525, alt: 39.874619181387 },
    { lat: 0.929230000865449, lon: -0.0789111757465902, alt: 39.8743746364205 },
    { lat: 0.929230001171984, lon: -0.0789111763592875, alt: 39.8746379639259 },
    { lat: 0.929230001493921, lon: -0.0789111770027701, alt: 39.8747241200562 },
    { lat: 0.929230001818924, lon: -0.0789111776523829, alt: 39.874649576029 },
    { lat: 0.929230002129581, lon: -0.078911178273325, alt: 39.8745375794021 },
    { lat: 0.929230002427034, lon: -0.0789111788678753, alt: 39.8744343700236 },
    { lat: 0.929230002723909, lon: -0.0789111794612649, alt: 39.8743663081036 },
    { lat: 0.92923000301661, lon: -0.0789111800463109, alt: 39.8743323252404 },
    { lat: 0.929230003303779, lon: -0.078911180620303, alt: 39.8743408008315 },
    { lat: 0.929230003593911, lon: -0.0789111812002176, alt: 39.8743659799748 },
    { lat: 0.9292300038886, lon: -0.078911181789239, alt: 39.8743922100142 },
    { lat: 0.929230004185152, lon: -0.0789111823819843, alt: 39.8744100625509 },
    { lat: 0.92923000448672, lon: -0.0789111829847529, alt: 39.8744214804623 },
    { lat: 0.929230004793093, lon: -0.0789111835971312, alt: 39.8746499794309 },
    { lat: 0.929230005227292, lon: -0.0789111844649117, alt: 39.8765806687007 },
    { lat: 0.92923000602577, lon: -0.0789111860603168, alt: 39.8798177789366 },
    { lat: 0.929230007192662, lon: -0.0789111883913522, alt: 39.882324974064 },
    { lat: 0.929230008475904, lon: -0.0789111909544388, alt: 39.8826476260632 },
    { lat: 0.929230009785597, lon: -0.0789111935696919, alt: 39.8826226158731 },
    { lat: 0.929230016721049, lon: -0.0789112074318029, alt: 39.8798988500441 },
    { lat: 0.929230080038591, lon: -0.0789113343602722, alt: 39.8781624329983 },
    { lat: 0.929230242755665, lon: -0.0789116636009634, alt: 39.8793909268881 },
    { lat: 0.929230556552064, lon: -0.0789123083659546, alt: 39.8821628076754 },
    { lat: 0.929231122155745, lon: -0.0789134775594669, alt: 39.8876514848921 },
    { lat: 0.929232033922957, lon: -0.0789153482694898, alt: 39.8950411617827 },
    { lat: 0.92923335756485, lon: -0.0789179571523501, alt: 39.9029506822003 },
    { lat: 0.929235096829601, lon: -0.0789212404519785, alt: 39.9132703092944 },
    { lat: 0.929237153250565, lon: -0.078925104262485, alt: 39.9253041250081 },
    { lat: 0.929239538781171, lon: -0.0789296534457426, alt: 39.9388634128289 },
    { lat: 0.92924226826784, lon: -0.0789349494395075, alt: 39.9538854900897 },
    { lat: 0.929245292267704, lon: -0.0789408893563335, alt: 39.9704334011488 },
    { lat: 0.929248545762029, lon: -0.0789473044690428, alt: 39.9900374088665 },
    { lat: 0.929252129591933, lon: -0.078954350733863, alt: 40.0105301385742 },
    { lat: 0.929256074380855, lon: -0.0789620840552426, alt: 40.0305469990887 },
    { lat: 0.929260245499823, lon: -0.0789702432001568, alt: 40.0507198597957 },
    { lat: 0.929264697491616, lon: -0.0789789337227634, alt: 40.0711876063026 },
    { lat: 0.929269506468044, lon: -0.0789883042779407, alt: 40.0920178763217 },
    { lat: 0.929274543256125, lon: -0.0789981100049186, alt: 40.1256322325397 },
    { lat: 0.929279740082534, lon: -0.0790082293190088, alt: 40.6110421587078 },
    { lat: 0.929285167705158, lon: -0.0790188385264138, alt: 44.9519596868194 },
    { lat: 0.929290757956509, lon: -0.0790298156637396, alt: 53.2068618193117 },
    { lat: 0.929296519651738, lon: -0.079041056873844, alt: 64.966568844681 },
    { lat: 0.929302527227405, lon: -0.0790526439286057, alt: 77.9280757534645 },
    { lat: 0.929308707221302, lon: -0.0790644065332043, alt: 89.6970921191813 },
    { lat: 0.929314941843714, lon: -0.0790760984466589, alt: 102.757686898394 },
    { lat: 0.929321446657648, lon: -0.0790881025621417, alt: 121.500970701896 },
    { lat: 0.929328144271903, lon: -0.0791002693439031, alt: 143.988756597391 },
    { lat: 0.929334872759238, lon: -0.079112306599215, alt: 166.350083550385 },
    { lat: 0.929341778752599, lon: -0.0791244961631459, alt: 185.848316392465 },
    { lat: 0.929349065006989, lon: -0.0791372771444338, alt: 197.019318993588 },
    { lat: 0.929356442821036, lon: -0.0791502934174199, alt: 206.146664052252 },
    { lat: 0.929363899596398, lon: -0.0791637411751838, alt: 217.173459377023 },
    { lat: 0.929371347993839, lon: -0.0791776687887161, alt: 229.529066533185 },
    { lat: 0.929378787654012, lon: -0.0791921130455933, alt: 242.012939268271 },
    { lat: 0.929386372456261, lon: -0.0792073513232543, alt: 252.749211271012 },
    { lat: 0.929393853755982, lon: -0.0792228841084492, alt: 263.09319373076 },
    { lat: 0.929401384988287, lon: -0.0792388671122219, alt: 279.722868449848 },
    { lat: 0.929409031070732, lon: -0.0792551871266421, alt: 302.858842139712 },
    { lat: 0.929416725345248, lon: -0.0792716044427926, alt: 327.951565130436 },
    { lat: 0.929424503894204, lon: -0.0792881310209782, alt: 354.393744821937 },
    { lat: 0.929432381639632, lon: -0.0793047818110704, alt: 381.684822424329 },
    { lat: 0.929440366357839, lon: -0.0793215589278348, alt: 410.922680258286 },
    { lat: 0.929448460036764, lon: -0.0793384632307175, alt: 441.970801009644 },
    { lat: 0.929456439437749, lon: -0.0793550324428373, alt: 472.545382027315 },
    { lat: 0.929464422826165, lon: -0.0793715195444268, alt: 500.631031111046 },
    { lat: 0.929472598171984, lon: -0.0793883095110341, alt: 526.89194736744 },
    { lat: 0.929480817077015, lon: -0.0794050907249103, alt: 551.524718433337 },
    { lat: 0.929488926129798, lon: -0.0794215472412033, alt: 575.450847190017 },
    { lat: 0.92949709095172, lon: -0.0794380132245798, alt: 599.902796045932 },
    { lat: 0.9295054798158, lon: -0.0794548241747075, alt: 625.342828439813 },
    { lat: 0.929513771658092, lon: -0.0794713344410762, alt: 650.526412376811 },
    { lat: 0.929522063163659, lon: -0.0794877407204866, alt: 675.748732634923 },
    { lat: 0.929530400234941, lon: -0.0795041485726294, alt: 701.082970548636 },
    { lat: 0.929539014451382, lon: -0.0795210493326545, alt: 727.459272622524 },
    { lat: 0.929547354273141, lon: -0.079537374049545, alt: 753.37542202153 },
    { lat: 0.929555809514435, lon: -0.0795538886920231, alt: 779.913793473843 },
    { lat: 0.929564411480419, lon: -0.0795706550616604, alt: 807.156044100693 },
    { lat: 0.929573064124973, lon: -0.0795874861066834, alt: 834.880426463593 },
    { lat: 0.92958157503607, lon: -0.0796040098976737, alt: 862.49200685509 },
    { lat: 0.92959008868864, lon: -0.079620510291657, alt: 888.713801594912 },
    { lat: 0.929599008150208, lon: -0.079637759391315, alt: 909.924693169738 },
    { lat: 0.929607584894264, lon: -0.0796542392783052, alt: 926.142200983674 },
    { lat: 0.929616408269028, lon: -0.0796708571333273, alt: 941.433739720889 },
    { lat: 0.929625484285764, lon: -0.0796871405884099, alt: 954.666815305447 },
    { lat: 0.929634932980116, lon: -0.0797026575572822, alt: 964.492374948452 },
    { lat: 0.929645053433717, lon: -0.0797175769888742, alt: 969.478259279344 },
    { lat: 0.929655707416488, lon: -0.0797314771260853, alt: 968.273559264157 },
    { lat: 0.929666780075029, lon: -0.0797436839813463, alt: 968.177528288864 },
    { lat: 0.929678463496123, lon: -0.0797541463243402, alt: 972.92966316629 },
    { lat: 0.929690851976736, lon: -0.0797631906777877, alt: 976.554081886522 },
    { lat: 0.929703327918356, lon: -0.0797701300496497, alt: 982.319197592713 },
    { lat: 0.929716019163705, lon: -0.0797751460399999, alt: 989.502010860495 },
    { lat: 0.929729180903995, lon: -0.0797782441275682, alt: 998.392023835369 },
    { lat: 0.929742389773975, lon: -0.0797791476942649, alt: 1007.21442113129 },
    { lat: 0.929755310212501, lon: -0.0797777292016029, alt: 1015.60435069141 },
    { lat: 0.929767998277232, lon: -0.0797737638546001, alt: 1024.90303388261 },
    { lat: 0.929780250053253, lon: -0.0797669367790289, alt: 1039.13105116499 },
    { lat: 0.929791941052568, lon: -0.079757273706913, alt: 1057.23372729034 },
    { lat: 0.929802356648447, lon: -0.0797456691057005, alt: 1075.06398655889 },
    { lat: 0.929811973931392, lon: -0.0797321237468911, alt: 1089.06957985761 },
    { lat: 0.929820874039969, lon: -0.0797170662508892, alt: 1093.47540195009 },
    { lat: 0.929828840622479, lon: -0.0797006936562203, alt: 1089.62248739645 },
    { lat: 0.929835657472164, lon: -0.0796827103466351, alt: 1082.63069583224 },
    { lat: 0.929841161475203, lon: -0.0796631736396075, alt: 1074.26782042068 },
    { lat: 0.929845137823394, lon: -0.0796428794086019, alt: 1064.88366670966 },
    { lat: 0.929847595198509, lon: -0.0796217575855458, alt: 1054.84204332606 },
    { lat: 0.929848402303148, lon: -0.0796001710789447, alt: 1046.13999681589 },
    { lat: 0.929847448304824, lon: -0.0795781649026955, alt: 1039.60373049657 },
    { lat: 0.929844801746697, lon: -0.0795567192137066, alt: 1035.05127146006 },
    { lat: 0.929840508462684, lon: -0.0795361936023456, alt: 1032.81139165094 },
    { lat: 0.92983465232435, lon: -0.0795165584415646, alt: 1031.5141845244 },
    { lat: 0.929827386298347, lon: -0.0794977935154975, alt: 1027.57428978844 },
    { lat: 0.929819222296618, lon: -0.0794805451527852, alt: 1021.46201081168 },
    { lat: 0.92981012362979, lon: -0.0794647416295016, alt: 1017.22240987658 },
    { lat: 0.929800125027008, lon: -0.0794498889272599, alt: 1015.03205455066 },
    { lat: 0.929789770378259, lon: -0.07943576087886, alt: 1015.57738843066 },
    { lat: 0.929779418904661, lon: -0.0794219445022118, alt: 1017.4670696302 },
    { lat: 0.929768819958895, lon: -0.079407658675637, alt: 1019.75384729084 },
    { lat: 0.929758515980939, lon: -0.0793935663510984, alt: 1022.13325317664 },
    { lat: 0.929748246441114, lon: -0.0793792941569555, alt: 1024.53710093453 },
    { lat: 0.929737899858062, lon: -0.0793646609896765, alt: 1026.81497743743 },
    { lat: 0.929727748366883, lon: -0.0793499891707864, alt: 1028.66264454235 },
    { lat: 0.929718017508797, lon: -0.0793355208254996, alt: 1029.77099718725 },
    { lat: 0.929708321441982, lon: -0.0793206069179925, alt: 1029.9062379615 },
    { lat: 0.929698689881736, lon: -0.0793051878775016, alt: 1028.70374546378 },
    { lat: 0.929689438577113, lon: -0.0792897880158396, alt: 1026.11122774245 },
    { lat: 0.929680270394681, lon: -0.079274141495881, alt: 1022.98168538977 },
    { lat: 0.929671181631089, lon: -0.0792585930610758, alt: 1023.75736924131 },
    { lat: 0.929662070798414, lon: -0.07924303123539, alt: 1026.79515670766 },
    { lat: 0.929653090270618, lon: -0.0792277166268039, alt: 1028.54373571245 },
    { lat: 0.929644074591598, lon: -0.0792123671937442, alt: 1028.11751047812 },
    { lat: 0.929635238244163, lon: -0.0791973463961364, alt: 1025.25044325526 },
    { lat: 0.929626528092796, lon: -0.0791825623513663, alt: 1019.77409811881 },
    { lat: 0.929617779177089, lon: -0.0791677027163295, alt: 1011.6007035024 },
    { lat: 0.929609036632622, lon: -0.079152551779003, alt: 1002.24830886055 },
    { lat: 0.929600643134532, lon: -0.0791373041433466, alt: 994.889892217834 },
    { lat: 0.929592803571219, lon: -0.0791220745462409, alt: 989.611394092901 },
    { lat: 0.929584939346643, lon: -0.0791057658472292, alt: 984.877949051331 },
    { lat: 0.929577482239742, lon: -0.0790897613685603, alt: 979.444112861611 },
    { lat: 0.929570161592304, lon: -0.079073788572976, alt: 973.212871124317 },
    { lat: 0.929562954112407, lon: -0.0790578966675624, alt: 967.415416081609 },
    { lat: 0.929555819750098, lon: -0.0790420349381517, alt: 965.181733015647 },
    { lat: 0.92954868965094, lon: -0.0790260649295784, alt: 961.518882104306 },
    { lat: 0.929541653179058, lon: -0.079010184689654, alt: 955.528989430276 },
    { lat: 0.929534725557237, lon: -0.0789944296636186, alt: 946.739462454712 },
    { lat: 0.929527969310413, lon: -0.0789789375755479, alt: 935.342827227275 },
    { lat: 0.929521232818696, lon: -0.0789633490521069, alt: 921.180944638932 },
    { lat: 0.929514468174995, lon: -0.0789475135468767, alt: 908.571529899752 },
    { lat: 0.929507973673892, lon: -0.07893214910115, alt: 907.753767815882 },
    { lat: 0.929501629291333, lon: -0.0789170860459505, alt: 917.139145328149 },
    { lat: 0.92949545314587, lon: -0.0789023962179703, alt: 927.748372067877 },
    { lat: 0.929489426763522, lon: -0.0788880852301191, alt: 936.058801923198 },
    { lat: 0.929483411774564, lon: -0.0788738605634314, alt: 939.756267714746 },
    { lat: 0.929477537298124, lon: -0.078860022805413, alt: 937.314352209975 },
    { lat: 0.929471770469699, lon: -0.0788464923595475, alt: 930.787801893545 },
    { lat: 0.929465953681327, lon: -0.0788329036005066, alt: 923.794427860541 },
    { lat: 0.929460273671413, lon: -0.0788196916380343, alt: 917.453043792366 },
    { lat: 0.929454623228528, lon: -0.0788066167486253, alt: 914.43152136977 },
    { lat: 0.929449050074373, lon: -0.0787937952747239, alt: 915.820043115799 },
    { lat: 0.929443546465335, lon: -0.0787812105532593, alt: 921.851778051512 },
    { lat: 0.92943807530353, lon: -0.0787687548274781, alt: 929.176899324766 },
    { lat: 0.92943277272296, lon: -0.0787567067074336, alt: 934.355884218848 },
    { lat: 0.92942765491082, lon: -0.0787450683029333, alt: 938.751671785619 },
    { lat: 0.929422329723467, lon: -0.0787329061637035, alt: 941.335428558428 },
    { lat: 0.929417110025518, lon: -0.0787209176641052, alt: 938.06661750567 },
    { lat: 0.929411872955651, lon: -0.0787088271622484, alt: 927.795769325479 },
    { lat: 0.929406490418201, lon: -0.0786963406102291, alt: 911.159639514384 },
    { lat: 0.929401111859979, lon: -0.0786837878944337, alt: 893.33082733525 },
    { lat: 0.92939559853609, lon: -0.0786708646811176, alt: 875.458684583555 },
    { lat: 0.929389970771742, lon: -0.078657696733202, alt: 858.607642652561 },
    { lat: 0.92938446103943, lon: -0.0786448347725513, alt: 850.595706136293 },
    { lat: 0.929378820111339, lon: -0.078631686868461, alt: 847.604155881295 },
    { lat: 0.929373103257136, lon: -0.0786184644834827, alt: 844.881855175633 },
    { lat: 0.929367302041714, lon: -0.0786052581588671, alt: 842.398005814526 },
    { lat: 0.929361372961145, lon: -0.0785921460010998, alt: 837.010765324328 },
    { lat: 0.92935520834598, lon: -0.0785793142991383, alt: 823.61823835506 },
    { lat: 0.929348394322782, lon: -0.0785666555756703, alt: 800.557630554668 },
    { lat: 0.929340898048986, lon: -0.0785550138968639, alt: 774.115414543711 },
    { lat: 0.929332669967644, lon: -0.0785448253953782, alt: 749.586522403702 },
    { lat: 0.929323556529245, lon: -0.0785361710980115, alt: 729.576121145706 },
    { lat: 0.929313788744076, lon: -0.0785293374089837, alt: 714.814201798882 },
    { lat: 0.929303957194073, lon: -0.0785245476273803, alt: 703.379442429507 },
    { lat: 0.929293849761402, lon: -0.0785214383931009, alt: 691.266219054629 },
    { lat: 0.929283727545684, lon: -0.0785198329161104, alt: 674.173748352052 },
    { lat: 0.929273563006652, lon: -0.0785199859103716, alt: 656.821211669279 },
    { lat: 0.92926350892397, lon: -0.0785219437513337, alt: 640.975342886234 },
    { lat: 0.929253723200224, lon: -0.0785256429016493, alt: 626.499180791571 },
    { lat: 0.929244105464117, lon: -0.0785311587368076, alt: 613.874691075804 },
    { lat: 0.929235140575673, lon: -0.0785380891963027, alt: 601.984335051224 },
    { lat: 0.929226561402368, lon: -0.07854626540737, alt: 584.973113525506 },
    { lat: 0.929218306847183, lon: -0.0785556380305714, alt: 560.736710271494 },
    { lat: 0.929210725491808, lon: -0.0785660291475932, alt: 534.369073600636 },
    { lat: 0.929203707611533, lon: -0.0785778397045306, alt: 509.651633790208 },
    { lat: 0.929197503579058, lon: -0.0785906769885372, alt: 488.144671234932 },
    { lat: 0.929192056685245, lon: -0.0786043334760331, alt: 466.827817429834 },
    { lat: 0.929187370890008, lon: -0.0786185543980619, alt: 443.905109331747 },
    { lat: 0.929183343268029, lon: -0.0786336381910012, alt: 418.908016664021 },
    { lat: 0.929180106688298, lon: -0.0786493543074329, alt: 394.184477793569 },
    { lat: 0.929177779628849, lon: -0.0786652778291567, alt: 372.474230867178 },
    { lat: 0.929176291551478, lon: -0.0786814383535294, alt: 352.155297000431 },
    { lat: 0.929175571800831, lon: -0.0786976677375852, alt: 330.930400547496 },
    { lat: 0.929175635400009, lon: -0.0787139108268078, alt: 309.402673516962 },
    { lat: 0.929176443922995, lon: -0.0787303711545447, alt: 285.332654844884 },
    { lat: 0.929177902497626, lon: -0.0787464180796037, alt: 257.669239193542 },
    { lat: 0.929179931320629, lon: -0.0787622733915041, alt: 226.880832876418 },
    { lat: 0.929182423122962, lon: -0.0787780866572698, alt: 198.221037047713 },
    { lat: 0.929185307196568, lon: -0.0787939401227142, alt: 173.325686366338 },
    { lat: 0.929188561487516, lon: -0.0788092777802385, alt: 152.917550489064 },
    { lat: 0.929192351762978, lon: -0.0788242471681597, alt: 132.879926334321 },
    { lat: 0.92919677494903, lon: -0.0788385151797227, alt: 113.26485131851 },
    { lat: 0.929201881255143, lon: -0.0788519393571063, alt: 94.8958109594192 },
    { lat: 0.929207427851072, lon: -0.0788644240579954, alt: 78.0744107565753 },
    { lat: 0.929213214336609, lon: -0.0788765766504607, alt: 66.4621019383181 },
    { lat: 0.929218829757575, lon: -0.0788880314698129, alt: 62.2034714784095 },
    { lat: 0.929224408724529, lon: -0.0788992354165407, alt: 56.6205877633546 },
    { lat: 0.929229827452273, lon: -0.0789100147046381, alt: 47.4875066829822 },
    { lat: 0.929235011829735, lon: -0.0789201755465915, alt: 41.5269718217628 },
    { lat: 0.929240117456909, lon: -0.0789299868414294, alt: 40.7888298150179 },
    { lat: 0.929245245076458, lon: -0.0789397154989452, alt: 40.135377353006 },
    { lat: 0.929250160035212, lon: -0.078949057075993, alt: 40.1356679205662 },
    { lat: 0.929254893336042, lon: -0.0789581007592747, alt: 40.1208137114688 },
    { lat: 0.929259507268237, lon: -0.0789669711158422, alt: 40.107214328918 },
    { lat: 0.929264001990017, lon: -0.0789756646104044, alt: 40.0946633616597 },
    { lat: 0.929268309708538, lon: -0.0789840329957768, alt: 40.0832237202261 },
    { lat: 0.929272471098718, lon: -0.0789921389676271, alt: 40.0726616284479 },
    { lat: 0.929276569182754, lon: -0.0790001479135829, alt: 40.0625882428707 },
    { lat: 0.929280493826789, lon: -0.0790078630979372, alt: 40.0529921076183 },
    { lat: 0.929284246056614, lon: -0.0790152949796267, alt: 40.0440865487731 },
    { lat: 0.929287964581797, lon: -0.0790226986011362, alt: 40.0355554344257 },
    { lat: 0.929291619549547, lon: -0.0790299793521594, alt: 40.0274057803405 },
    { lat: 0.929295126537141, lon: -0.0790369629163475, alt: 40.0197945117216 },
    { lat: 0.929298528191483, lon: -0.079043773830182, alt: 40.0125445983088 },
    { lat: 0.929301819432042, lon: -0.0790504435007562, alt: 40.0056814080756 },
    { lat: 0.929305031494318, lon: -0.0790570125799759, alt: 39.9992254613339 },
    { lat: 0.929308155501798, lon: -0.0790633837987935, alt: 39.993086035071 },
    { lat: 0.92931121352113, lon: -0.0790695590461205, alt: 39.9872711935936 },
    { lat: 0.929314246565861, lon: -0.0790756274458959, alt: 39.9818717319629 },
    { lat: 0.929317171490175, lon: -0.0790814405004026, alt: 39.9761964752616 },
    { lat: 0.929320045105746, lon: -0.0790870049993339, alt: 39.9714912676513 },
    { lat: 0.929322945834335, lon: -0.0790924074638744, alt: 39.9666797864434 },
    { lat: 0.929325766749033, lon: -0.0790975766702726, alt: 39.9619001818629 },
    { lat: 0.929328532447408, lon: -0.0791026674180628, alt: 39.9583652381309 },
    { lat: 0.92933124737711, lon: -0.0791076967203322, alt: 39.9539777913802 },
    { lat: 0.929333842537868, lon: -0.0791125047279193, alt: 39.9500352606258 },
    { lat: 0.929336385690393, lon: -0.079117184825577, alt: 39.9471852237151 },
    { lat: 0.929338897458589, lon: -0.079121764841112, alt: 39.9435897334677 },
    { lat: 0.929341338359281, lon: -0.0791261805516691, alt: 39.9401517367025 },
    { lat: 0.929343745398481, lon: -0.0791305139555128, alt: 39.9370747730158 },
    { lat: 0.929346111878487, lon: -0.0791347701435909, alt: 39.9343455761336 },
    { lat: 0.929348390798059, lon: -0.0791388409957496, alt: 39.9319001274088 },
    { lat: 0.929350648345901, lon: -0.0791428374113074, alt: 39.929568747125 },
    { lat: 0.929352883239837, lon: -0.0791467935344315, alt: 39.9272673344412 },
    { lat: 0.929355037821148, lon: -0.0791506128908756, alt: 39.9250611674154 },
    { lat: 0.929357136133714, lon: -0.0791543470852414, alt: 39.9227505606134 },
    { lat: 0.929359081178127, lon: -0.0791582233923859, alt: 39.9176875387974 },
    { lat: 0.929360624641589, lon: -0.0791622487308754, alt: 39.9159206488516 },
    { lat: 0.929361961174799, lon: -0.0791663214221346, alt: 39.9148771998538 },
    { lat: 0.92936333570435, lon: -0.0791702454425082, alt: 39.9129916484356 },
    { lat: 0.929364856362596, lon: -0.079173928047001, alt: 39.9098471993839 },
    { lat: 0.929366554593528, lon: -0.0791771505208383, alt: 39.9085269686946 },
    { lat: 0.929368309812868, lon: -0.0791801016357474, alt: 39.911142425636 },
    { lat: 0.929369832039163, lon: -0.0791828004795949, alt: 39.9156148443394 },
    { lat: 0.929371027727556, lon: -0.0791850375223607, alt: 39.9093460841938 },
    { lat: 0.929371940699404, lon: -0.079186719069537, alt: 39.9047772628216 },
    { lat: 0.929372560570854, lon: -0.0791878564721397, alt: 39.9016998972462 },
    { lat: 0.929372896158097, lon: -0.0791884718171368, alt: 39.8999719633105 },
    { lat: 0.929372965518604, lon: -0.0791885992299496, alt: 39.8818105849379 },
    { lat: 0.929372965798881, lon: -0.0791885997459486, alt: 39.8762736104428 },
    { lat: 0.929372966059284, lon: -0.0791886002253662, alt: 39.8759162867262 },
    { lat: 0.929372968255944, lon: -0.0791886042691509, alt: 39.8780400614322 },
    { lat: 0.929373001457333, lon: -0.0791886654317036, alt: 39.8795786683728 },
    { lat: 0.929373125191112, lon: -0.0791888943126358, alt: 39.8809735840546 },
    { lat: 0.929373392192487, lon: -0.0791893935092692, alt: 39.8823099753435 },
    { lat: 0.929373806716281, lon: -0.0791901768382583, alt: 39.8832732374409 },
    { lat: 0.929374357069021, lon: -0.0791912179348771, alt: 39.8845032668671 },
    { lat: 0.929375040552665, lon: -0.0791925173473882, alt: 39.8861819751209 },
    { lat: 0.929375825633189, lon: -0.0791940170728865, alt: 39.8881195856483 },
    { lat: 0.929376716715894, lon: -0.0791957326630262, alt: 39.8902875913985 },
    { lat: 0.929377728716498, lon: -0.0791976993713099, alt: 39.8927511325655 },
    { lat: 0.929378822605969, lon: -0.0791998472962781, alt: 39.8953836539834 },
    { lat: 0.92938000919466, lon: -0.0792022035510595, alt: 39.8979098944545 },
    { lat: 0.92938129625509, lon: -0.079204768437974, alt: 39.8986927045517 },
    { lat: 0.929382602702058, lon: -0.0792073422306993, alt: 39.8981585733838 },
    { lat: 0.929383906541426, lon: -0.0792098775046672, alt: 39.8972796898465 },
    { lat: 0.929385211574233, lon: -0.0792123975459073, alt: 39.8962666169054 },
    { lat: 0.9293864621642, lon: -0.0792148224916003, alt: 39.8952343417219 },
    { lat: 0.929387670635745, lon: -0.0792171875221231, alt: 39.8943549358073 },
    { lat: 0.929388863434513, lon: -0.0792195355170534, alt: 39.8936820296945 },
    { lat: 0.929390017793146, lon: -0.0792218213655308, alt: 39.8931369532255 },
    { lat: 0.929391145457844, lon: -0.0792240596946623, alt: 39.8926264735218 },
    { lat: 0.929392245400242, lon: -0.0792262539142516, alt: 39.8921184370637 },
    { lat: 0.92939332647635, lon: -0.0792284117139041, alt: 39.8916181848716 },
    { lat: 0.929394409420394, lon: -0.0792305699461385, alt: 39.8911173788239 },
    { lat: 0.9293954522401, lon: -0.0792326464841502, alt: 39.8906447626158 },
    { lat: 0.929396479648298, lon: -0.0792346933551882, alt: 39.8901902333877 },
    { lat: 0.929397501712386, lon: -0.0792367319824136, alt: 39.8897470993145 },
    { lat: 0.929398520315982, lon: -0.0792386479173375, alt: 39.8891064945297 },
    { lat: 0.929399620237679, lon: -0.0792403514610668, alt: 39.888842054498 },
    { lat: 0.929400758459739, lon: -0.0792419751202066, alt: 39.8886349377988 },
    { lat: 0.929401873912427, lon: -0.0792435784435512, alt: 39.8883398332238 },
    { lat: 0.929402968249289, lon: -0.0792451675789291, alt: 39.8880047465495 },
    { lat: 0.929404039917027, lon: -0.0792467242659214, alt: 39.8876602699638 },
    { lat: 0.929405091260105, lon: -0.079248249557167, alt: 39.8873211629625 },
    { lat: 0.929406140581361, lon: -0.0792497752067535, alt: 39.8869855453703 },
    { lat: 0.929407183533543, lon: -0.0792511912373944, alt: 39.8861729535202 },
    { lat: 0.929408315833829, lon: -0.0792522295235869, alt: 39.8852783792692 },
    { lat: 0.929409522997981, lon: -0.0792529245527927, alt: 39.8854998536685 },
    { lat: 0.929410714639127, lon: -0.0792534053686389, alt: 39.8846262416374 },
    { lat: 0.929411891035975, lon: -0.0792534643453083, alt: 39.8837360429684 },
    { lat: 0.929413021563809, lon: -0.0792530491712459, alt: 39.8838069293415 },
    { lat: 0.929414335904494, lon: -0.0792521902696324, alt: 39.8833619829135 },
];

//Pilot 19, Scenario 1 for 3D visualization.csv
export const expectedData = [
    { lat: 0.929216202787225, lon: -0.0789034263911186, alt: 39.9368303037955 },
    { lat: 0.929216202988808, lon: -0.0789034260988396, alt: 39.8778791092682 },
    { lat: 0.929216202992891, lon: -0.0789034260929202, alt: 39.8768520065474 },
    { lat: 0.929216202993008, lon: -0.0789034260927532, alt: 39.8759990241246 },
    { lat: 0.929216203081033, lon: -0.0789034259651106, alt: 39.877300470426 },
    { lat: 0.929216203311406, lon: -0.078903425631055, alt: 39.877624464132 },
    { lat: 0.929216203537196, lon: -0.0789034253036443, alt: 39.8778964637379 },
    { lat: 0.929216203777997, lon: -0.0789034249544679, alt: 39.8781682873345 },
    { lat: 0.929216204068812, lon: -0.0789034245327644, alt: 39.878633581012 },
    { lat: 0.929216204384505, lon: -0.0789034240749867, alt: 39.8789922732163 },
    { lat: 0.929216204769615, lon: -0.0789034235165457, alt: 39.880013134355 },
    { lat: 0.929216205221402, lon: -0.0789034228614104, alt: 39.8804813180084 },
    { lat: 0.929216205671071, lon: -0.0789034222093502, alt: 39.8802032971383 },
    { lat: 0.92921620608435, lon: -0.0789034216100618, alt: 39.8797569715759 },
    { lat: 0.92921620645227, lon: -0.0789034210765513, alt: 39.8794359205948 },
    { lat: 0.929216206797653, lon: -0.0789034205757204, alt: 39.879249558849 },
    { lat: 0.929216207138037, lon: -0.0789034200821369, alt: 39.8791721758302 },
    { lat: 0.929216207477471, lon: -0.0789034195899338, alt: 39.8791768273256 },
    { lat: 0.929216207813301, lon: -0.0789034191029569, alt: 39.8792129405484 },
    { lat: 0.929216208153897, lon: -0.0789034186090677, alt: 39.8792630022141 },
    { lat: 0.929216208504351, lon: -0.0789034181008837, alt: 39.8793125806 },
    { lat: 0.929216208852089, lon: -0.0789034175966411, alt: 39.8793447559197 },
    { lat: 0.929216209201201, lon: -0.0789034170904059, alt: 39.8793677445267 },
    { lat: 0.929216209556813, lon: -0.0789034165747398, alt: 39.8793744148645 },
    { lat: 0.929216209917041, lon: -0.0789034160523806, alt: 39.8793680137976 },
    { lat: 0.929216210271056, lon: -0.0789034155390307, alt: 39.8793492674639 },
    { lat: 0.929216210617521, lon: -0.0789034150366308, alt: 39.8793446996042 },
    { lat: 0.929216210963193, lon: -0.0789034145353816, alt: 39.8793303063619 },
    { lat: 0.929216211313764, lon: -0.078903414027028, alt: 39.8793304468345 },
    { lat: 0.929216211665503, lon: -0.0789034135169823, alt: 39.8793486793654 },
    { lat: 0.929216212013767, lon: -0.0789034130119787, alt: 39.8793635688244 },
    { lat: 0.929216212362236, lon: -0.0789034125066748, alt: 39.8793735929619 },
    { lat: 0.929216212716466, lon: -0.0789034119930155, alt: 39.8793814199527 },
    { lat: 0.929216213070617, lon: -0.0789034114794684, alt: 39.8793855429578 },
    { lat: 0.92921621341897, lon: -0.0789034109743299, alt: 39.879375983212 },
    { lat: 0.929216213765646, lon: -0.0789034104716222, alt: 39.8793717074672 },
    { lat: 0.929216214117594, lon: -0.0789034099612718, alt: 39.8793684320557 },
    { lat: 0.929216214464412, lon: -0.0789034094583584, alt: 39.8793668876097 },
    { lat: 0.929216214811733, lon: -0.0789034089547177, alt: 39.8793716245476 },
    { lat: 0.929216215168242, lon: -0.0789034084377535, alt: 39.8793902267323 },
    { lat: 0.929216215521658, lon: -0.0789034079252749, alt: 39.8793991859459 },
    { lat: 0.929216215870035, lon: -0.0789034074201034, alt: 39.8794070703446 },
    { lat: 0.929216216218012, lon: -0.0789034069155114, alt: 39.8794110839489 },
    { lat: 0.929216216570926, lon: -0.0789034064037648, alt: 39.8794034764333 },
    { lat: 0.929216216925038, lon: -0.0789034058902769, alt: 39.8793978412785 },
    { lat: 0.929216217278839, lon: -0.0789034053772387, alt: 39.8793962033776 },
    { lat: 0.929216217626624, lon: -0.0789034048729254, alt: 39.8793943074358 },
    { lat: 0.929216217975155, lon: -0.0789034043675273, alt: 39.8794060585363 },
    { lat: 0.929216218328862, lon: -0.0789034038546263, alt: 39.8794195379038 },
    { lat: 0.929216218681829, lon: -0.0789034033427985, alt: 39.8794285322352 },
    { lat: 0.929216219028937, lon: -0.078903402839468, alt: 39.8794347119816 },
    { lat: 0.929216219377133, lon: -0.0789034023345557, alt: 39.8794383070887 },
    { lat: 0.929216219732881, lon: -0.0789034018186928, alt: 39.8794275245102 },
    { lat: 0.929216220084664, lon: -0.078903401308586, alt: 39.879423057883 },
    { lat: 0.929216220432652, lon: -0.0789034008039792, alt: 39.8794225594226 },
    { lat: 0.929216220778898, lon: -0.0789034003018947, alt: 39.8794223334243 },
    { lat: 0.929216221130726, lon: -0.0789033997917191, alt: 39.8794375716602 },
    { lat: 0.929216221483441, lon: -0.0789033992802558, alt: 39.8794497797526 },
    { lat: 0.929216221836605, lon: -0.0789033987681455, alt: 39.8794576866207 },
    { lat: 0.929216222183946, lon: -0.0789033982644766, alt: 39.879463541953 },
    { lat: 0.929216222531128, lon: -0.0789033977610409, alt: 39.8794621728149 },
    { lat: 0.929216222882962, lon: -0.0789033972508539, alt: 39.879452075975 },
    { lat: 0.929216223234866, lon: -0.0789033967405678, alt: 39.8794497193255 },
    { lat: 0.929216223588693, lon: -0.0789033962274957, alt: 39.8794488090447 },
    { lat: 0.929216223933714, lon: -0.0789033957271903, alt: 39.8794516615179 },
    { lat: 0.929216224280932, lon: -0.0789033952236983, alt: 39.8794707886424 },
    { lat: 0.929216224634126, lon: -0.0789033947115391, alt: 39.8794793360276 },
    { lat: 0.929216224991168, lon: -0.0789033941937993, alt: 39.8794875433734 },
    { lat: 0.929216225339477, lon: -0.0789033936887223, alt: 39.8794909361044 },
    { lat: 0.929216225689958, lon: -0.0789033931805026, alt: 39.8794842194429 },
    { lat: 0.929216226042704, lon: -0.0789033926689964, alt: 39.8794783866999 },
    { lat: 0.929216226394094, lon: -0.0789033921594537, alt: 39.879476569358 },
    { lat: 0.92921622674512, lon: -0.0789033916504421, alt: 39.8794763856246 },
    { lat: 0.929216227164267, lon: -0.0789033910425857, alt: 39.8800422510816 },
    { lat: 0.929216235688868, lon: -0.0789033786812867, alt: 39.8817360229038 },
    { lat: 0.929216298821715, lon: -0.0789032873218605, alt: 39.8828285276082 },
    { lat: 0.929216490565375, lon: -0.0789030123977146, alt: 39.8848818922908 },
    { lat: 0.929216900137903, lon: -0.078902434162343, alt: 39.8863982697475 },
    { lat: 0.929217541920839, lon: -0.0789015375779488, alt: 39.8861395377282 },
    { lat: 0.929218330005312, lon: -0.0789004457305704, alt: 39.8934216632626 },
    { lat: 0.929219057126506, lon: -0.0788994423774306, alt: 39.8928031917542 },
    { lat: 0.929219723914357, lon: -0.0788985195557278, alt: 39.888823101854 },
    { lat: 0.929220333609142, lon: -0.0788976935604054, alt: 39.8862773649274 },
    { lat: 0.929220921787909, lon: -0.0788969441731684, alt: 39.8831140358174 },
    { lat: 0.929221491444593, lon: -0.078896296333508, alt: 39.8888303881451 },
    { lat: 0.929221962536754, lon: -0.0788959736759805, alt: 39.8883422225595 },
    { lat: 0.929222276265188, lon: -0.0788959800068846, alt: 39.8885395684433 },
    { lat: 0.92922245069647, lon: -0.078896116115102, alt: 39.8833382764899 },
    { lat: 0.929222666142052, lon: -0.0788963722672887, alt: 39.8847285641065 },
    { lat: 0.929223035618596, lon: -0.0788969065383934, alt: 39.8869517576994 },
    { lat: 0.929223608777833, lon: -0.0788978485706384, alt: 39.888012681555 },
    { lat: 0.929224326678609, lon: -0.0788991108901182, alt: 39.8884196484234 },
    { lat: 0.929224975498185, lon: -0.0789003651480018, alt: 39.8985548109457 },
    { lat: 0.929225411291092, lon: -0.0789012121575076, alt: 39.9043815696433 },
    { lat: 0.929225579547346, lon: -0.0789015273998837, alt: 39.8993743465507 },
    { lat: 0.929225588806184, lon: -0.0789015448073465, alt: 39.8788128950597 },
    { lat: 0.929225590179622, lon: -0.0789015473788097, alt: 39.8893464812423 },
    { lat: 0.929225592618448, lon: -0.0789015519427234, alt: 39.8952724633078 },
    { lat: 0.929225595675602, lon: -0.0789015576634447, alt: 39.8977093214462 },
    { lat: 0.929225613484561, lon: -0.0789015911173143, alt: 39.8905922795669 },
    { lat: 0.929225781197191, lon: -0.0789019093706434, alt: 39.8871326527423 },
    { lat: 0.929226236933018, lon: -0.078902794049534, alt: 39.8920016423146 },
    { lat: 0.929227076368207, lon: -0.0789044658672716, alt: 39.8980351986842 },
    { lat: 0.929228301920284, lon: -0.0789069455020087, alt: 39.9055489904912 },
    { lat: 0.929229851898617, lon: -0.0789100709482692, alt: 39.9152608544248 },
    { lat: 0.929231749367593, lon: -0.0789138596468383, alt: 39.9269738382935 },
    { lat: 0.929234037180178, lon: -0.078918366870342, alt: 39.9405857908567 },
    { lat: 0.929236672913603, lon: -0.0789235109017971, alt: 39.955433954132 },
    { lat: 0.929239588078214, lon: -0.0789291819138893, alt: 39.9716171566735 },
    { lat: 0.929242821157557, lon: -0.0789354682888903, alt: 39.9889787348583 },
    { lat: 0.92924642744161, lon: -0.0789424717696041, alt: 40.0118588237118 },
    { lat: 0.929250339046512, lon: -0.0789500621602446, alt: 40.0325667700485 },
    { lat: 0.929254474112132, lon: -0.078958085576352, alt: 40.0545077306003 },
    { lat: 0.929258890154346, lon: -0.0789666515421691, alt: 40.080129187756 },
    { lat: 0.929263650709522, lon: -0.078975879049103, alt: 40.1100060692267 },
    { lat: 0.929268681264469, lon: -0.0789856281535586, alt: 40.1546064777299 },
    { lat: 0.929273962594125, lon: -0.0789958654916204, alt: 40.2110507889794 },
    { lat: 0.929279397932675, lon: -0.07900639953906, alt: 40.1883028986122 },
    { lat: 0.929285060593744, lon: -0.0790173623291447, alt: 41.0604240804627 },
    { lat: 0.929291023998039, lon: -0.0790289002741146, alt: 45.4610624585727 },
    { lat: 0.929297164162452, lon: -0.0790407746925119, alt: 52.7677618034127 },
    { lat: 0.929303358558276, lon: -0.0790527477385682, alt: 62.0612218552263 },
    { lat: 0.929309716271221, lon: -0.0790650290597499, alt: 73.3861421888871 },
    { lat: 0.929316315219037, lon: -0.0790777763859827, alt: 84.9209721639483 },
    { lat: 0.929323063489057, lon: -0.0790908350746229, alt: 97.5130847914218 },
    { lat: 0.92932982462153, lon: -0.0791039737761192, alt: 113.018033278911 },
    { lat: 0.929336702363083, lon: -0.0791174267447174, alt: 131.213815365563 },
    { lat: 0.92934378605948, lon: -0.0791313970153608, alt: 153.25476422927 },
    { lat: 0.929350906802939, lon: -0.0791455609722049, alt: 179.696055473584 },
    { lat: 0.929358059804244, lon: -0.0791599007816738, alt: 208.238807000362 },
    { lat: 0.929365341890775, lon: -0.0791746051863245, alt: 237.19387607157 },
    { lat: 0.929372569465766, lon: -0.0791893029162083, alt: 265.908449865703 },
    { lat: 0.929379853982982, lon: -0.0792042241121348, alt: 295.810177252194 },
    { lat: 0.92938730039715, lon: -0.0792195913629535, alt: 328.547620694194 },
    { lat: 0.92939477930323, lon: -0.0792351404700125, alt: 363.154374350092 },
    { lat: 0.929402166465601, lon: -0.0792506077800678, alt: 398.027826834277 },
    { lat: 0.929409581150111, lon: -0.0792662451565766, alt: 434.401473165798 },
    { lat: 0.929417123280225, lon: -0.0792822700885252, alt: 473.762697325234 },
    { lat: 0.929424680097706, lon: -0.0792984375900899, alt: 512.960344550373 },
    { lat: 0.92943217631058, lon: -0.0793145865226145, alt: 551.677804928095 },
    { lat: 0.929439764004549, lon: -0.0793310541165413, alt: 593.087437564809 },
    { lat: 0.929447279036306, lon: -0.0793472632505281, alt: 635.620878150131 },
    { lat: 0.929455433634714, lon: -0.0793626351677396, alt: 677.109958023082 },
    { lat: 0.929464940373383, lon: -0.0793762383089115, alt: 718.109805847042 },
    { lat: 0.929475542901475, lon: -0.0793877057216991, alt: 749.099832903835 },
    { lat: 0.929487017973384, lon: -0.0793962276473306, alt: 771.26765741562 },
    { lat: 0.929499323126796, lon: -0.0794014738210114, alt: 786.093435161947 },
    { lat: 0.92951234149798, lon: -0.0794026377994067, alt: 796.941549224963 },
    { lat: 0.92952536408631, lon: -0.0793992195370802, alt: 804.418195933115 },
    { lat: 0.92953752223554, lon: -0.0793911388407388, alt: 810.133528749114 },
    { lat: 0.929548278900979, lon: -0.0793784079269468, alt: 815.698760584651 },
    { lat: 0.929557045765785, lon: -0.0793612805526949, alt: 821.888555712376 },
    { lat: 0.929563100287612, lon: -0.0793413141855159, alt: 827.278959777609 },
    { lat: 0.929566469831769, lon: -0.0793193811348041, alt: 830.247250414979 },
    { lat: 0.929566893801871, lon: -0.079296200670008, alt: 833.63507288069 },
    { lat: 0.929564440849351, lon: -0.0792737681279348, alt: 841.913317782726 },
    { lat: 0.929559493960691, lon: -0.0792520541610902, alt: 857.997769036899 },
    { lat: 0.929553019120278, lon: -0.0792321487556875, alt: 881.212330334799 },
    { lat: 0.929545524393836, lon: -0.0792135052162432, alt: 913.75053772225 },
    { lat: 0.929537484655179, lon: -0.0791951868983806, alt: 951.214478279687 },
    { lat: 0.929529423117102, lon: -0.0791772734410286, alt: 989.026005733207 },
    { lat: 0.929521372675888, lon: -0.0791593848922552, alt: 1027.1634949488 },
    { lat: 0.929513495573157, lon: -0.0791418098024589, alt: 1060.58059301592 },
    { lat: 0.929505665857215, lon: -0.079124281673318, alt: 1083.48993305545 },
    { lat: 0.929497726649375, lon: -0.0791064595542299, alt: 1093.10595048869 },
    { lat: 0.929489802324712, lon: -0.0790886573386305, alt: 1092.11649034048 },
    { lat: 0.929482024002774, lon: -0.0790711987661905, alt: 1087.49876058469 },
    { lat: 0.929474217370259, lon: -0.0790536990385621, alt: 1085.93647751725 },
    { lat: 0.92946645443528, lon: -0.0790363200930962, alt: 1085.11713783916 },
    { lat: 0.929458801201433, lon: -0.079019210070729, alt: 1086.01510635027 },
    { lat: 0.929451123866898, lon: -0.0790020206211994, alt: 1088.66838982367 },
    { lat: 0.929443622580273, lon: -0.0789850907833704, alt: 1091.74695934602 },
    { lat: 0.92943641753831, lon: -0.0789686643590653, alt: 1095.05247814188 },
    { lat: 0.929429403651755, lon: -0.0789524371684746, alt: 1097.68030365044 },
    { lat: 0.929422552780376, lon: -0.078936066967541, alt: 1099.15796304509 },
    { lat: 0.929416140297087, lon: -0.0789200493646547, alt: 1100.90117972426 },
    { lat: 0.929410037546273, lon: -0.0789041261910961, alt: 1101.5423445119 },
    { lat: 0.929404046333091, lon: -0.0788880340207835, alt: 1098.39364444765 },
    { lat: 0.929398192708373, lon: -0.078872202956723, alt: 1095.08687249862 },
    { lat: 0.92939254352632, lon: -0.0788569869877693, alt: 1094.25016978131 },
    { lat: 0.929387020339709, lon: -0.0788421618446205, alt: 1095.05942715833 },
    { lat: 0.929381540153678, lon: -0.0788274706765717, alt: 1096.95359427488 },
    { lat: 0.929376264835043, lon: -0.0788133135235777, alt: 1097.88469522854 },
    { lat: 0.929371094026833, lon: -0.0787994043671903, alt: 1097.04942151623 },
    { lat: 0.92936596166227, lon: -0.0787855615397179, alt: 1095.20237171926 },
    { lat: 0.929360920538386, lon: -0.0787719261226059, alt: 1092.35304408207 },
    { lat: 0.92935595332652, lon: -0.0787584508877674, alt: 1088.22197857434 },
    { lat: 0.929351130195202, lon: -0.0787453268000079, alt: 1082.72104234875 },
    { lat: 0.929346360470886, lon: -0.0787323071346446, alt: 1075.93377405783 },
    { lat: 0.929341564084923, lon: -0.0787191708493404, alt: 1069.29812545962 },
    { lat: 0.92933689926158, lon: -0.0787063519509147, alt: 1062.15807523597 },
    { lat: 0.929332265994065, lon: -0.0786935719333236, alt: 1054.49541575276 },
    { lat: 0.929327554532356, lon: -0.0786805165931394, alt: 1046.40498643673 },
    { lat: 0.929322781775521, lon: -0.078667212950354, alt: 1038.92107618951 },
    { lat: 0.929317985709463, lon: -0.0786537603132225, alt: 1031.91564848002 },
    { lat: 0.929313118759978, lon: -0.0786400230644296, alt: 1026.47175286447 },
    { lat: 0.929308102131635, lon: -0.0786257769915388, alt: 1023.24598281211 },
    { lat: 0.929303118955425, lon: -0.0786115453705196, alt: 1021.80805658509 },
    { lat: 0.92929808841589, lon: -0.0785971020440121, alt: 1021.55201859816 },
    { lat: 0.929292935178395, lon: -0.0785822330812705, alt: 1021.28044247434 },
    { lat: 0.92928773672131, lon: -0.0785671616428244, alt: 1020.28329695292 },
    { lat: 0.929282503821599, lon: -0.078551917227872, alt: 1020.0193410749 },
    { lat: 0.929277350559157, lon: -0.0785368385163644, alt: 1020.452678561 },
    { lat: 0.929272227982009, lon: -0.0785217891867002, alt: 1021.19337650967 },
    { lat: 0.929267055165844, lon: -0.0785065342114716, alt: 1022.15359857899 },
    { lat: 0.929261908926304, lon: -0.0784915006334067, alt: 1023.94624529289 },
    { lat: 0.929256612345743, lon: -0.0784772800681245, alt: 1022.96478908035 },
    { lat: 0.929250783963308, lon: -0.0784639327224305, alt: 1014.08719917061 },
    { lat: 0.929244133510085, lon: -0.0784515391759444, alt: 996.711998369534 },
    { lat: 0.92923658447406, lon: -0.0784409202377619, alt: 976.550285548688 },
    { lat: 0.92922836973742, lon: -0.0784325120369188, alt: 954.906614109227 },
    { lat: 0.929219534426361, lon: -0.0784264314037526, alt: 932.452997755397 },
    { lat: 0.929210140428381, lon: -0.0784226976647012, alt: 907.839809582247 },
    { lat: 0.929200575645391, lon: -0.0784215956785671, alt: 882.152612571883 },
    { lat: 0.929191253207372, lon: -0.078423040475602, alt: 856.129065224446 },
    { lat: 0.929182190103159, lon: -0.078426720963852, alt: 827.271535163423 },
    { lat: 0.929173416081126, lon: -0.0784326155707293, alt: 795.707901784831 },
    { lat: 0.929165201426191, lon: -0.0784404436282659, alt: 761.010034344486 },
    { lat: 0.929157751036556, lon: -0.0784497645483727, alt: 723.298923468356 },
    { lat: 0.929150976960773, lon: -0.0784605342632493, alt: 683.788199857549 },
    { lat: 0.929144810811369, lon: -0.0784727946492029, alt: 644.789864609631 },
    { lat: 0.929139426100821, lon: -0.0784861357555758, alt: 608.842717361734 },
    { lat: 0.929134912551894, lon: -0.0785001029857284, alt: 577.137995877505 },
    { lat: 0.929131159083344, lon: -0.0785147390016501, alt: 550.499378475454 },
    { lat: 0.929128014866679, lon: -0.0785300404519017, alt: 528.714529375363 },
    { lat: 0.929125383253636, lon: -0.0785455382802126, alt: 510.267032181619 },
    { lat: 0.929123179488045, lon: -0.0785608488736956, alt: 493.391528915498 },
    { lat: 0.929121313623922, lon: -0.078576160340257, alt: 475.89031449257 },
    { lat: 0.929119822626066, lon: -0.0785917145175544, alt: 455.715741187668 },
    { lat: 0.929119071545389, lon: -0.0786072655512573, alt: 433.008565957462 },
    { lat: 0.929119390471554, lon: -0.078622473478179, alt: 408.07651070414 },
    { lat: 0.929120932095627, lon: -0.0786374024088193, alt: 380.982151061417 },
    { lat: 0.92912369053827, lon: -0.0786520430198665, alt: 353.666355853299 },
    { lat: 0.929127308561585, lon: -0.0786661334118262, alt: 328.219522140203 },
    { lat: 0.929131357937255, lon: -0.0786798698238685, alt: 306.012077543842 },
    { lat: 0.929135438592009, lon: -0.078693226713795, alt: 288.058469875739 },
    { lat: 0.929139507754736, lon: -0.0787064808026945, alt: 272.334630361316 },
    { lat: 0.929143624267673, lon: -0.0787198376919196, alt: 257.164376772229 },
    { lat: 0.929147786614988, lon: -0.0787330495656448, alt: 240.787147074372 },
    { lat: 0.929152104232033, lon: -0.0787456791442828, alt: 223.248810992331 },
    { lat: 0.929156753601102, lon: -0.0787577963709174, alt: 205.228528099115 },
    { lat: 0.929161783297221, lon: -0.0787695711136725, alt: 186.122921879552 },
    { lat: 0.92916692136517, lon: -0.0787807428828716, alt: 168.050102726355 },
    { lat: 0.929172094781153, lon: -0.0787916833444551, alt: 151.656236329959 },
    { lat: 0.929177286568461, lon: -0.0788026839304049, alt: 135.003809078294 },
    { lat: 0.929182407594535, lon: -0.0788135619727292, alt: 117.676303800318 },
    { lat: 0.929187514182143, lon: -0.0788242316489444, alt: 102.362113167966 },
    { lat: 0.929192544517369, lon: -0.0788344759969368, alt: 89.2665376225139 },
    { lat: 0.92919756606835, lon: -0.0788444814329354, alt: 77.2746583298676 },
    { lat: 0.929202630313479, lon: -0.0788544721473194, alt: 65.4832418714713 },
    { lat: 0.929207634416522, lon: -0.0788643443736911, alt: 54.4223546286007 },
    { lat: 0.929212485709302, lon: -0.078873942153611, alt: 45.7539686449384 },
    { lat: 0.929217247204688, lon: -0.0788833700408196, alt: 40.5500568164799 },
    { lat: 0.929222000261193, lon: -0.0788927804307957, alt: 40.1553038952434 },
    { lat: 0.929226658667697, lon: -0.0789020294369889, alt: 40.1278606556129 },
    { lat: 0.929231198522342, lon: -0.0789110717206138, alt: 40.1190376702092 },
    { lat: 0.929235612348976, lon: -0.0789198898198477, alt: 40.1196821146049 },
    { lat: 0.929240090199049, lon: -0.0789288566059419, alt: 40.1303786468838 },
    { lat: 0.929244704815011, lon: -0.0789380977105293, alt: 40.1272960557764 },
    { lat: 0.929249223868436, lon: -0.0789471343291375, alt: 40.1091707440634 },
    { lat: 0.929253491056021, lon: -0.0789556676815075, alt: 40.0890363094537 },
    { lat: 0.929257561307489, lon: -0.0789637819077828, alt: 40.0687139944751 },
    { lat: 0.929261484875822, lon: -0.0789715859418021, alt: 40.0483519313824 },
    { lat: 0.929265169100125, lon: -0.0789789119821005, alt: 40.0287395416914 },
    { lat: 0.929268556418706, lon: -0.0789856362605031, alt: 40.0104200413649 },
    { lat: 0.929271740303023, lon: -0.0789919199362137, alt: 39.9930774225819 },
    { lat: 0.929274660845921, lon: -0.078997652631482, alt: 39.9771993418858 },
    { lat: 0.929277293899118, lon: -0.0790028105965621, alt: 39.9624318531228 },
    { lat: 0.92927968726621, lon: -0.0790074970109918, alt: 39.94981649556 },
    { lat: 0.929281762442561, lon: -0.0790115587569163, alt: 39.9375106132351 },
    { lat: 0.929283637831061, lon: -0.0790152161356392, alt: 39.9208090081011 },
    { lat: 0.92928550056526, lon: -0.0790188199208111, alt: 39.9198486660136 },
    { lat: 0.929287296323856, lon: -0.0790222811539696, alt: 39.9179877907361 },
    { lat: 0.92928905245395, lon: -0.0790256530531452, alt: 39.916346641825 },
    { lat: 0.929290803662686, lon: -0.0790290009461731, alt: 39.9148847241824 },
    { lat: 0.929292486795219, lon: -0.0790322155641028, alt: 39.9140354156372 },
    { lat: 0.929294037687811, lon: -0.079035204254536, alt: 39.921227762164 },
    { lat: 0.929295432092222, lon: -0.0790379459017176, alt: 39.902296768038 },
    { lat: 0.929296776283907, lon: -0.0790406256798413, alt: 39.902966293956 },
    { lat: 0.929298000055375, lon: -0.0790432409747953, alt: 39.9011057872744 },
    { lat: 0.929298999530538, lon: -0.079045944235052, alt: 39.8997092232292 },
    { lat: 0.929299712824939, lon: -0.0790487406359962, alt: 39.8975477986796 },
    { lat: 0.929300097063011, lon: -0.0790515284736205, alt: 39.8967168481829 },
    { lat: 0.929300202219957, lon: -0.0790541789792648, alt: 39.8949224241302 },
    { lat: 0.929300068854841, lon: -0.079056658357326, alt: 39.8947102620005 },
    { lat: 0.929299719198729, lon: -0.0790589016767265, alt: 39.8935324455237 },
    { lat: 0.929299135648952, lon: -0.079060755645663, alt: 39.8909870668051 },
    { lat: 0.929298424143713, lon: -0.0790622494736569, alt: 39.8897279241241 },
    { lat: 0.929297655434601, lon: -0.0790635527216063, alt: 39.8879308601415 },
    { lat: 0.929296888786488, lon: -0.0790648447839814, alt: 39.8877997116047 },
    { lat: 0.929296158171983, lon: -0.0790661062975325, alt: 39.8882889565682 },
    { lat: 0.929295466091881, lon: -0.0790672187442292, alt: 39.9004408405342 },
    { lat: 0.929295128127368, lon: -0.0790676983675665, alt: 39.9028718701105 },
];

//Pilot 23, Scenario 1 for 3D visualization.csv
export const data = [
    { lat: 0.929216202786879, lon: -0.0789034263915757, alt: 39.9373087392857 },
    { lat: 0.929216202993907, lon: -0.0789034260913519, alt: 39.8745123382103 },
    { lat: 0.929216203002205, lon: -0.0789034260793198, alt: 39.8737382695 },
    { lat: 0.929216203002408, lon: -0.0789034260790251, alt: 39.8722656491318 },
    { lat: 0.929216203092159, lon: -0.0789034259488765, alt: 39.8740300201108 },
    { lat: 0.929216203275133, lon: -0.0789034256835533, alt: 39.8739901851268 },
    { lat: 0.929216203476511, lon: -0.0789034253915445, alt: 39.8747697910867 },
    { lat: 0.929216203710702, lon: -0.0789034250519516, alt: 39.875035432829 },
    { lat: 0.929216203969445, lon: -0.0789034246767565, alt: 39.8752597576103 },
    { lat: 0.929216204240834, lon: -0.0789034242832237, alt: 39.8753525148429 },
    { lat: 0.929216204530989, lon: -0.0789034238624778, alt: 39.8756639302017 },
    { lat: 0.929216204863335, lon: -0.0789034233805535, alt: 39.8761263390231 },
    { lat: 0.92921620521682, lon: -0.0789034228679761, alt: 39.8761870012492 },
    { lat: 0.929216205548717, lon: -0.0789034223867006, alt: 39.8760011935783 },
    { lat: 0.929216205863246, lon: -0.0789034219306114, alt: 39.8757762646886 },
    { lat: 0.929216206158428, lon: -0.0789034215025773, alt: 39.8755885500737 },
    { lat: 0.929216206439563, lon: -0.0789034210949123, alt: 39.8754697547931 },
    { lat: 0.929216206708344, lon: -0.0789034207051612, alt: 39.8754090368352 },
    { lat: 0.929216206974575, lon: -0.0789034203191072, alt: 39.8754018207325 },
    { lat: 0.92921620724643, lon: -0.0789034199248999, alt: 39.8754216411945 },
    { lat: 0.929216207523562, lon: -0.078903419523039, alt: 39.8754603293133 },
    { lat: 0.929216207802132, lon: -0.0789034191190926, alt: 39.8754977747643 },
    { lat: 0.929216208079838, lon: -0.0789034187163998, alt: 39.8755296336853 },
    { lat: 0.929216208358876, lon: -0.0789034183117764, alt: 39.8755516173393 },
    { lat: 0.92921620864225, lon: -0.078903417900864, alt: 39.8755565658086 },
    { lat: 0.929216208925056, lon: -0.078903417490775, alt: 39.8755515917029 },
    { lat: 0.929216209202366, lon: -0.0789034170886559, alt: 39.8755427063596 },
    { lat: 0.929216209479109, lon: -0.0789034166873582, alt: 39.8755371508402 },
    { lat: 0.929216209759358, lon: -0.0789034162809787, alt: 39.8755284894955 },
    { lat: 0.929216210039299, lon: -0.0789034158750444, alt: 39.8755343917997 },
    { lat: 0.929216210319683, lon: -0.0789034154684696, alt: 39.8755436624017 },
    { lat: 0.929216210599224, lon: -0.0789034150631139, alt: 39.8755580945847 },
    { lat: 0.929216210876666, lon: -0.0789034146608032, alt: 39.8755663631728 },
    { lat: 0.929216211158946, lon: -0.0789034142514787, alt: 39.8755693695868 },
    { lat: 0.929216211440549, lon: -0.0789034138431333, alt: 39.8755625167255 },
    { lat: 0.929216211727012, lon: -0.0789034134277409, alt: 39.8756723330786 },
    { lat: 0.929216212037121, lon: -0.0789034129780601, alt: 39.8760000103409 },
    { lat: 0.929216212382217, lon: -0.0789034124776433, alt: 39.8762889535962 },
    { lat: 0.929216212743609, lon: -0.0789034119535992, alt: 39.8764634137882 },
    { lat: 0.929216213115367, lon: -0.0789034114145257, alt: 39.8764999035066 },
    { lat: 0.929216213474577, lon: -0.0789034108936473, alt: 39.8764554314257 },
    { lat: 0.92921621382753, lon: -0.0789034103818376, alt: 39.8763901731223 },
    { lat: 0.929216214180938, lon: -0.078903409869367, alt: 39.8763316591502 },
    { lat: 0.929216214535206, lon: -0.0789034093556487, alt: 39.8762999369563 },
    { lat: 0.929216214877496, lon: -0.0789034088593027, alt: 39.8762765751771 },
    { lat: 0.929216215222126, lon: -0.0789034083595605, alt: 39.8762668328682 },
    { lat: 0.929216215573298, lon: -0.0789034078503337, alt: 39.8762927714334 },
    { lat: 0.929216215924926, lon: -0.0789034073404443, alt: 39.8763140080596 },
    { lat: 0.929216216278296, lon: -0.0789034068280318, alt: 39.8763314913403 },
    { lat: 0.929216216623633, lon: -0.0789034063272676, alt: 39.8763423547795 },
    { lat: 0.929216216971426, lon: -0.0789034058229469, alt: 39.8763513168162 },
    { lat: 0.929216217322098, lon: -0.0789034053144474, alt: 39.8763465474966 },
    { lat: 0.9292162176753, lon: -0.0789034048022805, alt: 39.8763411749766 },
    { lat: 0.929216218023594, lon: -0.0789034042972287, alt: 39.8763260245734 },
    { lat: 0.929216218365289, lon: -0.0789034038017443, alt: 39.876323030009 },
    { lat: 0.929216218707089, lon: -0.0789034033061081, alt: 39.8763359726532 },
    { lat: 0.92921621905789, lon: -0.0789034027974174, alt: 39.8763520905342 },
    { lat: 0.929216219411441, lon: -0.078903402284741, alt: 39.8763664109049 },
    { lat: 0.929216219756716, lon: -0.0789034017840686, alt: 39.8763726728095 },
    { lat: 0.929216220101298, lon: -0.0789034012843991, alt: 39.8763693224474 },
    { lat: 0.929216220451163, lon: -0.0789034007770715, alt: 39.8763635469438 },
    { lat: 0.929216220799903, lon: -0.0789034002713708, alt: 39.8763624631792 },
    { lat: 0.929216221148327, lon: -0.0789033997661301, alt: 39.8763534954345 },
    { lat: 0.929216221490217, lon: -0.0789033992703679, alt: 39.876349577635 },
    { lat: 0.929216221833771, lon: -0.0789033987721888, alt: 39.8763654105561 },
    { lat: 0.929216222185598, lon: -0.0789033982620146, alt: 39.8763872462866 },
    { lat: 0.929216222536072, lon: -0.0789033977537991, alt: 39.8763949555978 },
    { lat: 0.92921622288064, lon: -0.0789033972541486, alt: 39.8764015674314 },
    { lat: 0.929216223229252, lon: -0.0789033967486357, alt: 39.876392666789 },
    { lat: 0.929216223588506, lon: -0.0789033962276893, alt: 39.8763941935982 },
    { lat: 0.929216223932279, lon: -0.0789033957291938, alt: 39.8763825103539 },
    { lat: 0.929216224275431, lon: -0.0789033952315989, alt: 39.8763756738968 },
    { lat: 0.929216224623501, lon: -0.0789033947268756, alt: 39.8763851395392 },
    { lat: 0.929216224966978, lon: -0.0789033942288136, alt: 39.8764006279159 },
    { lat: 0.929216225311793, lon: -0.0789033937288108, alt: 39.8764140972634 },
    { lat: 0.929216225660322, lon: -0.078903393223426, alt: 39.8763673493217 },
    { lat: 0.929216225982738, lon: -0.0789033927559002, alt: 39.8760457304122 },
    { lat: 0.929216226314678, lon: -0.0789033922745437, alt: 39.8762737349277 },
    { lat: 0.929216226911901, lon: -0.0789033914081476, alt: 39.8795666459012 },
    { lat: 0.929216228151773, lon: -0.0789033896092323, alt: 39.8840701923143 },
    { lat: 0.92921623637062, lon: -0.0789033776909735, alt: 39.882112759839 },
    { lat: 0.929216305063678, lon: -0.0789032781560538, alt: 39.8809997127388 },
    { lat: 0.929216427839409, lon: -0.0789030944177922, alt: 39.881215276089 },
    { lat: 0.929216612675806, lon: -0.0789028011494707, alt: 39.8802286901159 },
    { lat: 0.929216869165761, lon: -0.0789023648988953, alt: 39.8801401876759 },
    { lat: 0.92921719643831, lon: -0.0789017827268666, alt: 39.8805166568259 },
    { lat: 0.929217618097239, lon: -0.0789010227155917, alt: 39.8812763614313 },
    { lat: 0.929218150672523, lon: -0.0789000656882177, alt: 39.8813014652939 },
    { lat: 0.929218732230502, lon: -0.0788990518871462, alt: 39.8910902269347 },
    { lat: 0.929219181445241, lon: -0.078898289518038, alt: 39.8842534571883 },
    { lat: 0.929219559639405, lon: -0.0788976518661663, alt: 39.8807637650999 },
    { lat: 0.929219908875433, lon: -0.0788971833525565, alt: 39.8840447629147 },
    { lat: 0.929220218759277, lon: -0.0788969498979375, alt: 39.8781938335762 },
    { lat: 0.929220502590995, lon: -0.0788968214444827, alt: 39.8800120563055 },
    { lat: 0.929220815461065, lon: -0.0788967700163312, alt: 39.8813009742184 },
    { lat: 0.929221264756533, lon: -0.0788968109870197, alt: 39.8824436671458 },
    { lat: 0.929221935480513, lon: -0.0788969919771078, alt: 39.8846066408425 },
    { lat: 0.929222813108593, lon: -0.0788974110756181, alt: 39.8866444637247 },
    { lat: 0.929223694245307, lon: -0.0788981661138576, alt: 39.8959649977545 },
    { lat: 0.929224292608819, lon: -0.0788989672763649, alt: 39.8937068551838 },
    { lat: 0.929224631044322, lon: -0.0788996470022869, alt: 39.8840293216392 },
    { lat: 0.929224786569392, lon: -0.0789003035628733, alt: 39.879184684758 },
    { lat: 0.929224889189711, lon: -0.0789008949110655, alt: 39.8815909327148 },
    { lat: 0.929224985506602, lon: -0.0789013057107247, alt: 39.8796053683624 },
    { lat: 0.929225071294384, lon: -0.0789015731325973, alt: 39.8828938716584 },
    { lat: 0.929225110426314, lon: -0.0789016677991297, alt: 39.8826302016029 },
    { lat: 0.929225113655038, lon: -0.0789016756502614, alt: 39.8817357779299 },
    { lat: 0.929225132211891, lon: -0.0789017229536624, alt: 39.8819945222087 },
    { lat: 0.929225241487612, lon: -0.0789019832239932, alt: 39.8839045486291 },
    { lat: 0.929225514697898, lon: -0.0789025563323267, alt: 39.8896720736803 },
    { lat: 0.929225992699939, lon: -0.0789032438639867, alt: 39.8888984495085 },
    { lat: 0.929226590829889, lon: -0.0789040720975952, alt: 39.8945153487854 },
    { lat: 0.929227041220927, lon: -0.0789048107226167, alt: 39.9010134549427 },
    { lat: 0.929227233330344, lon: -0.0789051348304459, alt: 39.8980062936391 },
    { lat: 0.929227245902939, lon: -0.0789051560912923, alt: 39.8732643098777 },
    { lat: 0.929227246149531, lon: -0.0789051565089743, alt: 39.8756735319368 },
    { lat: 0.929227246854585, lon: -0.0789051577016131, alt: 39.8811977954992 },
    { lat: 0.929227248581656, lon: -0.0789051606213176, alt: 39.8880487203549 },
    { lat: 0.929227263848964, lon: -0.0789051864880949, alt: 39.8866553653743 },
    { lat: 0.929227411398838, lon: -0.0789054395616305, alt: 39.883305508197 },
    { lat: 0.929227826149349, lon: -0.0789061689881632, alt: 39.8875078578829 },
    { lat: 0.929228614996795, lon: -0.0789076289879511, alt: 39.8938910074334 },
    { lat: 0.929229780833945, lon: -0.0789099050839747, alt: 39.9009612415838 },
    { lat: 0.929231261516749, lon: -0.0789128650307172, alt: 39.9101268146962 },
    { lat: 0.929233095075785, lon: -0.0789164730337281, alt: 39.9214093243752 },
    { lat: 0.929235309410175, lon: -0.0789207991154417, alt: 39.9345810246176 },
    { lat: 0.929237860370815, lon: -0.0789257765680347, alt: 39.9490690922506 },
    { lat: 0.929240678459416, lon: -0.0789313061308469, alt: 39.9647744029784 },
    { lat: 0.929243827131429, lon: -0.0789374257887254, alt: 39.9817259167555 },
    { lat: 0.92924737039696, lon: -0.0789442247741818, alt: 40.000124310197 },
    { lat: 0.929251155342385, lon: -0.0789515018994929, alt: 40.0190485167413 },
    { lat: 0.929255202952337, lon: -0.0789593482961647, alt: 40.0386167863939 },
    { lat: 0.92925958649437, lon: -0.0789679004707236, alt: 40.0595516083143 },
    { lat: 0.929264240341193, lon: -0.0789770067599112, alt: 40.0863610706179 },
    { lat: 0.929269065709851, lon: -0.0789864746721022, alt: 40.106805839588 },
    { lat: 0.92927415788527, lon: -0.0789965098490206, alt: 40.1356207380194 },
    { lat: 0.929279566326102, lon: -0.0790071958277456, alt: 40.5120250488134 },
    { lat: 0.929285078694282, lon: -0.0790180965710711, alt: 43.6996740601774 },
    { lat: 0.929290758361574, lon: -0.0790293361101057, alt: 50.6070409375101 },
    { lat: 0.929296659711327, lon: -0.0790410324395172, alt: 63.9824623877494 },
    { lat: 0.929302560735329, lon: -0.0790527511297891, alt: 83.8454383663613 },
    { lat: 0.92930850957153, lon: -0.0790645860723454, alt: 107.867526253237 },
    { lat: 0.92931459307842, lon: -0.0790767090096854, alt: 134.956110656998 },
    { lat: 0.929320731581526, lon: -0.0790889604712599, alt: 162.942874267691 },
    { lat: 0.929326821775004, lon: -0.0791011363711243, alt: 191.274632108752 },
    { lat: 0.929332973088481, lon: -0.0791134637666846, alt: 219.871946525235 },
    { lat: 0.929339316477834, lon: -0.0791262166962181, alt: 245.720380640783 },
    { lat: 0.929345783827506, lon: -0.0791392811315355, alt: 266.980457818736 },
    { lat: 0.929352241047142, lon: -0.0791524306130101, alt: 288.992527351304 },
    { lat: 0.929358744603269, lon: -0.0791658080332121, alt: 316.09907589913 },
    { lat: 0.9293654456538, lon: -0.0791797062092981, alt: 342.635207807401 },
    { lat: 0.929372244975497, lon: -0.0791939189549606, alt: 366.244894808564 },
    { lat: 0.92937902020537, lon: -0.079208202198896, alt: 388.907656979687 },
    { lat: 0.929385866289049, lon: -0.0792227593816413, alt: 411.635215036571 },
    { lat: 0.9293929093275, lon: -0.0792378439006396, alt: 435.674970539756 },
    { lat: 0.929400036847128, lon: -0.0792531807300041, alt: 458.849898966013 },
    { lat: 0.929407187140681, lon: -0.0792686409900408, alt: 483.470455471839 },
    { lat: 0.929414197349979, lon: -0.0792838736795164, alt: 508.903347536102 },
    { lat: 0.92942117848565, lon: -0.0792991161844083, alt: 533.830976722195 },
    { lat: 0.929428244964879, lon: -0.0793146221657751, alt: 559.047116434063 },
    { lat: 0.929435283377366, lon: -0.0793301378804727, alt: 584.386914377812 },
    { lat: 0.929442187515983, lon: -0.0793453855209883, alt: 608.301604859768 },
    { lat: 0.929449096316402, lon: -0.0793606475080875, alt: 631.040965111785 },
    { lat: 0.929456143593194, lon: -0.0793762037269965, alt: 650.963967644606 },
    { lat: 0.929463221273075, lon: -0.0793918155014871, alt: 668.024080754166 },
    { lat: 0.929470335039951, lon: -0.0794074991529709, alt: 683.74587561891 },
    { lat: 0.929477357850507, lon: -0.0794229754980462, alt: 700.494676766896 },
    { lat: 0.929484416802607, lon: -0.0794384774558307, alt: 716.980842855776 },
    { lat: 0.9294917642835, lon: -0.0794541453576774, alt: 734.240015417514 },
    { lat: 0.929499858288909, lon: -0.0794687204459622, alt: 750.821546827798 },
    { lat: 0.929509136948852, lon: -0.0794803220708954, alt: 773.271970506724 },
    { lat: 0.929519393146673, lon: -0.0794892511503575, alt: 793.934436500254 },
    { lat: 0.929530614198031, lon: -0.0794944956711787, alt: 817.548574790889 },
    { lat: 0.929542153462801, lon: -0.0794955904518488, alt: 844.0439172269 },
    { lat: 0.929553520034856, lon: -0.0794937559315286, alt: 864.397797567537 },
    { lat: 0.929564791177569, lon: -0.0794896768998996, alt: 875.571777054579 },
    { lat: 0.929576025598734, lon: -0.0794831818243733, alt: 881.024385068707 },
    { lat: 0.929586796941193, lon: -0.079474274818198, alt: 885.764919203674 },
    { lat: 0.929596836771888, lon: -0.079463392857479, alt: 890.186868775781 },
    { lat: 0.929606160455029, lon: -0.0794506604061432, alt: 893.697232807247 },
    { lat: 0.929614389492807, lon: -0.0794355195078166, alt: 904.750260250145 },
    { lat: 0.929620660805894, lon: -0.0794182250151066, alt: 929.124860237855 },
    { lat: 0.92962513218176, lon: -0.0793999547110346, alt: 955.960314234906 },
    { lat: 0.929628159983677, lon: -0.0793809047847463, alt: 978.605761237832 },
    { lat: 0.929630091359682, lon: -0.0793609913310188, alt: 990.592582293354 },
    { lat: 0.929630638235836, lon: -0.0793407511086284, alt: 994.445245881174 },
    { lat: 0.92962969848783, lon: -0.0793207151515332, alt: 992.144868339813 },
    { lat: 0.92962713393447, lon: -0.0793009206734321, alt: 986.225153019035 },
    { lat: 0.929622968491538, lon: -0.0792812965674992, alt: 975.244347094836 },
    { lat: 0.929617282443495, lon: -0.0792625670308554, alt: 959.962297084363 },
    { lat: 0.929610369725018, lon: -0.0792451272695618, alt: 940.403553233976 },
    { lat: 0.929602481915929, lon: -0.0792284723432892, alt: 918.491724642267 },
    { lat: 0.929594002136351, lon: -0.0792117490185954, alt: 903.368518107642 },
    { lat: 0.929585267836033, lon: -0.0791951114451239, alt: 903.583721546412 },
    { lat: 0.929576616744757, lon: -0.0791788488499996, alt: 904.28973057762 },
    { lat: 0.929567896504658, lon: -0.0791625530873229, alt: 901.083832108614 },
    { lat: 0.929559014161914, lon: -0.0791460398157788, alt: 896.154760089077 },
    { lat: 0.929550178087847, lon: -0.0791296340949019, alt: 892.912789495374 },
    { lat: 0.929541668733155, lon: -0.0791135551806586, alt: 891.730885947561 },
    { lat: 0.929533588491458, lon: -0.0790974519492193, alt: 908.121839757218 },
    { lat: 0.929525922104469, lon: -0.0790812670087268, alt: 939.916763055589 },
    { lat: 0.929518564352791, lon: -0.0790652832291241, alt: 967.378103315541 },
    { lat: 0.929511480627564, lon: -0.0790497582028197, alt: 983.564485600624 },
    { lat: 0.929504505101555, lon: -0.0790343968243659, alt: 988.146516130867 },
    { lat: 0.929497472527908, lon: -0.0790188539257957, alt: 981.219830675886 },
    { lat: 0.92949049027451, lon: -0.0790033655342547, alt: 966.634770081711 },
    { lat: 0.929483694858213, lon: -0.0789882093325675, alt: 960.098873740801 },
    { lat: 0.929477015264611, lon: -0.0789732219187809, alt: 962.800919823414 },
    { lat: 0.929470349832543, lon: -0.0789581796366357, alt: 967.539205027006 },
    { lat: 0.929463814204479, lon: -0.0789433439711997, alt: 970.575187582413 },
    { lat: 0.929457392596975, lon: -0.0789286975955241, alt: 970.750402757829 },
    { lat: 0.929451184635899, lon: -0.0789145028914111, alt: 969.974494974637 },
    { lat: 0.929445083865677, lon: -0.0789005382343463, alt: 970.435488440504 },
    { lat: 0.929439022477733, lon: -0.0788866609835817, alt: 976.167940416835 },
    { lat: 0.929433120411497, lon: -0.0788731404032457, alt: 984.797567341446 },
    { lat: 0.929427471716128, lon: -0.0788601793243098, alt: 992.360117172114 },
    { lat: 0.929421973875542, lon: -0.0788475207686208, alt: 999.245259866323 },
    { lat: 0.929416539181884, lon: -0.0788349045848439, alt: 1003.05629327562 },
    { lat: 0.929411265411291, lon: -0.0788224600914165, alt: 1003.58925619758 },
    { lat: 0.929406231722532, lon: -0.0788103155353139, alt: 1002.54202288932 },
    { lat: 0.929401361995278, lon: -0.0787982465124165, alt: 1001.4656487451 },
    { lat: 0.929396541897542, lon: -0.0787859213103576, alt: 1000.43690812867 },
    { lat: 0.92939186571158, lon: -0.0787735724819565, alt: 998.574985470004 },
    { lat: 0.929387147331563, lon: -0.0787608892319097, alt: 993.761171519406 },
    { lat: 0.929382286295855, lon: -0.0787477378464721, alt: 986.415543514067 },
    { lat: 0.929377348806913, lon: -0.078734374604268, alt: 979.642857261386 },
    { lat: 0.929372432872549, lon: -0.0787211022858639, alt: 976.258463935061 },
    { lat: 0.929367472432836, lon: -0.0787077407887552, alt: 975.744741598366 },
    { lat: 0.929362417355506, lon: -0.0786941533646345, alt: 980.535002362953 },
    { lat: 0.929357376935108, lon: -0.0786806269215637, alt: 987.158841732956 },
    { lat: 0.929352436190388, lon: -0.0786673815247584, alt: 993.610733705088 },
    { lat: 0.929347516691325, lon: -0.0786541973376006, alt: 999.989654690539 },
    { lat: 0.929342535623417, lon: -0.0786408423232628, alt: 1005.22494122361 },
    { lat: 0.929337649340692, lon: -0.0786277249292946, alt: 1009.18198029426 },
    { lat: 0.929332775244446, lon: -0.0786146130927385, alt: 1012.03233128713 },
    { lat: 0.929327832621317, lon: -0.0786012784784366, alt: 1014.41880630465 },
    { lat: 0.929322909790042, lon: -0.0785879488140738, alt: 1015.99325509405 },
    { lat: 0.92931810213891, lon: -0.0785748737120535, alt: 1017.02477308638 },
    { lat: 0.929313325055304, lon: -0.0785618172131603, alt: 1016.00852056578 },
    { lat: 0.929308496706527, lon: -0.0785485521053229, alt: 1012.64913012626 },
    { lat: 0.929303667154824, lon: -0.0785352678673412, alt: 1009.01192150816 },
    { lat: 0.929298913280353, lon: -0.0785222195006429, alt: 1005.47564479639 },
    { lat: 0.929294142332107, lon: -0.0785091785165972, alt: 1002.49957935752 },
    { lat: 0.929289259439044, lon: -0.0784959769771791, alt: 1001.38318949152 },
    { lat: 0.929284393626739, lon: -0.0784831135987358, alt: 1001.8304713232 },
    { lat: 0.929279430500184, lon: -0.0784704070321255, alt: 1001.34288215397 },
    { lat: 0.929274244911671, lon: -0.0784576250177887, alt: 992.503254604132 },
    { lat: 0.929268801795259, lon: -0.0784450923959146, alt: 971.88982066423 },
    { lat: 0.929263035218558, lon: -0.0784332148171645, alt: 945.739092620452 },
    { lat: 0.929256691233424, lon: -0.0784222393615574, alt: 924.240881850403 },
    { lat: 0.929249651084441, lon: -0.0784123569949577, alt: 908.18307016165 },
    { lat: 0.929242098930751, lon: -0.0784040716108759, alt: 894.832174453228 },
    { lat: 0.929234303390166, lon: -0.0783974477043506, alt: 879.283365712715 },
    { lat: 0.929226218099076, lon: -0.0783924844690357, alt: 859.467038628745 },
    { lat: 0.929217758255112, lon: -0.0783894779372207, alt: 836.865665727069 },
    { lat: 0.929209365013163, lon: -0.0783885182857611, alt: 811.867442324555 },
    { lat: 0.929201027575847, lon: -0.0783892146201277, alt: 780.24768395105 },
    { lat: 0.929192707307537, lon: -0.0783916725097887, alt: 744.159014201812 },
    { lat: 0.929184676861273, lon: -0.0783960018930477, alt: 708.124985102745 },
    { lat: 0.929177193123259, lon: -0.0784019301035077, alt: 673.446857053717 },
    { lat: 0.929170293503973, lon: -0.0784095099686239, alt: 643.746873870315 },
    { lat: 0.929163940049579, lon: -0.0784185198134993, alt: 616.01028535798 },
    { lat: 0.929158178045892, lon: -0.0784284715261269, alt: 585.655325285274 },
    { lat: 0.929153135891503, lon: -0.0784391518582952, alt: 557.024024227814 },
    { lat: 0.929148756291742, lon: -0.0784505545595122, alt: 532.066322555221 },
    { lat: 0.929144982327068, lon: -0.0784627231128911, alt: 512.486830445138 },
    { lat: 0.929141761029267, lon: -0.0784752419764473, alt: 493.215476925271 },
    { lat: 0.929138971694482, lon: -0.0784878171800548, alt: 468.036246566681 },
    { lat: 0.929136531220677, lon: -0.0785007102468715, alt: 437.158135319167 },
    { lat: 0.929134437761745, lon: -0.0785141382576523, alt: 402.403792725279 },
    { lat: 0.929132746388799, lon: -0.0785278435330889, alt: 364.637327854211 },
    { lat: 0.929131598842387, lon: -0.0785416308662182, alt: 334.555776033411 },
    { lat: 0.929130953158135, lon: -0.0785555241471851, alt: 311.288063313198 },
    { lat: 0.92913077035076, lon: -0.0785695802427447, alt: 293.428895077103 },
    { lat: 0.929131024687571, lon: -0.0785831814710656, alt: 281.095986477687 },
    { lat: 0.929131676834014, lon: -0.0785964930995065, alt: 273.252009766412 },
    { lat: 0.929132690270679, lon: -0.078609707758717, alt: 267.60695677593 },
    { lat: 0.929133935967491, lon: -0.0786226638066924, alt: 257.778564877503 },
    { lat: 0.929135279303253, lon: -0.0786353566220748, alt: 243.718082987999 },
    { lat: 0.929136712773673, lon: -0.0786482451831066, alt: 230.02882932379 },
    { lat: 0.929138246122152, lon: -0.0786616782818275, alt: 215.834631474699 },
    { lat: 0.929139876894579, lon: -0.078675348964629, alt: 200.318613499068 },
    { lat: 0.929141761243847, lon: -0.0786889299608302, alt: 186.919033598919 },
    { lat: 0.929144093336067, lon: -0.0787025049595101, alt: 176.47204503416 },
    { lat: 0.929146985137026, lon: -0.0787161833986008, alt: 168.112945739316 },
    { lat: 0.929150525851534, lon: -0.078729479284016, alt: 164.480045617685 },
    { lat: 0.929154596602471, lon: -0.0787420901772324, alt: 161.846638057705 },
    { lat: 0.929159122084248, lon: -0.0787543262529332, alt: 153.865379936673 },
    { lat: 0.929164104346844, lon: -0.0787664739401593, alt: 142.593027729385 },
    { lat: 0.929169113744501, lon: -0.0787783962083739, alt: 134.800503288567 },
    { lat: 0.929174009665881, lon: -0.0787904035775535, alt: 134.017389708212 },
    { lat: 0.929178867544065, lon: -0.0788026525314622, alt: 132.443272561976 },
    { lat: 0.929183723480687, lon: -0.0788148397872496, alt: 123.946615294377 },
    { lat: 0.929188639430883, lon: -0.078826619481239, alt: 110.776146156151 },
    { lat: 0.929193739915004, lon: -0.07883802419028, alt: 96.6007963577095 },
    { lat: 0.92919911256593, lon: -0.0788491691930781, alt: 84.2253681748975 },
    { lat: 0.92920446921602, lon: -0.0788596802799701, alt: 77.3657995806837 },
    { lat: 0.929209755715121, lon: -0.0788698973295815, alt: 70.9950446582247 },
    { lat: 0.929215033925367, lon: -0.0788800808135839, alt: 63.4209759109122 },
    { lat: 0.929220209584549, lon: -0.0788900894411414, alt: 52.8357560790769 },
    { lat: 0.929225177261948, lon: -0.0788997600080152, alt: 45.7721501946404 },
    { lat: 0.929230002503248, lon: -0.0789092588207234, alt: 41.9288861305018 },
    { lat: 0.929234774804976, lon: -0.0789187720347328, alt: 40.1158662209657 },
    { lat: 0.929239297892308, lon: -0.0789278696438481, alt: 40.1173468027159 },
    { lat: 0.929243763907726, lon: -0.0789368479547179, alt: 40.1020909811964 },
    { lat: 0.929248044209303, lon: -0.0789453754221412, alt: 40.0898701641124 },
    { lat: 0.929252216805118, lon: -0.0789536098542428, alt: 40.0768678343317 },
    { lat: 0.929256361326326, lon: -0.0789616726366976, alt: 40.0668752511349 },
    { lat: 0.929260318886471, lon: -0.0789693744144812, alt: 40.0567224190869 },
    { lat: 0.929264144878518, lon: -0.0789768972687093, alt: 40.04749094084 },
    { lat: 0.92926793142187, lon: -0.0789843507981117, alt: 40.0385835409073 },
    { lat: 0.929271615097824, lon: -0.0789915837263378, alt: 40.0302270860591 },
    { lat: 0.929275132872488, lon: -0.0789985176933832, alt: 40.0223195192031 },
    { lat: 0.929278551193509, lon: -0.0790052654417701, alt: 40.014747474696 },
    { lat: 0.929281946109735, lon: -0.0790119323405563, alt: 40.0075857942763 },
    { lat: 0.929285218129384, lon: -0.0790184417492449, alt: 40.0005609382848 },
    { lat: 0.929288317430206, lon: -0.0790246352577767, alt: 39.9920123802619 },
    { lat: 0.929291250888974, lon: -0.0790303360444731, alt: 39.9769996625849 },
    { lat: 0.929293958770418, lon: -0.0790355948166582, alt: 39.9620053545085 },
    { lat: 0.929296323727048, lon: -0.0790402668849916, alt: 39.9496436370344 },
    { lat: 0.929298415044772, lon: -0.0790443992471498, alt: 39.9377072471162 },
    { lat: 0.929300201394783, lon: -0.0790480733054723, alt: 39.9265567525858 },
    { lat: 0.929301550917854, lon: -0.0790513204660637, alt: 39.9179230555011 },
    { lat: 0.92930249606165, lon: -0.079054068380578, alt: 39.9063064289786 },
    { lat: 0.929303061146491, lon: -0.0790564644679329, alt: 39.8980551603021 },
    { lat: 0.929303225841372, lon: -0.0790585496751411, alt: 39.895648226943 },
    { lat: 0.929303124584904, lon: -0.0790601689766028, alt: 39.8938549702808 },
    { lat: 0.929302905865838, lon: -0.0790612703173128, alt: 39.8929256937737 },
    { lat: 0.929302683864866, lon: -0.0790618967933346, alt: 39.8917256346671 },
    { lat: 0.929302550833976, lon: -0.0790621413615629, alt: 39.8895484233746 },
    { lat: 0.929302532130307, lon: -0.0790621676666314, alt: 39.8799964720031 },
    { lat: 0.929302531372692, lon: -0.0790621688262655, alt: 39.8810927628278 },
    { lat: 0.929302529577643, lon: -0.0790621716374758, alt: 39.8845565031577 },
    { lat: 0.929302526032783, lon: -0.0790621773219749, alt: 39.8870717092937 },
    { lat: 0.929302521007889, lon: -0.0790621854543438, alt: 39.8890836730429 },
    { lat: 0.929302515245132, lon: -0.0790621947436562, alt: 39.8892613608713 },
    { lat: 0.929302509177688, lon: -0.0790622043922038, alt: 39.8889448271741 },
    { lat: 0.92930250201679, lon: -0.07906221549009, alt: 39.8882805574921 },
    { lat: 0.929302487586091, lon: -0.0790622366324749, alt: 39.8877399226802 },
    { lat: 0.929302444992802, lon: -0.0790622938113128, alt: 39.8874424535464 },
    { lat: 0.929302315720203, lon: -0.0790624195633118, alt: 39.8883457726118 },
    { lat: 0.929301997206868, lon: -0.0790625293412861, alt: 39.8917693343978 },
    { lat: 0.929301467542084, lon: -0.0790624305526125, alt: 39.8893789591975 },
    { lat: 0.929300810118848, lon: -0.0790622750289451, alt: 39.8884958460853 },
    { lat: 0.929300180936516, lon: -0.0790623377658806, alt: 39.8867283668528 },
    { lat: 0.929299653954463, lon: -0.0790626500397515, alt: 39.8812978935688 },
    { lat: 0.929299182533018, lon: -0.0790630260833843, alt: 39.8814237557375 },
    { lat: 0.929298761965785, lon: -0.0790634159381361, alt: 39.8815418770223 },
    { lat: 0.929298551973288, lon: -0.0790636372746128, alt: 39.8804299105657 },
];

//Pilot 27, Scenario 1 for 3D visualization.csv
export const data1 = [
    { lat: 0.929216202787295, lon: -0.0789034263910187, alt: 39.9368056250944 },
    { lat: 0.92921620298664, lon: -0.0789034261019855, alt: 39.8759600360837 },
    { lat: 0.929216202989699, lon: -0.0789034260975493, alt: 39.8750613946281 },
    { lat: 0.929216202989805, lon: -0.0789034260973976, alt: 39.8749898024003 },
    { lat: 0.929216203067713, lon: -0.0789034259844229, alt: 39.8757017315721 },
    { lat: 0.929216203257237, lon: -0.0789034257095965, alt: 39.8752439449617 },
    { lat: 0.929216203485563, lon: -0.0789034253785093, alt: 39.8761872935116 },
    { lat: 0.929216203762107, lon: -0.0789034249775004, alt: 39.8764890053272 },
    { lat: 0.929216204063376, lon: -0.0789034245406389, alt: 39.8767364914958 },
    { lat: 0.929216204375821, lon: -0.0789034240875719, alt: 39.8768660533049 },
    { lat: 0.929216204727306, lon: -0.0789034235778948, alt: 39.877384641511 },
    { lat: 0.929216205125256, lon: -0.0789034230008404, alt: 39.8776954037323 },
    { lat: 0.929216205524758, lon: -0.0789034224215359, alt: 39.8775618678438 },
    { lat: 0.929216205908999, lon: -0.0789034218643795, alt: 39.8774456187222 },
    { lat: 0.929216206282071, lon: -0.0789034213234006, alt: 39.8773118692939 },
    { lat: 0.929216206633057, lon: -0.0789034208144475, alt: 39.8771223583724 },
    { lat: 0.92921620698913, lon: -0.0789034202981163, alt: 39.8772619685037 },
    { lat: 0.929216207357242, lon: -0.0789034197643267, alt: 39.877448133825 },
    { lat: 0.929216207741064, lon: -0.0789034192077579, alt: 39.877592821472 },
    { lat: 0.929216208141723, lon: -0.0789034186267728, alt: 39.877679572957 },
    { lat: 0.929216208543533, lon: -0.0789034180441183, alt: 39.8776927577558 },
    { lat: 0.929216208943689, lon: -0.0789034174638655, alt: 39.8776709446603 },
    { lat: 0.92921620934045, lon: -0.0789034168885327, alt: 39.8776472730541 },
    { lat: 0.929216209734978, lon: -0.0789034163164408, alt: 39.8776280192569 },
    { lat: 0.929216210126189, lon: -0.0789034157491572, alt: 39.8776199449449 },
    { lat: 0.929216210511158, lon: -0.0789034151909241, alt: 39.8776101294445 },
    { lat: 0.929216210898335, lon: -0.0789034146294896, alt: 39.8776117701913 },
    { lat: 0.929216211276453, lon: -0.0789034140811926, alt: 39.8776209586105 },
    { lat: 0.929216211671769, lon: -0.078903413507957, alt: 39.8776292443479 },
    { lat: 0.929216212056686, lon: -0.0789034129498046, alt: 39.877629522381 },
    { lat: 0.929216212447764, lon: -0.0789034123827135, alt: 39.8776315147571 },
    { lat: 0.929216212833785, lon: -0.0789034118229555, alt: 39.8776327898974 },
    { lat: 0.929216213219751, lon: -0.0789034112632793, alt: 39.877635885637 },
    { lat: 0.929216213608061, lon: -0.0789034107002025, alt: 39.877647621784 },
    { lat: 0.929216214002753, lon: -0.07890341012787, alt: 39.8776672683759 },
    { lat: 0.929216214382568, lon: -0.0789034095771116, alt: 39.8776636937103 },
    { lat: 0.92921621476594, lon: -0.0789034090211968, alt: 39.8776598465114 },
    { lat: 0.929216215154859, lon: -0.0789034084572388, alt: 39.8776526759688 },
    { lat: 0.929216215548823, lon: -0.0789034078859614, alt: 39.8776419847377 },
    { lat: 0.929216215939655, lon: -0.0789034073192265, alt: 39.8776444624477 },
    { lat: 0.929216216329439, lon: -0.0789034067540144, alt: 39.8776592432033 },
    { lat: 0.929216216714181, lon: -0.0789034061961128, alt: 39.8776787491324 },
    { lat: 0.929216217106906, lon: -0.078903405626633, alt: 39.8776901295773 },
    { lat: 0.92921621749563, lon: -0.0789034050629554, alt: 39.8776948153325 },
    { lat: 0.929216217878216, lon: -0.0789034045081796, alt: 39.8776836780541 },
    { lat: 0.929216218263151, lon: -0.0789034039499944, alt: 39.8776835172392 },
    { lat: 0.929216218651852, lon: -0.0789034033863495, alt: 39.8776736240231 },
    { lat: 0.929216219042557, lon: -0.0789034028197993, alt: 39.8776677140214 },
    { lat: 0.929216219431973, lon: -0.0789034022551167, alt: 39.8776797746357 },
    { lat: 0.929216219818993, lon: -0.0789034016939091, alt: 39.8776911135722 },
    { lat: 0.929216220210972, lon: -0.0789034011255085, alt: 39.8777097414459 },
    { lat: 0.929216220601388, lon: -0.0789034005593778, alt: 39.8777176271492 },
    { lat: 0.929216220994907, lon: -0.0789033999887519, alt: 39.8777175260468 },
    { lat: 0.929216221379159, lon: -0.0789033994315615, alt: 39.8777105336229 },
    { lat: 0.929216221767781, lon: -0.0789033988680322, alt: 39.8777093223189 },
    { lat: 0.929216222151073, lon: -0.0789033983122319, alt: 39.8777007338265 },
    { lat: 0.929216222536103, lon: -0.0789033977539116, alt: 39.8776933686265 },
    { lat: 0.929216222922142, lon: -0.0789033971941284, alt: 39.8777059413842 },
    { lat: 0.929216223317226, lon: -0.0789033966212301, alt: 39.8777282495031 },
    { lat: 0.929216223699359, lon: -0.0789033960671097, alt: 39.8777381342402 },
    { lat: 0.92921622409018, lon: -0.0789033955003903, alt: 39.8777522719478 },
    { lat: 0.929216224477838, lon: -0.0789033949382621, alt: 39.8777394364584 },
    { lat: 0.92921622487099, lon: -0.0789033943681609, alt: 39.8777350231096 },
    { lat: 0.929216225262118, lon: -0.078903393801, alt: 39.8777356198487 },
    { lat: 0.929216225649686, lon: -0.0789033932389967, alt: 39.8777197223385 },
    { lat: 0.92921622604313, lon: -0.0789033926684758, alt: 39.8777233954155 },
    { lat: 0.9292162264357, lon: -0.0789033920992201, alt: 39.8777403708868 },
    { lat: 0.929216226812841, lon: -0.0789033915523366, alt: 39.87776133216 },
    { lat: 0.929216227188989, lon: -0.078903391006892, alt: 39.877767544797 },
    { lat: 0.929216227573675, lon: -0.07890339044907, alt: 39.8777734889867 },
    { lat: 0.929216227966001, lon: -0.0789033898801714, alt: 39.8777659413565 },
    { lat: 0.929216228354176, lon: -0.0789033893172914, alt: 39.8777663260272 },
    { lat: 0.929216228743248, lon: -0.0789033887531105, alt: 39.8777556044442 },
    { lat: 0.929216229166849, lon: -0.0789033881388218, alt: 39.8780902337578 },
    { lat: 0.929216235294012, lon: -0.0789033792537145, alt: 39.8798989804374 },
    { lat: 0.929216289214865, lon: -0.0789033011387406, alt: 39.8810942071343 },
    { lat: 0.929216455421999, lon: -0.0789030617982439, alt: 39.8824634682858 },
    { lat: 0.929216797534382, lon: -0.0789025741130061, alt: 39.883726103586 },
    { lat: 0.929217328730215, lon: -0.078901827509843, alt: 39.8844591557386 },
    { lat: 0.92921801358651, lon: -0.0789008816553071, alt: 39.8846223610718 },
    { lat: 0.929218799052998, lon: -0.0788998548241668, alt: 39.8838369369885 },
    { lat: 0.929219659782917, lon: -0.0788990210429501, alt: 39.8828627939936 },
    { lat: 0.929220581845763, lon: -0.0788985546156409, alt: 39.8823565440856 },
    { lat: 0.92922151639541, lon: -0.0788984800039417, alt: 39.8819976768816 },
    { lat: 0.929222396817384, lon: -0.0788987424079201, alt: 39.8818303952108 },
    { lat: 0.929223190627389, lon: -0.0788992547568125, alt: 39.8816547175614 },
    { lat: 0.929223911387956, lon: -0.0788998991506031, alt: 39.8816604972387 },
    { lat: 0.92922457711889, lon: -0.0789005701916913, alt: 39.8815583197986 },
    { lat: 0.9292251894583, lon: -0.0789013187745336, alt: 39.881580052632 },
    { lat: 0.92922575834627, lon: -0.0789021527520685, alt: 39.8817343456597 },
    { lat: 0.929226257560908, lon: -0.0789030283107976, alt: 39.8873457868805 },
    { lat: 0.929226617562815, lon: -0.0789036875218632, alt: 39.8943862203686 },
    { lat: 0.929226840580786, lon: -0.0789040891119468, alt: 39.8844779693182 },
    { lat: 0.929226919352073, lon: -0.0789042418286503, alt: 39.8875888222133 },
    { lat: 0.929226920352132, lon: -0.0789042437649862, alt: 39.8777539835224 },
    { lat: 0.929226920636362, lon: -0.0789042443112351, alt: 39.8774849064558 },
    { lat: 0.929226920925264, lon: -0.078904244866463, alt: 39.8774200618032 },
    { lat: 0.929226921274314, lon: -0.0789042455372275, alt: 39.8784546982764 },
    { lat: 0.929226922020298, lon: -0.0789042469700596, alt: 39.8828364238763 },
    { lat: 0.929226923490751, lon: -0.0789042497939823, alt: 39.8873370820389 },
    { lat: 0.929226933808665, lon: -0.078904269625312, alt: 39.8842364449734 },
    { lat: 0.929227021179426, lon: -0.0789044376815758, alt: 39.8822322956089 },
    { lat: 0.929227248112147, lon: -0.0789048766714049, alt: 39.883703529567 },
    { lat: 0.929227675215915, lon: -0.0789057169299426, alt: 39.8875656254191 },
    { lat: 0.929228411209673, lon: -0.0789071682044922, alt: 39.8945246213648 },
    { lat: 0.929229528993998, lon: -0.0789093570577213, alt: 39.9024225976393 },
    { lat: 0.929231021714509, lon: -0.0789122654607289, alt: 39.9117504826936 },
    { lat: 0.92923288506384, lon: -0.0789159132721265, alt: 39.9236138380838 },
    { lat: 0.929235078829766, lon: -0.0789202664011671, alt: 39.9373065324196 },
    { lat: 0.92923763492968, lon: -0.0789252402480647, alt: 39.9522973264441 },
    { lat: 0.929240540447316, lon: -0.0789308182437822, alt: 39.9689140812218 },
    { lat: 0.929243758729145, lon: -0.0789370521286605, alt: 39.9868104098067 },
    { lat: 0.929247262644083, lon: -0.0789439274019192, alt: 40.0058488839982 },
    { lat: 0.929250997988232, lon: -0.0789512783771162, alt: 40.0254157207636 },
    { lat: 0.929255027555477, lon: -0.078959188860196, alt: 40.0457558432235 },
    { lat: 0.929259414886033, lon: -0.0789677838005233, alt: 40.0661436566514 },
    { lat: 0.929264086157147, lon: -0.0789769266645157, alt: 40.2257900330824 },
    { lat: 0.929269002787805, lon: -0.0789865558892072, alt: 41.5440523832875 },
    { lat: 0.929274141958826, lon: -0.0789966407527352, alt: 44.6915141416906 },
    { lat: 0.929279486928925, lon: -0.0790071494698613, alt: 49.1399853113229 },
    { lat: 0.929285021650189, lon: -0.0790180460933851, alt: 55.3220694447812 },
    { lat: 0.929290722419078, lon: -0.0790292831755917, alt: 64.7662436347854 },
    { lat: 0.929296574913281, lon: -0.0790408258839469, alt: 77.1877608772852 },
    { lat: 0.929302467525621, lon: -0.0790524366159051, alt: 92.3917787256559 },
    { lat: 0.929308514192851, lon: -0.0790643197749595, alt: 107.216524628804 },
    { lat: 0.929314862115064, lon: -0.0790767428043635, alt: 118.305507357531 },
    { lat: 0.929321437315008, lon: -0.0790895264257281, alt: 127.11747207323 },
    { lat: 0.929328237980023, lon: -0.0791026357554721, alt: 136.788534836821 },
    { lat: 0.929335240626467, lon: -0.0791160081839849, alt: 151.199250292969 },
    { lat: 0.92934240779543, lon: -0.0791295611387548, alt: 171.934931153403 },
    { lat: 0.929349729046954, lon: -0.0791432754460355, alt: 196.448791799431 },
    { lat: 0.929357190454962, lon: -0.079157121696892, alt: 224.169152472782 },
    { lat: 0.929364787962549, lon: -0.0791710961686721, alt: 253.064005781515 },
    { lat: 0.929372489818324, lon: -0.0791851439061002, alt: 284.99341303029 },
    { lat: 0.9293802282188, lon: -0.0791991909563474, alt: 320.266124889801 },
    { lat: 0.929387973215452, lon: -0.0792132358489895, alt: 356.785651566168 },
    { lat: 0.929395599657122, lon: -0.0792270583301601, alt: 391.454114653877 },
    { lat: 0.929403239749587, lon: -0.0792408986263111, alt: 423.455047876348 },
    { lat: 0.929411046695389, lon: -0.0792550341631983, alt: 452.703559962808 },
    { lat: 0.929418899122405, lon: -0.0792692457729444, alt: 478.981264025342 },
    { lat: 0.929426755293441, lon: -0.079283475270458, alt: 504.296430075282 },
    { lat: 0.92943464309973, lon: -0.0792977852824437, alt: 527.85115305738 },
    { lat: 0.929442591780092, lon: -0.0793122261536937, alt: 546.882823446899 },
    { lat: 0.929450632853537, lon: -0.0793267414334318, alt: 564.472607066867 },
    { lat: 0.929459021934161, lon: -0.0793408145179082, alt: 584.933277275386 },
    { lat: 0.929468115391504, lon: -0.0793535411705785, alt: 610.824736939671 },
    { lat: 0.929477944465581, lon: -0.0793645782408649, alt: 638.689833250235 },
    { lat: 0.929488243365371, lon: -0.0793736219610045, alt: 665.540582458634 },
    { lat: 0.929499089376214, lon: -0.0793803802241766, alt: 695.1465832202 },
    { lat: 0.929510498939749, lon: -0.0793847837003642, alt: 727.502152048019 },
    { lat: 0.929522082860627, lon: -0.0793865565357302, alt: 761.658292461223 },
    { lat: 0.92953362460527, lon: -0.0793856092920943, alt: 797.116438488839 },
    { lat: 0.929544928287612, lon: -0.0793820411282995, alt: 831.661745856933 },
    { lat: 0.929555790033575, lon: -0.0793759131879344, alt: 864.170550388625 },
    { lat: 0.92956590901653, lon: -0.0793670894456456, alt: 896.541647312593 },
    { lat: 0.929575165950682, lon: -0.0793559169592793, alt: 925.258912586116 },
    { lat: 0.929583540156572, lon: -0.0793429935529509, alt: 944.93861733833 },
    { lat: 0.929591086538791, lon: -0.079328144882769, alt: 955.731939477592 },
    { lat: 0.929597295417891, lon: -0.0793114536024708, alt: 961.995811746809 },
    { lat: 0.929601895993685, lon: -0.0792933121060736, alt: 965.238456989154 },
    { lat: 0.929604664184401, lon: -0.0792741783356495, alt: 968.786755113018 },
    { lat: 0.9296057414229, lon: -0.0792545947491725, alt: 971.452063409099 },
    { lat: 0.929605336626865, lon: -0.0792352422755752, alt: 972.755250889251 },
    { lat: 0.929603665112525, lon: -0.0792160223808785, alt: 972.622643310917 },
    { lat: 0.92960092809445, lon: -0.079196829486914, alt: 969.289317683905 },
    { lat: 0.929597219123183, lon: -0.0791780323272601, alt: 963.827171038688 },
    { lat: 0.929592585789799, lon: -0.0791597364828021, alt: 958.405783731412 },
    { lat: 0.929587143150641, lon: -0.079141998591406, alt: 952.424408044617 },
    { lat: 0.929581033673562, lon: -0.0791248296861819, alt: 945.736800814524 },
    { lat: 0.929574504747718, lon: -0.0791080134246907, alt: 937.347750897422 },
    { lat: 0.929567918457444, lon: -0.0790915069370455, alt: 925.874266460752 },
    { lat: 0.929561241704067, lon: -0.0790748556088057, alt: 910.501336623505 },
    { lat: 0.929554459440275, lon: -0.0790579316524102, alt: 899.728352134002 },
    { lat: 0.929547701367389, lon: -0.0790410526503269, alt: 903.191902983848 },
    { lat: 0.929541002099257, lon: -0.0790243093369855, alt: 914.722645008921 },
    { lat: 0.929534358851509, lon: -0.0790076968072641, alt: 927.753240080239 },
    { lat: 0.929527765132948, lon: -0.0789911982688913, alt: 940.103661184888 },
    { lat: 0.929521217174176, lon: -0.078974803467713, alt: 952.704851215193 },
    { lat: 0.929514718965688, lon: -0.0789585219043759, alt: 966.222669076325 },
    { lat: 0.929508382669921, lon: -0.0789426345749966, alt: 979.718823379812 },
    { lat: 0.92950208850446, lon: -0.0789268411369969, alt: 990.962860360889 },
    { lat: 0.929495712189459, lon: -0.0789108286650718, alt: 998.864684314545 },
    { lat: 0.92948935601553, lon: -0.0788948518721379, alt: 1004.79933571184 },
    { lat: 0.929483019372815, lon: -0.078878908009487, alt: 1009.67266485247 },
    { lat: 0.929476704419548, lon: -0.07886300096048, alt: 1013.4561523598 },
    { lat: 0.92947041997232, lon: -0.078847146627948, alt: 1017.57878367694 },
    { lat: 0.929464198459353, lon: -0.0788314142047192, alt: 1025.57703675797 },
    { lat: 0.929458071843888, lon: -0.0788158758652798, alt: 1035.81759266215 },
    { lat: 0.929452135128593, lon: -0.0788007640844405, alt: 1043.27223733721 },
    { lat: 0.929446283306451, lon: -0.078785790458579, alt: 1047.90582770319 },
    { lat: 0.929440417014062, lon: -0.0787706848400558, alt: 1051.1233488791 },
    { lat: 0.92943462732824, lon: -0.0787556729355579, alt: 1054.70639149168 },
    { lat: 0.92942889151007, lon: -0.0787407068981068, alt: 1055.09224900438 },
    { lat: 0.929423179298845, lon: -0.0787257351624683, alt: 1048.62804353586 },
    { lat: 0.929417466719456, lon: -0.0787107262226741, alt: 1036.50319929903 },
    { lat: 0.929411846851834, lon: -0.0786959231896754, alt: 1026.24385751923 },
    { lat: 0.929406246357216, lon: -0.0786811297160082, alt: 1024.08602841443 },
    { lat: 0.929400509373228, lon: -0.0786659393484662, alt: 1027.23717006145 },
    { lat: 0.92939499705329, lon: -0.0786513150400799, alt: 1031.59222577192 },
    { lat: 0.929389413831885, lon: -0.0786365298309994, alt: 1033.76031193275 },
    { lat: 0.929383806472656, lon: -0.0786218249961206, alt: 1032.07429749296 },
    { lat: 0.929378154232988, lon: -0.0786072200671851, alt: 1030.18842649537 },
    { lat: 0.929372460185328, lon: -0.0785927441647454, alt: 1030.83434647941 },
    { lat: 0.929366814745294, lon: -0.0785785932505642, alt: 1029.84261506566 },
    { lat: 0.929361120893017, lon: -0.0785645236708234, alt: 1027.88249140144 },
    { lat: 0.929355284267622, lon: -0.0785503011498248, alt: 1024.48907693922 },
    { lat: 0.929349404567808, lon: -0.0785361904369563, alt: 1018.40976777465 },
    { lat: 0.929343373422338, lon: -0.0785223485626845, alt: 1008.85793625598 },
    { lat: 0.929336904801716, lon: -0.0785091709573826, alt: 996.678317846985 },
    { lat: 0.929329911317563, lon: -0.0784970417783062, alt: 980.577089910808 },
    { lat: 0.929322543830182, lon: -0.0784862698416664, alt: 960.041872976195 },
    { lat: 0.929314542511774, lon: -0.0784770099096586, alt: 943.01791306311 },
    { lat: 0.929306043671276, lon: -0.0784690494170986, alt: 923.807741546934 },
    { lat: 0.92929729053278, lon: -0.0784623499620946, alt: 898.304978242472 },
    { lat: 0.929288249398303, lon: -0.0784570860867768, alt: 870.973782007072 },
    { lat: 0.929278974343246, lon: -0.0784534695014327, alt: 846.321510103351 },
    { lat: 0.929269745944146, lon: -0.0784516089721577, alt: 826.723184711277 },
    { lat: 0.929260538879927, lon: -0.0784512647479647, alt: 808.192380266167 },
    { lat: 0.929251300667851, lon: -0.0784523328734405, alt: 788.469265497349 },
    { lat: 0.929242260378439, lon: -0.0784548693316211, alt: 769.763081914986 },
    { lat: 0.929233514899289, lon: -0.0784588650773603, alt: 753.456568957748 },
    { lat: 0.929225107170573, lon: -0.0784641665184552, alt: 737.449535844512 },
    { lat: 0.929217066596665, lon: -0.0784706473401136, alt: 720.15359849076 },
    { lat: 0.929209411816903, lon: -0.0784782459017535, alt: 701.09072747195 },
    { lat: 0.92920217446694, lon: -0.0784869007160941, alt: 680.059390698187 },
    { lat: 0.929195502674719, lon: -0.0784963989576726, alt: 658.162318595016 },
    { lat: 0.929189313831628, lon: -0.0785067944749584, alt: 635.045189161774 },
    { lat: 0.929183523656295, lon: -0.0785181873849536, alt: 609.73562429899 },
    { lat: 0.929178287160202, lon: -0.078530380664037, alt: 584.240009164468 },
    { lat: 0.929173636994845, lon: -0.0785432990730806, alt: 559.232103702145 },
    { lat: 0.929169639602201, lon: -0.0785568440138409, alt: 536.744659270535 },
    { lat: 0.929166322383842, lon: -0.0785709023322001, alt: 517.238501690516 },
    { lat: 0.929163681479971, lon: -0.0785850687189876, alt: 498.172844646358 },
    { lat: 0.929161653202409, lon: -0.0785994826709134, alt: 478.664708937051 },
    { lat: 0.929160223954044, lon: -0.0786143293802599, alt: 459.756219579428 },
    { lat: 0.929159379277297, lon: -0.0786292561386632, alt: 441.316876442398 },
    { lat: 0.929159036640027, lon: -0.0786441769268015, alt: 423.204502846542 },
    { lat: 0.929159118551846, lon: -0.0786591980062184, alt: 405.123747839968 },
    { lat: 0.92915952482561, lon: -0.0786738330399724, alt: 386.061716600013 },
    { lat: 0.929160210370972, lon: -0.0786883492989112, alt: 365.406616798467 },
    { lat: 0.929161189104504, lon: -0.0787030263213794, alt: 343.667764203481 },
    { lat: 0.929162453240536, lon: -0.0787176067990505, alt: 321.882115670031 },
    { lat: 0.929164026779067, lon: -0.0787320626904721, alt: 300.488070984354 },
    { lat: 0.929165998477496, lon: -0.0787463312822319, alt: 278.352423319876 },
    { lat: 0.929168505729328, lon: -0.0787603288652395, alt: 254.768251320209 },
    { lat: 0.929171605131911, lon: -0.0787739832359143, alt: 230.470130123859 },
    { lat: 0.929175239896443, lon: -0.078786980243209, alt: 208.84542405398 },
    { lat: 0.92917930046066, lon: -0.0787995624856257, alt: 188.797797047185 },
    { lat: 0.92918363345061, lon: -0.0788120926883524, alt: 167.484477008215 },
    { lat: 0.929188155903365, lon: -0.0788243507476086, alt: 149.0858876403 },
    { lat: 0.929192905075003, lon: -0.0788362397875546, alt: 131.359535837534 },
    { lat: 0.929197944302622, lon: -0.0788476315177933, alt: 115.173358233621 },
    { lat: 0.92920326033146, lon: -0.0788584559643366, alt: 101.212915608949 },
    { lat: 0.929208720035669, lon: -0.078868849450516, alt: 85.0181668723937 },
    { lat: 0.929214161939195, lon: -0.0788790563407176, alt: 69.5416607751417 },
    { lat: 0.92921935974195, lon: -0.0788890749010415, alt: 57.3751879312142 },
    { lat: 0.929224380456857, lon: -0.0788990438694424, alt: 46.6529967706936 },
    { lat: 0.929229351507506, lon: -0.0789089875276902, alt: 41.5431274018483 },
    { lat: 0.929234196918487, lon: -0.0789185824282813, alt: 42.1164018847343 },
    { lat: 0.92923896253687, lon: -0.0789279189961593, alt: 40.1494133944522 },
    { lat: 0.929243508262107, lon: -0.0789368352726405, alt: 40.1107596057341 },
    { lat: 0.929247965904283, lon: -0.0789456256433721, alt: 40.1048678938971 },
    { lat: 0.929252291929573, lon: -0.0789541969521426, alt: 40.0928238212255 },
    { lat: 0.929256494899604, lon: -0.0789625386742264, alt: 40.0817967256972 },
    { lat: 0.929260580888087, lon: -0.0789706408245498, alt: 40.0715437211236 },
    { lat: 0.929264561731295, lon: -0.0789785122426856, alt: 40.0606062167826 },
    { lat: 0.929268399276029, lon: -0.078986069904108, alt: 40.0434780552323 },
    { lat: 0.92927198297887, lon: -0.0789930699114104, alt: 40.0244668710899 },
    { lat: 0.929275382606528, lon: -0.0789995534498084, alt: 40.0075607678851 },
    { lat: 0.929278657809968, lon: -0.0790056039804744, alt: 39.9922985618503 },
    { lat: 0.92928170233345, lon: -0.0790111381253833, alt: 39.9778020461391 },
    { lat: 0.92928446098343, lon: -0.0790161591260931, alt: 39.963145883939 },
    { lat: 0.929286907311889, lon: -0.0790206945788663, alt: 39.9504422900699 },
    { lat: 0.9292890039316, lon: -0.0790246618696721, alt: 39.9386862801367 },
    { lat: 0.92929078185129, lon: -0.0790281045098441, alt: 39.9288630867738 },
    { lat: 0.929292303834891, lon: -0.0790310560088351, alt: 39.9207794220869 },
    { lat: 0.929293549336612, lon: -0.0790334293597407, alt: 39.9136633837664 },
    { lat: 0.929294540263204, lon: -0.0790354337302619, alt: 39.8919255477412 },
    { lat: 0.929295413806047, lon: -0.0790374319355151, alt: 39.8913554176411 },
    { lat: 0.929296227664356, lon: -0.0790394367627095, alt: 39.8910647953862 },
    { lat: 0.929297000946174, lon: -0.0790414363108867, alt: 39.8907710806576 },
    { lat: 0.929297756313801, lon: -0.0790434103940768, alt: 39.890425974682 },
    { lat: 0.929298459496031, lon: -0.0790453907112487, alt: 39.8900316838746 },
    { lat: 0.929299092738102, lon: -0.0790473478074384, alt: 39.8897450964645 },
    { lat: 0.929299645872056, lon: -0.0790493214840245, alt: 39.8889578825819 },
    { lat: 0.929300017650918, lon: -0.0790513855115782, alt: 39.8885777964852 },
    { lat: 0.929300191709826, lon: -0.0790534620166621, alt: 39.8882012405553 },
    { lat: 0.929300216650361, lon: -0.0790555128888897, alt: 39.8879564877311 },
    { lat: 0.929300114330471, lon: -0.0790575097730415, alt: 39.8873860193125 },
    { lat: 0.929299845151152, lon: -0.0790594095618572, alt: 39.8870673425832 },
    { lat: 0.92929940314932, lon: -0.0790611537015301, alt: 39.8863570885061 },
    { lat: 0.929298767087272, lon: -0.0790626366866242, alt: 39.8858546698202 },
    { lat: 0.929298173801469, lon: -0.0790635865591158, alt: 39.8867338783906 },
];

// Dummy data representing datetime values and lat/lon/altitude values
const _pathData = [
    {
        datetime: new Date("2021-11-16T09:40:00Z"),
        lat: 0.929216203,
        lon: -0.078903427,
        alt: 40.00000441,
    },
    {
        datetime: new Date("2021-11-16T09:41:00Z"),
        lat: 0.929216216,
        lon: -0.078903408,
        alt: 39.87227878,
    },
    {
        datetime: new Date("2021-11-16T09:42:00Z"),
        lat: 0.929224368,
        lon: -0.078899472,
        alt: 39.87881578,
    },
    {
        datetime: new Date("2021-11-16T09:43:00Z"),
        lat: 0.929478715,
        lon: -0.079333083,
        alt: 767.2856833,
    },
    {
        datetime: new Date("2021-11-16T09:44:00Z"),
        lat: 0.929232156,
        lon: -0.078595734,
        alt: 843.5370893,
    },
    {
        datetime: new Date("2021-11-16T09:45:00Z"),
        lat: 0.929338423,
        lon: -0.079123584,
        alt: 39.98006903,
    },
    {
        datetime: new Date("2021-11-16T09:46:00Z"),
        lat: 0.929413355,
        lon: -0.079251768,
        alt: 39.88785942,
    },
    // Add more data points as needed...
];

//Runway co-ords for IDAS
const _runwayCorners = [
    { lat: 0.929218233041, lon: -0.07889708524, alt: 40 },
    { lat: 0.929223853001, lon: -0.078889196349, alt: 40 },
    { lat: 0.929451426483, lon: -0.079352651079, alt: 40 },
    { lat: 0.929456749737, lon: -0.07934486691, alt: 40 },
];
