import React from "react";
import { useIntl } from "react-intl";

import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";

import { SubTitle, Title, Divider } from "../../session-detail.styles";

import { Section } from "./top-stats-styles";
import { secondsToMinutes } from "./utils";

export type TopStatProps = {
    description: string;
    value: value[];
    code: string;
    position: number;
};

type value = {
    title: string;
    result: number | string;
    unit: string;
    transform?: string; // String representation of a transformation expression (e.g., "(input) => input < 100 ? 1 : 0")
};

export const CustomTopStat = ({ description, value }: TopStatProps) => {
    const intl = useIntl();

    const applyTransform = (result: number | string, transform?: string): number | string => {
        if (transform) {
            try {
                // Create a function from the transform string and apply it to the result
                const transformFunction = new Function("input", `return (${transform})(input);`);

                return transformFunction(result);
            } catch (error) {
                return result; // Fallback to the original result if there's an error
            }
        }

        return result;
    };

    const getValue = (result: number | string, unit: string, transform?: string) => {
        const transformedResult = applyTransform(result, transform);

        if (typeof transformedResult === "string") {
            return transformedResult;
        }

        const numericValue =
            typeof transformedResult === "string"
                ? parseFloat(transformedResult)
                : transformedResult;

        if (!isNaN(numericValue)) {
            let updatedValue = `${Math.round(numericValue).toLocaleString()}${unit}`;

            if (unit.toLowerCase() === "s" && typeof secondsToMinutes === "function") {
                updatedValue = secondsToMinutes(numericValue);
            }

            if (numericValue < 1 && numericValue > 0) {
                updatedValue = `${numericValue.toFixed(1)}${unit}`;
            }

            return updatedValue;
        }
    };

    const emptyStateTitle = intl.formatMessage(
        {
            id: "session-details:custom-top-stats-empty-state",
        },
        { title: description },
    );

    return (
        <Section.Container>
            <Title>{description}</Title>
            {value?.length === 0 ? (
                <EmptyState title={emptyStateTitle} emptyStateType={EmptyStateType.Charts} />
            ) : (
                <Section.SubContainer>
                    {value.map((stat: value, index: number) => {
                        return (
                            <React.Fragment key={`${stat.result}-${index}`}>
                                <Section.Wrapper>
                                    <SubTitle>{stat.title}</SubTitle>
                                    <Section.Value>
                                        {getValue(stat.result, stat.unit, stat.transform)}
                                    </Section.Value>
                                </Section.Wrapper>
                                {index < value.length - 1 && <Divider />}
                            </React.Fragment>
                        );
                    })}
                </Section.SubContainer>
            )}
        </Section.Container>
    );
};
