import styled from "styled-components";

import base from "@/themes/base/base";

export const StyledRadio = styled.div<{ color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${(props) => props.color};
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.15625rem 0.125rem 0.09375rem;
`;

export const RadioInner = styled.div<{ selected: boolean; color: string }>`
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    background: ${(props) => (props.selected ? props.color : base.colors.neutral.grey[800])};
`;
