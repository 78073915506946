import styled from "styled-components";

export const StyledGridLayoutItem = styled.div`
    display: flex;
    width: 100%;
`;

export const GridLayoutItem = styled.div`
    width: 100%;
`;
