import { TableContainer } from "@mui/material";

import styled from "styled-components";

import base from "@/themes/base/base";

export const StyledTableContainer = styled(TableContainer)`
    .MuiTableHead-root {
    }

    //All Rows
    .MuiTableRow-root {
        border: none;

        background-color: ${base.colors.neutral.grey[800]}; //background-color for all rows

        &:not(:last-of-type) {
            border-bottom: 1px solid ${base.colors.neutral.grey[900]};
        }
    }

    //Table Head
    .MuiTableRow-head {
        background-color: ${base.colors.neutral.grey[700]};
        border-bottom: 1px solid ${base.colors.neutral.grey[900]};
    }

    .MuiTableRow-body {
    }

    //Table cell Wrapper
    .MuiTableCell-root {
        border-bottom: none; //ensures no border at the bottom
    }

    //Table Head
    .MuiTableCell-head {
        ${base.typography.smallMedium};

        color: ${base.colors.neutral.grey[50]};
        text-transform: uppercase;
        padding: 0.375rem 1rem;

        &:first-of-type {
            border-top-left-radius: 0.5rem; //border radius of table head
        }

        &:last-of-type {
            border-top-right-radius: 0.5rem; //border radius of table head
        }
    }

    //shared cell styles - overrides inlineStyles (with sx prop)
    .MuiTableCell-body {
    }

    //IF TABLE PAGINATION IS IMPLEMENTED
    //selected page number
    /* .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        border: 1px solid ${base.colors.primary.orange[500]};
    } */

    //hover page number
    /* .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root:hover {
        border: 1px solid ${base.colors.neutral.grey[50]};
        background-color: ${base.colors.neutral.grey[500]};
    } */

    /* .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
        background-color: ${base.colors.neutral.grey[500]};
        border: 1px solid ${base.colors.primary.orange[500]};
    } */
`;

export const HeadTableCellWithTooltipContent = styled.div`
    display: flex;
    gap: 0.25rem;
    align-items: center;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.5rem;
`;
