import { FormattedMessage } from "react-intl";

import { EmptyChartsStateIcon, EmptySessionsStateIcon, ErrorStateIcon } from "@/components/icons";

import { EmptyStateType } from "./empty-state.types";
import type { EmptyStateProps } from "./empty-state.types";

import {
    EmptyStateContent,
    EmptyStateSecondaryWrapperDiv,
    EmptyStateWrapperDiv,
    Description,
    Title,
    TitleAndDescriptionWrapper,
} from "./empty-state.styles";

const EmptyState = ({
    title,
    description,
    emptyStateType,
    minHeight,
    svgWidth,
    svgHeight,
}: EmptyStateProps) => {
    const renderStateSVG = (emptyStateType: EmptyStateType) => {
        switch (emptyStateType) {
            case EmptyStateType.Sessions:
                return <EmptySessionsStateIcon width={svgWidth} height={svgHeight} />;
            case EmptyStateType.Charts:
                return <EmptyChartsStateIcon width={svgWidth} height={svgHeight} />;
            case EmptyStateType.Error:
                return <ErrorStateIcon width={svgWidth} height={svgHeight} />;
        }
    };

    return (
        <EmptyStateWrapperDiv>
            <EmptyStateSecondaryWrapperDiv minHeight={minHeight}>
                <EmptyStateContent>
                    {renderStateSVG(emptyStateType)}

                    <TitleAndDescriptionWrapper>
                        {title && (
                            <Title>
                                <FormattedMessage id={title} />
                            </Title>
                        )}
                        {description && (
                            <Description>
                                <FormattedMessage id={description} />
                            </Description>
                        )}
                    </TitleAndDescriptionWrapper>
                </EmptyStateContent>
            </EmptyStateSecondaryWrapperDiv>
        </EmptyStateWrapperDiv>
    );
};

export default EmptyState;
