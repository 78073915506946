import { AiOutlineLoading } from "react-icons/ai";

import { get } from "lodash";
import styled from "styled-components";

import { CardLoaderContainer, spin } from "./loader.styles";

const Sizes: {
    [prop: string]: string;
} = {
    extraSmall: "0.5rem",
    small: "1rem",
    medium: "2rem",
    large: "4rem",
    extraLarge: "6rem",
};

type PropsLoaderAnimation = {
    [prop: string]: any;
    color: string | number;
};
const LoaderAnimation = styled(AiOutlineLoading)<PropsLoaderAnimation>`
    animation: ${spin} 1.6s infinite linear;
    fill: ${({ theme, color }) =>
        color ? get(theme.colors, color) : theme.colors.neutral.grey[700]};
`;

type Props = {
    size?: string;
    color?: string;
    [prop: string]: any;
};

const Loader = ({ size = "medium", color = "primary.orange[500]", ...props }: Props) => (
    <LoaderAnimation fontSize={Sizes[size]} color={color} {...props} />
);

export default Loader;

export type CardLoaderProps = {
    transparent?: boolean;
    height?: number | string;
};

//deprecated
export const CardLoader = (props: CardLoaderProps) => (
    <CardLoaderContainer {...props}>
        <Loader />
    </CardLoaderContainer>
);
