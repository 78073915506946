import { useState } from "react";
import { useIntl } from "react-intl";

import { useUser } from "@/context/user";

import TraineesFilter from "@/components/filters/trainees";
import { VRIntlProviderComponent } from "@/components/providers/intl-provider";
import Page from "@/components/ui/page";

import { FiltersList } from "@/enums/dashboard";
import { Roles } from "@/enums/user";

import Benefits from "./components/benefits";
import MiniSessionsList from "./components/mini-sessions-list/mini-sessions-list";
import ProgressOverTime from "./components/progress-over-time";

import { LeftColumn, Main, RightColumn } from "./index.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

export default function OverviewPage() {
    const intl = useIntl();
    const pageName = intl.formatMessage({ id: "overview:description" });
    const [filterOpen, setFilterOpen] = useState(FiltersList.None);
    const {
        state: { id: userId, role },
    } = useUser();
    const [selectedUserId, setSelectedUserId] = useState(role === Roles.Trainee ? userId : null);

    const onUserSelected = (userId: string | null) => {
        setSelectedUserId(userId);
    };

    const isAdminOrInstructor = role === Roles.Instructor || role === Roles.Admin;

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="overview-page" fallback={null}>
            <Page
                description={pageName}
                title={intl.formatMessage({ id: "overview:title" })}
                rightContent={
                    isAdminOrInstructor && (
                        <TraineesFilter
                            isOpen={FiltersList.TraineesList === filterOpen}
                            handleOnClick={(val: any) => setFilterOpen(val)}
                            onUserSelected={onUserSelected}
                            initialUserId={selectedUserId}
                        />
                    )
                }
            >
                <Main>
                    <LeftColumn>
                        <ProgressOverTime userId={selectedUserId} />
                        <Benefits userId={selectedUserId} />
                    </LeftColumn>

                    <RightColumn>
                        <MiniSessionsList userId={selectedUserId} />
                    </RightColumn>
                </Main>
            </Page>
        </VRIntlProviderComponent>
    );
}
