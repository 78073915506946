export const extractDate = (dateString: string | undefined): string => {
    const date = new Date(dateString || "");
    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const day: number = date.getDate();

    const formattedMonth: string = month < 10 ? "0" + month : month.toString();
    const formattedDay: string = day < 10 ? "0" + day : day.toString();

    return `${formattedDay}/${formattedMonth}/${year}`;
};
