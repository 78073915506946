import { useEffect, useState } from "react";

import { getCombinedCharts } from "@/api/sessions";

import useQueryParams from "@/utils/use-query-params";

export const usePolling = (poll: number) => {
    const params: any = useQueryParams();
    const userId = params.get("userId");
    const from: string = params.get("from");
    const to: string = params.get("to");
    const simulationInstanceId: string = params.get("simulationInstanceId");
    const scenarioInstanceId: string = params.get("scenarioInstanceId");

    const [timelineData, setTimelineData] = useState<any>();

    useEffect(() => {
        async function getData() {
            const data = await getCombinedCharts({
                from,
                to,
                userId,
                simulationInstanceId,
                scenarioInstanceId,
            });
            setTimelineData(data);
        }

        getData();
    }, [poll]);

    return {
        timelineData,
    };
};
