import styled from "styled-components";

import base from "@/themes/base/base";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${base.colors.neutral.grey[800]};
    padding: 1.5rem;
    gap: 1.25rem;
    border-radius: 0.5rem;
    overflow: auto;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;
