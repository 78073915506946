import type { IconProps } from "@/types/icon";

const CalendarIcon32 = ({
    width = 32,
    height = 32,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        {...remainingProps}
    >
        <path
            d="M11.8518 18.5667C11.558 18.5667 11.3117 18.4717 11.113 18.2816C10.9142 18.0915 10.8148 17.856 10.8148 17.575C10.8148 17.294 10.9142 17.0585 11.113 16.8685C11.3117 16.6784 11.558 16.5834 11.8518 16.5834C12.1457 16.5834 12.392 16.6784 12.5907 16.8685C12.7895 17.0585 12.8889 17.294 12.8889 17.575C12.8889 17.856 12.7895 18.0915 12.5907 18.2816C12.392 18.4717 12.1457 18.5667 11.8518 18.5667ZM16 18.5667C15.7062 18.5667 15.4599 18.4717 15.2611 18.2816C15.0623 18.0915 14.963 17.856 14.963 17.575C14.963 17.294 15.0623 17.0585 15.2611 16.8685C15.4599 16.6784 15.7062 16.5834 16 16.5834C16.2938 16.5834 16.5401 16.6784 16.7389 16.8685C16.9377 17.0585 17.037 17.294 17.037 17.575C17.037 17.856 16.9377 18.0915 16.7389 18.2816C16.5401 18.4717 16.2938 18.5667 16 18.5667ZM20.1481 18.5667C19.8543 18.5667 19.608 18.4717 19.4093 18.2816C19.2105 18.0915 19.1111 17.856 19.1111 17.575C19.1111 17.294 19.2105 17.0585 19.4093 16.8685C19.608 16.6784 19.8543 16.5834 20.1481 16.5834C20.442 16.5834 20.6883 16.6784 20.887 16.8685C21.0858 17.0585 21.1852 17.294 21.1852 17.575C21.1852 17.856 21.0858 18.0915 20.887 18.2816C20.6883 18.4717 20.442 18.5667 20.1481 18.5667ZM8.74074 26.5C8.17037 26.5 7.6821 26.3058 7.27592 25.9174C6.86975 25.529 6.66666 25.0621 6.66666 24.5167V10.6334C6.66666 10.0879 6.86975 9.62103 7.27592 9.23262C7.6821 8.84422 8.17037 8.65002 8.74074 8.65002H9.77778V6.66669H11.8518V8.65002H20.1481V6.66669H22.2222V8.65002H23.2593C23.8296 8.65002 24.3179 8.84422 24.7241 9.23262C25.1302 9.62103 25.3333 10.0879 25.3333 10.6334V24.5167C25.3333 25.0621 25.1302 25.529 24.7241 25.9174C24.3179 26.3058 23.8296 26.5 23.2593 26.5H8.74074ZM8.74074 24.5167H23.2593V14.6H8.74074V24.5167ZM8.74074 12.6167H23.2593V10.6334H8.74074V12.6167Z"
            fill={fill}
        />
    </svg>
);

export default CalendarIcon32;
