import React from "react";

import styled from "styled-components";

import PauseIcon from "../../../../../stories/assets/pause.svg";
import PlayIcon from "../../../../../stories/assets/play.svg";

interface PlayPauseButtonProps {
    isDisabled: boolean;
    isPlaying: boolean;
    togglePlayPause: () => void;
}

interface ButtonContainerProps {
    isDisabled: boolean;
}

interface IconProps {
    isDisabled: boolean;
}

const ButtonContainer = styled.div<ButtonContainerProps>`
    cursor: pointer;
    display: flex;
    margin-right: 0.25rem;
    pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
`;

const Icon = styled.img<IconProps>`
    width: 1.5rem;
    filter: ${({ isDisabled }) => (isDisabled ? "grayscale(100%) brightness(35%)" : "none")};
`;

const PlayPauseButton: React.FC<PlayPauseButtonProps> = ({
    isDisabled,
    isPlaying,
    togglePlayPause,
}) => {
    return (
        <ButtonContainer
            isDisabled={isDisabled}
            onClick={!isDisabled ? togglePlayPause : undefined}
        >
            <Icon
                isDisabled={isDisabled}
                src={isDisabled ? PlayIcon : isPlaying ? PauseIcon : PlayIcon}
            />
        </ButtonContainer>
    );
};

export default PlayPauseButton;
