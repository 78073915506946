import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import ContentLoader from "@/components/content-loader/content-loader";
import { StyledRadioButton } from "@/components/ui/styled-radio";
import Tooltip from "@/components/ui/tooltip/tooltip";

import type { ChartData, ChartProps, ChartState, TimelineChartData } from "@/types/timeline-chart";

import {
    Input,
    Label,
    RadioWrapper,
    Title,
    TitleAndRadioWrapper,
} from "../../session-detail.styles";

import CombinedChart from "./combined-chart";
import { usePolling } from "./utils/usePolling";

import { Container } from "./timeline-chart-flight.styles";

const TimelineChartFlight = ({ ...remainingProps }) => {
    const [poll, setPoll] = useState(0);
    const { timelineData } = usePolling(poll);

    const timelineDataSets: TimelineChartData[] = useMemo(
        () => timelineData?.filter((dataSet: any) => dataSet.chartType === "Timeline") || [],
        [timelineData],
    );
    const timelineChartData: ChartData[] = useMemo(
        () => timelineDataSets?.flatMap((chart: any) => chart.datasets) || [],
        [timelineDataSets],
    );
    const areaChartData: ChartData[] = useMemo(
        () => timelineChartData?.filter((chart: any) => chart.type === "area") || [],
        [timelineChartData],
    );
    const rangeBarData: any = useMemo(
        () => timelineChartData?.filter((chart: any) => chart.type === "rangeBar") || [],
        [timelineChartData],
    );

    const sortedDataForRadio = timelineChartData?.sort(
        (a: ChartData, b: ChartData) => a?.position - b?.position,
    );
    const chartTypes: ChartProps = useMemo(() => {
        return sortedDataForRadio.reduce((acc: any, obj: any) => {
            acc[obj.name] = {
                label: obj.radioLabel,
                color: obj.color,
                disabled: obj.data.length === 0 ? true : false,
            };

            return acc;
        }, {});
    }, [timelineChartData]);

    const [chartStateByType, setChartStateByType] = useState<ChartState>({});

    const computeInitialState = (chartTypes: ChartProps) => {
        return Object.keys(chartTypes).reduce(
            (acc, key) => ({
                ...acc,
                [key]: true, //initialize all chart types to be visible (true)
            }),
            {},
        );
    };

    //useEffect for initializing state based on chart types
    useEffect(() => {
        const initialState = computeInitialState(chartTypes);
        setChartStateByType(initialState);
    }, [chartTypes]);

    useEffect(() => {
        if (rangeBarData.length === 0 && poll < 30) {
            //maximum polling time as 5 minutes
            setTimeout(() => {
                setPoll(poll + 1);
            }, 10000);
        }
    }, [rangeBarData]);

    if (!timelineData) {
        return <ContentLoader height="18.875rem" />;
    }

    const changeHandler = (chartType: string) => {
        setChartStateByType((prevState) => ({
            ...prevState,
            [chartType]: !prevState[chartType],
        }));
    };

    //component should not load if area chart data is empty
    if (areaChartData.length === 0) return null;

    return (
        <Container {...remainingProps}>
            <TitleAndRadioWrapper>
                <Title>
                    <FormattedMessage id={"session-detail:timeline"} />
                    <Tooltip formattedMessage="session-detail:timeline-chart-tooltip" />
                </Title>

                <RadioWrapper>
                    {Object.entries(chartTypes).map(([chartType, chartProps]) => {
                        const classNames = `
              ${chartStateByType[chartType] ? "" : "unchecked"} 
              ${chartProps?.disabled ? "disabled" : ""}`.trim();

                        const testId = chartProps?.label.toLowerCase().replace(/\s+/g, "-");

                        return (
                            <Label
                                key={chartType}
                                htmlFor={chartType}
                                className={classNames}
                                data-test-id={testId}
                            >
                                <Input
                                    type="checkbox"
                                    defaultChecked
                                    id={chartType}
                                    onChange={() => changeHandler(chartType)}
                                    disabled={chartProps?.disabled}
                                />
                                <StyledRadioButton
                                    selected={!!chartStateByType[chartType]}
                                    color={chartProps?.color}
                                />
                                {chartProps?.label}
                            </Label>
                        );
                    })}
                </RadioWrapper>
            </TitleAndRadioWrapper>

            <CombinedChart
                areaChartData={areaChartData}
                rangeBarData={rangeBarData}
                chartStateByType={chartStateByType}
            />
        </Container>
    );
};

export default TimelineChartFlight;
