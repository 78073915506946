import styled from "styled-components";

export const Container = styled.img`
    position: fixed;
    z-index: 0;
    bottom: 0;
    right: 0;
    height: calc(100vh - var(--heat-header-height));
    width: calc(100% - var(--navigation-width));
`;
