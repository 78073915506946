//the purpose of this function is to check whether a component is part of a shared row
//in page-layout API response
export const isComponentPartOfSharedRow = (gridLayoutData: any, componentName: string) => {
    const gridObjectContainingComponent = gridLayoutData.find((item: any) => {
        //if at least one of the items meets the condition, return the whole object
        return item.cols.some((col: any) => {
            return col.component === componentName;
        });
    });

    if (gridObjectContainingComponent?.cols?.length > 1) {
        return true;
    } else {
        return false;
    }
};
