import React, { useCallback } from "react";

import { debounce } from "lodash";

import { CustomSlider } from "./styles";

interface FlightSliderProps {
    isDisabled: boolean;
    currentTime: string;
    currentTick: number;
    totalTickCount: number;
    onSliderChange: (newValue: any) => void;
    onPausePlayback: () => void;
    onResumePlayback: () => void;
}

const FlightSlider: React.FC<FlightSliderProps> = React.memo(
    ({
        isDisabled,
        currentTime,
        currentTick,
        totalTickCount,
        onSliderChange,
        onPausePlayback,
        onResumePlayback,
    }) => {
        const debouncedHandleSliderChange = useCallback(debounce(onSliderChange, 100), [
            onSliderChange,
        ]);
        const handleSliderStart = useCallback(() => onPausePlayback(), [onPausePlayback]);
        const handleSliderStop = useCallback(() => onResumePlayback(), [onResumePlayback]);

        return (
            <CustomSlider
                isdisabled={isDisabled.toString()}
                value={currentTick}
                onChange={(event, newValue) => debouncedHandleSliderChange(newValue)}
                aria-labelledby="flight-timeline"
                step={1}
                marks={[]}
                min={0}
                max={totalTickCount - 1}
                valueLabelDisplay="auto"
                valueLabelFormat={currentTime}
                onMouseDown={handleSliderStart}
                onMouseUp={handleSliderStop}
                style={{
                    flexGrow: 1,
                    marginBottom: 0,
                    pointerEvents: isDisabled ? "none" : "auto",
                }}
            />
        );
    },
);

FlightSlider.displayName = "FlightSlider";

export default FlightSlider;
