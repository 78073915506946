import { FormattedMessage } from "react-intl";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import moment from "moment";

import { CalendarIcon28 } from "@/components/icons";

import { ProgressFilters } from "@/enums/overview";

import {
    ButtonContainer,
    StyledDatePicker,
    FilterContainer,
    DatePickerStyles,
} from "./index.styles";

const FilterGroup = ({
    activeKey,
    onChange,
    date,
    onDateChange,
    onYearChange,
    sessionStart,
}: {
    activeKey: string;
    onChange: (filter: string) => void;
    date: moment.Moment | null;
    onDateChange: (val: moment.Moment | null) => void;
    onYearChange: (val: moment.Moment | null) => void;
    sessionStart: {
        startDate: string;
    };
}) => {
    return (
        <FilterContainer>
            <DatePickerStyles />

            {activeKey === ProgressFilters.Month && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StyledDatePicker
                        views={["year", "month"]}
                        openTo="month"
                        value={date}
                        onMonthChange={(date) => {
                            onDateChange(date);
                        }}
                        minDate={moment(sessionStart?.startDate)}
                        maxDate={moment()}
                        slots={{
                            openPickerIcon: CalendarIcon28,
                        }}
                    />
                </LocalizationProvider>
            )}

            {activeKey === ProgressFilters.Year && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StyledDatePicker
                        views={["year"]}
                        openTo="year"
                        value={date}
                        className="year"
                        onYearChange={(date) => {
                            onYearChange(date);
                        }}
                        minDate={moment(sessionStart?.startDate)}
                        maxDate={moment()}
                        slots={{
                            openPickerIcon: CalendarIcon28,
                        }}
                    />
                </LocalizationProvider>
            )}

            <ButtonContainer aria-label="Basic button group">
                {Object.keys(ProgressFilters).map((key) => {
                    const filter = ProgressFilters[key];

                    return (
                        <li
                            key={filter}
                            className={activeKey === filter ? "btn-active" : ""}
                            onClick={() => onChange(filter)}
                        >
                            <FormattedMessage id={`overview:${filter}`} />
                        </li>
                    );
                })}
            </ButtonContainer>
        </FilterContainer>
    );
};

export default FilterGroup;
