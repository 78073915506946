import type { IconProps } from "@/types/icon";

const ArrowLeftIcon = ({
    width = 18,
    height = 14,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 18 14`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path
            d="M7.33382 12.8334L1.50049 7.00002M1.50049 7.00002L7.33382 1.16669M1.50049 7.00002L16.5005 7.00002"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ArrowLeftIcon;
