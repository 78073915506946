import type { Moment } from "moment";

export interface MVPFormInputs {
    firstName: string;
    lastName: string;
    dateOfBirth?: Moment;
    gender?: string;
}

export const genderOptions = [
    {
        code: "M",
        value: "Male",
        placeHolder: null,
    },
    {
        code: "F",
        value: "Female",
        placeHolder: null,
    },
    {
        code: "NA",
        value: "Prefer not to say",
        placeHolder: null,
    },
];
