import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useMsal } from "@azure/msal-react";

import { useModal } from "@/context/modal/modal";

import Loader from "@/components/ui/loader";

import {
    ButtonsWrapper,
    LogoutDescription,
    ModalContentWrapperDiv,
    StyledCancelButton,
    StyledConfirmButton,
} from "../modal-contents.styles";
interface Props {
    title: string;
    description?: string;
}

const LogoutConfirmationModal = ({ description }: Props) => {
    const { instance } = useMsal();
    const { closeModal } = useModal();
    const [loading, setLoading] = useState(false);

    const handleLogOut = () => {
        setLoading(true);
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    const handleOnCancel = () => {
        closeModal();
    };

    const intl = useIntl();
    const localizedText = intl.formatMessage({ id: description });
    const logoutText = localizedText.split("\\n");

    return (
        <ModalContentWrapperDiv>
            <LogoutDescription>
                {logoutText?.map((logoutText, index) => <div key={index}>{logoutText}</div>)}
            </LogoutDescription>

            <ButtonsWrapper>
                <StyledConfirmButton
                    onClick={handleLogOut}
                    disabled={loading}
                    data-test-id="confirm-log-out"
                    fullWidth
                >
                    {loading ? (
                        <Loader size="medium" color="#666666" fontSize="1.6875rem" />
                    ) : (
                        <FormattedMessage id="logout:modal-contents:title" />
                    )}
                </StyledConfirmButton>

                <StyledCancelButton
                    onClick={handleOnCancel}
                    data-test-id="cancel-log-out"
                    fullWidth
                >
                    <FormattedMessage id="logout:modal-contents:cancel" />
                </StyledCancelButton>
            </ButtonsWrapper>
        </ModalContentWrapperDiv>
    );
};

export default LogoutConfirmationModal;
