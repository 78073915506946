import client from "@/client";

import { parseDashboardFilters } from "@/utils/api";

export const getOverviewCustomTopStats = async (filters) => {
    const { data = [] } = await client.get(
        `/v1/Overview/custom-top-stats${parseDashboardFilters(filters)}`,
    );

    return data;
};

//Progress Bar Chart component
export const getProgressBarChart = async (filters) => {
    const { data } = await client.get(
        `/v1/Overview/progress-chart${parseDashboardFilters(filters)}`,
    );

    return data;
};

//Progress Custom Top stats component
export const getProgressStats = async (filters) => {
    const { data } = await client.get(
        `/v1/Overview/progress-stats${parseDashboardFilters(filters)}`,
    );

    return data;
};

//Progress calendar picker min,max dates
export const getSessionsStart = async (filters) => {
    const { data } = await client.get(
        `/v1/Overview/sessions-start${parseDashboardFilters(filters)}`,
    );

    return data;
};
