import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import moment from "moment";

import { completeRegistration } from "@/api/user";

import { PrimaryButton } from "@/pages/shared/shared.styles";

import Loader from "@/components/ui/loader";

import { RadioControllerInput } from "../components/radio-input-controller/radio-with-input";
import TextInput from "../components/text-input/text-input";
import {
    Error,
    ErrorBanner,
    FieldWrapper,
    TwoColumnsWrapper,
    FormInput,
    FormWrapper,
    Label,
    StyledPrimaryButton,
    StyledSecondaryButton,
    SurveyTitle,
    MVPButtonWrapper,
    BottomSeparator,
    GlobalFormWrapper,
    MVPButtonsWrapper,
} from "../survey.styles";
import type { OnBoardingForm, WelcomeSurveyData } from "../survey.types";

import { genderOptions } from "./mvp-survey.types";
import type { MVPFormInputs } from "./mvp-survey.types";

const MVPSurvey = ({
    handleOnContinue,
    clientSurveyData,
    isClientSurvey,
    setIsClientSpecific,
    setIsMVP,
    setMVPSurveyValues,
    defaultValues,
}: {
    handleOnContinue: () => void;
    clientSurveyData: WelcomeSurveyData;
    isClientSurvey: boolean;
    setIsClientSpecific: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMVP: React.Dispatch<React.SetStateAction<boolean>>;
    setMVPSurveyValues: React.Dispatch<React.SetStateAction<MVPFormInputs>>;
    defaultValues?: MVPFormInputs;
}) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);

    const thereIsClientSpecificSurvey = isClientSurvey;

    const onBoardingQuestions: OnBoardingForm = clientSurveyData.onBoardingForm;

    const { gender, dateOfBirth } = onBoardingQuestions;

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isValid },
        control,
        setValue,
    } = useForm<MVPFormInputs>({
        shouldUseNativeValidation: false,
        defaultValues,
    });

    // Pre-fill the form fields when the component mounts
    useEffect(() => {
        if (defaultValues) {
            Object.keys(defaultValues).forEach((key) => {
                setValue(key as keyof MVPFormInputs, defaultValues[key as keyof MVPFormInputs]);
            });
        }
    }, [defaultValues, setValue]);

    const onSubmit = async () => {
        const values = getValues();

        const mappedValues: MVPFormInputs = dateOfBirth
            ? { ...values, dateOfBirth: moment(values.dateOfBirth, "DD/MM/YYYY") }
            : { ...values };

        if (!isClientSurvey && isValid) {
            setIsLoading(true);
            await completeRegistration(mappedValues);
            handleOnContinue();
        } else if (isClientSurvey && isValid) {
            setIsClientSpecific(true);
            setIsMVP(false);
            setMVPSurveyValues(values);
        }
    };

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            {Object.keys(errors).length > 0 && (
                <ErrorBanner>
                    <FormattedMessage id={"survey:error-banner"} />
                </ErrorBanner>
            )}

            <SurveyTitle>
                <FormattedMessage id={"survey:title"} />
            </SurveyTitle>

            <GlobalFormWrapper>
                <TwoColumnsWrapper marginTop={"1.5rem"}>
                    <FieldWrapper>
                        <TextInput
                            registeredField="firstName"
                            labelFormattedMessage="survey:first-name"
                            labelMarginBottom={"1.5rem"}
                            placeholderFormattedMessage="survey:first-name"
                            formattedRequiredMessage="survey:required"
                            emptyFieldError={errors?.firstName}
                            register={register}
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        <TextInput
                            registeredField="lastName"
                            labelFormattedMessage="survey:surname"
                            labelMarginBottom={"1.5rem"}
                            placeholderFormattedMessage="survey:surname"
                            formattedRequiredMessage="survey:required"
                            emptyFieldError={errors?.lastName}
                            register={register}
                        />
                    </FieldWrapper>

                    {gender && (
                        <FieldWrapper>
                            <RadioControllerInput<MVPFormInputs>
                                register={register}
                                control={control}
                                errors={errors}
                                registeredField={"gender"}
                                labelFormattedMessage="survey:gender"
                                labelMarginBottom={"1.5rem"}
                                options={genderOptions}
                                emptyFieldError={errors?.gender}
                                requiredRule={intl.formatMessage({ id: "survey:required" })}
                            />
                        </FieldWrapper>
                    )}

                    {dateOfBirth && (
                        <FieldWrapper>
                            <Label marginBottom={"1.5rem"}>
                                <FormattedMessage id={"survey:dob"} />
                            </Label>
                            <FormInput
                                className={errors?.dateOfBirth ? "error" : ""}
                                {...register("dateOfBirth", {
                                    required: intl.formatMessage({ id: "survey:required" }),
                                    validate: (value) =>
                                        moment(value, "DD/MM/YYYY", true).isValid() ||
                                        "Please enter your date of birth using the DD/MM/YYYY format.",
                                })}
                                placeholder={intl.formatMessage({ id: "survey:date-format" })}
                            />
                            {errors?.dateOfBirth && <Error>{errors.dateOfBirth.message}</Error>}
                            {!errors?.dateOfBirth && <BottomSeparator />}
                        </FieldWrapper>
                    )}
                </TwoColumnsWrapper>

                {/* CLIENT SPECIFIC */}
                {thereIsClientSpecificSurvey && (
                    <MVPButtonsWrapper>
                        <StyledSecondaryButton disabled>
                            <FormattedMessage id={"survey:go-back"} />
                        </StyledSecondaryButton>
                        <StyledPrimaryButton disabled={isLoading} type="submit">
                            {isLoading ? (
                                <Loader size="medium" color="#666666" fontSize="1.5rem" />
                            ) : (
                                <FormattedMessage id={"survey:continue"} />
                            )}
                        </StyledPrimaryButton>
                    </MVPButtonsWrapper>
                )}

                {/* ONLY MVP */}
                {!thereIsClientSpecificSurvey && (
                    <MVPButtonWrapper>
                        <PrimaryButton disabled={isLoading} type="submit" fullWidth>
                            {isLoading ? (
                                <Loader size="medium" color="#666666" fontSize="1.5rem" />
                            ) : (
                                <FormattedMessage id={"survey:continue"} />
                            )}
                        </PrimaryButton>
                    </MVPButtonWrapper>
                )}
            </GlobalFormWrapper>
        </FormWrapper>
    );
};

export default MVPSurvey;
