import styled, { css } from "styled-components";

import base from "@/themes/base/base";

import Button from "@/components/ui/button";

interface ButtonStylingProps {
    fullWidth?: boolean;
}

export const ButtonStyles = css`
    ${base.typography.largeMedium};

    padding: 0.3125rem 0.75rem;
    gap: 0.25rem;
    border-radius: 3.125rem;
    border: 0;
`;

export const PrimaryButton = styled(Button)<ButtonStylingProps>`
    ${ButtonStyles};

    color: ${base.colors.neutral.grey[900]};
    background: ${base.colors.primary.orange[500]};
    border: 1px solid ${base.colors.primary.orange[500]};

    & svg path {
        fill: ${base.colors.neutral.grey[900]};
    }

    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  width: 100%;
              `
            : css`
                  width: fit-content;
              `}

    &:hover {
        background: ${base.colors.primary.orange[300]};
        border: 1px solid ${base.colors.primary.orange[300]};
    }

    &:active {
        background: ${base.colors.primary.orange[500]};
        border: 1px solid ${base.colors.primary.orange[500]};
    }

    &:disabled {
        color: ${base.colors.neutral.grey[500]};
        background: ${base.colors.neutral.grey[700]};
        border: 1px solid ${base.colors.neutral.grey[700]};

        & svg path {
            fill: ${base.colors.neutral.grey[500]};
        }
    }
`;

export const SecondaryButton = styled(Button)<ButtonStylingProps>`
    ${ButtonStyles};

    color: ${base.colors.neutral.grey[50]};
    background: transparent;
    border: 1px solid ${base.colors.neutral.grey[50]};

    & svg path {
        fill: ${base.colors.neutral.grey[50]};
    }

    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  width: 100%;
              `
            : css`
                  width: fit-content;
              `}

    &:hover {
        background: ${base.colors.neutral.grey[700]};
    }

    &:disabled {
        color: ${base.colors.neutral.grey[600]};
        background-color: transparent;
        border: 1px solid ${base.colors.neutral.grey[600]};

        & svg path {
            fill: ${base.colors.neutral.grey[600]};
        }
    }
`;

export const TertiaryButton = styled(Button)<ButtonStylingProps>`
    ${ButtonStyles};

    color: ${base.colors.primary.orange[500]};
    background: transparent;
    border: 1px solid transparent;

    & svg path {
        fill: ${base.colors.primary.orange[500]};
    }

    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  width: 100%;
              `
            : css`
                  width: fit-content;
              `}

    &:hover {
        background: ${base.colors.neutral.grey[700]};
        border: 1px solid ${base.colors.neutral.grey[700]};
    }

    &:disabled {
        color: ${base.colors.neutral.grey[600]};
        background-color: transparent;
        border: 1px solid transparent;

        & svg path {
            fill: ${base.colors.neutral.grey[600]};
        }
    }
`;
