import type { IconProps } from "@/types/icon";

const LogoutIcon32 = ({
    width = 32,
    height = 33,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path
            d="M8.22222 26.5C7.61111 26.5 7.08796 26.2824 6.65278 25.8472C6.21759 25.412 6 24.8889 6 24.2778V8.72222C6 8.11111 6.21759 7.58796 6.65278 7.15278C7.08796 6.71759 7.61111 6.5 8.22222 6.5H16V8.72222H8.22222V24.2778H16V26.5H8.22222ZM20.4444 22.0556L18.9167 20.4444L21.75 17.6111H12.6667V15.3889H21.75L18.9167 12.5556L20.4444 10.9444L26 16.5L20.4444 22.0556Z"
            fill={fill}
        />
    </svg>
);

export default LogoutIcon32;
