import type { IconProps } from "@/types/icon";

const CrossIcon32 = ({
    width = 32,
    height = 32,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        {...remainingProps}
    >
        <path
            d="M8.88867 23.1109L23.1109 8.88867M8.88867 8.88867L23.1109 23.1109"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
        />
    </svg>
);

export default CrossIcon32;
