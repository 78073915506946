import { useUser } from "@/context/user";

import Avatar from "@/components/ui/avatar";

import { AvatarContainer, Container, Email, Name, NameDiv } from "./styles";

const UserBadge = () => {
    const { state: user } = useUser();

    return (
        <Container>
            <AvatarContainer>
                <Avatar size="large" editable={false} name={user.name} photo={user.photo} />
                <NameDiv>
                    <Name style={{ color: "#F2F2F2", lineHeight: "27px", fontSize: "18px" }}>
                        {user.name}
                    </Name>
                    <Email style={{ color: "#999", fontSize: "16px", lineHeight: "24px" }}>
                        {user.email}
                    </Email>
                </NameDiv>
            </AvatarContainer>
        </Container>
    );
};

export default UserBadge;
