import type { IconProps } from "@/types/icon";

const ClockIcon24 = ({
    width = 24,
    height = 24,
    fill = "#999999",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        {...remainingProps}
    >
        <path
            d="M14.64 15.76L15.76 14.64L12.8 11.68V8H11.2V12.32L14.64 15.76ZM12 20C10.8933 20 9.85333 19.79 8.88 19.37C7.90667 18.95 7.06 18.38 6.34 17.66C5.62 16.94 5.05 16.0933 4.63 15.12C4.21 14.1467 4 13.1067 4 12C4 10.8933 4.21 9.85333 4.63 8.88C5.05 7.90667 5.62 7.06 6.34 6.34C7.06 5.62 7.90667 5.05 8.88 4.63C9.85333 4.21 10.8933 4 12 4C13.1067 4 14.1467 4.21 15.12 4.63C16.0933 5.05 16.94 5.62 17.66 6.34C18.38 7.06 18.95 7.90667 19.37 8.88C19.79 9.85333 20 10.8933 20 12C20 13.1067 19.79 14.1467 19.37 15.12C18.95 16.0933 18.38 16.94 17.66 17.66C16.94 18.38 16.0933 18.95 15.12 19.37C14.1467 19.79 13.1067 20 12 20ZM12 18.4C13.7733 18.4 15.2833 17.7767 16.53 16.53C17.7767 15.2833 18.4 13.7733 18.4 12C18.4 10.2267 17.7767 8.71667 16.53 7.47C15.2833 6.22333 13.7733 5.6 12 5.6C10.2267 5.6 8.71667 6.22333 7.47 7.47C6.22333 8.71667 5.6 10.2267 5.6 12C5.6 13.7733 6.22333 15.2833 7.47 16.53C8.71667 17.7767 10.2267 18.4 12 18.4Z"
            fill={fill}
        />
    </svg>
);

export default ClockIcon24;
