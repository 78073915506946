import { PolarAngleAxis, PolarGrid, Radar, RadarChart, ResponsiveContainer } from "recharts";

import type { ChartPoint, DataAttribute } from "@/types/session-details";
import type { ChartState } from "@/types/timeline-chart";

import { RadarColors } from "@/enums/session-details";

import { ChartWrapper } from "./radar-chart-styles";

type RadarChartProps = {
    dataSets: DataAttribute[];
    chartState: ChartState;
};

const RadarC = ({ dataSets, chartState }: RadarChartProps) => {
    return (
        <ChartWrapper>
            <ResponsiveContainer width="100%" height="100%">
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={dataSets}>
                    <PolarGrid stroke="#666" gridType="circle" />
                    <PolarAngleAxis dataKey="name" tickSize={12} tick={(d) => <TickText d={d} />} />

                    {chartState.expectedScore && (
                        <Radar
                            name="Expected score"
                            dataKey="expectedScore"
                            strokeWidth={2}
                            stroke={RadarColors.ExpectedScore}
                            fill={RadarColors.ExpectedScore}
                            fillOpacity={0.2}
                            isAnimationActive={false}
                            label={(point: ChartPoint) => (
                                <ScoreText point={point} fill={RadarColors.ExpectedScore} />
                            )}
                        />
                    )}

                    {chartState.actualScore && (
                        <Radar
                            name="Score Achieved"
                            dataKey="actualScore"
                            strokeWidth={2}
                            stroke={RadarColors.AchievedScore}
                            fill={RadarColors.AchievedScore}
                            isAnimationActive={false}
                            fillOpacity={0.2}
                            label={(point: ChartPoint) => (
                                <ScoreText point={point} fill={RadarColors.AchievedScore} />
                            )}
                        />
                    )}

                    {!chartState.actualScore && !chartState.expectedScore && (
                        <Radar name="Empty Chart" dataKey="emptyScore" isAnimationActive={false} />
                    )}
                </RadarChart>
            </ResponsiveContainer>
        </ChartWrapper>
    );
};

// to set the score label as per design
const ScoreText = ({ point, fill }: { point: any; fill: string }) => {
    let transformedY = point.y;
    let transformedX = point.x;

    if (point.index == 0) {
        transformedY = point.y - 2; //top
    } else if (point.index == 1) {
        transformedX = point.x + 8; //right
        transformedY = point.y + 4;
    } else if (point.index == 2) {
        transformedY = point.y + 10; //bottom
    } else if (point.index == 3) {
        transformedX = point.x - 8; //left
        transformedY = point.y + 4;
    }

    return (
        <text fill={fill} x={transformedX} y={transformedY} textAnchor="middle">
            {point.value}
        </text>
    );
};

const TickText = ({ d }: { d: any }) => {
    const { payload, x, y, textAnchor, index } = d;
    // to set the label position for bottom
    const isBottomLabel = index === 2;

    return (
        <text x={x} y={isBottomLabel ? y + 10 : y} textAnchor={textAnchor}>
            <tspan x={x} dy="0em">
                {payload.value}
            </tspan>
        </text>
    );
};

export default RadarC;
