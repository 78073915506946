import type { IconProps } from "@/types/icon";

const TimeIcon24 = ({
    width = 24,
    height = 25,
    fill = "#999999",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path
            d="M14.64 16.26L15.76 15.14L12.8 12.18V8.5H11.2V12.82L14.64 16.26ZM12 20.5C10.8933 20.5 9.85333 20.29 8.88 19.87C7.90667 19.45 7.06 18.88 6.34 18.16C5.62 17.44 5.05 16.5933 4.63 15.62C4.21 14.6467 4 13.6067 4 12.5C4 11.3933 4.21 10.3533 4.63 9.38C5.05 8.40667 5.62 7.56 6.34 6.84C7.06 6.12 7.90667 5.55 8.88 5.13C9.85333 4.71 10.8933 4.5 12 4.5C13.1067 4.5 14.1467 4.71 15.12 5.13C16.0933 5.55 16.94 6.12 17.66 6.84C18.38 7.56 18.95 8.40667 19.37 9.38C19.79 10.3533 20 11.3933 20 12.5C20 13.6067 19.79 14.6467 19.37 15.62C18.95 16.5933 18.38 17.44 17.66 18.16C16.94 18.88 16.0933 19.45 15.12 19.87C14.1467 20.29 13.1067 20.5 12 20.5ZM12 18.9C13.7733 18.9 15.2833 18.2767 16.53 17.03C17.7767 15.7833 18.4 14.2733 18.4 12.5C18.4 10.7267 17.7767 9.21667 16.53 7.97C15.2833 6.72333 13.7733 6.1 12 6.1C10.2267 6.1 8.71667 6.72333 7.47 7.97C6.22333 9.21667 5.6 10.7267 5.6 12.5C5.6 14.2733 6.22333 15.7833 7.47 17.03C8.71667 18.2767 10.2267 18.9 12 18.9Z"
            fill={fill}
        />
    </svg>
);

export default TimeIcon24;
