import type { HTMLProps } from "react";

import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox } from "./checkbox.styles";

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
    className?: string;
    checked: boolean;
}

export const Checkbox = ({ className, checked }: CheckboxProps) => (
    <CheckboxContainer className={className}>
        <HiddenCheckbox defaultChecked={checked} />
        <StyledCheckbox
            checked={checked}
            className={`styled-checkbox ${checked ? "checked" : "unchecked"}`}
        >
            <Icon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
            </Icon>
        </StyledCheckbox>
    </CheckboxContainer>
);

export default Checkbox;
