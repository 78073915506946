import { createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import type { ReactNode, Reducer } from "react";

import { getFeatureToggles } from "@/api/feature-toggles";

import { useApplication } from "@/context/application";

import type { RequestStatus } from "@/types/requests";

import useQueryParams from "@/utils/use-query-params";

import { reducer, SET_FEATURES } from "./reducer";

export type FeatureTogglesProps = {
    [prop: string]: any;
};

export const initialState: FeatureTogglesProps = {};

const FeatureTogglesContext = createContext<any>({});

type Props = {
    children: ReactNode;
};

const fetchFeatureToggles = async () => {
    return await getFeatureToggles();
};

const FeatureTogglesProvider = ({ children }: Props) => {
    const application = useApplication();
    const [state, dispatch] = useReducer<Reducer<FeatureTogglesProps, any>>(reducer, initialState);
    const [_status, setStatus] = useState<RequestStatus>("IDLE");
    const [_error, setError] = useState<boolean>(false);
    const params: any = useQueryParams();
    const isFeatureActive = useCallback(
        (name: string) =>
            state[name] &&
            (state[name] === "true" || state[name] === "yes" || state[name] === true),
        [state, dispatch],
    );

    useEffect(() => {
        setStatus("PENDING");
        fetchFeatureToggles()
            .then((data: FeatureTogglesProps) => {
                dispatch({
                    type: SET_FEATURES,
                    payload: Object.keys(data).reduce((acc: FeatureTogglesProps, key: string) => {
                        acc[key] = data[key];

                        if (params.has(key) && process.env.REACT_APP_ENV !== "production") {
                            const value = params.get(key);
                            acc[key] = value === "true" || value === "yes";
                        }

                        return acc;
                    }, {}),
                });
                setStatus("RESOLVED");
                application.setLoaderQueueResolved("FEATURE_TOGGLES");
            })
            .catch((_e) => {
                setError(true);
            });
    }, []);

    return (
        <FeatureTogglesContext.Provider value={{ state, dispatch, isFeatureActive }}>
            {children}
        </FeatureTogglesContext.Provider>
    );
};

function useFeatureToggles() {
    const context = useContext(FeatureTogglesContext);

    if (context === undefined) {
        throw new Error("useFeatureToggles must be used within a FeatureTogglesProvider");
    }

    return context;
}

export { FeatureTogglesProvider, useFeatureToggles };
