import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { Line, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

import * as THREE from "three";

import type { ChartState } from "@/types/timeline-chart";

import { data, expectedData } from "../../data";
import { average, latLongToXYZ } from "../../utils";

//Arrow component to show the direction of circuit
const Arrow: React.FC<{ position: THREE.Vector3; color: string; direction: string }> = ({
    position,
    color,
    direction,
}) => {
    const arrowShape = new THREE.Shape();

    if (direction === "left") {
        arrowShape.moveTo(-10, 0); // Top vertex
        arrowShape.lineTo(10, 10); // Bottom right vertex
        arrowShape.lineTo(0, 0); // Middle vertex
        arrowShape.lineTo(10, -10); // Bottom left vertex
        arrowShape.lineTo(-10, 0); // Close the arrow
    } else if (direction === "right") {
        arrowShape.moveTo(10, 0);
        arrowShape.lineTo(-10, -10);
        arrowShape.lineTo(0, 0);
        arrowShape.lineTo(-10, 10);
        arrowShape.lineTo(10, 0);
    }

    const arrowGeometry = new THREE.ShapeGeometry(arrowShape);

    return (
        <mesh position={position} rotation={[Math.PI / 2, 0, -Math.PI / 4]}>
            <bufferGeometry attach="geometry" {...arrowGeometry} />
            <meshBasicMaterial color={color} />
        </mesh>
    );
};

// Plane component for runway - first point from data
const RunwayPlane: React.FC<{ position: THREE.Vector3 }> = ({ position }) => {
    return (
        <>
            <mesh
                position={[position.x, position.y, position.z - 20]}
                rotation={[Math.PI / 4, Math.PI / 4, 0]}
            >
                <boxGeometry args={[120, 1, 25]} />
                <meshBasicMaterial color="#121212" />
            </mesh>
        </>
    );
};

type CircuitProps = {
    chartStateByType: ChartState;
};

interface ThreeSceneHandle {
    resetCamera: () => void;
}

const Circuit3D = forwardRef<ThreeSceneHandle, CircuitProps>(function Circuit3D(
    { chartStateByType },
    ref,
) {
    const orbitRef = useRef<any>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const camera = new THREE.PerspectiveCamera(40);

    // Define the resetCamera function and expose it to the parent
    useImperativeHandle(ref, () => ({
        resetCamera() {
            camera.position.set(40, -(Math.abs(centerY) + 1000), 50);
            orbitRef.current.target.set(0, 0, 0);
            orbitRef.current.update();
        },
    }));

    const points = data.map(({ lat, lon, alt }) => {
        const [x, y, z] = latLongToXYZ(lat, lon, alt);

        return new THREE.Vector3(x, y, z);
    });

    const expectedPoints = expectedData.map(({ lat, lon, alt }) => {
        const [x, y, z] = latLongToXYZ(lat, lon, alt);

        return new THREE.Vector3(x, y, z);
    });

    const [centerX, centerY, centerZ] = ["x", "y", "z"].map((axis) => average(points, axis));

    //Translate the points to set a new scale as circuit area is small and invisible
    //With these new points we calculate the camera position for x,z axis - it may differ to each client
    points.forEach((point) => {
        point.setX((point.x - centerX) * 100);
        point.setY(point.y - centerY);
        point.setZ((point.z - centerZ) * 100);
    });

    expectedPoints.forEach((point) => {
        point.setX((point.x - centerX) * 100);
        point.setY(point.y - centerY);
        point.setZ((point.z - centerZ) * 100);
    });

    //getting the path direction based on x value ascending/descending
    const getDirection = (points: THREE.Vector3[]) => {
        return points[0].x > points[10].x ? "left" : "right";
    };

    //Top view camera position
    camera.position.set(40, -(Math.abs(centerY) + 500), 50);

    return (
        <Canvas ref={canvasRef} camera={camera} style={{ height: "300px" }}>
            {/* Add lighting to make sure objects are visible */}
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />

            {/* Add grid helper and axis helper for visual debugging */}
            {/* <gridHelper args={[1000, 20]} /> */}
            {/* <axesHelper args={[1000]} /> */}

            {/* Line to show circuit path and arrow to show direction */}
            {chartStateByType.Circuit && (
                <>
                    <Line points={points} color="#B3B3B3" lineWidth={2} />;
                    <Arrow position={points[0]} color="#B3B3B3" direction={getDirection(points)} />
                </>
            )}

            {/* Line to show expected circuit path and arrow to show direction */}
            {chartStateByType.ExpectedCircuit && (
                <>
                    <Line
                        points={expectedPoints}
                        color="#EE7127"
                        lineWidth={2}
                        dashed
                        dashSize={2}
                        gapSize={2}
                    />
                    <Arrow
                        position={expectedPoints[120]}
                        color="#EE7127"
                        direction={getDirection(expectedPoints)}
                    />
                </>
            )}

            <RunwayPlane position={points[0]} />

            {/* Controls to orbit around the scene */}
            <OrbitControls
                ref={orbitRef}
                enablePan
                minDistance={50}
                maxDistance={650}
                enableRotate={true}
            />
        </Canvas>
    );
});

export default Circuit3D;
