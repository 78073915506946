import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { useMsal } from "@azure/msal-react";

import { dataPrivacyReview, getDataPrivacy } from "@/api/onboarding";

import { useUser } from "@/context/user";

import { PrimaryButton, TertiaryButton } from "@/pages/shared/shared.styles";

import { VRIntlProviderComponent } from "@/components/providers/intl-provider";
import Loader from "@/components/ui/loader";

import { checkIfScrollable } from "@/utils/check-if-scrollable";

import { Container, PageLayout, StyledHeatLogo } from "../onboarding-styles";

import {
    ButtonWrapper,
    GlobalWrapper,
    PolicyTitle,
    Statement,
    TitleAndTextContentWrapper,
} from "./privacy.styles";

const localeFn = (target: string) => import(`./../locale/${target.toLowerCase()}.json`);

const PrivacyStatement = () => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const {
        state: { registrationCompleted },
    } = useUser();

    const [loading, setLoading] = useState({
        accept: false,
        decline: false,
    });

    const [hasScrollableArea, setHasScrollableArea] = useState(false);

    const { isFetching: isLoading, data: dataPrivacy } = useQuery(
        ["getDataPrivacy"],
        () => getDataPrivacy(),
        { suspense: false, refetchOnMount: false, useErrorBoundary: false },
    );

    /* This useEffect checks if content to render is bigger or not than the parent container
    setting hasScrollableArea variable as true or false */
    useEffect(() => {
        const statementContainer = document.getElementById("DataPrivacyStatement");
        const containerMaxHeight = 35.4375 * 16; //convert to px, because of checkScrollable function

        if (statementContainer) {
            setHasScrollableArea(checkIfScrollable(statementContainer, containerMaxHeight));
        }
    }, [dataPrivacy]);

    const handleLogOut = useCallback(async () => {
        setLoading((prev) => ({
            ...prev,
            decline: true,
        }));

        await dataPrivacyReview(dataPrivacy?.version, false);

        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }, [instance]);

    const handleOnAccept = async () => {
        setLoading((prev) => ({
            ...prev,
            accept: true,
        }));

        await dataPrivacyReview(dataPrivacy?.version, true);

        if (!registrationCompleted) navigate(`/welcome`);
        else navigate(`/overview`);
    };

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="privacy-page" fallback={null}>
            <PageLayout>
                <Container>
                    <StyledHeatLogo />

                    <GlobalWrapper>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <>
                                <TitleAndTextContentWrapper>
                                    <PolicyTitle>
                                        <FormattedMessage id={"privacy:title"} />
                                    </PolicyTitle>

                                    <Statement
                                        id="DataPrivacyStatement"
                                        hasScrollableArea={hasScrollableArea}
                                        dangerouslySetInnerHTML={{
                                            __html: dataPrivacy?.fileContent,
                                        }}
                                    ></Statement>
                                </TitleAndTextContentWrapper>

                                <ButtonWrapper hasScrollableArea={hasScrollableArea}>
                                    <PrimaryButton
                                        onClick={handleOnAccept}
                                        disabled={loading.accept}
                                        fullWidth
                                    >
                                        {loading.accept ? (
                                            <Loader
                                                size="medium"
                                                color="#666666"
                                                fontSize="1.5rem"
                                            />
                                        ) : (
                                            <FormattedMessage id={"privacy:accept"} />
                                        )}
                                    </PrimaryButton>

                                    <TertiaryButton onClick={handleLogOut} fullWidth>
                                        {loading.decline ? (
                                            <Loader
                                                size="medium"
                                                color="#EE7127"
                                                fontSize="1.5rem"
                                            />
                                        ) : (
                                            <FormattedMessage id={"privacy:decline"} />
                                        )}
                                    </TertiaryButton>
                                </ButtonWrapper>
                            </>
                        )}
                    </GlobalWrapper>
                </Container>
            </PageLayout>
        </VRIntlProviderComponent>
    );
};

export default PrivacyStatement;
