import moment from "moment";

import { DashboardFilter } from "@/enums/dashboard";

type ParamsType = {
    [prop: string]: any;
};

export const parseDashboardFilters = (params: ParamsType) => {
    if (!params) return ``;

    const values: string[] = Object.values(DashboardFilter);

    const queryParams = Object.keys(params)
        .filter((k: string) => values.includes(k) && params[k] !== null)
        .map((k: string) => {
            let value = params[k];

            switch (k) {
                case DashboardFilter.From:
                case DashboardFilter.To:
                    value = moment(value, ["MM/DD/YYYY", "YYYY-MM-DD", "DD/MM/YYYY"]).format(
                        "YYYY-MM-DD",
                    );
                    break;
            }

            return `${k}=${value}`;
        });

    return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
};
