import styled from "styled-components";

import base from "@/themes/base/base";
import { mobileMedia } from "@/themes/breakpoints";

export const Main = styled.main`
    margin-top: var(--heat-header-height);
    box-sizing: border-box;
    padding: 0 1.5rem 0 calc(var(--navigation-width) + 1.5rem);
    min-height: 100%;
    position: relative;
    z-index: 1;

    @media (max-width: 767px) {
        padding: calc(9.5rem) 1rem 0 1rem;
        padding-top: 433px; //to review when we have mobile designs
    }
`;

export const Header = styled.header`
    position: sticky;
    top: var(--heat-header-height);
    z-index: 3;
    background: ${base.colors.neutral.grey[900]};
    padding: 1.5rem 0;
`;

export const Back = styled.div`
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    path {
        fill: ${({ theme }) => theme.colors.neutral.grey[300]};
        stroke: ${({ theme }) => theme.colors.neutral.grey[300]};
    }
`;

export const TitleText = styled.div`
    ${base.typography.extraLarge2Bold};
    height: 2.5rem;

    display: flex;
    align-items: center;

    ${mobileMedia} {
        ${base.typography.largeMedium};
    }
`;

export const Counter = styled.span`
    ${base.typography.baseRegular};

    color: ${base.colors.neutral.grey[300]};
    padding-left: 0.5rem;
`;

export const HeaderTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PageTitle = styled.h3`
    color: ${base.colors.neutral.grey[50]};
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0.02rem;
    margin: 0 0 1.5rem;
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LeftCol = styled.div`
    flex: 0.6;
    margin-bottom: 1rem;

    @media (min-width: 768px) and (max-width: 1279px) {
        flex: 0.4;
    }

    @media (max-width: 768px) {
        flex: 1;
    }
`;

export const RightCol = styled.div`
    flex: 0.4;
    justify-content: flex-end;
    display: flex;

    @media (min-width: 768px) and (max-width: 1279px) {
        flex: 0.6;
    }

    @media (max-width: 767px) {
        flex: 1;
    }
`;

export const Content = styled.div``;

export const BreadcrumbContainer = styled.div`
    display: flex;
    padding-bottom: 0.5rem;
`;

export const BackButton = styled.div``;

type BreadcrumbLinkProps = {
    path?: string;
};
export const BreadcrumbLink = styled.span<BreadcrumbLinkProps>`
    cursor: ${({ path }) => (path ? "pointer" : "default")};
    ${({ theme, path }) => !path && `color: ${theme.colors.neutral.grey[50]}`};

    &::after {
        content: " / ";
    }

    &:last-child {
        &::after {
            content: "";
        }
    }
`;

export const PageLoaderContainer = styled.div`
    padding-left: 16.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media (max-width: 767px) {
        padding-left: 0;
    }
`;
