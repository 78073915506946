import styled from "styled-components";

import base from "@/themes/base/base";

export const Container = styled.div`
    display: flex;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem;
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 2px;
    border: 1px solid ${base.colors.neutral.grey[300]};
    border-radius: 2px;
    cursor: pointer;

    &:hover {
        background: ${base.colors.neutral.grey[600]};
    }
`;
