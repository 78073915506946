import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { TooltipIcon18, TooltipIcon24 } from "@/components/icons";

import type { TooltipProps } from "./tooltip.types";

import { CustomTooltip, IconWrapper, StyledUl, TooltipContent } from "./tooltip.styles";

const Tooltip = ({
    simpleText,
    formattedMessage,
    simpleTextArray,
    formattedMessageArray,
}: TooltipProps) => {
    const [isOpen, setIsOpen] = useState(false);

    //close the tooltip on scroll
    useEffect(() => {
        const handleScroll = () => {
            setIsOpen(false);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <CustomTooltip
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            title={
                <TooltipContent>
                    <IconWrapper>
                        <TooltipIcon18 />
                    </IconWrapper>
                    {simpleText && <span>{simpleText}</span>}
                    {formattedMessage && <FormattedMessage id={formattedMessage} />}
                    {simpleTextArray && (
                        <StyledUl>
                            {simpleTextArray.map((string) => {
                                return (
                                    <li key={string}>
                                        <span>{string}</span>
                                    </li>
                                );
                            })}
                        </StyledUl>
                    )}
                    {formattedMessageArray && (
                        <StyledUl>
                            {formattedMessageArray.map((string) => {
                                return (
                                    <li key={string}>
                                        <FormattedMessage id={string} />
                                    </li>
                                );
                            })}
                        </StyledUl>
                    )}
                </TooltipContent>
            }
            arrow
        >
            {/* icon needs to be wrapped in a div in order to keep the hover feature */}
            <IconWrapper>
                <TooltipIcon24 />
            </IconWrapper>
        </CustomTooltip>
    );
};

export default Tooltip;
