import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";

import { getOverviewCustomTopStats } from "@/api/overview";

import ContentLoader from "@/components/content-loader/content-loader";
import { CalendarIcon24 } from "@/components/icons";

import type { BenefitsPropTypes, BenefitsType, Value } from "@/types/overview";

import useMediaQuery from "@/utils/hooks/use-media-query";

import {
    Container,
    Header,
    IconAndSubtitleWrapper,
    StatName,
    StatValue,
    StatsWrapper,
    StyledDivider,
    SubTitle,
    Title,
    TitleContainer,
} from "../../index.styles";

import ContextFilter from "./context-filter";
import EmptyState from "./empty-state";
import { applyDatePreset, convertBenefitsValues } from "./utils";

import { ContentWrapper } from "./benefits.styles";

const Benefits = ({ userId }: BenefitsPropTypes) => {
    const [selectedPreset, setSelectedPreset] = useState<string>("last7days");
    const { from, to, type } = applyDatePreset(selectedPreset);

    const isSmallerThan1328px = useMediaQuery("(max-width: 1327px)");

    const {
        isFetching,
        data: customTopStats,
        isError,
    } = useQuery<BenefitsType>(
        ["getOverviewCustomTopStats", from, to, userId],
        () => getOverviewCustomTopStats({ userId, from, to, type }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: true,
        },
    );

    const noBenefitsData = customTopStats?.value.length === 0 && !isError;

    const renderBenefitsComponent = useCallback(() => {
        if (isFetching) {
            return <ContentLoader height={isSmallerThan1328px ? "5.5rem" : "4rem"} />;
        }

        if (isError || noBenefitsData) {
            return <EmptyState />;
        }

        return (
            <>
                {customTopStats?.value?.map((item: Value, index: number) => {
                    return (
                        <React.Fragment key={item.title}>
                            <StatsWrapper>
                                <StatName>{item.title}</StatName>
                                <StatValue>
                                    {convertBenefitsValues(item.result, item.unit, item.title)}
                                </StatValue>
                            </StatsWrapper>
                            {index < customTopStats.value.length - 1 && <StyledDivider />}
                        </React.Fragment>
                    );
                })}
            </>
        );
    }, [customTopStats, isFetching, noBenefitsData, isSmallerThan1328px]);

    return (
        <Container>
            <Header>
                <TitleContainer>
                    <Title>
                        <FormattedMessage id="overview:benefits:title" />
                    </Title>
                    <ContextFilter selectedPreset={selectedPreset} onSelect={setSelectedPreset} />
                </TitleContainer>
                <IconAndSubtitleWrapper>
                    <CalendarIcon24 fill="#999999" />
                    <SubTitle>
                        <FormattedMessage id={`overview:${selectedPreset}`} />
                    </SubTitle>
                </IconAndSubtitleWrapper>
            </Header>

            <ContentWrapper>{renderBenefitsComponent()}</ContentWrapper>
        </Container>
    );
};

export default Benefits;
