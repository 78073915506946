import type { IconProps } from "@/types/icon";

const SuccessIcon128 = ({ width = 128, height = 129, ...remainingProps }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 128 129"
        fill="none"
        {...remainingProps}
    >
        <path
            d="M63.9997 107.166C58.0975 107.166 52.5508 106.046 47.3597 103.806C42.1686 101.566 37.653 98.5263 33.813 94.6863C29.973 90.8463 26.933 86.3308 24.693 81.1397C22.453 75.9486 21.333 70.4019 21.333 64.4997C21.333 58.5975 22.453 53.0508 24.693 47.8597C26.933 42.6686 29.973 38.153 33.813 34.313C37.653 30.473 42.1686 27.433 47.3597 25.193C52.5508 22.953 58.0975 21.833 63.9997 21.833C68.6219 21.833 72.9952 22.5086 77.1197 23.8597C81.2441 25.2108 85.0486 27.0952 88.533 29.513L82.3463 35.8063C79.6441 34.0997 76.7641 32.7663 73.7063 31.8063C70.6486 30.8463 67.413 30.3663 63.9997 30.3663C54.5419 30.3663 46.4886 33.6908 39.8397 40.3397C33.1908 46.9886 29.8663 55.0419 29.8663 64.4997C29.8663 73.9575 33.1908 82.0108 39.8397 88.6597C46.4886 95.3086 54.5419 98.633 63.9997 98.633C73.4575 98.633 81.5108 95.3086 88.1597 88.6597C94.8086 82.0108 98.133 73.9575 98.133 64.4997C98.133 63.2197 98.0619 61.9397 97.9197 60.6597C97.7775 59.3797 97.5641 58.1352 97.2797 56.9263L104.213 49.993C104.995 52.2686 105.6 54.6152 106.026 57.033C106.453 59.4508 106.666 61.9397 106.666 64.4997C106.666 70.4019 105.546 75.9486 103.306 81.1397C101.066 86.3308 98.0263 90.8463 94.1863 94.6863C90.3463 98.5263 85.8308 101.566 80.6397 103.806C75.4486 106.046 69.9019 107.166 63.9997 107.166ZM58.0263 84.1263L39.893 65.993L45.8663 60.0197L58.0263 72.1797L100.693 29.4063L106.666 35.3797L58.0263 84.1263Z"
            fill="#999999"
        />
        <path
            d="M58.0269 84.1262L39.8936 65.9929L45.8669 60.0196L58.0269 72.1796L100.694 29.4062L106.667 35.3796L58.0269 84.1262Z"
            fill="#EE7127"
        />
    </svg>
);

export default SuccessIcon128;
