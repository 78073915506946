import type { IconProps } from "@/types/icon";

const TargetIcon20 = ({
    width = 20,
    height = 20,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        {...remainingProps}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7031 3.73827C13.6094 4.0666 15.9344 6.38368 16.2625 9.29174H17.5V10.6989H16.2625C15.9344 13.6163 13.6094 15.9334 10.7031 16.2617V17.5H9.29688V16.2617C6.38125 15.9334 4.06563 13.6163 3.7375 10.6989H2.5V9.29174H3.7375C4.06563 6.38368 6.38125 4.0666 9.29688 3.73827V2.5H10.7031V3.73827ZM5.05 9.99531C5.05 12.7345 7.27187 14.9578 10 14.9578C12.7281 14.9578 14.95 12.7251 14.95 9.99531C14.95 7.26548 12.7281 5.04221 10 5.04221C7.27187 5.04221 5.05 7.2561 5.05 9.99531ZM12.1094 9.99532C12.1094 11.161 11.165 12.106 10 12.106C8.83502 12.106 7.89062 11.161 7.89062 9.99532C7.89062 8.82961 8.83502 7.88462 10 7.88462C11.165 7.88462 12.1094 8.82961 12.1094 9.99532Z"
            fill={fill}
        />
    </svg>
);

export default TargetIcon20;
