import type { IconProps } from "@/types/icon";

const DirectionArrowTopRightIcon24 = ({
    width = 24,
    height = 24,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        {...remainingProps}
    >
        <path
            d="M12.9 21L10.05 13.95L3 11.1V9.7L21 3L14.3 21H12.9ZM13.55 17.3L17.6 6.4L6.7 10.45L11.6 12.4L13.55 17.3Z"
            fill={fill}
        />
    </svg>
);

export default DirectionArrowTopRightIcon24;
