import MapTimeline from "@/pages/session-detail/components/map-timeline/map-timeline";

import { ModalContentWrapperDiv } from "../modal-contents.styles";

const MapTimelineModal = () => {
    return (
        <ModalContentWrapperDiv>
            <MapTimeline />
        </ModalContentWrapperDiv>
    );
};

export default MapTimelineModal;
