import { useState } from "react";
import { useIntl } from "react-intl";

import { useSessionDetailsContext } from "@/context/session-details/session-details";

import base from "@/themes/base/base";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import { StyledRadioButton } from "@/components/ui/styled-radio";

import type { ChartState } from "@/types/timeline-chart";

import {
    Input,
    Label,
    RadioWrapper,
    Title,
    TitleAndRadioWrapper,
} from "../../session-detail.styles";

import RadarC from "./radar-chart";
import { Container } from "./radar-chart-styles";

const ScoreRadarChart = () => {
    const intl = useIntl();
    const { customChartsData, isFetchingCustomCharts } = useSessionDetailsContext();

    const radarChartData = customChartsData?.filter(
        (dataSet: any) => dataSet.chartType === "Radar",
    )[0];

    const scoreTypes = {
        expectedScore: {
            label: intl.formatMessage({
                id: "session-detail:expected-score",
            }),
            color: base.colors.primary.orange[500],
        },
        actualScore: {
            label: intl.formatMessage({
                id: "session-detail:score-achieved",
            }),
            color: base.colors.neutral.grey[50],
        },
    };

    const [chartStateByType, setScoreStateByType] = useState<ChartState>({
        actualScore: true,
        expectedScore: true,
    });

    const changeHandler = (scoreType: string) => {
        setScoreStateByType((prevState: ChartState) => ({
            ...prevState,
            [scoreType]: !prevState[scoreType],
        }));
    };

    if (isFetchingCustomCharts) {
        return <ContentLoader height="28.0625rem" />;
    }

    return (
        <Container>
            <TitleAndRadioWrapper>
                <Title>{radarChartData?.description}</Title>

                <RadioWrapper>
                    {Object.entries(scoreTypes).map(([scoreType, chartProps]) => {
                        const classNames = `
                            ${chartStateByType[scoreType] ? "" : "unchecked"}`.trim();

                        return (
                            <Label key={scoreType} htmlFor={scoreType} className={classNames}>
                                <Input
                                    type="checkbox"
                                    defaultChecked
                                    id={scoreType}
                                    onChange={() => changeHandler(scoreType)}
                                />
                                <StyledRadioButton
                                    selected={!!chartStateByType[scoreType]}
                                    color={chartProps?.color}
                                ></StyledRadioButton>
                                {chartProps?.label}
                            </Label>
                        );
                    })}
                </RadioWrapper>
            </TitleAndRadioWrapper>

            {radarChartData?.datasets?.length === 0 ? (
                <EmptyState
                    title={"session-detail:radar-chart-empty-state"}
                    emptyStateType={EmptyStateType.Charts}
                    minHeight="21.4375rem"
                />
            ) : (
                <RadarC dataSets={radarChartData.datasets} chartState={chartStateByType} />
            )}
        </Container>
    );
};

export default ScoreRadarChart;
