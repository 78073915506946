export const transformData = (reportsData: any) => {
    const csvData: any = [];
    let headerData: any = [];

    //looping through each dataset
    reportsData?.map((dataSet: any) => {
        //each dataset may have multiple scenarios/sessions
        dataSet?.data?.map((sessionData: any) => {
            const filteredSessionData = filterKeysWithoutUnderscore(sessionData);
            //headerData to populate the header in CSV
            headerData = Object.keys(filteredSessionData).map((key: any) => ({
                label: key.replace(/_/g, " "),
                key: key,
            }));

            //pushing row data to csvData
            csvData.push(filteredSessionData);
        });
    });

    return { csvData, headerData };
};

//function to filter out keys with underscore, such data should not be exported to CSV
export const filterKeysWithoutUnderscore = (item: any) => {
    const filteredEntries = Object.entries(item).filter(([key]) => !key.startsWith("_"));

    return Object.fromEntries(filteredEntries);
};
