import LayoutGrid from "@/components/layout-grid/layout-grid";
import { VRIntlProviderComponent } from "@/components/providers/intl-provider";
import Page, { PageLoader } from "@/components/ui/page";

import CircuitTopStats from "./components/circuit-top-stats";
import CockpitTimeline from "./components/cockpit-timeline";
import CustomTopStats from "./components/custom-top-stats";
import { EyeTracking } from "./components/eye-tracking";
import { FireMissions } from "./components/fire-missions";
import FlyingCircuit from "./components/flying-circuit-3d";
import { LandingScore } from "./components/landing-score";
import MapTimeline from "./components/map-timeline/map-timeline";
import { OverallScore } from "./components/overall-score";
import { PerformanceScore } from "./components/performance-score/performance-score";
import ScoreRadarChart from "./components/radar-chart";
import { ResponseTime } from "./components/response-time";
import { CircuitScoring } from "./components/scoring";
import { SemiCircularGauge } from "./components/semi-circular-gauge";
import TimelineChartDriving from "./components/timeline-chart-driving";
import TimelineChartFlight from "./components/timeline-chart-flight";
import TimelineChartFiring from "./components/timeline-chart-with-firing";

import { useSessionInfo } from "./hooks/useSessionInfo";

import { Main } from "./session-detail.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const SessionDetail = () => {
    const { getSessionSubtitleDetails, logoUrl, title, state } = useSessionInfo();

    //const [isFullScreen, setIsFullScreen] = useState(false);

    //const toggleFullScreen = () => {
    //    setIsFullScreen(!isFullScreen);
    //};

    const getComponentById = (component: string) => {
        switch (component) {
            case "LandingScore":
                return <LandingScore />;
            case "PerformanceScore":
                return <PerformanceScore />;
            case "CustomTopStats":
                return <CustomTopStats />;
            case "TimelineChartFlight":
                return <TimelineChartFlight />;
            case "TimelineChartFiring":
                return <TimelineChartFiring />;
            case "TimelineChartDriving":
                return <TimelineChartDriving />;
            case "MapTimeline":
                return <MapTimeline />;
            case "OverallScore":
                return <OverallScore />;
            case "ResponseTime":
                return <ResponseTime />;
            case "FireMissions":
                return <FireMissions />;
            case "EyeTracking":
                return <EyeTracking />;
            case "CockpitTimeline":
                return <CockpitTimeline />;
            case "SemiCircularGauge":
                return <SemiCircularGauge />;
            case "FlyingCircuit":
                return <FlyingCircuit />;
            case "CircuitTopStats":
                return <CircuitTopStats />;
            case "CircuitScoring":
                return <CircuitScoring />;
            case "RadarChart":
                return <ScoreRadarChart />;
            default:
                return null;
        }
    };

    return (
        <Page
            pageTitle={false}
            title={title}
            showBackButton={true}
            showDetails={true}
            subtitleDetailText={getSessionSubtitleDetails}
            logoUrl={state?.logoUrl || logoUrl}
        >
            <Main>
                <LayoutGrid getComponentById={getComponentById} />
            </Main>
        </Page>
    );
};

const SessionDetailPage = () => (
    <VRIntlProviderComponent localeFn={localeFn} id="session-detail" fallback={<PageLoader />}>
        <SessionDetail />
    </VRIntlProviderComponent>
);

export default SessionDetailPage;
