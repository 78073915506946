import styled from "styled-components";

export const ScoreWrapper = styled.div`
    color: "#FFF";
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Score = styled.span<{ $fontSize: string }>`
    font-size: ${(props) => props.$fontSize};
    display: block;
    font-weight: 400;
    line-height: ${(props) => props.$fontSize};
`;

export const TotalScore = styled.span<{ $fontSize: string }>`
    font-weight: 300;
    line-height: ${(props) => props.$fontSize};
    font-size: ${(props) => props.$fontSize};
`;
