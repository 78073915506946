import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { cloneDeep } from "lodash";

import { useSessionDetailsContext } from "@/context/session-details/session-details";

import base from "@/themes/base/base";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import { MultiSelectDropdown } from "@/components/filters/multi-select/multi-select";
import type { MultiSelectOption } from "@/components/filters/multi-select/multi-select.types";
import { TargetIcon16 } from "@/components/icons";
import { StyledRadioButton } from "@/components/ui/styled-radio";

import type { ResponseTimeData, ChartType } from "@/types/session-details";

import {
    Input,
    Label,
    RadioWrapper,
    Title,
    TitleAndRadioWrapper,
} from "../../session-detail.styles";

import { options } from "./chart-config";
import { Container, CustomBarChart, DropdownContainer, TitleContainer } from "./styles";

export const ResponseTime = () => {
    const { customChartsData, isFetchingCustomCharts } = useSessionDetailsContext();

    const sessionResponseTimeData = customChartsData?.find(
        (d: any) => d.code === "session-response-time",
    );

    //TODO: This should come from the backend
    const chartTypes: ChartType[] = [
        { name: "phase1", label: "Phase 1", color: base.colors.primary.orange[100] },
        { name: "phase2", label: "Phase 2", color: base.colors.primary.orange[300] },
        { name: "phase3", label: "Phase 3", color: base.colors.primary.orange[500] },
        { name: "phase4", label: "Phase 4", color: base.colors.primary.blue[500] },
        {
            name: "annotations",
            label: "Annotations",
            color: base.colors.neutral.grey[500],
        },
    ];

    const [chartStateByType, setChartStateByType] = useState<any>({
        phase1: true,
        phase2: true,
        phase3: true,
        phase4: true,
        annotations: true,
    });

    const [responseData, setResponseData] = useState<ResponseTimeData[]>([]);
    const [filteredResponseData, setFilteredResponseData] = useState<ResponseTimeData[]>([]);
    const [seriesData, setSeriesData] = useState<seriesType>();
    const [selectedItems, setSelectedItems] = useState<MultiSelectOption[]>([]);
    const [chartOptions, setChartOptions] = useState<any>(options);
    const annotationsRef = useRef(chartStateByType.annotations);

    //Map through the data and return an array of objects containing fire
    //mission number and phase duration in seconds.
    const constructMissionData = (missionData: any[]) => {
        const dataPattern = /^Phase\d+DurationSeconds$/;

        const result = missionData.map((currentValue) => {
            const newDataObject: ResponseTimeData = {};
            Object.keys(currentValue).forEach((key) => {
                if (dataPattern.test(key) || key === "FireMissionNumber") {
                    newDataObject[key] = currentValue[key];

                    if (key === "FireMissionNumber") {
                        newDataObject[key] = "Fire Mission " + currentValue[key];
                    }
                }
            });

            return newDataObject;
        });

        return result?.length ? result : null;
    };

    type seriesType = {
        name: string;
        data: any[];
        color: string;
    }[];

    //constructs the series data that apex charts expects.
    const constructSeriesData = (dataSeries: any[]) => {
        const series = chartTypes?.map((chartType: any) => ({
            name: chartType.name,
            data:
                dataSeries
                    ?.filter(() => chartStateByType[chartType.name] === true)
                    .map(
                        (item: any) => item[chartType.label.replace(" ", "") + "DurationSeconds"],
                    ) || [],
            color: chartType.color,
        }));
        setSeriesData(series);
    };

    //Sets up the component states for use.
    const initializeChartData = () => {
        const sessionResponseTimeDatasets =
            constructMissionData(sessionResponseTimeData?.datasets) || [];
        const initialSelections =
            sessionResponseTimeData?.datasets?.map((mission: any) => ({
                label: "Fire Mission " + mission.FireMissionNumber.toString(),
                id: "Fire Mission " + mission.FireMissionNumber.toString(),
            })) || [];

        if (sessionResponseTimeDatasets) {
            constructSeriesData(sessionResponseTimeDatasets);
            setResponseData(sessionResponseTimeDatasets);
            setFilteredResponseData(sessionResponseTimeDatasets);
            setSelectedItems(initialSelections);
            updateChartOptions(sessionResponseTimeDatasets);
        }
    };

    useEffect(() => {
        initializeChartData();
        annotationsRef.current = chartStateByType.annotations;
    }, [customChartsData]);

    useEffect(() => {
        constructSeriesData(filteredResponseData);
    }, [chartStateByType, chartOptions]);

    useEffect(() => {
        if (annotationsRef.current !== chartStateByType.annotations) {
            annotationsRef.current = chartStateByType.annotations;
            updateChartOptions(filteredResponseData);
        }
    }, [chartStateByType]);

    //Updates the chart type state from the radio button
    //only if there is more than one active or its annotations
    const changeHandler = (chartType: any) => {
        const allPhasesOff =
            Object.entries(chartStateByType).filter(
                ([key, value]) => key.startsWith("phase") && value,
            ).length === 1 &&
            Object.entries(chartStateByType).some(([key, value]) => key === chartType && value);

        if (!allPhasesOff || chartType === "annotations") {
            setChartStateByType((prevState: any) => ({
                ...prevState,
                [chartType]: !prevState[chartType],
            }));
        }
    };

    //Updates and returns the Apex chart options
    const updateChartOptions = (chartResponseData: any) => {
        const optionsCopy = cloneDeep(chartOptions);
        const originalOptionsCopy = cloneDeep(options);
        let annotations = originalOptionsCopy?.annotations?.xaxis;

        annotations = annotations.filter(() => annotationsRef.current !== false);
        optionsCopy.annotations.xaxis = annotations;

        //Update Xaxis categories
        const updatedCategories = chartResponseData?.map((mission: any) => {
            return mission.FireMissionNumber.toString();
        });

        //Ensure there is an empty string if the array is empty
        //Apex charts will return a list of numbers otherwise.
        const newCategories =
            updatedCategories && updatedCategories?.length > 0 ? updatedCategories : [""];

        optionsCopy.xaxis.categories = newCategories;

        setChartOptions(optionsCopy);
    };

    if (isFetchingCustomCharts) return <ContentLoader height="32.625rem" />;

    const dropdownOptions = Array.from(
        new Set(responseData?.map((mission) => mission.FireMissionNumber)),
    ).map((id) => {
        return {
            label: `${id}`,
            id: id.toString(),
        };
    });

    //Find selected Ids in the response data and use that
    //to overwrite the series data and X-axis to display only the selection
    const handleSelectionChange = (selected: MultiSelectOption[]) => {
        const selectedIds = new Set(selected.map((item) => item.id));

        const filteredResponse = responseData?.filter((response) =>
            selectedIds.has(response.FireMissionNumber.toString()),
        );

        setSelectedItems(selected);
        constructSeriesData(filteredResponse);
        setFilteredResponseData(filteredResponse);
        updateChartOptions(filteredResponse);
    };

    return (
        <Container>
            <TitleAndRadioWrapper>
                <TitleContainer>
                    <Title>
                        <FormattedMessage id={"session-detail:response-time"} />
                    </Title>
                    <DropdownContainer>
                        {sessionResponseTimeData?.datasets?.length > 0 ? (
                            <MultiSelectDropdown
                                options={dropdownOptions}
                                title="Fire Missions"
                                style={{ width: "230px" }}
                                icon={<TargetIcon16 />}
                                handleOnClick={() => {}}
                                onSelectionChange={(selectedItems) =>
                                    handleSelectionChange(selectedItems)
                                }
                                popUpWidth={"190px"}
                                initialSelectedOptions={selectedItems}
                                showSelectAll={true}
                            />
                        ) : (
                            <></>
                        )}
                    </DropdownContainer>
                </TitleContainer>
            </TitleAndRadioWrapper>

            {sessionResponseTimeData?.datasets?.length > 0 ? (
                <>
                    <RadioWrapper>
                        {chartTypes.map((chartType: any) => (
                            <Label
                                key={chartType.name}
                                htmlFor={chartType.name}
                                className={chartStateByType[chartType.name] ? "" : "unchecked"}
                            >
                                <Input
                                    type="checkbox"
                                    defaultChecked
                                    id={chartType.name}
                                    onChange={() => changeHandler(chartType.name)}
                                />
                                <StyledRadioButton
                                    selected={!!chartStateByType[chartType.name]}
                                    color={chartType?.color}
                                ></StyledRadioButton>
                                {chartType?.label}
                            </Label>
                        ))}
                    </RadioWrapper>
                    <CustomBarChart
                        series={seriesData || []}
                        options={chartOptions}
                        type="bar"
                        height={responseData?.length * 40 + 30}
                    />
                </>
            ) : (
                <EmptyState
                    title={"session-detail:response-time-empty-state-description"}
                    emptyStateType={EmptyStateType.Charts}
                    minHeight="26.6875rem"
                />
            )}
        </Container>
    );
};
