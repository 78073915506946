import styled, { css } from "styled-components";

import base from "@/themes/base/base";

import { Container as SemiCircularGaugeContainer } from "../semi-circular-gauge/gauge-styles";

export const ScoringWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: ${base.colors.neutral.grey[800]};
`;

export const SubtitleAndTooltipWrapper = styled.div`
    display: flex;
    gap: 0.25rem;
    padding: 0.65625rem 0;
`;

export const ScoringAndCircuitWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const CircuitScoreWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
`;

export const ProgressBarAndSubtitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
`;

export const DividerWrapper = styled.div`
    display: flex;
    padding: 0 1.5rem;
`;

export const Divider = styled.div`
    background-color: ${base.colors.neutral.grey[900]};
    width: 0.0625rem;
    height: 8rem;
`;

export const LinearProgressAndButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
`;

interface StylingProps {
    isIndividualCircuit?: boolean;
}

export const LinearProgressWrapper = styled.div<StylingProps>`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    ${({ isIndividualCircuit }) =>
        !isIndividualCircuit &&
        css`
            max-height: 11.875rem;
            overflow-y: scroll;
            padding-right: 0.375rem; //the space between the content & the scrollbar
        `}

    //scrollbar
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    //draggable indicator
    &::-webkit-scrollbar-thumb {
        background-color: ${base.colors.neutral.grey[600]};
    }

    //scrollbar track
    ::-webkit-scrollbar-track {
        background: transparent;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
`;

export const CircuitProgressContainer = styled.div`
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
`;

interface LinearProgressBarWrapperStylingProps {
    labelWidth: number | undefined;
}

export const LinearProgressBarAndResultWrapper = styled.div<LinearProgressBarWrapperStylingProps>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: ${({ labelWidth }) => labelWidth && `calc(100% - ${labelWidth}px)`};

    @media (min-width: 768px) and (max-width: 1023px) {
        width: ${({ labelWidth }) => labelWidth && `calc(93% - ${labelWidth}px)`};
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
        width: ${({ labelWidth }) => labelWidth && `calc(95% - ${labelWidth}px)`};
    }

    @media (min-width: 1280px) and (max-width: 1439px) {
        width: ${({ labelWidth }) => labelWidth && `calc(97% - ${labelWidth}px)`};
    }

    @media (min-width: 1440px) {
        width: ${({ labelWidth }) => labelWidth && `calc(98% - ${labelWidth}px)`};
    }
`;

interface LabelButtonStylingProps {
    labelWidth: number;
}

export const LabelButton = styled.button<LabelButtonStylingProps>`
    ${base.typography.smallRegular};

    font-family: "Roboto";

    display: flex;
    justify-content: flex-end;
    width: ${({ labelWidth }) => (labelWidth ? `${labelWidth}px` : "auto")};
    color: ${base.colors.neutral.grey[300]};
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: right;
    cursor: pointer;

    &:hover {
        color: ${base.colors.neutral.grey[50]};
        text-decoration: underline;
    }
`;

export const ResultLabel = styled.span`
    ${base.typography.largeRegular};
`;

export const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
`;

interface ComponentsWrapperProps {
    scrollableContainerHeight: number;
}

export const ComponentsScrollableWrapper = styled.div<ComponentsWrapperProps>`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: ${({ scrollableContainerHeight }) => `${scrollableContainerHeight}px`};
    overflow-y: scroll;

    //these two attributes allow placing the scrollbar inside the 'padding' of the modal
    //1.25rem (negative margin for scrollbar offset) - 0.75rem (right padding) = 0.5rem (scrollbar width)
    padding: 0 0.75rem 0 1.5rem; //adds padding inside the scrollable wrapper
    margin: 0 -1.25rem 0 -1.5rem; //negative margin shift the content outward by the same amount as the padding

    //scrollbar
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    //draggable indicator
    &::-webkit-scrollbar-thumb {
        background-color: ${base.colors.neutral.grey[600]};
    }

    //scrollbar track
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    & > :last-of-type {
        margin-bottom: 1.5rem;
    }
`;

export const SemiCircularGaugeAndCircuitScoringWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
    width: 100%;

    ${SemiCircularGaugeContainer} {
        width: 50.46%;
    }
`;
