import type { ReactNode } from "react";
import React from "react";

import Loader from "@/components/ui/loader";

import {
    PageInfoWrapper,
    LogoImage,
    LoaderContainer,
    StyledTitleText,
    StyledUserInfoText,
    TitleDetailsWrapperDiv,
    PageHeaderWrapperDiv,
} from "./page-header.styles";

interface PageHeaderProps {
    isLoading?: boolean;
    logoUrl?: string;
    currentPage?: any;
    title?: string;
    subtitleDetailText?:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | React.ReactPortal
        | null;
    rightContent?: JSX.Element;
}

const PageHeader = ({
    isLoading,
    logoUrl,
    currentPage,
    title,
    subtitleDetailText,
    rightContent,
    ...remainingProps
}: PageHeaderProps) => {
    return (
        <PageHeaderWrapperDiv {...remainingProps}>
            <PageInfoWrapper>
                {isLoading ? (
                    <LoaderContainer>
                        <Loader size="medium" />
                    </LoaderContainer>
                ) : (
                    <LogoImage src={logoUrl} />
                )}

                <TitleDetailsWrapperDiv>
                    <StyledTitleText>{currentPage ? currentPage.label : title}</StyledTitleText>
                    <StyledUserInfoText>{subtitleDetailText}</StyledUserInfoText>
                </TitleDetailsWrapperDiv>
            </PageInfoWrapper>
            {rightContent}
        </PageHeaderWrapperDiv>
    );
};

export default PageHeader;
