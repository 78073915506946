import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { triggerAnalytics } from "@/api/sessions";

import ContentLoader from "@/components/content-loader/content-loader";
import Loader from "@/components/ui/loader";
import { ProgressBar } from "@/components/ui/progress-bar/progress-bar";
import Tooltip from "@/components/ui/tooltip/tooltip";

import { Title, SubTitle, Divider } from "../../session-detail.styles";

import { usePolling } from "./usePolling";

import { Section } from "./score-chart.styles";

export type Score = {
    scoreTitle: string;
    score: number;
    description: string;
    position: number;
};

export const LandingScore = () => {
    const [poll, setPoll] = useState(0);
    const { landingScore, breakdownData, simulationInstanceId } = usePolling(poll);
    const breakdownDataSorted: any = breakdownData?.sort((a, b) => a.position - b.position);

    useEffect(() => {
        if (breakdownData?.length === 0) {
            triggerAnalytics({ simulationInstanceId });
            setTimeout(() => {
                setPoll(poll + 1);
            }, 10000);
        }
    }, [breakdownData]);

    if (breakdownData === undefined) {
        return <ContentLoader height="26.5rem" />;
    }

    return (
        <Section.Container>
            {breakdownData.length === 0 ? (
                <Section.NoData>
                    <Section.NoDataContent>
                        <Loader></Loader>
                        <Title>
                            <FormattedMessage id={"session-detail:data-loading"} />
                        </Title>
                    </Section.NoDataContent>
                </Section.NoData>
            ) : (
                <>
                    {landingScore && (
                        <Section.LandingScore>
                            <Title>
                                {landingScore.scoreTitle}
                                <Tooltip simpleText={landingScore.description} />
                            </Title>

                            <ProgressBar
                                width={320}
                                valueEnd={Math.round(landingScore.score)}
                                scoreFontSize="6rem"
                                totalScoreFontSize="3rem"
                            />

                            {/* <Section.Description> 
                        <FormattedMessage id={"session-detail:score-description"} />
                        <CustomTooltip title={
                                <TooltipContent>
                                    <StyledImg src={TooltipSVG} width="14px" />
                                    <FormattedMessage id={"session-detail:tooltip-landing-score-desc"} />
                                </TooltipContent>
                            } arrow>
                            <StyledImgMargin alt="tooltip" src={TooltipSVG} width="18px"/>
                        </CustomTooltip>
                    </Section.Description> */}
                        </Section.LandingScore>
                    )}

                    {breakdownData?.length !== 0 && (
                        <Section.Breakdown>
                            <Title>
                                <FormattedMessage id={"session-detail:score-breakdown"} />
                            </Title>

                            <Section.BreakDownContainer>
                                {breakdownDataSorted?.map((score: Score, index: number) => {
                                    return (
                                        <React.Fragment key={score.scoreTitle}>
                                            <Section.SubContainer>
                                                <ProgressBar
                                                    width={192}
                                                    valueEnd={Math.trunc(score.score)}
                                                    scoreFontSize="3rem"
                                                    totalScoreFontSize="1.5rem"
                                                />

                                                <SubTitle>
                                                    <>{score.scoreTitle}</>
                                                    {/* api text */}
                                                    <Tooltip simpleText={score.description} />
                                                </SubTitle>
                                            </Section.SubContainer>
                                            {index < breakdownData.length - 1 && <Divider />}
                                        </React.Fragment>
                                    );
                                })}
                            </Section.BreakDownContainer>
                        </Section.Breakdown>
                    )}
                </>
            )}
        </Section.Container>
    );
};
