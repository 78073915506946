import type { IconProps } from "@/types/icon";

const LockIcon = ({ width = 14, height = 18, fill = "#F2F2F2", ...remainingProps }: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path
            d="M13.3371 6.50571C13.0843 6.25286 12.7843 6.12857 12.4286 6.12857H10.9286V4.07143C10.9286 2.94429 10.53 1.98429 9.73714 1.19143C8.94429 0.398571 7.98429 0 6.85714 0C5.84143 0 4.96286 0.321429 4.21286 0.964286C3.46286 1.60714 3.00857 2.40857 2.85 3.36429C2.82 3.54857 2.86286 3.71571 2.97857 3.85714C3.09429 3.99857 3.24429 4.07143 3.43286 4.07143C3.6 4.07143 3.75 4.02 3.87857 3.92143C4.00714 3.82286 4.08429 3.69429 4.11429 3.53571C4.24286 2.89286 4.56 2.35714 5.07429 1.92857C5.58429 1.5 6.17571 1.28571 6.85714 1.28571C7.63286 1.28571 8.28857 1.55571 8.82857 2.1C9.37286 2.64 9.64286 3.29571 9.64286 4.07143V6.12857H1.28571C0.934286 6.12857 0.63 6.25286 0.377143 6.50571C0.124286 6.75857 0 7.05857 0 7.41429V16.7143C0 17.0657 0.124286 17.37 0.377143 17.6229C0.63 17.8757 0.934286 18 1.28571 18H12.4286C12.7843 18 13.0843 17.8757 13.3371 17.6229C13.59 17.37 13.7143 17.0657 13.7143 16.7143V7.41429C13.7143 7.05857 13.59 6.75857 13.3371 6.50571ZM8.02714 13.2429C7.70571 13.5557 7.31571 13.7143 6.86143 13.7143C6.40714 13.7143 6.01714 13.5557 5.69143 13.2429C5.37 12.93 5.20714 12.5529 5.20714 12.1157C5.20714 11.6786 5.37 11.2886 5.69143 10.9371C6.01286 10.59 6.39857 10.4143 6.85286 10.4143C7.30714 10.4143 7.69714 10.59 8.02286 10.9371C8.34429 11.2886 8.50714 11.6786 8.50714 12.1071C8.50714 12.5357 8.34857 12.9257 8.02714 13.2429Z"
            fill={fill}
        />
    </svg>
);

export default LockIcon;
