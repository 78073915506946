import { LinearProgress } from "@mui/material";

import styled from "styled-components";

import base from "@/themes/base/base";

export const StyledLinearProgress = styled(LinearProgress)`
    &.MuiLinearProgress-root {
        background-color: ${base.colors.neutral.grey[700]};
        height: 0.5rem;
        border-radius: 0.5rem;
        width: 100%;

        .MuiLinearProgress-bar {
            background-color: ${base.colors.primary.orange[500]};
            border-radius: 0.5rem;
        }
    }
`;
