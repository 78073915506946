import type { IconProps } from "@/types/icon";

const AddUserIcon24 = ({
    width = 24,
    height = 24,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        {...remainingProps}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2056 3.75V4.47796V6.60061H20.3281H21.0561V8.05654H20.3281H18.2056V10.1786V10.9066H16.7497V10.1786V8.05654H14.6274L13.8995 8.05654V6.60061H14.6274H16.7497V4.47796V3.75H18.2056ZM9.4694 12.5928C11.0036 12.5928 12.2328 11.3544 12.2328 9.82018C12.2328 8.28601 11.0036 7.04758 9.4694 7.04758C7.93523 7.04758 6.6968 8.28601 6.6968 9.82018C6.6968 11.3544 7.93523 12.5928 9.4694 12.5928ZM3 17.6759C3 15.5225 7.31601 14.4412 9.4694 14.4412C11.6228 14.4412 15.9388 15.5225 15.9388 17.6759V19.9864H3V17.6759Z"
            fill={fill}
        />
    </svg>
);

export default AddUserIcon24;
