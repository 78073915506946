import type { IconProps } from "@/types/icon";

const EmptyChartsStateIcon = ({ width = 128, height = 128, ...remainingProps }: IconProps) => (
    <div data-test-id="empty_charts">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 129 128"
            fill="none"
            {...remainingProps}
        >
            <g clipPath="url(#clip0_9645_54855)">
                <mask
                    id="mask0_9645_54855"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="129"
                    height="128"
                >
                    <path d="M128.211 0H0.210938V128H128.211V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_9645_54855)">
                    <path
                        d="M74.2772 121.135L73.6872 117.708H73.6647L73.3922 116.165C70.4147 116.71 67.3222 116.97 64.2072 116.97C57.8422 116.97 51.7397 115.835 46.0872 113.77L45.5447 115.245L44.9322 116.948L44.3547 118.513L42.9397 122.415L42.3047 124.14C49.1322 126.648 56.5047 127.998 64.1947 127.998C67.9772 127.998 71.6922 127.668 75.3047 127.045L74.9772 125.23L74.2747 121.135H74.2772Z"
                        fill="#121212"
                    />
                    <path
                        d="M96.5286 110.095L95.4861 108.607L94.5911 107.337C93.3336 108.232 92.0211 109.062 90.6836 109.857L91.4761 111.207L92.3811 112.785L92.9011 113.67L93.4336 114.6L94.2936 116.097L95.2911 117.835L96.2086 119.422C97.8286 118.47 99.3911 117.47 100.921 116.405L97.4886 111.47L96.5361 110.107L96.5236 110.095H96.5286Z"
                        fill="#121212"
                    />
                    <path
                        d="M127.964 58.2876L122.006 58.8101L120.646 58.9351L120.341 58.9576L118.541 59.1176V59.1401L116.979 59.2651C117.104 60.7976 117.171 62.3401 117.171 63.8826C117.171 65.4251 117.104 67.0251 116.979 68.5801L122.391 69.1701V69.1476L124.111 69.3301V69.2851L126.094 69.5001V69.4776L127.929 69.6701C128.099 67.7526 128.201 65.8226 128.201 63.8726C128.201 61.9226 128.111 60.1276 127.951 58.2901H127.961L127.964 58.2876Z"
                        fill="#121212"
                    />
                    <path
                        d="M121.337 73.9675L119.695 73.6825L117.917 73.365L116.377 73.0925C115.017 80.875 111.95 88.08 107.59 94.31L108.88 95.205L112.072 97.4525L113.477 98.44V98.43L115.12 99.5875L116.602 100.643C121.89 93.1075 125.595 84.405 127.235 75.0225L125.422 74.6925L121.335 73.9675H121.337Z"
                        fill="#121212"
                    />
                    <path
                        d="M108.624 101.223L107.344 100.155L105.962 98.9975L104.762 97.9875C103.777 99.18 102.734 100.303 101.637 101.403L105.499 105.273L106.722 106.498L108.127 107.905L109.429 109.21C110.742 107.893 112.012 106.51 113.212 105.103L111.819 103.923L108.637 101.245L108.624 101.223Z"
                        fill="#121212"
                    />
                    <path
                        d="M89.6316 118.515L88.9516 117.062L88.7141 116.562L88.5566 116.232L87.2441 113.418L86.5766 112C83.8141 113.293 80.9041 114.37 77.9141 115.165L78.3116 116.675L79.0141 119.285L79.2066 120.035L80.2941 124.075L80.7691 125.835C84.3941 124.882 87.8916 123.578 91.2441 122.01L90.4616 120.342L89.6116 118.527L89.6341 118.518L89.6316 118.515Z"
                        fill="#121212"
                    />
                    <path
                        d="M2.27367 58.4573L5.98867 58.7748L11.4237 59.2523C11.6962 56.1423 12.2512 53.0923 13.0212 50.1398L11.5162 49.7423L9.76117 49.2648L8.73117 48.9923L7.75617 48.7323L6.09117 48.2773L4.15367 47.7548L2.37617 47.2773C1.42367 50.8398 0.768672 54.5273 0.451172 58.2948L2.28617 58.4423V58.4648L2.27367 58.4523V58.4573Z"
                        fill="#121212"
                    />
                    <path
                        d="M7.65352 43.2649L8.16352 43.4474L9.66852 44.0024L11.231 44.5799L12.9285 45.2049L14.401 45.7499C15.476 42.7874 16.8235 39.9399 18.386 37.2524L13.7435 34.3599C13.7435 34.3599 13.721 34.3824 13.721 34.3949L12.2485 33.4749C12.2485 33.4749 12.226 33.5099 12.226 33.5199L10.5285 32.4649C10.5285 32.4649 10.506 32.4874 10.506 32.4999L8.94351 31.5249C7.01851 34.8374 5.37602 38.3099 4.04102 41.9524L5.77352 42.5874L7.64102 43.2799L7.65352 43.2674V43.2649Z"
                        fill="#121212"
                    />
                    <path
                        d="M18.142 25.1549L19.467 26.2674L22.4445 28.7649L23.6445 29.7749C25.6145 27.4149 27.812 25.2249 30.1795 23.2499L29.172 22.0474L28.0045 20.6524L26.9395 19.3699L24.267 16.1924L23.0895 14.7749C20.247 17.1699 17.6095 19.8024 15.207 22.6724L16.622 23.8524L18.152 25.1349L18.1395 25.1574L18.142 25.1549Z"
                        fill="#121212"
                    />
                    <path
                        d="M29.7128 14.5348L30.6978 15.9523L32.9403 19.1298L33.8353 20.4223C40.0403 16.0323 47.2428 12.9798 55.0128 11.6173L54.7403 10.0748L54.4128 8.24734L54.1303 6.62484L53.4053 2.52984L53.0778 0.714844C43.7003 2.35984 35.0053 6.06984 27.5078 11.3698L28.5603 12.8798L29.7153 14.5248V14.5373L29.7128 14.5348Z"
                        fill="#121212"
                    />
                    <path
                        d="M20.8238 94.3125L19.5438 95.2075L18.0613 96.2525L16.7013 97.205L13.3038 99.6L11.7988 100.655C13.9388 103.717 16.3388 106.567 18.9788 109.2L20.2813 107.895L21.6963 106.477L22.9188 105.252L26.7813 101.382C24.6063 99.205 22.6138 96.8325 20.8463 94.3025L20.8238 94.315V94.3125Z"
                        fill="#121212"
                    />
                    <path
                        d="M94.5697 20.435L95.4647 19.1425L97.7072 15.965L98.6922 14.5475L99.8472 12.9125L100.9 11.4025C91.9422 5.0725 81.2972 0.9975 69.7797 0L69.2472 5.9675L68.9297 9.45H68.9522L68.8047 11.015C78.3297 11.8425 87.1397 15.2025 94.5572 20.4325H94.5697V20.435Z"
                        fill="#121212"
                    />
                    <path
                        d="M116.89 49.755L119.72 48.9825L120.252 48.8475L124.262 47.7575L126.04 47.28C123.085 36.2625 117.275 26.415 109.437 18.5725L105.225 22.7925L104.035 23.9725L102.755 25.255L101.645 26.3675C108.122 32.8575 112.947 41.015 115.392 50.1375L116.897 49.74L116.887 49.7525L116.89 49.755Z"
                        fill="#121212"
                    />
                    <path
                        d="M29.1723 105.737L28.0048 107.122L27.5623 107.645L26.6573 108.722L25.8198 109.732L24.2673 111.582L23.0898 113C25.9323 115.395 28.9898 117.527 32.2173 119.422L33.1348 117.835L34.1323 116.097L34.9923 114.6L35.6048 113.545L36.0573 112.772L36.9623 111.195L37.7548 109.845C35.0823 108.28 32.5573 106.497 30.2023 104.522L29.1948 105.725L29.1723 105.737Z"
                        fill="#121212"
                    />
                    <path
                        d="M14.7772 86.9827L16.1922 86.3127C15.5472 84.9277 14.9572 83.4877 14.4147 82.0352L12.9422 82.5802L11.2447 83.1926L9.68219 83.7701L4.05469 85.8127C4.69969 87.5727 5.42469 89.2952 6.20719 90.9752L7.87219 90.2151L9.97969 89.2277L11.2472 88.6376L14.7797 86.9802L14.7772 86.9827Z"
                        fill="#121212"
                    />
                    <path
                        d="M4.94594 74.3649L6.65594 74.0599L10.4959 73.3799L12.0359 73.1074C11.5159 70.1224 11.2309 67.0724 11.2309 63.9399L9.66844 63.9624V63.9399L7.85594 63.9624V63.9399L6.19094 63.9624V63.9399L0.210938 64.0199C0.210938 67.7649 0.538437 71.4399 1.16344 75.0374L2.97594 74.7074L4.93594 74.3549V74.3649H4.94844H4.94594Z"
                        fill="#121212"
                    />
                </g>
                <g filter="url(#filter0_d_9645_54855)">
                    <path
                        d="M7.68461 29.425H122.327C123.017 29.425 123.577 29.985 123.577 30.675V95.7375C123.577 97.0275 122.53 98.0726 121.242 98.0726H7.68461C6.39461 98.0726 5.34961 97.025 5.34961 95.7375V31.76C5.34961 30.47 6.39711 29.425 7.68461 29.425Z"
                        fill="#4D4D4D"
                    />
                    <path
                        d="M14.9746 93.2477L14.9746 69.9727C14.9746 69.7642 14.8056 69.5952 14.5971 69.5952H9.62961C9.42112 69.5952 9.25211 69.7642 9.25211 69.9727L9.25211 93.2477C9.25211 93.4562 9.42112 93.6252 9.62961 93.6252H14.5971C14.8056 93.6252 14.9746 93.4562 14.9746 93.2477Z"
                        fill="#262626"
                    />
                    <path
                        d="M67.41 33.885H12.11C10.5305 33.885 9.25 35.1655 9.25 36.745C9.25 38.3245 10.5305 39.605 12.11 39.605H67.41C68.9895 39.605 70.27 38.3245 70.27 36.745C70.27 35.1655 68.9895 33.885 67.41 33.885Z"
                        fill="#999999"
                    />
                    <path
                        d="M41.6677 41.4927H16.8802C15.8267 41.4927 14.9727 42.3467 14.9727 43.4002C14.9727 44.4537 15.8267 45.3077 16.8802 45.3077H41.6677C42.7211 45.3077 43.5752 44.4537 43.5752 43.4002C43.5752 42.3467 42.7211 41.4927 41.6677 41.4927Z"
                        fill="#999999"
                    />
                    <path
                        d="M13.065 43.4002C13.065 42.3467 12.211 41.4927 11.1575 41.4927C10.104 41.4927 9.25 42.3467 9.25 43.4002C9.25 44.4537 10.104 45.3077 11.1575 45.3077C12.211 45.3077 13.065 44.4537 13.065 43.4002Z"
                        fill="#999999"
                    />
                    <path
                        d="M113.956 36.7474C113.956 38.327 115.236 39.6074 116.816 39.6074C118.395 39.6074 119.676 38.327 119.676 36.7474C119.676 35.1679 118.395 33.8874 116.816 33.8874C115.236 33.8874 113.956 35.1679 113.956 36.7474Z"
                        fill="#999999"
                    />
                    <path
                        d="M49.875 93.2477V69.9727C49.875 69.7642 49.706 69.5952 49.4975 69.5952H44.53C44.3215 69.5952 44.1525 69.7642 44.1525 69.9727V93.2477C44.1525 93.4562 44.3215 93.6252 44.53 93.6252H49.4975C49.706 93.6252 49.875 93.4562 49.875 93.2477Z"
                        fill="#262626"
                    />
                    <path
                        d="M41.1484 93.2125V65.0275C41.1484 64.7997 40.9638 64.615 40.7359 64.615H35.8384C35.6106 64.615 35.4259 64.7997 35.4259 65.0275V93.2125C35.4259 93.4403 35.6106 93.625 35.8384 93.625H40.7359C40.9638 93.625 41.1484 93.4403 41.1484 93.2125Z"
                        fill="#262626"
                    />
                    <path
                        d="M67.3242 93.1252V51.7002C67.3242 51.4241 67.1004 51.2002 66.8242 51.2002H62.1017C61.8256 51.2002 61.6017 51.4241 61.6017 51.7002V93.1252C61.6017 93.4013 61.8256 93.6252 62.1017 93.6252H66.8242C67.1004 93.6252 67.3242 93.4013 67.3242 93.1252Z"
                        fill="#262626"
                    />
                    <path
                        d="M23.6992 93.2125V65.0275C23.6992 64.7997 23.5145 64.615 23.2867 64.615H18.3892C18.1614 64.615 17.9767 64.7997 17.9767 65.0275V93.2125C17.9767 93.4403 18.1614 93.625 18.3892 93.625H23.2867C23.5145 93.625 23.6992 93.4403 23.6992 93.2125Z"
                        fill="#262626"
                    />
                    <path
                        d="M58.5996 93.2125V65.0275C58.5996 64.7997 58.4149 64.615 58.1871 64.615H53.2896C53.0618 64.615 52.8771 64.7997 52.8771 65.0275V93.2125C52.8771 93.4403 53.0618 93.625 53.2896 93.625H58.1871C58.4149 93.625 58.5996 93.4403 58.5996 93.2125Z"
                        fill="#262626"
                    />
                    <path
                        d="M32.4668 93.1252V51.7002C32.4668 51.4241 32.2429 51.2002 31.9668 51.2002H27.2443C26.9682 51.2002 26.7443 51.4241 26.7443 51.7002L26.7443 93.1252C26.7443 93.4013 26.9682 93.6252 27.2443 93.6252H31.9668C32.2429 93.6252 32.4668 93.4013 32.4668 93.1252Z"
                        fill="#262626"
                    />
                    <path
                        d="M102.225 93.1252V51.7002C102.225 51.4241 102.001 51.2002 101.725 51.2002H97.0021C96.726 51.2002 96.5021 51.4241 96.5021 51.7002V93.1252C96.5021 93.4013 96.726 93.6252 97.0021 93.6252H101.725C102.001 93.6252 102.225 93.4013 102.225 93.1252Z"
                        fill="#262626"
                    />
                    <path
                        d="M93.5 93.2125V65.0275C93.5 64.7997 93.3153 64.615 93.0875 64.615H88.19C87.9622 64.615 87.7775 64.7997 87.7775 65.0275V93.2125C87.7775 93.4403 87.9622 93.625 88.19 93.625H93.0875C93.3153 93.625 93.5 93.4403 93.5 93.2125Z"
                        fill="#262626"
                    />
                    <path
                        d="M119.676 93.2477V69.9727C119.676 69.7642 119.507 69.5952 119.298 69.5952H114.331C114.122 69.5952 113.953 69.7642 113.953 69.9727V93.2477C113.953 93.4562 114.122 93.6252 114.331 93.6252H119.298C119.507 93.6252 119.676 93.4562 119.676 93.2477Z"
                        fill="#262626"
                    />
                    <path
                        d="M76.0508 93.2125V65.0275C76.0508 64.7997 75.8661 64.615 75.6383 64.615H70.7408C70.513 64.615 70.3283 64.7997 70.3283 65.0275V93.2125C70.3283 93.4403 70.513 93.625 70.7408 93.625H75.6383C75.8661 93.625 76.0508 93.4403 76.0508 93.2125Z"
                        fill="#262626"
                    />
                    <path
                        d="M110.949 93.2127V65.0277C110.949 64.7999 110.765 64.6152 110.537 64.6152H105.639C105.411 64.6152 105.227 64.7999 105.227 65.0277V93.2127C105.227 93.4405 105.411 93.6252 105.639 93.6252H110.537C110.765 93.6252 110.949 93.4405 110.949 93.2127Z"
                        fill="#262626"
                    />
                    <path
                        d="M84.8164 93.2477V69.9727C84.8164 69.7642 84.6474 69.5952 84.4389 69.5952H79.4714C79.2629 69.5952 79.0939 69.7642 79.0939 69.9727V93.2477C79.0939 93.4562 79.2629 93.6252 79.4714 93.6252H84.4389C84.6474 93.6252 84.8164 93.4562 84.8164 93.2477Z"
                        fill="#262626"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_9645_54855"
                    x="0.349609"
                    y="26.925"
                    width="128.227"
                    height="78.6475"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.5" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_9645_54855"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_9645_54855"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_9645_54855">
                    <rect width="128" height="128" fill="white" transform="translate(0.210938)" />
                </clipPath>
            </defs>
        </svg>
    </div>
);

export default EmptyChartsStateIcon;
