import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { useSessionDetailsContext } from "@/context/session-details/session-details";

import Tooltip from "@/components/ui/tooltip/tooltip";

import type { ChartDataSet, TimelineChartData } from "@/types/timeline-chart";

import { Title } from "../../session-detail.styles";

import Controls from "./controls";
import { useFlightDataTimeline } from "./hooks/useFlightDataTimeline";
import Instruments from "./instruments";
import { Wrapper, Main } from "./styles";

const CockpitTimeline = () => {
    const { combinedChartsData, isFetchingCombinedCharts } = useSessionDetailsContext();

    const cockpitChartData: Array<{ [key: string]: ChartDataSet[] }> = useMemo(() => {
        const data = (combinedChartsData as TimelineChartData[])?.find(
            (chart) => chart.chartType === "Instrument",
        )?.datasets;

        return data || [];
    }, [combinedChartsData]);

    const transformedCockpitData = useMemo(() => {
        return (cockpitChartData as Array<{ [key: string]: ChartDataSet[] }>).reduce(
            (acc: any, set: any) => ({ ...acc, ...set }),
            {},
        );
    }, [cockpitChartData]);

    const {
        currentTick,
        setTick,
        isPlaying,
        play,
        pause,
        totalTickCount,
        currentTimecode,
        durationTimecode,
        currentObservations,
    } = useFlightDataTimeline(transformedCockpitData);

    const noData = useMemo(() => durationTimecode === "0:00", [durationTimecode]);

    return (
        <Wrapper bottomGap={false} borderTopRadius={true} borderBottomRadius={true}>
            <Title>
                <FormattedMessage id="session-detail:cockpit" />
                <Tooltip formattedMessage="session-detail:timeline-cockpit-tooltip" />
            </Title>

            <Main>
                <Instruments
                    noData={noData}
                    isLoading={isFetchingCombinedCharts}
                    airSpeed={currentObservations.AirSpeed}
                    bank={currentObservations.AttitudeBank}
                    pitch={currentObservations.AttitudePitch}
                    altitude={currentObservations.Altimeter}
                    heading={currentObservations.Heading}
                    verticalSpeed={currentObservations.VerticalSpeed}
                />
                <Controls
                    isDisabled={noData}
                    isPlaying={isPlaying}
                    play={play}
                    pause={pause}
                    currentTick={currentTick}
                    setTick={setTick}
                    totalTickCount={totalTickCount}
                    currentTime={currentTimecode}
                    duration={durationTimecode}
                />
            </Main>
        </Wrapper>
    );
};

export default CockpitTimeline;
