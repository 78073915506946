import type { IconProps } from "@/types/icon";

const UsersIcon24 = ({
    width = 24,
    height = 24,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        {...remainingProps}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0818 8.72727C11.0818 10.2364 9.87273 11.4545 8.36364 11.4545C6.85455 11.4545 5.63636 10.2364 5.63636 8.72727C5.63636 7.21818 6.85455 6 8.36364 6C9.87273 6 11.0818 7.21818 11.0818 8.72727ZM18.3545 8.72727C18.3545 10.2364 17.1455 11.4545 15.6364 11.4545C14.1273 11.4545 12.9091 10.2364 12.9091 8.72727C12.9091 7.21818 14.1273 6 15.6364 6C17.1455 6 18.3545 7.21818 18.3545 8.72727ZM8.36364 13.2727C6.24545 13.2727 2 14.3364 2 16.4545V18.7273H14.7273V16.4545C14.7273 14.3364 10.4818 13.2727 8.36364 13.2727ZM14.7545 13.3182C15.0727 13.2909 15.3727 13.2727 15.6364 13.2727C17.7545 13.2727 22 14.3364 22 16.4545V18.7273H16.5455V16.4545C16.5455 15.1091 15.8091 14.0818 14.7545 13.3182Z"
            fill={fill}
        />
    </svg>
);

export default UsersIcon24;
