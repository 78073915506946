import type { IconProps } from "@/types/icon";

const UsersIcon32 = ({
    width = 32,
    height = 32,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        {...remainingProps}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8064 11.4821C14.8064 13.4089 13.2345 14.9643 11.2727 14.9643C9.31091 14.9643 7.72727 13.4089 7.72727 11.4821C7.72727 9.55536 9.31091 8 11.2727 8C13.2345 8 14.8064 9.55536 14.8064 11.4821ZM24.2609 11.4821C24.2609 13.4089 22.6891 14.9643 20.7273 14.9643C18.7655 14.9643 17.1818 13.4089 17.1818 11.4821C17.1818 9.55536 18.7655 8 20.7273 8C22.6891 8 24.2609 9.55536 24.2609 11.4821ZM11.2727 17.2857C8.51909 17.2857 3 18.6438 3 21.3482V24.25H19.5455V21.3482C19.5455 18.6438 14.0264 17.2857 11.2727 17.2857ZM19.5809 17.3438C19.9945 17.3089 20.3845 17.2857 20.7273 17.2857C23.4809 17.2857 29 18.6438 29 21.3482V24.25H21.9091V21.3482C21.9091 19.6304 20.9518 18.3188 19.5809 17.3438Z"
            fill={fill}
        />
    </svg>
);

export default UsersIcon32;
