import type { StatsDataSet } from "@/types/session-details";

//Transforming datasets to array of arrays with 2 sets, to easily manage a row with max of 2 items with a divider
export const arrayOfTwoDatasets = (dataSets: StatsDataSet[]) => {
    dataSets.sort((a: any, b: any) => a.order - b.order);
    const result = [];

    for (let i = 0; i < dataSets.length; i += 2) {
        result.push(dataSets.slice(i, i + 2));
    }

    return result;
};
