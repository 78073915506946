import { FormattedMessage } from "react-intl";
import type {
    InfiniteData,
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "react-query";
import { useMutation } from "react-query";

import { updateUserStatus } from "@/api/users";

import { useModal } from "@/context/modal/modal";

import {
    UserStatus,
    UserStatusAction,
    UserStatusActionNoun,
    UserStatusPerformedAction,
} from "@/pages/users/components/users-table/users-table.types";
import { getStatus } from "@/pages/users/utils/utils";

import GenericError from "@/components/ui/generic-error";
import Loader from "@/components/ui/loader";

import type { User } from "@/types/users";

import {
    ButtonsWrapper,
    Description,
    ModalContentWrapperDiv,
    StyledCancelButton,
    StyledConfirmButton,
    Title,
} from "../../modal-contents.styles";

import ConfirmationModalContent from "../confirmation/confirmation-modal-content";

interface ChangeStatusModalContentProps {
    user: User;
    refetchUsersData: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<InfiniteData<any>, unknown>>;
}

const ChangeStatusModalContent = ({ user, refetchUsersData }: ChangeStatusModalContentProps) => {
    const { closeModal, openModal } = useModal();

    const userId = user.userId;

    const currentStatus = getStatus(user.isRegistrationCompleted, user.isActive);

    const futureStatusActionNoun =
        currentStatus === UserStatus.Active
            ? UserStatusAction.Deactivate
            : UserStatusAction.Activate;

    const futureStatusAction =
        currentStatus === UserStatus.Active
            ? UserStatusActionNoun.Deactivation
            : UserStatusActionNoun.Activation;

    const oppositeAction =
        currentStatus === UserStatus.Active
            ? UserStatusAction.Reactivate
            : UserStatusAction.Deactivate;

    const statusDisplay =
        futureStatusActionNoun === UserStatusAction.Deactivate
            ? UserStatusPerformedAction.Deactivated
            : UserStatusPerformedAction.Activated;

    const { mutate, isLoading } = useMutation(updateUserStatus, {
        onSuccess: () => {
            openModal({
                content: (
                    <ConfirmationModalContent
                        title="users:users-table:modal-contents:change-status:confirmation-modal:title"
                        titleIntlValues={{ status: statusDisplay }}
                        description="users:users-table:modal-contents:change-status:confirmation-modal:description"
                        descriptionIntlValues={{
                            oppositeAction: oppositeAction.toLowerCase(),
                        }}
                    />
                ),
            });

            refetchUsersData();
        },
        onError: () => {
            openModal({ content: <GenericError /> });
        },
    });

    const handleChangeStatusButton = () => {
        const isActive = currentStatus === UserStatus.Active ? false : true;

        mutate({ userId, isActive }); //PUT request to change status -> /User?UpdateRole API { userId: string, isActive: boolean}
    };

    return (
        <ModalContentWrapperDiv>
            <Title>{`${futureStatusActionNoun} user`}</Title>
            <Description>
                <FormattedMessage
                    id="users:users-table:modal-contents:change-status:description"
                    values={{
                        action: futureStatusAction,
                        name: user.displayName,
                        oppositeAction: oppositeAction.toLowerCase(),
                    }}
                />
            </Description>
            <ButtonsWrapper>
                <StyledConfirmButton
                    disabled={isLoading}
                    onClick={handleChangeStatusButton}
                    fullWidth
                >
                    {isLoading ? (
                        <Loader size="medium" color="#666666" fontSize="1.6875rem" />
                    ) : (
                        <FormattedMessage id="users:users-table:modal-contents:change-status:primary-button" />
                    )}
                </StyledConfirmButton>
                <StyledCancelButton onClick={closeModal} fullWidth>
                    <FormattedMessage id="users:users-table:modal-contents:secondary-button" />
                </StyledCancelButton>
            </ButtonsWrapper>
        </ModalContentWrapperDiv>
    );
};

export default ChangeStatusModalContent;
