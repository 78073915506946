import { useModal } from "@/context/modal/modal";

import UserBadge from "@/modules/user-badge";

import { LogoutIcon32 } from "@/components/icons";
import LogoutConfirmationModal from "@/components/ui/modal-with-context/modal-contents/logout-confirmation/logout-confirmation-modal-content";

import { Container, Wrapper, LogoutWrapper } from "./styles";

const TopBar = () => {
    const { openModal, setWidth, setMinHeight } = useModal();

    const confirmLogout = () => {
        setWidth("540px");
        setMinHeight("352px");
        openModal({
            content: (
                <LogoutConfirmationModal
                    title="logout:modal-contents:title"
                    description="logout:modal-contents:description"
                />
            ),
        });
    };

    return (
        <Wrapper>
            <Container>
                <LogoutWrapper onClick={confirmLogout} data-test-id="log-out">
                    <LogoutIcon32 />
                </LogoutWrapper>
                <UserBadge />
            </Container>
        </Wrapper>
    );
};

export default TopBar;
