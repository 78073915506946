import styled from "styled-components";

import base from "@/themes/base/base";

export const TableContainer = styled.div`
    max-height: 16.5rem;
    overflow-x: scroll;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
    }

    //draggable indicator
    &::-webkit-scrollbar-thumb {
        background-color: ${base.colors.neutral.grey[600]};
        border-radius: 0.25rem;
    }

    //scrollbar's track
    &::-webkit-scrollbar-track {
        background-color: ${base.colors.neutral.grey[800]};
    }

    //scrollbar's corner
    &::-webkit-scrollbar-corner {
        background-color: ${base.colors.neutral.grey[800]};
    }
`;

export const StyledTable = styled.table`
    width: 100%;
    color: white;
    border-collapse: collapse; //remove borders from table header
    white-space: nowrap;
`;

export const StyledHead = styled.thead`
    width: 100%;
    table-layout: fixed;
`;

export const StyledHeaderRow = styled.tr``;

export const StyledHeader = styled.th`
    ${base.typography.smallMedium};

    background-color: ${base.colors.neutral.grey[500]};
    padding: 0.5rem 2rem;
    width: 12.5%;
`;

export const ScrollableTableBody = styled.tbody`
    max-height: 16rem;
    overflow-y: auto;
    width: 100%;
`;

export const StyledRow = styled.tr`
    width: 100%;
    background-color: ${base.colors.neutral.grey[700]};

    &:nth-child(even) {
        background-color: ${base.colors.neutral.grey[900]};
    }
`;

export const StyledCell = styled.td`
    ${base.typography.smallRegular};

    &:last-of-type {
        text-align: left;
    }

    text-align: center;
    width: 6.2%;
    padding: 0.75rem 1.5rem;
`;
