import type { IconProps } from "@/types/icon";

const CardArrowIcon48 = ({
    fill = "#999999",
    width = 48,
    height = 48,
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 48 49"
        fill={fill}
        {...remainingProps}
    >
        <path
            d="M17.25 34.6879L19.4235 36.8334L31.5 24.8334L19.4113 12.8334L17.25 14.9788L27.1774 24.8334L17.25 34.6879Z"
            fill={fill}
        />
    </svg>
);

export default CardArrowIcon48;
