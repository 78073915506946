export const ADD_LOADER_QUEUE = "ADD_LOADER_QUEUE";
export const SET_LOADER_RESOLVED = "SET_LOADER_RESOLVED";
export const SET_USER_SELECTED = "SET_USER_SELECTED";

type Payload = {
    type: string;
    payload: any;
};

export const reducer = (state: any, { type, payload }: Payload) => {
    switch (type) {
        case SET_USER_SELECTED:
            return {
                ...state,
                userSelected: payload,
            };
        case ADD_LOADER_QUEUE:
            return {
                ...state,
                loadingQueue: {
                    ...state.loadingQueue,
                    [payload.name]: false,
                },
            };
        case SET_LOADER_RESOLVED:
            return {
                ...state,
                loadingQueue: {
                    ...state.loadingQueue,
                    [payload.name]: true,
                },
            };
        default: {
            throw new Error(`Unhandled action type: ${type}`);
        }
    }
};
