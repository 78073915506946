import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import moment from "moment";

import { checkIfScrollable } from "@/utils/check-if-scrollable";

import { RadioControllerInput } from "../../components/radio-input-controller/radio-with-input";
import Select from "../../components/select/select";
import SurveySlider from "../../components/slider";
import TextInput from "../../components/text-input/text-input";
import type { MVPFormInputs } from "../../mvp-survey/mvp-survey.types";
import {
    ErrorBanner,
    FieldWrapper,
    FormWrapper,
    SurveyTitle,
    TwoColumnsWrapper,
    ClientSpecificButtonsWrapper,
    StyledSecondaryButton,
    StyledPrimaryButton,
    SingleColumnsWrapper,
    GlobalFormWrapper,
    StyledLoader,
    TitleAndSurveyWrapper,
} from "../../survey.styles";
import type { FormInputs, Question, QuestionOption } from "../../survey.types";
import {
    handleSurveySubmit,
    radioWithInputLastQuestionCode,
    radioWithInputLastQuestionPlaceholder,
} from "../../utils";

import type { MLRSSurveyProps } from "./mlrs-survey.types";

const MLRSSurvey = ({
    handleOnContinue,
    clientSurveyData,
    setIsMVP,
    setIsClientSpecific,
    mvpSurveyValues,
}: MLRSSurveyProps) => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(false);
    const [hasScrollableArea, setHasScrollableArea] = useState(false);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isValid },
        control,
        setValue,
        clearErrors,
    } = useForm<FormInputs>({
        shouldUseNativeValidation: false,
    });

    const questions: Question[] = clientSurveyData.sections?.[0].questions || [];

    const errorsLength = Object.keys(errors).length;
    const formHasErrors = errorsLength > 0;

    const mappedMvpSurveyValues: MVPFormInputs = mvpSurveyValues.dateOfBirth
        ? {
              ...mvpSurveyValues,
              dateOfBirth: moment(mvpSurveyValues.dateOfBirth, "DD/MM/YYYY"),
          }
        : { ...mvpSurveyValues };

    const onSubmit = async () => {
        await handleSurveySubmit({
            getValues,
            mappedMvpSurveyValues,
            clientSurveyData,
            questions,
            setIsLoading,
            handleOnContinue,
            isValid,
        });
    };

    const onClickGoBack = () => {
        setIsMVP(true);
        setIsClientSpecific(false);
    };

    /* This useEffect checks if content to render is bigger or not than the parent container
    setting hasScrollableArea variable as true or false */
    useEffect(() => {
        const globalFormWrapper = document.getElementById("GlobalFormWrapper");
        const containerMaxHeightWithNoErrors = 39.6875 * 16; //convert to px, because of checkScrollable function
        const containerMaxHeightWithErrors = 35.5 * 16; //convert to px, because of checkScrollable function

        if (globalFormWrapper) {
            const maxHeight = formHasErrors
                ? containerMaxHeightWithErrors
                : containerMaxHeightWithNoErrors;
            setHasScrollableArea(checkIfScrollable(globalFormWrapper, maxHeight));
        }
    }, [formHasErrors]);

    // Pre-fill the MVP survey, in case of clicking GoBack button
    useEffect(() => {
        if (mvpSurveyValues) {
            (Object.keys(mvpSurveyValues) as Array<keyof MVPFormInputs>).forEach((key) => {
                const value = mvpSurveyValues[key];

                if (value !== undefined) {
                    setValue(key, value as any);
                }
            });
        }
    }, [mvpSurveyValues, setValue]);

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            {Object.keys(errors).length > 0 && (
                <ErrorBanner>
                    <FormattedMessage id={"survey:error-banner"} />
                </ErrorBanner>
            )}

            <TitleAndSurveyWrapper>
                <SurveyTitle>
                    <FormattedMessage id={"mlrs-survey:title"} />
                </SurveyTitle>

                <GlobalFormWrapper
                    id="GlobalFormWrapper"
                    hasScrollableArea={hasScrollableArea}
                    formHasErrors={formHasErrors}
                    maxHeightWithNoErrors={"39.6875rem"}
                    maxHeightWithErrors={"35.5rem"}
                >
                    <TwoColumnsWrapper marginBottom={"1.5rem"}>
                        <FieldWrapper>
                            {/* index 00 TEXT INPUT */}
                            <TextInput
                                registeredField={questions[0].code}
                                label={questions[0].title}
                                labelMarginBottom={"0.625rem"}
                                labelHeight={"3.375rem"}
                                placeholder={questions[0].placeHolder}
                                formattedRequiredMessage="survey:required"
                                emptyFieldError={errors?.[questions[0].code]}
                                register={register}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            {/* index 01 SELECT */}
                            <Select
                                registeredField={questions[1].code}
                                label={questions[1].title}
                                labelMarginBottom={"0.625rem"}
                                labelHeight={"3.375rem"}
                                selectPlaceholder={questions[1].placeHolder}
                                options={questions[1].options as QuestionOption[]}
                                formattedRequiredMessage="survey:required"
                                fieldError={errors?.[questions[1].code]}
                                register={register}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            {/* index 02 RADIO with input */}
                            <RadioControllerInput<FormInputs>
                                registeredField={questions[2].code}
                                register={register}
                                control={control}
                                errors={errors}
                                label={questions[2].title}
                                labelMarginBottom={"0.625rem"}
                                labelHeight={"3.375rem"}
                                options={questions[2].options as QuestionOption[]}
                                emptyFieldError={errors?.[questions[2].code]} //the global question CODE -> if no radio is selected
                                requiredRule={intl.formatMessage({ id: "survey:required" })}
                                // values required for input verification
                                NAValue={radioWithInputLastQuestionCode(questions[2])} //value that serves as error, if not filled
                                inputOption={radioWithInputLastQuestionCode(questions[2])}
                                inputPlaceholder={radioWithInputLastQuestionPlaceholder(
                                    questions[2],
                                )}
                                emptyInputError={
                                    errors?.[radioWithInputLastQuestionCode(questions[2])]
                                } //the particular question CODE -> if input is empty
                                clearErrors={clearErrors}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            {/* index 03 RADIO with input */}
                            <RadioControllerInput<FormInputs>
                                registeredField={questions[3].code}
                                register={register}
                                control={control}
                                errors={errors}
                                label={questions[3].title}
                                labelMarginBottom={"0.625rem"}
                                labelHeight={"3.375rem"}
                                options={questions[3].options as QuestionOption[]}
                                emptyFieldError={errors?.[questions[3].code]} //the global question CODE -> if no radio is selected
                                requiredRule={intl.formatMessage({ id: "survey:required" })}
                                // values required for input verification
                                NAValue={radioWithInputLastQuestionCode(questions[3])} //value that serves as error, if not filled
                                inputOption={radioWithInputLastQuestionCode(questions[3])}
                                inputPlaceholder={radioWithInputLastQuestionPlaceholder(
                                    questions[3],
                                )}
                                emptyInputError={
                                    errors?.[radioWithInputLastQuestionCode(questions[3])]
                                } //the particular question CODE -> if input is empty
                                clearErrors={clearErrors}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            {/* index 04 RADIO without input */}
                            <RadioControllerInput<FormInputs>
                                registeredField={questions[4].code}
                                register={register}
                                control={control}
                                errors={errors}
                                label={questions[4].title}
                                labelMarginBottom={"1.5rem"}
                                labelHeight={"3.375rem"}
                                options={questions[4].options as QuestionOption[]}
                                emptyFieldError={errors?.[questions[4].code]} //the global question CODE -> if no radio is selected
                                requiredRule={intl.formatMessage({ id: "survey:required" })}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            {/* index 05 RADIO with input */}
                            <RadioControllerInput<FormInputs>
                                registeredField={questions[5].code}
                                register={register}
                                control={control}
                                errors={errors}
                                label={questions[5].title}
                                labelMarginBottom={"1.5rem"}
                                labelHeight={"3.375rem"}
                                options={questions[5].options as QuestionOption[]}
                                emptyFieldError={errors?.[questions[5].code]} //the global question CODE -> if no radio is selected
                                requiredRule={intl.formatMessage({ id: "survey:required" })}
                                // values required for input verification
                                NAValue={radioWithInputLastQuestionCode(questions[5])} //value that serves as error, if not filled
                                inputOption={radioWithInputLastQuestionCode(questions[5])}
                                inputPlaceholder={radioWithInputLastQuestionPlaceholder(
                                    questions[5],
                                )}
                                emptyInputError={
                                    errors?.[radioWithInputLastQuestionCode(questions[5])]
                                } //the particular question CODE -> if input is empty
                                clearErrors={clearErrors}
                            />
                        </FieldWrapper>
                    </TwoColumnsWrapper>

                    <SingleColumnsWrapper>
                        <FieldWrapper>
                            {/* index 06 RADIO without input */}
                            <RadioControllerInput<FormInputs>
                                registeredField={questions[6].code}
                                register={register}
                                control={control}
                                errors={errors}
                                label={questions[6].title}
                                labelMarginBottom={"1.5rem"}
                                options={questions[6].options as QuestionOption[]}
                                emptyFieldError={errors?.[questions[6].code]} //the global question CODE -> if no radio is selected
                                requiredRule={intl.formatMessage({ id: "survey:required" })}
                            />
                        </FieldWrapper>
                    </SingleColumnsWrapper>

                    <TwoColumnsWrapper marginTop={"1.5rem"} marginBottom={"1.5rem"}>
                        <FieldWrapper>
                            {/* index 07 SLIDER */}
                            <SurveySlider
                                registeredField={questions[7].code}
                                control={control}
                                options={(questions[7].options ?? []).map(
                                    (option: QuestionOption) => ({
                                        value: parseInt(option.value),
                                        label: option.value,
                                    }),
                                )}
                                step={1}
                                min={1}
                                max={10}
                                label={questions[7].title}
                                labelMarginBottom={"1.5rem"}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            {/* index 08 RADIO without input */}
                            <RadioControllerInput<FormInputs>
                                registeredField={questions[8].code}
                                register={register}
                                control={control}
                                errors={errors}
                                label={questions[8].title}
                                labelMarginBottom={"1.5rem"}
                                labelHeight={"3.375rem"}
                                options={questions[8].options as QuestionOption[]}
                                emptyFieldError={errors?.[questions[8].code]} //the global question CODE -> if no radio is selected
                                requiredRule={intl.formatMessage({ id: "survey:required" })}
                            />
                        </FieldWrapper>
                    </TwoColumnsWrapper>

                    <SingleColumnsWrapper>
                        <FieldWrapper>
                            {/* index 09 TEXT INPUT */}
                            <TextInput
                                registeredField={questions[9].code}
                                label={questions[9].title}
                                labelMarginBottom={"1.5rem"}
                                placeholder={questions[9].placeHolder}
                                formattedRequiredMessage="survey:required"
                                emptyFieldError={errors?.[questions[9].code]}
                                register={register}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            {/* 10 RADIO without input */}
                            <RadioControllerInput<FormInputs>
                                registeredField={questions[10].code}
                                register={register}
                                control={control}
                                errors={errors}
                                label={questions[10].title}
                                labelMarginBottom={"1.5rem"}
                                options={questions[10].options as QuestionOption[]}
                                emptyFieldError={errors?.[questions[10].code]} //the global question CODE -> if no radio is selected
                                requiredRule={intl.formatMessage({ id: "survey:required" })}
                            />
                        </FieldWrapper>
                    </SingleColumnsWrapper>
                </GlobalFormWrapper>
            </TitleAndSurveyWrapper>

            <ClientSpecificButtonsWrapper hasScrollableArea={hasScrollableArea}>
                <StyledSecondaryButton onClick={onClickGoBack}>
                    <FormattedMessage id={"survey:go-back"} />
                </StyledSecondaryButton>
                <StyledPrimaryButton disabled={isLoading} type="submit">
                    {isLoading ? (
                        <StyledLoader size="medium" color="#666666" fontSize="1.5rem" />
                    ) : (
                        <FormattedMessage id={"survey:continue"} />
                    )}
                </StyledPrimaryButton>
            </ClientSpecificButtonsWrapper>
        </FormWrapper>
    );
};

export default MLRSSurvey;
