import type { IconProps } from "@/types/icon";

const SortDashesIcon24 = ({
    fill = "#f2f2f2",
    width = 24,
    height = 24,
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        {...remainingProps}
    >
        <path d="M3 18V16H9V18H3ZM3 13V11H15V13H3ZM3 8V6H21V8H3Z" fill={fill} />
    </svg>
);

export default SortDashesIcon24;
