import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";

import { getTableData } from "@/api/sessions";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import { MultiSelectDropdown } from "@/components/filters/multi-select/multi-select";
import type { MultiSelectOption } from "@/components/filters/multi-select/multi-select.types";
import { TargetIcon20 } from "@/components/icons";
import DataTable from "@/components/ui/data-table/data-table";

import { DropdownFilterVariant } from "@/types/filters";
import type { FireMissionsDataSet, TableHeaders } from "@/types/session-details";

import useQueryParams from "@/utils/use-query-params";

import { Title } from "../../session-detail.styles";

import { Container, HeaderContainer } from "./fire-mission.styles";

export const FireMissions = ({ ...remainingProps }) => {
    const intl = useIntl();
    const params: any = useQueryParams();
    const userId = params.get("userId");
    const from: string = params.get("from");
    const to: string = params.get("to");
    const simulationId: string = params.get("simulationInstanceId");
    const scenarioInstanceId: string = params.get("scenarioInstanceId");

    const { isFetching, data, isError } = useQuery(
        ["getTableData", from, to, userId, simulationId, scenarioInstanceId],
        () =>
            getTableData({
                from,
                to,
                userId,
                simulationInstanceId: simulationId,
                scenarioInstanceId,
            }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
        },
    );

    const fireMissionsTableHeaders: TableHeaders[] = data?.[0]?.headers || [];

    const [selectedItems, setSelectedItems] = useState<MultiSelectOption[]>([]);
    const [fireMissionsData, setFireMissionsData] = useState<FireMissionsDataSet[]>([]);
    const [filteredFireMissionsData, setFilteredFireMissionsData] = useState<FireMissionsDataSet[]>(
        [],
    );

    const hasData = fireMissionsData.length > 0 && !isError;
    const isDataEmpty = !isError && !hasData;

    const tableTitle = intl.formatMessage({
        id: "session-details:fire-missions-table:title",
    });

    //initialize all missions as selected by default
    useEffect(() => {
        const initialFireMissionsData: FireMissionsDataSet[] = data?.[0]?.rows || [];
        setFireMissionsData(initialFireMissionsData);
        setFilteredFireMissionsData(initialFireMissionsData);

        const initialSelections = initialFireMissionsData?.map((mission: any) => ({
            label: `Fire Mission ${mission.FireMissionNumber}`,
            id: mission.FireMissionNumber,
        }));

        setSelectedItems(initialSelections);
    }, [data]);

    const handleSelectionChange = (selectedItems: MultiSelectOption[]) => {
        setSelectedItems(selectedItems);

        const filteredData: any = fireMissionsData?.filter((item: any) =>
            selectedItems?.some((listItem) => item.FireMissionNumber === listItem.id),
        );

        setFilteredFireMissionsData(filteredData);
    };

    if (isFetching) {
        return <ContentLoader height="12.625rem" />;
    }

    return (
        <Container {...remainingProps}>
            <HeaderContainer>
                <Title>{tableTitle}</Title>
                {hasData && (
                    <MultiSelectDropdown
                        variant={DropdownFilterVariant.Secondary}
                        options={fireMissionsData?.map((mission: any) => ({
                            label: `Fire Mission ${mission.FireMissionNumber}`,
                            id: mission.FireMissionNumber,
                        }))}
                        initialSelectedOptions={selectedItems}
                        title={tableTitle}
                        showSelectAll
                        popUpWidth="16.125rem"
                        icon={<TargetIcon20 />}
                        handleOnClick={() => {}}
                        onSelectionChange={(selectedItems) => handleSelectionChange(selectedItems)}
                    />
                )}
            </HeaderContainer>

            {hasData && (
                <DataTable
                    tableData={filteredFireMissionsData}
                    tableHeaders={fireMissionsTableHeaders}
                />
            )}

            {isDataEmpty && (
                <EmptyState
                    emptyStateType={EmptyStateType.Charts}
                    title="session-details:fire-missions-table:empty-state:title"
                    minHeight="16.5rem"
                />
            )}

            {isError && (
                <EmptyState
                    emptyStateType={EmptyStateType.Error}
                    title="session-details:fire-missions-table:error-state:title"
                    description="session-details:fire-missions-table:error-state:description"
                    minHeight="16.5rem"
                />
            )}
        </Container>
    );
};
