import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useMsal } from "@azure/msal-react";

import { deleteAccount } from "@/api/settings";

import { useModal } from "@/context/modal/modal";
import { useUser } from "@/context/user";

import Loader from "@/components/ui/loader";

import {
    ButtonsWrapper,
    Description,
    ModalContentWrapperDiv,
    StyledCancelButton,
    StyledConfirmButton,
    Title,
} from "../modal-contents.styles";

interface Props {
    title: string;
    description?: string;
}

const DeleteAccountModal = ({ title }: Props) => {
    const { instance } = useMsal();
    const { closeModal } = useModal();
    const [loading, setLoading] = useState(false);
    const { state: user } = useUser();

    const handleDeleteAccount = async () => {
        setLoading(true);
        await deleteAccount(user.id);
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    const handleOnCancel = () => {
        closeModal();
    };

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id={title} />
            </Title>

            <Description>
                <FormattedMessage
                    id={"account-delete:modal-contents:description"}
                    values={{ lineBreak: <br /> }}
                />
            </Description>

            <ButtonsWrapper>
                <StyledConfirmButton
                    onClick={handleOnCancel}
                    data-test-id="delete-cancel"
                    fullWidth
                >
                    <FormattedMessage id="account-delete:modal-contents:cancel" />
                </StyledConfirmButton>

                <StyledCancelButton onClick={handleDeleteAccount} disabled={loading} fullWidth>
                    {loading ? (
                        <Loader size="medium" color="#666666" fontSize="1.5rem" />
                    ) : (
                        <FormattedMessage id="account-delete:modal-contents:delete" />
                    )}
                </StyledCancelButton>
            </ButtonsWrapper>
        </ModalContentWrapperDiv>
    );
};

export default DeleteAccountModal;
