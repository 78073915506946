import {
    ScrollableTableBody,
    StyledCell,
    StyledHead,
    StyledHeader,
    StyledHeaderRow,
    StyledRow,
    StyledTable,
    TableContainer,
} from "./data-table.styles";

interface DataTableProps {
    tableData: any[] | null;
    tableHeaders: any[] | null;
}

const DataTable = ({ tableData, tableHeaders }: DataTableProps) => {
    return (
        <TableContainer>
            <StyledTable>
                <StyledHead>
                    <StyledHeaderRow>
                        {tableHeaders?.map((header: any) => {
                            return <StyledHeader key={header}>{header}</StyledHeader>;
                        })}
                    </StyledHeaderRow>
                </StyledHead>

                <ScrollableTableBody>
                    {tableData?.map((item: any, ind: number) => {
                        return (
                            <StyledRow key={ind}>
                                {Object.values(item).map((parameter: any, index: number) => (
                                    <StyledCell key={`${parameter}-${index}`}>
                                        {Math.round(parameter)}
                                    </StyledCell>
                                ))}
                            </StyledRow>
                        );
                    })}
                </ScrollableTableBody>
            </StyledTable>
        </TableContainer>
    );
};

export default DataTable;
