import type { IconProps } from "@/types/icon";

const TargetIcon16 = ({
    width = 16,
    height = 17,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 20 20`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <g clipPath="url(#clip0_7056_23580)">
            <path
                d="M10 0.996094V4.99609"
                stroke={fill}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5 9.99609H1"
                stroke={fill}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 9.99609H15"
                stroke={fill}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 14.9961V18.9961"
                stroke={fill}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 9.99609C17 13.8621 13.866 16.9961 10 16.9961C6.13401 16.9961 3 13.8621 3 9.99609C3 6.1301 6.13401 2.99609 10 2.99609C13.866 2.99609 17 6.1301 17 9.99609Z"
                stroke={fill}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 9.99609C13 11.6529 11.6569 12.9961 10 12.9961C8.34315 12.9961 7 11.6529 7 9.99609C7 8.33924 8.34315 6.99609 10 6.99609C11.6569 6.99609 13 8.33924 13 9.99609Z"
                fill={fill}
            />
        </g>
        <defs>
            <clipPath id="clip0_7056_23580">
                <rect width="20" height="20" fill={fill} />
            </clipPath>
        </defs>
    </svg>
);

export default TargetIcon16;
