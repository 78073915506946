import client from "@/client";

export const getUser = async () => await client.get(`v1/user/current`);

export const completeRegistration = async (fields) =>
    await client.put(`v1/user/current/completeregistration`, fields);

export const getWelcomeSurvey = async () => {
    const { data } = await client.get(`/v1/survey/welcome-survey`);

    return data;
};

export const getUserSiteMap = async () => {
    const { data } = await client.post(`/v1/sitemap`);

    return data.sitemap || data.sections;
};
