import { RadioGroup } from "@mui/material";

import styled, { css } from "styled-components";

import { PrimaryButton, SecondaryButton } from "@/pages/shared/shared.styles";

import base from "@/themes/base/base";

import Loader from "@/components/ui/loader";

import type { RadioGroupProps } from "@mui/material";

export const WelcomeTitle = styled.span`
    ${base.typography.extraLarge2Regular};
`;

export const WelcomeDesc = styled.span`
    ${base.typography.baseRegular};
`;

export const SurveyTitle = styled.div`
    ${base.typography.extraLargeMedium};
    border-bottom: 1px solid ${base.colors.primary.orange[500]};
    text-align: center;
    width: 100%;
`;

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const MVPButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    width: 22rem;
`;

interface ClientSpecificButtonsWrapperStylingProps {
    hasScrollableArea?: boolean;
}

export const ClientSpecificButtonsWrapper = styled.div<ClientSpecificButtonsWrapperStylingProps>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: ${({ hasScrollableArea }) => (hasScrollableArea ? "1.5rem" : "3rem")};
`;

export const MVPButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 3rem;
`;

interface LabelStylingProps {
    height?: string;
    marginBottom: string;
}

export const Label = styled.label<LabelStylingProps>`
    ${base.typography.baseMedium};
    margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
    height: ${({ height }) => height && height};
`;

export const Error = styled.span`
    ${base.typography.smallRegular};
    margin-top: 0.25rem;
    color: ${base.colors.semantic.red[500]};
`;

export const BottomSeparator = styled.div`
    height: 1.5rem;
    margin-top: 0.25rem;
`;

export const FormInput = styled.input`
    ${base.typography.smallRegular};
    background: ${base.colors.neutral.grey[900]};
    color: ${base.colors.neutral.grey[50]};
    padding: 0.5rem 0.75rem;
    border-radius: 2px;
    border: 1px solid transparent;
    outline: none;
    &:focus {
        border: 1px solid #ee7127;
    }
    &.error {
        border: 1px solid ${base.colors.semantic.red[500]};
    }

    /* Override the background and text color for autofill */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        //input background-color
        -webkit-box-shadow: 0 0 0px 1000px ${base.colors.neutral.grey[900]} inset !important;
        //text color
        -webkit-text-fill-color: ${base.colors.neutral.grey[50]} !important;
    }
`;

export const TitleAndSurveyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
`;

interface GlobalFormWrapperStylingProps {
    hasScrollableArea?: boolean;
    formHasErrors?: boolean;
    maxHeightWithNoErrors?: string;
    maxHeightWithErrors?: string;
}

export const GlobalFormWrapper = styled.div<GlobalFormWrapperStylingProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-right: ${({ hasScrollableArea }) => hasScrollableArea && "0.625rem"};
    max-height: ${({ formHasErrors, maxHeightWithErrors, maxHeightWithNoErrors }) =>
        formHasErrors ? maxHeightWithErrors : maxHeightWithNoErrors};

    //scrollable area
    overflow-y: auto; //if 'scroll', mvp survey would display scrollbar space

    /* width */
    ::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #262626;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #4d4d4d;
        border-radius: 0.5rem;
    }
`;

interface TwoColumnsWrapperStylingProps {
    marginTop?: string;
    marginBottom?: string;
}

export const TwoColumnsWrapper = styled.div<TwoColumnsWrapperStylingProps>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    width: 100%;
    margin-top: ${({ marginTop }) => marginTop && marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
`;

interface SingleColumnWrapperStylingProps {
    marginTop?: string;
    marginBottom?: string;
}

export const SingleColumnsWrapper = styled.div<SingleColumnWrapperStylingProps>`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: ${({ marginTop }) => marginTop && marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
    width: 100%;
`;

export const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const RadioLabel = styled.label`
    display: flex;
    gap: 0.5rem;
    ${base.typography.baseRegular};
`;

export const ErrorBanner = styled.div`
    width: 100%;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    background-color: ${base.colors.semantic.red[500]};
    color: ${base.colors.neutral.grey[50]};
    ${base.typography.smallRegular};
`;

export const CustomIcon = styled("span")({
    borderRadius: "50%",
    width: 18,
    height: 18,
    boxShadow: `0 0 0 1px ${base.colors.neutral.grey[50]}`,
    backgroundColor: "transparent",

    ".Mui-focusVisible &": {
        outline: "1px auto #F4A06E",
    },

    "input:hover ~ &": {
        boxShadow: `0 0 0 1px ${base.colors.primary.orange[500]}`,
    },

    "input:disabled ~ &": {
        boxShadow: `0 0 0 1px ${base.colors.neutral.grey[700]}`,
        background: "#121212",
    },
});

export const CustomCheckedIcon = styled(CustomIcon)({
    boxShadow: `0 0 0 2px ${base.colors.primary.orange[500]}`,
    backgroundColor: `${base.colors.primary.orange[500]}`,

    "&::before": {
        display: "block",
        width: 18,
        height: 18,
        backgroundImage: "radial-gradient(#000,#000 28%,transparent 40%)",
        content: '""',
    },

    "input:hover ~ &": {
        boxShadow: `0 0 0 2px ${base.colors.primary.orange[300]}`,
        backgroundColor: `${base.colors.primary.orange[300]}`,
    },

    "input:disabled ~ &": {
        boxShadow: `0 0 0 1px ${base.colors.neutral.grey[700]}`,
        background: `${base.colors.neutral.grey[700]}`,
    },
});

interface StyledRadioGroupStylingProps extends RadioGroupProps {
    hasError?: boolean;
}

export const StyledRadioGroup = styled(RadioGroup).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        (prop as string) !== "hasError" && defaultValidatorFn(prop),
})<StyledRadioGroupStylingProps>`
    gap: 1rem;

    .MuiFormControlLabel-root {
        align-self: flex-start;
        margin-left: -0.625rem;

        .MuiButtonBase-root {
            color: ${base.colors.neutral.grey[50]};
            padding: 0 9px 0 0;
            margin-left: 12px;
        }

        &:hover {
            background-color: ${base.colors.neutral.grey[600]};
        }
    }

    .MuiTypography-root {
        ${base.typography.baseRegular};
    }

    .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
    }

    &.error {
        .radio-icon {
            box-shadow: ${base.colors.semantic.red[500]} 0px 0px 0px 1px;
        }
    }

    ${({ hasError }) =>
        hasError &&
        css`
            .radio-icon {
                box-shadow: ${base.colors.semantic.red[500]} 0px 0px 0px 1px;
            }
        `}
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    width: fit-content;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
    width: fit-content;
`;

export const StyledLoader = styled(Loader)`
    width: 5.125rem;
`;
