import client from "@/client";

import { Features } from "@/enums/features";

export const getFeatureToggles = async () => {
    const { data } = await client.get(
        `/v1/settings/feature-toggle?features=${encodeURIComponent(Object.values(Features).join(","))}`,
    );

    return data;
};
