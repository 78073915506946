import styled from "styled-components";

import base from "@/themes/base/base";

import Text from "@/components/ui/text";

export const PageHeaderWrapperDiv = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
`;

export const PageInfoWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const LogoImage = styled.img`
    width: 3.5rem;
    height: 3.5rem;
    align-self: flex-start;
`;

export const TitleDetailsWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const StyledTitleText = styled(Text)`
    ${base.typography.extraLarge2Bold};

    color: ${base.colors.neutral.grey[50]};
`;

export const StyledUserInfoText = styled(Text)`
    ${base.typography.baseRegular};

    color: ${base.colors.neutral.grey[300]};
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
`;
