import { RadioInner, StyledRadio } from "./styled-radio.styles";

type RadioProps = {
    selected: boolean;
    color?: string;
};

export const StyledRadioButton = ({ selected, color = "#F2F2F2" }: RadioProps) => {
    return (
        <StyledRadio color={color}>
            <RadioInner selected={selected} color={color}></RadioInner>
        </StyledRadio>
    );
};
