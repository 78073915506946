import styled from "styled-components";

export const FiltersContainer = styled.div`
    display: flex;
    gap: 0.625rem;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 1.5rem;

    @media (max-width: 1023px) {
        top: 1.25rem;
    }
`;
