import styled from "styled-components";

import base from "@/themes/base/base";

export const Main = styled.div`
    position: relative;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    border-radius: 0.8rem;
    display: flex;
    gap: 1.5rem;
`;

export const TrainingTimeChartContainer = styled.div`
    box-sizing: border-box;
    padding: 2rem 0.5rem;
    width: 100%;
    display: flex;
    place-items: center;
    gap: 1rem;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.legacy.gray[300]};

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const Container = styled.div`
    display: flex;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const Title = styled.span`
    ${base.typography.extraLargeMedium};
    display: flex;
    gap: 0.25rem;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const IconAndSubtitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const SubTitle = styled.span`
    color: ${base.colors.neutral.grey[300]};
    margin-left: 0.25rem;
    ${base.typography.extraSmallRegular};
`;

export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 0.67407;
`;

export const RightColumn = styled.div`
    display: flex;
    flex: 0.32593;
`;

export const StatsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.25rem;
`;

export const StatName = styled.span`
    ${base.typography.baseRegular};
    color: ${base.colors.neutral.grey[300]};
`;

export const StatValue = styled.span`
    ${base.typography.extraLarge2Light};

    color: ${base.colors.primary.orange[500]};
`;

export const HDivider = styled.div`
    background-color: ${base.colors.neutral.grey[900]};
    width: 4rem;
    height: 0.0625rem;
`;

export const Divider = styled.div`
    background-color: ${base.colors.neutral.grey[900]};
    width: 0.0625rem;
    height: 4rem;
    margin: 1.5rem 0;
`;

export const StyledDivider = styled(Divider)`
    margin: 0;
`;
