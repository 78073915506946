import Chart from "react-apexcharts";

import styled, { css } from "styled-components";

import base from "@/themes/base/base";

export const Container = styled.div`
    display: flex;
    border-radius: 8px;
    width: 100%;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem 1.5rem 0;

    svg {
        pointer-events: all;
    }
`;

export const tooltipStyles = css`
    border-radius: 2px;
    padding: 8px 8px;
    background-color: #4d4d4d;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    span {
        color: #eee;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
    }

    span.value-label {
        margin-right: 4px;
    }

    span.no-icon {
        margin-left: 28px;
    }

    span.hidden {
        display: none;
    }

    img {
        vertical-align: middle;
        margin: 0 5px 4px 0;
    }

    img.timeline {
        margin: 0px 4px 2px 2px;
    }
`;

export const CustomAreaChart = styled(Chart)`
    margin: 0 0 5px -10px;

    .apexcharts-tooltip {
        border: none !important;
        box-shadow: none !important;
    }

    .custom-tooltip {
        ${tooltipStyles}
    }
`;

export const ScatterChart = styled(Chart)`
    margin: 5px 0 -20px -10px;

    .apexcharts-gridline:first-child {
        display: none;
    }

    .apexcharts-tooltip {
        border: none !important;
        box-shadow: none !important;
    }

    .custom-tooltip {
        ${tooltipStyles}
    }
`;
