export const data = [
    {
        code: "circuit-top-stats",
        name: "Take-Off",
        description: "Tooltip description",
        order: 0,
        dataSets: [
            {
                title: "AOA",
                subTitle: null,
                order: 0,
                values: [
                    {
                        result: 444,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 666,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 888,
                        order: 2,
                        description: "Max.",
                    },
                ],
            },
            {
                title: "Degrees Nose Up",
                subTitle: "(in HUD)",
                order: 1,
                values: [
                    {
                        result: 74,
                        order: 0,
                        description: null,
                    },
                ],
            },
            {
                title: "Speed",
                subTitle: "(before upwind turn)",
                order: 2,
                values: [
                    {
                        result: 10,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 35,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 60,
                        order: 1,
                        description: "Max.",
                    },
                ],
            },
        ],
    },
    {
        code: "circuit-top-stats",
        name: "Upwind Turn",
        description: "Tooltip description",
        order: 1,
        dataSets: [
            {
                title: "Lookout",
                subTitle: null,
                order: 0,
                values: [
                    {
                        result: 999,
                        order: 0,
                        description: null,
                    },
                ],
            },
            {
                title: "AOA",
                subTitle: null,
                order: 1,
                values: [
                    {
                        result: 222,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 555,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 888,
                        order: 2,
                        description: "Max.",
                    },
                ],
            },
            {
                title: "AOB",
                subTitle: null,
                order: 2,
                values: [
                    {
                        result: 333,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 555,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 999,
                        order: 2,
                        description: "Max.",
                    },
                ],
            },
            {
                title: "Speed",
                subTitle: null,
                order: 2,
                values: [
                    {
                        result: 10,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 35,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 60,
                        order: 1,
                        description: "Max.",
                    },
                ],
            },
        ],
    },
    {
        code: "circuit-top-stats",
        name: "Downwind Leg",
        description: "Tooltip description",
        order: 2, //change according to position in page
        dataSets: [
            {
                title: "Height",
                subTitle: null,
                order: 0,
                values: [
                    {
                        result: 234,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 874,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 952,
                        order: 2,
                        description: "Max.",
                    },
                ],
            },
            {
                title: "Heading",
                subTitle: null,
                order: 1,
                values: [
                    {
                        result: 367,
                        order: 0,
                        description: "Mean",
                    },
                ],
            },
            {
                title: "Speed",
                subTitle: null,
                order: 2,
                values: [
                    {
                        result: 245,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 167,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 659,
                        order: 2,
                        description: "Max.",
                    },
                ],
            },
            {
                title: "AOA",
                subTitle: null,
                order: 3,
                values: [
                    {
                        result: 11,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 87,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 34,
                        order: 1,
                        description: "Max.",
                    },
                ],
            },
        ],
    },
    {
        code: "circuit-top-stats",
        name: "Final Turn",
        description: "Tooltip description",
        order: 3, //change according to position in page
        dataSets: [
            {
                title: "Lookout",
                subTitle: null,
                order: 0,
                values: [
                    {
                        result: 952,
                        order: 2,
                        description: null,
                    },
                ],
            },
            {
                title: "AOB",
                subTitle: null,
                order: 2,
                values: [
                    {
                        result: 245,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 167,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 659,
                        order: 2,
                        description: "Max.",
                    },
                ],
            },
            {
                title: "Nose Down Attitude",
                subTitle: null,
                order: 1,
                values: [
                    {
                        result: 245,
                        order: 0,
                        description: "(in HUD)",
                    },
                ],
            },
            {
                title: "AOA",
                subTitle: null,
                order: 3,
                values: [
                    {
                        result: 11,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 87,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 34,
                        order: 1,
                        description: "Max.",
                    },
                ],
            },
        ],
    },
    {
        code: "circuit-top-stats",
        name: "Final Approach",
        description: "Tooltip description",
        order: 4, //change according to position in page
        dataSets: [
            {
                title: "Nose Down Attitude",
                subTitle: null,
                order: 0,
                values: [
                    {
                        result: 952,
                        order: 2,
                        description: "(in HUD)",
                    },
                ],
            },
            {
                title: "AOB",
                subTitle: null,
                order: 1,
                values: [
                    {
                        result: 245,
                        order: 0,
                        description: "Min.",
                    },
                    {
                        result: 167,
                        order: 1,
                        description: "Avg.",
                    },
                    {
                        result: 659,
                        order: 2,
                        description: "Max.",
                    },
                ],
            },
        ],
    },
    {
        code: "circuit-top-stats",
        name: "Landing",
        description: "Tooltip description",
        order: 5, //change according to position in page
        dataSets: [
            {
                title: "Touchdown Point",
                subTitle: null,
                order: 0,
                values: [
                    {
                        result: 952,
                        order: 2,
                        description: null,
                    },
                ],
            },
            {
                title: "Runway Centreline",
                subTitle: null,
                order: 1,
                values: [
                    {
                        result: 245,
                        order: 0,
                        description: null,
                    },
                ],
            },
        ],
    },
];
