const feetToKilometers = (feet: number) => feet * 0.0003048;

// Helper function to convert latitude, longitude, and altitude to 3D coordinates
export const latLongToXYZ = (
    latitude: number,
    longitude: number,
    altitude: number,
): [number, number, number] => {
    const earthRadius = 6400; // Earth radius in kilometers
    const r = earthRadius + feetToKilometers(altitude); // Add altitude in kilometers
    const latRad = latitude; // Already in radians
    const lonRad = longitude; // Already in radians

    const x = r * Math.cos(latRad) * Math.cos(lonRad);
    const y = r * Math.cos(latRad) * Math.sin(lonRad);
    const z = r * Math.sin(latRad);

    return [x, y, z];
};

export const average = (points: any, val: string) => {
    return (
        (Math.min(...points.map((point: any) => point[val])) +
            Math.max(...points.map((point: any) => point[val]))) /
        2
    );
};
