import { useFeatureToggles } from "@/context/feature-toggles";

import {
    LockIcon,
    SupportIcon32,
    UsersIcon,
    SessionsIcon32,
    ProgressIcon32,
    SettingsIcon32,
    ReportsIcon32,
    PizzaChartIcon32,
} from "@/components/icons";

import { Features } from "@/enums/features";

const useGetNavigationItems = () => {
    const { isFeatureActive } = useFeatureToggles();
    const siteMapStringify = window.localStorage.getItem("siteMap");

    let siteMap = siteMapStringify ? JSON.parse(siteMapStringify) : [];

    const iconMapping: any = {
        "overview-page": { icon: PizzaChartIcon32, enabled: true },
        "sessions-page": { icon: SessionsIcon32, enabled: true },
        "authentication-page": {
            icon: LockIcon,
            enabled: isFeatureActive(Features.AuthenticationPage),
        },
        "trainees-page": { icon: UsersIcon, enabled: true },
        "users-page": { icon: UsersIcon, enabled: true },
        "reports-page": { icon: ReportsIcon32, enabled: true },
        "progress-page": { icon: ProgressIcon32, enabled: true },
        "setting-page": { icon: SettingsIcon32, enabled: true },
        "support-page": { icon: SupportIcon32, enabled: true },
    };

    siteMap.forEach((section: any) => {
        // Filter out navigation items that are not enabled
        section.navigationItems = section.navigationItems.filter((item: any) => {
            const mapping = iconMapping[item.code];

            if (mapping) {
                item.icon = mapping.icon;
                item.enabled = mapping.enabled;

                return item.enabled; // Keep only enabled items
            }

            return false; // Exclude items without a mapping
        });

        // Sort the filtered navigation items within each section by order
        section.navigationItems.sort((a: any, b: any) => a.order - b.order);
    });

    // Filter out sections that are not visible or have no items after filtering
    siteMap = siteMap.filter((section: any) => section.navigationItems.length > 0);

    // Sort the filtered sections by order
    siteMap.sort((a: any, b: any) => a.order - b.order);

    return siteMap;
};

export default useGetNavigationItems;
