import styled from "styled-components";

import base from "../../../../themes/base/base";

export const CircuitStatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex-wrap: wrap;
    width: 100%;
`;

export const Section = {
    Container: styled.div`
        display: flex;
        border-radius: 8px;
        background: ${base.colors.neutral.grey[800]};
        flex-direction: column;
        padding: 1.5rem;
        gap: 1rem;
        flex: 5;
        height: 100%;
        box-sizing: border-box;
    `,

    SubContainer: styled.div`
        display: flex;
        gap: 1rem;
        flex-direction: column;
    `,

    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        width: 100%;
    `,

    RowWrapper: styled.div`
        display: flex;
        justify-content: space-evenly;
    `,

    StatTitle: styled.span`
        ${base.typography.baseRegular};

        color: ${base.colors.neutral.grey[300]};
        text-align: center;
    `,

    StatSubTitle: styled.span`
        ${base.typography.extraSmallRegular};

        color: ${base.colors.neutral.grey[300]};
        text-align: center;
    `,

    StatWrapper: styled.div`
        display: flex;
        gap: 2rem;
        justify-content: center;
    `,

    StatValueWrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    `,

    StatValue: styled.span`
        ${base.typography.extraLarge2Light};

        color: ${base.colors.primary.orange[500]};
        text-align: center;
        font-weight: 300;
    `,

    StatValueDescription: styled.span`
        ${base.typography.extraSmallRegular};

        color: ${base.colors.neutral.grey[300]};
        text-align: center;
    `,
};
