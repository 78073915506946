import { createContext, useCallback, useContext, useReducer } from "react";
import type { ReactNode } from "react";

import { ADD_LOADER_QUEUE, reducer, SET_LOADER_RESOLVED, SET_USER_SELECTED } from "./reducer";

type StateProps = {
    loadingQueue: {
        [prop: string]: boolean;
    };
    userSelected: null | string;
};

export const initialState: StateProps = {
    loadingQueue: {
        USER: false,
        AUTH: false,
        FEATURE_TOGGLES: false,
    },
    userSelected: null,
};

const ApplicationContext = createContext<any>({});

type Props = {
    children: ReactNode;
};

const ApplicationProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ApplicationContext.Provider
            value={{
                state,
                dispatch,
                isLoaderQueueReady: useCallback(
                    () => Object.values(state.loadingQueue).every((v) => v === true),
                    [state.loadingQueue],
                ),
                addLoaderQueue: (name: string) =>
                    dispatch({ type: ADD_LOADER_QUEUE, payload: { name } }),
                setLoaderQueueResolved: (name: string) =>
                    dispatch({ type: SET_LOADER_RESOLVED, payload: { name } }),
                setSelectedUser: (payload: { id: string; name: string }) =>
                    dispatch({ type: SET_USER_SELECTED, payload }),
                getSelectedUser: useCallback(() => state.userSelected, [state.userSelected]),
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

function useApplication() {
    const context = useContext(ApplicationContext);

    if (context === undefined) {
        throw new Error("useApplication must be used within a ApplicationProvider");
    }

    return context;
}

export { ApplicationProvider, useApplication };
