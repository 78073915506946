import type { IconProps } from "@/types/icon";

const CalendarIcon24 = ({
    width = 24,
    height = 24,
    fill = "#999999",
    ...remainingProps
}: IconProps) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} {...remainingProps}>
        <path d="M8.88889 13.925C8.66852 13.925 8.4838 13.8537 8.33472 13.7112C8.18565 13.5686 8.11111 13.392 8.11111 13.1813C8.11111 12.9705 8.18565 12.7939 8.33472 12.6513C8.4838 12.5088 8.66852 12.4375 8.88889 12.4375C9.10926 12.4375 9.29398 12.5088 9.44306 12.6513C9.59213 12.7939 9.66667 12.9705 9.66667 13.1813C9.66667 13.392 9.59213 13.5686 9.44306 13.7112C9.29398 13.8537 9.10926 13.925 8.88889 13.925ZM12 13.925C11.7796 13.925 11.5949 13.8537 11.4458 13.7112C11.2968 13.5686 11.2222 13.392 11.2222 13.1813C11.2222 12.9705 11.2968 12.7939 11.4458 12.6513C11.5949 12.5088 11.7796 12.4375 12 12.4375C12.2204 12.4375 12.4051 12.5088 12.5542 12.6513C12.7032 12.7939 12.7778 12.9705 12.7778 13.1813C12.7778 13.392 12.7032 13.5686 12.5542 13.7112C12.4051 13.8537 12.2204 13.925 12 13.925ZM15.1111 13.925C14.8907 13.925 14.706 13.8537 14.5569 13.7112C14.4079 13.5686 14.3333 13.392 14.3333 13.1813C14.3333 12.9705 14.4079 12.7939 14.5569 12.6513C14.706 12.5088 14.8907 12.4375 15.1111 12.4375C15.3315 12.4375 15.5162 12.5088 15.6653 12.6513C15.8144 12.7939 15.8889 12.9705 15.8889 13.1813C15.8889 13.392 15.8144 13.5686 15.6653 13.7112C15.5162 13.8537 15.3315 13.925 15.1111 13.925ZM6.55556 19.875C6.12778 19.875 5.76157 19.7294 5.45694 19.438C5.15231 19.1467 5 18.7966 5 18.3875V7.975C5 7.56594 5.15231 7.21576 5.45694 6.92445C5.76157 6.63315 6.12778 6.4875 6.55556 6.4875H7.33333V5H8.88889V6.4875H15.1111V5H16.6667V6.4875H17.4444C17.8722 6.4875 18.2384 6.63315 18.5431 6.92445C18.8477 7.21576 19 7.56594 19 7.975V18.3875C19 18.7966 18.8477 19.1467 18.5431 19.438C18.2384 19.7294 17.8722 19.875 17.4444 19.875H6.55556ZM6.55556 18.3875H17.4444V10.95H6.55556V18.3875ZM6.55556 9.4625H17.4444V7.975H6.55556V9.4625Z" />
    </svg>
);

export default CalendarIcon24;
