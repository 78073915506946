import type { IconProps } from "@/types/icon";

const PizzaChartIcon32 = ({
    width = 32,
    height = 32,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        {...remainingProps}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7 29C8.109 28.35 3 22.773 3 16C3 9.227 8.109 3.65 14.7 3V29ZM17.339 3V14.687H29C28.389 8.525 23.488 3.611 17.339 3ZM17.339 29V17.313H29C28.389 23.475 23.501 28.389 17.339 29Z"
            fill={fill}
        />
    </svg>
);

export default PizzaChartIcon32;
