import type {
    InfiniteData,
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "react-query";

export interface UsersTableProps {
    lastUserInRef: (instance: HTMLTableRowElement | null) => void;
    usersData: any;
    refetchUsersData: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<InfiniteData<any>, unknown>>;
    isFetchingUsersData: boolean;
    isErrorInUsersData: boolean;
}

export enum ActionMenuOption {
    ChangeRole = "Change Role",
    DeactivateUser = "Deactivate User",
    ReactivateUser = "Reactivate User",
    ResendInvite = "Resend Invite",
}

export enum UserStatus {
    Active = "Active",
    Pending = "Pending",
    Deactivated = "Deactivated",
}

export enum UserStatusAction {
    Activate = "Activate",
    Deactivate = "Deactivate",
    Reactivate = "Reactivate",
}

export enum UserStatusActionNoun {
    Activation = "activation",
    Deactivation = "deactivation",
}

export enum UserStatusPerformedAction {
    Activated = "activated",
    Deactivated = "deactivated",
}
