import client from "@/client";

import { parseDashboardFilters } from "@/utils/api";

export const getReports = async (filters) => {
    if (filters.userId == null) {
        const { data } = await client.get(`/v1/Report${parseDashboardFilters(filters)}`);

        return data;
    } else {
        const { data } = await client.get(
            `/v1/Report?userId=${filters.userId}&${parseDashboardFilters(filters)}`,
        );

        return data;
    }
};
