import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.main};
`;

export const LoaderContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.legacy.gray[900]};
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
`;
