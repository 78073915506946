import { Slider } from "@mui/material";

import styled from "styled-components";

import base from "@/themes/base/base";

export const SurveySliderWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomSlider = styled(Slider)`
    &.MuiSlider-root {
        color: ${base.colors.primary.orange[500]};
        margin-left: 10px;
        width: 95%; //if 100%, a scrollbar appears in the slider */
    }

    .MuiSlider-thumb {
        &:hover {
            box-shadow: none;
        }
        color: ${base.colors.primary.orange[500]};
    }

    .MuiSlider-mark {
        display: none;
    }

    .MuiSlider-rail {
        color: ${base.colors.neutral.grey[500]};
    }

    .MuiSlider-markLabel {
        ${base.typography.extraSmallRegular};
        color: ${base.colors.neutral.grey[300]};
    }
`;
