import client from "@/client";

import { parseDashboardFilters } from "@/utils/api";

export const getCustomCharts = async ({
    simulationInstanceId,
    scenarioInstanceId,
    from,
    to,
    userId,
}: {
    simulationInstanceId?: string;
    scenarioInstanceId?: string;
    from: string;
    to: string;
    userId: string;
}) => {
    const { data } = await client.get(
        `/v1/session/${simulationInstanceId}/custom-charts?scenarioInstanceId=${scenarioInstanceId}&from=${from}&to=${to}&userId=${userId}`,
    );

    return data;
};

export const getCombinedCharts = async ({
    simulationInstanceId,
    scenarioInstanceId,
    from,
    to,
    userId,
}: {
    simulationInstanceId?: string;
    scenarioInstanceId?: string;
    from: string;
    to: string;
    userId: string;
}) => {
    const { data } = await client.get(
        `/v1/session/${simulationInstanceId}/combined-custom-charts?scenarioInstanceId=${scenarioInstanceId}&from=${from}&to=${to}&userId=${userId}`,
    );

    return data;
};

export const getCombinedCustomTopStats = async ({
    simulationInstanceId,
    scenarioInstanceId,
    from,
    to,
    userId,
}: {
    simulationInstanceId?: string;
    scenarioInstanceId?: string;
    from: string;
    to: string;
    userId: string;
}) => {
    const { data } = await client.get(
        `/v1/session/${simulationInstanceId}/combined-custom-top-stats?scenarioInstanceId=${scenarioInstanceId}&from=${from}&to=${to}&userId=${userId}`,
    );

    return data;
};

export const getTableData = async ({
    simulationInstanceId,
    scenarioInstanceId,
    from,
    to,
    userId,
}: {
    simulationInstanceId?: string;
    scenarioInstanceId?: string;
    from: string;
    to: string;
    userId: string;
}) => {
    const { data } = await client.get(
        `/v1/session/${simulationInstanceId}/data-tables?scenarioInstanceId=${scenarioInstanceId}&userId=${userId}&from=${from}&to=${to}`,
    );

    return data;
};

export const triggerAnalytics = async ({
    simulationInstanceId,
}: {
    simulationInstanceId?: string;
}) => {
    const { data } = await client.post(`v1/analytics/${simulationInstanceId}`);

    return data;
};

export const getMapData = async (
    sessionId: string,
    scenarioInstanceId: string,
    start: number,
    end: number,
) => {
    const { data } = await client.get(
        `/v1/Map?sessionId=${sessionId}&scenarioInstanceId=${scenarioInstanceId}&start=${start}&end=${end}`,
    );

    return data;
};

export const getSessions = async (filters: any) => {
    const { data } = await client.get(`/v1/Session/sessions${parseDashboardFilters(filters)}`);

    return data;
};
