import type { IconProps } from "@/types/icon";

const ProgressIcon32 = ({
    width = 32,
    height = 32,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <g id="Nav Progress Icon">
            <path
                id="Progress Icon"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.88889 3.25098H26.1111C27.7 3.25098 29 4.55098 29 6.13987V26.3621C29 27.951 27.7 29.251 26.1111 29.251H5.88889C4.3 29.251 3 27.951 3 26.3621V6.13987C3 4.55098 4.3 3.25098 5.88889 3.25098ZM8.77778 23.4732H11.6667V13.3621H8.77778V23.4732ZM17.4444 23.4732H14.5556V9.02875H17.4444V23.4732ZM20.3333 23.4732H23.2222V17.6954H20.3333V23.4732Z"
                fill={fill}
            />
        </g>
    </svg>
);

export default ProgressIcon32;
