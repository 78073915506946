import { useEffect, useState } from "react";

import { cloneDeep } from "lodash";

import { type ChartData, type ChartState } from "@/types/timeline-chart";

import { areaOptions, scatterOptions } from "./chart-config";
import { CustomAreaChart, ScatterChart } from "./styles";
import { convertDataToApex, getMaxAxis, getMaxValue, getMinAxis } from "./utils";

const BUFFER_VALUE = 0.1;

type CombinedChartProps = {
    chartStateByType: {
        [k: string]: boolean | undefined;
    };
    areaChartData: ChartData[];
    scatterChartData: ChartData[];
};

const getAreaSeriesData = (chartData: ChartData[]) => {
    const finalData = chartData.map((chart: ChartData, index: number) => {
        return {
            name: chart.name,
            data: convertDataToApex(chart.data) || [],
            yAxis: index,
            color: chart.color,
            unit: chart.unit,
        };
    });

    return finalData;
};

const getAreaOptionsData = (chartData: ChartData[]) => {
    const optionsCopy = cloneDeep(areaOptions);
    //to set yaxis for area chart
    const yAxisData = chartData?.map((chart: ChartData) => {
        return {
            title: {
                name: chart.name,
            },
            min: 0,
            max: getMaxValue(chart.data) + BUFFER_VALUE,
            show: false,
        };
    });
    optionsCopy.yaxis = cloneDeep(yAxisData);

    return optionsCopy;
};

//set scatter chart axis to area chart data
const getScatterOptionsData = (chartData: ChartData[]) => {
    const finalOptions = cloneDeep(scatterOptions);
    const chartAxisData = chartData.flatMap((chart: ChartData) => chart.data);

    finalOptions.xaxis.min = getMinAxis(chartAxisData);
    finalOptions.xaxis.max = getMaxAxis(chartAxisData);

    return finalOptions;
};

//create series data from API
const getScatterSeriesData = (scatterData: ChartData[], chartData: ChartData[]) => {
    const sortedData = scatterData.sort((a, b) => b.position - a.position);
    const chartAxisData = chartData.flatMap((chart: ChartData) => chart.data);
    const minAxis = getMinAxis(chartAxisData);

    const finalData = sortedData?.map((chart: ChartData) => {
        return {
            data: convertDataToApex(chart?.data) || [],
            color: chart?.color,
            name: chart.name,
            radioLabel: chart.radioLabel,
            startTime: new Date(minAxis),
        };
    });

    return finalData;
};

//filter series data on radio toggle
const filterSeriesData = (data: any, chartStateByType: ChartState) => {
    let filteredData = cloneDeep(data);
    filteredData = filteredData.map((series: any) => {
        if (chartStateByType[series.name] === false) {
            series.data = [];
        }

        return series;
    });

    return filteredData;
};

const CombinedChartFiring = ({
    chartStateByType,
    areaChartData,
    scatterChartData,
}: CombinedChartProps) => {
    const firstScatterChart = scatterChartData?.filter(
        (chart: ChartData) => chart?.type === "scatter1",
    );
    const secondScatterChart = scatterChartData.filter(
        (chart: ChartData) => chart?.type === "scatter",
    );
    const areaSeriesData = getAreaSeriesData(areaChartData);
    const areaOptions = getAreaOptionsData(areaChartData);

    const firstScatterOptionsData = getScatterOptionsData(areaChartData);
    const firstScatterSeriesData = getScatterSeriesData(firstScatterChart, areaChartData);

    const secondScatterOptionsData = getScatterOptionsData(areaChartData);
    const secondScatterSeriesData = getScatterSeriesData(secondScatterChart, areaChartData);

    const [seriesAreaData, setSeriesData] = useState(areaSeriesData);
    const [secondScatterSeries, setSecondScatterSeries] = useState(secondScatterSeriesData);
    const [firstScatterSeries, setFirstScatterSeries] = useState(firstScatterSeriesData);

    useEffect(() => {
        //to disable area chart which is toggled off
        setSeriesData(filterSeriesData(areaSeriesData, chartStateByType));
        setSecondScatterSeries(filterSeriesData(secondScatterSeriesData, chartStateByType));
        setFirstScatterSeries(filterSeriesData(firstScatterSeriesData, chartStateByType));
    }, [chartStateByType]);

    return (
        <>
            <ScatterChart
                options={firstScatterOptionsData}
                series={firstScatterSeries}
                type="scatter"
                height={40}
            />

            <ScatterChart
                options={secondScatterOptionsData}
                series={secondScatterSeries}
                type="scatter"
                height={40}
            />

            <CustomAreaChart
                options={areaOptions}
                series={seriesAreaData}
                type="area"
                height={230}
            />
        </>
    );
};

export default CombinedChartFiring;
