import { useEffect } from "react";
import { useMap } from "react-leaflet";

const MapObserver = () => {
    const map = useMap();

    useEffect(() => {
        const invalidateMapSize = () => map.invalidateSize();
        const resizeObserver = new ResizeObserver(invalidateMapSize);
        const mapContainer = map.getContainer();
        resizeObserver.observe(mapContainer);

        return () => {
            resizeObserver.unobserve(mapContainer);
        };
    }, [map]);

    return null;
};

export default MapObserver;
