import type { IconProps } from "@/types/icon";

const EditIcon18 = ({
    width = 18,
    height = 18,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 46 46`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path
            d="M34.5038 1.13638C35.219 0.421174 36.3785 0.421174 37.0937 1.13638L44.8637 8.90633C45.5789 9.62154 45.5789 10.7811 44.8637 11.4963L42.2737 14.0863C41.5585 14.8015 40.3989 14.8015 39.6837 14.0863L31.9138 6.31635C31.1986 5.60114 31.1986 4.44157 31.9138 3.72636L34.5038 1.13638Z"
            fill={fill}
        />
        <path
            d="M26.7338 8.90633C27.449 8.19113 28.6086 8.19113 29.3238 8.90633L37.0937 16.6763C37.8089 17.3915 37.8089 18.5511 37.0937 19.2663L18.9639 37.3962C18.2486 38.1114 17.0891 38.1114 16.3739 37.3962L8.60391 29.6262C7.88871 28.911 7.88871 27.7514 8.60391 27.0362L26.7338 8.90633Z"
            fill={fill}
        />
        <path
            d="M6.81428 33.0165C5.85161 32.0539 4.21239 32.4408 3.78187 33.7324L0.697246 42.9863C0.220009 44.418 1.58209 45.7801 3.0138 45.3028L12.2677 42.2182C13.5592 41.7877 13.9462 40.1485 12.9835 39.1858L6.81428 33.0165Z"
            fill={fill}
        />
    </svg>
);

export default EditIcon18;
