import { useEffect, useState } from "react";

import { getCombinedCharts, getCustomCharts } from "@/api/sessions";

import useQueryParams from "@/utils/use-query-params";

import type { Score } from ".";

export const usePolling = (poll: number) => {
    const params: any = useQueryParams();
    const userId = params.get("userId");
    const from: string = params.get("from");
    const to: string = params.get("to");
    const simulationInstanceId: string = params.get("simulationInstanceId");
    const scenarioInstanceId: string = params.get("scenarioInstanceId");

    const [landingScore, setLandingScore] = useState<Score>();
    const [breakdownData, setBreakdownData] = useState<Score[]>();

    useEffect(() => {
        async function getData() {
            const data = await getCustomCharts({
                from,
                to,
                userId,
                simulationInstanceId,
                scenarioInstanceId,
            });
            const combinedData = await getCombinedCharts({
                from,
                to,
                userId,
                simulationInstanceId,
                scenarioInstanceId,
            });
            setLandingScore(
                data?.filter((item: any) => item.chartType === "ProgressBar")[0]?.datasets?.[0],
            );
            setBreakdownData(
                combinedData?.find((chart: any) => chart.chartType === "ProgressBar")?.datasets,
            );
        }

        getData();
    }, [poll]);

    return {
        landingScore,
        breakdownData,
        simulationInstanceId,
    };
};
