import { useCallback, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { getCustomerSettings } from "@/api/settings";

import { TertiaryButton } from "@/pages/shared/shared.styles";

import useGetNavigationItems from "@/modules/navigation/get-navigation-items";

import { ArrowLeftIcon, ChevronLeftSquareCornersIcon24 } from "@/components/icons";
import Loader from "@/components/ui/loader";
import PageHeader from "@/components/ui/page-header/page-header";
import Styled from "@/components/ui/styled";
import Text from "@/components/ui/text";

import useMediaQuery from "@/utils/hooks/use-media-query";

import {
    Back,
    BreadcrumbContainer,
    Content,
    Description,
    Header,
    LeftCol,
    Main,
    PageLoaderContainer,
    RightCol,
    HeaderTitle,
    PageTitle,
    BackButton,
    TitleText,
    Counter,
} from "./index.styles";

type Props = {
    title: string;
    pageTitle?: string | boolean;
    description?: string;
    rightContent?: ReactNode;
    code?: string;
    children: ReactNode;
    footer?: ReactNode;
    hasBackLink?: boolean;
    backLabel?: string;
    showDetails?: boolean;
    titleDetailText?: string;
    subtitleDetailText?: ReactNode;
    onBackClick?: () => void | undefined;
    showBackButton?: boolean;
    logoUrl?: string;
    counter?: number;
};

const Page = ({
    title,
    pageTitle,
    description,
    logoUrl,
    code,
    rightContent = null,
    footer,
    children,
    hasBackLink = false,
    backLabel,
    showDetails,
    subtitleDetailText = "",
    onBackClick,
    showBackButton = false,
    counter,
}: Props) => {
    const navigate = useNavigate();

    const isMobile = useMediaQuery("(max-width: 767px)");
    const navItems = useGetNavigationItems();

    let currentPage: any;
    navItems.forEach((section: any) => {
        const foundItem = section.navigationItems.find((item: any) => item.code === code);

        if (foundItem) {
            currentPage = foundItem;

            return;
        }
    });

    const options = {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: true,
    };

    const { isLoading } = useQuery([getCustomerSettings], getCustomerSettings, options);

    const handleBackButton = useCallback(() => {
        if (window.history.length > 1) {
            navigate(-1); //goes back one step
        } else {
            navigate("/sessions");
        }
    }, [navigate]);

    return (
        <Main>
            <Header>
                {hasBackLink && (
                    <Back onClick={onBackClick}>
                        <Styled marginRight="1rem">
                            <ArrowLeftIcon />
                        </Styled>
                        {backLabel}
                    </Back>
                )}

                {showBackButton && (
                    <BreadcrumbContainer>
                        <BackButton data-test-id="back">
                            <TertiaryButton onClick={handleBackButton}>
                                <ChevronLeftSquareCornersIcon24 />
                                <FormattedMessage id="page:breadcrumb-container:back-button" />
                            </TertiaryButton>
                        </BackButton>
                    </BreadcrumbContainer>
                )}

                {pageTitle && <PageTitle>{pageTitle}</PageTitle>}

                <HeaderTitle>
                    {!showDetails && (
                        <TitleText>
                            {currentPage ? currentPage.label : title}
                            {!!counter && <Counter>({counter})</Counter>}
                        </TitleText>
                    )}

                    {showDetails && (
                        <PageHeader
                            isLoading={isLoading}
                            logoUrl={logoUrl}
                            currentPage={currentPage}
                            title={title}
                            subtitleDetailText={subtitleDetailText}
                        />
                    )}

                    <Description>
                        {currentPage && (
                            <LeftCol>
                                {(currentPage.description || description) && !isMobile && (
                                    <Text variant="medium">
                                        {currentPage ? currentPage.description : description}
                                    </Text>
                                )}
                            </LeftCol>
                        )}
                        {rightContent && <RightCol>{rightContent}</RightCol>}
                    </Description>
                </HeaderTitle>
            </Header>

            <Content>{children}</Content>
            {footer}
        </Main>
    );
};

export const PageLoader = () => (
    <PageLoaderContainer>
        <Loader />
    </PageLoaderContainer>
);

export default Page;
