import styled from "styled-components";

import base from "@/themes/base/base";
import { desktopLargeMedia } from "@/themes/breakpoints";

import { HeatLogoForNavigationIcon } from "@/components/icons";

export const Wrapper = styled.div`
    position: fixed;
    height: 100%;
    width: var(--navigation-width);
    top: 0;
    left: 0;
    z-index: 999;
    border: 1px solid ${base.colors.neutral.grey[600]};
    background: ${base.colors.neutral.grey[800]};

    @media (max-width: 767px) {
        height: auto;
        padding-bottom: 0;
    }
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;
    display: flex;
    background-image: url("/assets/navigation-bg.png");
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;

    @media (min-width: 768px) {
        padding: 0 1rem 1rem;
    }

    ${desktopLargeMedia} {
        padding: 0 1.5rem 1.5rem;
    }
`;

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid ${base.colors.neutral.grey[600]};
    height: var(--heat-header-height);
    padding: 1rem 0;

    ${desktopLargeMedia} {
        padding: 1.5rem 0;
    }
`;

export const StyledHeatLogoForNavigation = styled(HeatLogoForNavigationIcon)`
    width: 174px;
    height: 46.95px;

    ${desktopLargeMedia} {
        width: 206px;
        height: 56px;
    }
`;

export const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;

export const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    &::-webkit-scrollbar {
        height: 0.2rem;
        display: none;
    }

    &:hover::-webkit-scrollbar {
        display: block;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #1a1a1a;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #666;
        border-radius: 0.35rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media screen and (max-width: 767px) {
        overflow: auto;
        padding: 0 0.5rem;
    }
`;

export const MenuItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
`;

export const MenuItemTitle = styled.h2`
    ${base.typography.baseMedium};
    text-transform: uppercase;
    color: ${base.colors.neutral.grey[300]};
`;

export const MenuItemContent = styled.ul`
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
        flex-direction: row;
        justify-content: center;
        border-top: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.grey[300]};
        padding-top: 1rem;
    }
    @media screen and (max-width: 767px) {
        justify-content: flex-start;
        border-bottom: none;
    }
`;

export const ContentItem = styled.a`
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0.5rem 0;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    gap: 0.25rem;
    text-decoration: none;
    color: ${base.colors.neutral.grey[50]};
    user-select: none;

    &:hover {
        background-color: ${base.colors.neutral.grey[600]};
    }

    @media screen and (max-width: 767px) {
        display: inline-block;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }
`;

export const Link = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    padding: 0.65rem 1.6rem;

    & svg path {
        fill: #f2f2f2;
        transition: all 0.1s ease-in-out;
    }

    &.active {
        > span {
            font-weight: 500;
        }

        & svg path {
            fill: #ee7127 !important;
        }
    }

    @media (min-width: 768px) {
        padding: 0;
    }
`;

export const Indicator = styled.div<{ isActive: boolean }>`
    width: 4px;
    height: 1.5rem;
    background-color: ${({ isActive }) => (isActive ? "#EE7127 !important" : "transparent")};
    border-radius: 0px 4px 4px 0px;
    transition: all 0.1s ease-in-out;
`;

export const StyledLinkText = styled.span`
    ${base.typography.baseRegular};
`;
