import type { IconProps } from "@/types/icon";

const CalendarIcon20 = ({
    width = 20,
    height = 20,
    fill = "#999999",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        {...remainingProps}
    >
        <path
            d="M7.40773 11.6042C7.22409 11.6042 7.07016 11.5449 6.94593 11.4261C6.8217 11.3073 6.75958 11.1601 6.75958 10.9845C6.75958 10.8088 6.8217 10.6616 6.94593 10.5429C7.07016 10.4241 7.22409 10.3647 7.40773 10.3647C7.59137 10.3647 7.74531 10.4241 7.86954 10.5429C7.99377 10.6616 8.05588 10.8088 8.05588 10.9845C8.05588 11.1601 7.99377 11.3073 7.86954 11.4261C7.74531 11.5449 7.59137 11.6042 7.40773 11.6042ZM10.0003 11.6042C9.81668 11.6042 9.66275 11.5449 9.53852 11.4261C9.41429 11.3073 9.35218 11.1601 9.35218 10.9845C9.35218 10.8088 9.41429 10.6616 9.53852 10.5429C9.66275 10.4241 9.81668 10.3647 10.0003 10.3647C10.184 10.3647 10.3379 10.4241 10.4621 10.5429C10.5864 10.6616 10.6485 10.8088 10.6485 10.9845C10.6485 11.1601 10.5864 11.3073 10.4621 11.4261C10.3379 11.5449 10.184 11.6042 10.0003 11.6042ZM12.5929 11.6042C12.4093 11.6042 12.2553 11.5449 12.1311 11.4261C12.0069 11.3073 11.9448 11.1601 11.9448 10.9845C11.9448 10.8088 12.0069 10.6616 12.1311 10.5429C12.2553 10.4241 12.4093 10.3647 12.5929 10.3647C12.7766 10.3647 12.9305 10.4241 13.0547 10.5429C13.179 10.6616 13.2411 10.8088 13.2411 10.9845C13.2411 11.1601 13.179 11.3073 13.0547 11.4261C12.9305 11.5449 12.7766 11.6042 12.5929 11.6042ZM5.46329 16.5626C5.10681 16.5626 4.80164 16.4412 4.54778 16.1985C4.29392 15.9557 4.16699 15.6639 4.16699 15.323V6.64591C4.16699 6.30503 4.29392 6.01321 4.54778 5.77046C4.80164 5.52771 5.10681 5.40633 5.46329 5.40633H6.11144V4.16675H7.40773V5.40633H12.5929V4.16675H13.8892V5.40633H14.5374C14.8938 5.40633 15.199 5.52771 15.4529 5.77046C15.7067 6.01321 15.8337 6.30503 15.8337 6.64591V15.323C15.8337 15.6639 15.7067 15.9557 15.4529 16.1985C15.199 16.4412 14.8938 16.5626 14.5374 16.5626H5.46329ZM5.46329 15.323H14.5374V9.12508H5.46329V15.323ZM5.46329 7.8855H14.5374V6.64591H5.46329V7.8855Z"
            fill={fill}
        />
    </svg>
);

export default CalendarIcon20;
