import { FormattedMessage } from "react-intl";

import { useModal } from "@/context/modal/modal";

import { PrimaryButton } from "@/pages/shared/shared.styles";

import { AddUserIcon24, MultipleFilterIcon24 } from "@/components/icons";
import AddUserModalContent from "@/components/ui/modal-with-context/modal-contents/users-page/add-user/add-user-modal-content";
import SearchBar from "@/components/ui/search-bar/search-bar";

import { DropdownFilterVariant } from "@/types/filters";

import { UserMultiSelectDropdown } from "../users-filter";

import type { OptionsBarProps } from "./options-bar.types";

import { ButtonText, OptionsBarWrapperDiv } from "./options-bar.styles";

const OptionsBar = ({
    onInputChange,
    refetchUsersData,
    searchInputValue,
    onRoleChange,
    onStatusChange,
}: OptionsBarProps) => {
    const { openModal, setWidth, setMinHeight } = useModal();

    const handleAddUserButtonClick = () => {
        setWidth("540px");
        setMinHeight("352px");
        openModal({
            content: (
                <AddUserModalContent
                    title="users:users-table:modal-contents:add-user:title"
                    description="users:users-table:modal-contents:add-user:description"
                    refetchUsersData={refetchUsersData}
                />
            ),
        });
    };

    return (
        <OptionsBarWrapperDiv>
            <PrimaryButton onClick={handleAddUserButtonClick}>
                <AddUserIcon24 fill="#121212" />
                <ButtonText>
                    <FormattedMessage id={"users:options-bar:button:text"} />
                </ButtonText>
            </PrimaryButton>
            <SearchBar
                disabled={false}
                debounceTimeout={400}
                placeholder="Search..."
                value={searchInputValue}
                onChange={onInputChange}
            />
            <UserMultiSelectDropdown
                onStatusChange={onStatusChange}
                onRoleChange={(option) => {
                    onRoleChange(option);
                }}
                title="Filters"
                icon={<MultipleFilterIcon24 />}
                variant={DropdownFilterVariant.Primary}
            />
        </OptionsBarWrapperDiv>
    );
};

export default OptionsBar;
