import { useCallback, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
    EventType,
    InteractionRequiredAuthError,
    PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";

import jwt_decode from "jwt-decode";
import { ThemeProvider } from "styled-components";

import { msalConfig } from "@/authConfig";

import { getUserSiteMap } from "@/api/user";

import { ApplicationProvider, useApplication } from "@/context/application";
import { FeatureTogglesProvider } from "@/context/feature-toggles";
import { ModalProvider } from "@/context/modal/modal";
import { UserProvider, useUser } from "@/context/user";
import { SET_ROLE, SET_TOKEN } from "@/context/user/reducer";

import PrivacyStatement from "@/pages/onboarding/privacy-statement";
import SurveySuccess from "@/pages/onboarding/success";
import OnboardingSurvey from "@/pages/onboarding/survey";
import OverviewPage from "@/pages/overview";
import PromoPage from "@/pages/promo";
import Reports from "@/pages/reports";
import SessionDetail from "@/pages/session-detail";
import SessionsPage from "@/pages/sessions";
import Settings from "@/pages/settings";
import UsersPage from "@/pages/users/users";

import Background from "@/modules/background";
import Navigation from "@/modules/navigation";
import TopBar from "@/modules/topbar";

import base from "@/themes/base/base";
import GlobalStyle from "@/themes/global";

import VRIntlSuspender, { VRIntlProviderComponent } from "@/components/providers/intl-provider";
import GenericError from "@/components/ui/generic-error";
import Loader from "@/components/ui/loader";
import ModalWithContext from "@/components/ui/modal-with-context/modal-with-context";

import { HeadlessPages, Pages } from "@/enums/pages";
import { Roles } from "@/enums/user";

import { LoaderContainer, Wrapper } from "./app.styles";

import type { EventMessage } from "@azure/msal-browser";
import type { AccountInfo } from "@azure/msal-common";

const loginRequest = {
    scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE],
};

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const Authenticator = () => {
    const application = useApplication();
    const [activeAccount, setActiveAccount] = useState<AccountInfo | null>(null);
    const [currentAccessToken, setCurrentAccessToken] = useState<string | null>(null);
    const { instance, accounts, inProgress } = useMsal();
    const { state: user, dispatch: userDispatch } = useUser();
    const [error, setError] = useState(null);

    const isAuthenticated = useIsAuthenticated();

    const loadUserSiteMap = async () => {
        return getUserSiteMap();
    };

    useEffect(() => {
        const cId: string | null = instance.addEventCallback((message: EventMessage) => {
            if (message.eventType === EventType.LOGOUT_SUCCESS) {
                window.localStorage.removeItem("accessToken");
                window.localStorage.removeItem("siteMap");
                window.localStorage.removeItem("users");
                userDispatch({ type: SET_TOKEN, payload: null });
            }
        });

        return () => {
            if (cId) instance.removeEventCallback(cId);
        };
    }, [instance]);

    useEffect(() => {
        if (!isAuthenticated && inProgress === "none") {
            instance.loginRedirect(loginRequest).catch((e: any) => {
                setActiveAccount(null);
                setError(e);
            });
        }
    }, [inProgress, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && inProgress === "none" && currentAccessToken) {
            loadUserSiteMap().then((data) => {
                window.localStorage.setItem("siteMap", JSON.stringify(data));
            });
        }
    }, [inProgress, isAuthenticated, currentAccessToken]);

    const getActiveAccount = useCallback(() => {
        if (accounts.length === 0) return;
        const currentAccount = accounts[0];
        let isExpiredToken = false;

        if (currentAccessToken) {
            const decodedToken: any = jwt_decode(currentAccessToken);
            const expirationTime = decodedToken.exp * 1000;
            isExpiredToken = expirationTime < Date.now();
        }

        if (!activeAccount || isExpiredToken) {
            setActiveAccount(currentAccount);

            instance.setActiveAccount(currentAccount);
            instance
                .acquireTokenSilent(loginRequest)
                .then((response: any) => {
                    const { accessToken } = response;
                    setCurrentAccessToken(accessToken);

                    const jwt: any = jwt_decode(accessToken);

                    const role = Object.values(Roles).filter(
                        (value) => jwt[`extension_IsHeat${value}`],
                    )[0];

                    userDispatch({ type: SET_ROLE, payload: role });
                    userDispatch({ type: SET_TOKEN, payload: accessToken });

                    window.localStorage.setItem("accessToken", accessToken);
                })
                .catch((e) => {
                    setActiveAccount(null);

                    if (e instanceof InteractionRequiredAuthError)
                        return msalInstance.acquireTokenRedirect(loginRequest);
                    // fallback to interaction when silent call fails
                    else setError(e);
                })
                .finally(() => application.setLoaderQueueResolved("AUTH"));
        }
    }, [accounts, application, instance, activeAccount, currentAccessToken]);

    useEffect(() => {
        const interval = setInterval(getActiveAccount, 1000);

        return () => clearInterval(interval);
    }, [getActiveAccount]);

    const isUserAuthenticated = activeAccount && isAuthenticated;

    if (error) {
        return <GenericError />;
    }

    if (!user.id && user.status !== "RESOLVED") {
        return null;
    }

    return isUserAuthenticated ? (
        <FeatureTogglesProvider>
            <VRIntlProviderComponent localeFn={localeFn} id="app" fallback={null}>
                <Content />
            </VRIntlProviderComponent>
        </FeatureTogglesProvider>
    ) : null;
};

const Content = () => {
    const { state: user } = useUser();
    const location = useLocation();
    const isHeadLessRoute = HeadlessPages.find((page) => location.pathname === page);

    const isAdminOrInstructor = user.role === Roles.Instructor || user.role === Roles.Admin;

    const hasOverview = () => {
        const siteMapString = window.localStorage.getItem("siteMap") as string;
        const siteMap = JSON.parse(siteMapString);
        const hasOverview = siteMap
            ?.find((nav: any) => nav.title.toLowerCase() === "dashboard")
            ?.navigationItems.some((item: any) => item.title.toLowerCase() === "overview");

        return hasOverview;
    };

    const renderPage = () => {
        if (user.acceptedDataPrivacy) {
            if (user.registrationCompleted) {
                return hasOverview() ? (
                    <Navigate to={Pages.Overview} />
                ) : (
                    <Navigate to={Pages.Sessions} />
                );
            } else {
                return <Navigate to={Pages.Welcome} />;
            }
        }

        return <Navigate to={Pages.Privacy} />;
    };

    return (
        <Wrapper className={isHeadLessRoute ? "head-less" : ""}>
            <GlobalStyle />
            {!isHeadLessRoute && (
                <>
                    <Background />
                    <TopBar />
                    <Navigation />
                </>
            )}

            <Routes>
                <Route path={Pages.Promo} element={<PromoPage />} />

                <Route path={Pages.Overview} element={<OverviewPage />} />
                {user.role === Roles.Admin && <Route path={Pages.Users} element={<UsersPage />} />}
                {isAdminOrInstructor && <Route path={Pages.Reports} element={<Reports />} />}
                <Route path={Pages.Settings} element={<Settings />} />

                <Route path={Pages.Sessions} element={<SessionsPage />} />
                <Route path={Pages.SessionDetail} element={<SessionDetail />} />

                {/* on-boarding routes */}
                <Route path={Pages.Privacy} element={<PrivacyStatement />} />
                <Route path={Pages.Welcome} element={<OnboardingSurvey />} />
                <Route path={Pages.Success} element={<SurveySuccess />} />

                <Route path="/" element={renderPage()} />
            </Routes>
        </Wrapper>
    );
};

const msalInstance = new PublicClientApplication({
    ...msalConfig,
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        redirectUri: window.location.origin,
        knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES],
    },
});

const client = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            retryDelay: 3000,
            suspense: true,
            useErrorBoundary: true,
            refetchOnWindowFocus: false,
        },
    },
});

const App = () => {
    const application = useApplication();

    return (
        <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={base}>
                <QueryClientProvider client={client}>
                    <VRIntlSuspender fallback={null}>
                        <BrowserRouter
                            future={{
                                v7_startTransition: true,
                                v7_relativeSplatPath: true,
                            }}
                        >
                            <ErrorBoundary fallback={<GenericError />}>
                                <UserProvider id={null}>
                                    <ModalProvider>
                                        <Authenticator />
                                        <ModalWithContext />
                                    </ModalProvider>
                                </UserProvider>
                            </ErrorBoundary>
                        </BrowserRouter>
                    </VRIntlSuspender>
                </QueryClientProvider>
                {!application.isLoaderQueueReady() && (
                    <LoaderContainer>
                        <Loader />
                    </LoaderContainer>
                )}
            </ThemeProvider>
        </MsalProvider>
    );
};

const ApplicationWrapper = () => (
    <ApplicationProvider>
        <App />
    </ApplicationProvider>
);

export default ApplicationWrapper;
