import { CircuitTopStat } from "./circuit-top-stat";
import { CircuitStatWrapper } from "./circuit-top-stats-styles";
import { data } from "./data";

const CircuitTopStats = () => {
    const circuitStatsData = data?.sort((stat1: any, stat2: any) => stat1.order - stat2.order);

    return (
        <CircuitStatWrapper>
            {circuitStatsData.map((stat: any, index: number) => (
                <CircuitTopStat
                    {...stat}
                    //id is needed for circuitScoring component to scroll into the corresponding stat when a circuit parameter is clicked
                    id={`circuit-top-stats-${index}`}
                    key={`${stat.code}-${index}`}
                />
            ))}
        </CircuitStatWrapper>
    );
};

export default CircuitTopStats;
