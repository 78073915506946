import LinearProgressBar from "@/components/ui/linear-progress-bar";

import {
    CircuitProgressContainer,
    LabelButton,
    LinearProgressBarAndResultWrapper,
    ResultLabel,
} from "../../scoring.styles";

type LinearProgressProps = {
    circuit: CircuitProps;
    handleOnClick?: any;
    maxLabelWidth: number;
    labelButtonRef: (element: HTMLButtonElement | null) => void;
};

type CircuitProps = {
    label: string;
    result: number;
};

const LinearProgressCircuit = ({
    circuit,
    handleOnClick,
    maxLabelWidth,
    labelButtonRef,
}: LinearProgressProps) => {
    return (
        <CircuitProgressContainer>
            <LabelButton
                id="circuits-label-button-id"
                ref={labelButtonRef}
                onClick={handleOnClick}
                labelWidth={maxLabelWidth}
            >
                {circuit.label}
            </LabelButton>

            <LinearProgressBarAndResultWrapper labelWidth={maxLabelWidth}>
                <LinearProgressBar value={circuit.result} />

                <ResultLabel>{`${circuit.result}%`}</ResultLabel>
            </LinearProgressBarAndResultWrapper>
        </CircuitProgressContainer>
    );
};

export default LinearProgressCircuit;
