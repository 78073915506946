export enum Pages {
    Sessions = "/sessions",
    SessionDetail = "/sessions/session-analysis",
    Overview = "/overview",
    Settings = "/settings",
    Promo = "/promo",
    Users = "/users",
    Reports = "/reports",
    Privacy = "/privacy-policy",
    Welcome = "/welcome",
    Success = "/success",
}

export const HeadlessPages = [Pages.Promo, Pages.Privacy, Pages.Welcome, Pages.Success];
