import type { IconProps } from "@/types/icon";

const ChevronLeftSquareCornersIcon24 = ({
    fill = "#F2F2F2",
    width = 24,
    height = 24,
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        {...remainingProps}
    >
        <path
            d="M15 7.07273L13.9323 6L8 12L13.9383 18L15 16.9273L10.1234 12L15 7.07273Z"
            fill={fill}
        />
    </svg>
);

export default ChevronLeftSquareCornersIcon24;
