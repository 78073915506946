import base from "@/themes/base/base";

import { getSecondsAsMinutes } from "./utils";

export const options = {
    annotations: {
        xaxis: [
            {
                x: 180,
                x2: 181,
                opacity: 0.8,
                fillColor: base.colors.neutral.grey[300],
                label: {
                    orientation: "horizontal",
                    text: "3m",

                    style: {
                        fontWeight: "bold",
                        color: base.colors.neutral.grey[900],
                        background: base.colors.neutral.grey[300],
                        padding: { bottom: 8, top: 8 },
                    },
                    offsetX: 1,
                    offsetY: -10,
                },
            },
            {
                x: 540,
                x2: 541,
                opacity: 0.8,
                fillColor: base.colors.neutral.grey[300],
                label: {
                    orientation: "horizontal",
                    text: "9m",
                    style: {
                        fontWeight: "bold",
                        color: base.colors.neutral.grey[900],
                        background: base.colors.neutral.grey[300],
                        padding: { bottom: 8, top: 8 },
                    },
                    offsetX: 1,
                    offsetY: -10,
                },
            },
        ],
    },
    chart: {
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
        animations: {
            enabled: false,
        },
        type: "bar" as const,
        stacked: true,
    },
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: "12px",
        },
    },
    stroke: {
        width: 0,
        colors: ["#fff"],
    },
    grid: {
        strokeDashArray: 8,
        borderColor: "#666666",
    },
    xaxis: {
        categories: [""],
        axisTicks: {
            show: false,
        },
        axisBorder: {
            show: true,
            color: "#666666",
        },
        tickPlacement: "on",
        labels: {
            style: {
                colors: base.colors.neutral.grey[50],
                fontSize: "1rem",
            },
            show: false,
        },
        tooltip: {
            enabled: true,
        },
    },
    tooltip: {
        enabled: true,
        x: {
            show: false,
        },
        y: {
            show: false,
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
            const categories = w.globals.labels;
            const phaseName = w.globals.seriesNames[seriesIndex];
            const phaseValue = series[seriesIndex][dataPointIndex];
            const phaseColor = w.globals.colors[seriesIndex];
            const seriesData = w.globals.initialSeries.filter(
                (chart: any) => chart.name !== "emptydata",
            );

            //Sum the first number from each array in the series, then move to the next index in each array,
            //adding those numbers together and avoiding any NaN.
            //The result is an array where each element represents the total sum for phases 1-3."
            const combinedTimeArray = series
                .slice(0, 3)
                .reduce((sumArray: any, currentArray: any) => {
                    currentArray.forEach((value: number, index: number) => {
                        sumArray[index] = (sumArray[index] || 0) + (isNaN(value) ? 0 : value);
                    });

                    return sumArray;
                }, []);

            const startingPhaseColors = w.globals.colors?.slice(0, 3);
            //Add 3 circles if not on phase 4
            const additionalCircles =
                phaseName !== "phase4"
                    ? startingPhaseColors
                          .map(
                              (color: string) => `
            <svg width="20" height="20" viewBox="0 0 20 20" style="margin-top:5px;">
                <circle cx="12" cy="12" r="6" fill="${color}" stroke="${base.colors.neutral.grey[300]}" stroke-width="2"/>
            </svg>
        `,
                          )
                          .join("")
                    : "";

            const tooltipContent =
                `<span class=${seriesData === "" ? "hidden" : ""}>` +
                `<strong>${categories[dataPointIndex]}</strong><br>` +
                `<span>${
                    additionalCircles === ""
                        ? ""
                        : additionalCircles +
                          `Phase 1-3</span>` +
                          `<div>
                           ${getSecondsAsMinutes(combinedTimeArray[dataPointIndex])} 
                           </div>`
                }` +
                `<svg width="20" height="20" viewBox="0 0 20 20" style="margin-right:8px;">` +
                `<circle cx="12" cy="12" r="6" fill=${phaseColor} stroke=${base.colors.neutral.grey[300]} stroke-width="2"/></svg>` +
                `<span class="value-label">` +
                phaseName.charAt(0).toUpperCase() +
                phaseName.slice(1).replace(/(\d+)/, " $1") +
                `</span>` +
                `<br>` +
                `<div class="value-label">` +
                `${getSecondsAsMinutes(phaseValue)}` +
                `</div>`;

            return '<div class="custom-tooltip">' + tooltipContent + "</div>";
        },
    },
    yaxis: {
        title: {
            text: undefined,
        },
        labels: {
            style: {
                colors: base.colors.neutral.grey[50],
                fontSize: "1rem",
            },
        },
    },

    fill: {
        colors: [
            base.colors.primary.orange[100],
            base.colors.primary.orange[300],
            base.colors.primary.orange[500],
            base.colors.primary.blue[500],
        ],
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
};
