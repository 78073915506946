import React from "react";
import { FormattedMessage } from "react-intl";
import type {
    InfiniteData,
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "react-query";
import { useMutation } from "react-query";

import { updateUserRole } from "@/api/users";

import { useModal } from "@/context/modal/modal";

import GenericError from "@/components/ui/generic-error";
import Loader from "@/components/ui/loader";

import type { User } from "@/types/users";

import { Roles } from "@/enums/user";

import {
    ModalContentWrapperDiv,
    Title,
    Description,
    ButtonsWrapper,
    StyledCancelButton,
    StyledConfirmButton,
} from "../../modal-contents.styles";

import ConfirmationModalContent from "../confirmation/confirmation-modal-content";

interface ChangeRoleModalContentProps {
    user: User;
    refetchUsersData: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<InfiniteData<any>, unknown>>;
}

const ChangeRoleModalContent = ({ user, refetchUsersData }: ChangeRoleModalContentProps) => {
    const { closeModal, openModal } = useModal();

    const userId = user.userId;

    const currentRole = user.roles.includes(Roles.Instructor) ? Roles.Instructor : Roles.Trainee;

    const newRole: Roles.Instructor | Roles.Trainee =
        currentRole === Roles.Instructor ? Roles.Trainee : Roles.Instructor;

    const { mutate, isLoading } = useMutation(updateUserRole, {
        onSuccess: () => {
            openModal({
                content: (
                    <ConfirmationModalContent title="users:users-table:modal-contents:change-role:confirmation-modal:title" />
                ),
            });

            refetchUsersData();
        },
        onError: () => {
            openModal({ content: <GenericError /> });
        },
    });

    const handleChangeRoleButton = () => {
        mutate({ userId, newRole }); //PUT request to change role -> /User?Role API { userId: string, newRole: string}
    };

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id="users:users-table:modal-contents:change-role:title" />
            </Title>
            <Description>
                <FormattedMessage id={"users:users-table:modal-contents:change-role:description"} />
            </Description>
            <ButtonsWrapper>
                <StyledConfirmButton
                    disabled={isLoading}
                    onClick={handleChangeRoleButton}
                    fullWidth
                >
                    {isLoading ? (
                        <Loader size="medium" color="#666666" fontSize="1.6875rem" />
                    ) : (
                        <FormattedMessage id="users:users-table:modal-contents:change-role:primary-button" />
                    )}
                </StyledConfirmButton>
                <StyledCancelButton onClick={closeModal} fullWidth>
                    <FormattedMessage id="users:users-table:modal-contents:secondary-button" />
                </StyledCancelButton>
            </ButtonsWrapper>
        </ModalContentWrapperDiv>
    );
};

export default ChangeRoleModalContent;
