import styled from "styled-components";

import base from "@/themes/base/base";

interface ContainerStylingProps {
    height?: string;
}

export const Container = styled.div.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        (prop as string) !== "height" && defaultValidatorFn(prop),
})<ContainerStylingProps>`
    display: flex;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    width: 100%;
    height: ${({ height }) => height && `${height}`};
`;

export const GaugeAndLegendContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

export const GaugeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    position: relative;
    width: 19rem;
    height: 11.6875rem;
`;

export const Label = styled.div`
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.02rem;
`;

export const LegendWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    height: 2rem;
`;

export const Legend = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const LegendIcon = styled.div`
    width: 1rem;
    height: 1rem;
    background: ${(props) => props.color};
`;

export const LegendText = styled.span`
    ${base.typography.smallRegular};

    color: ${base.colors.neutral.grey[300]};
`;
