import type { IconProps } from "@/types/icon";

const SettingsIcon32 = ({
    width = 32,
    height = 32,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <g id="Nav Settings Icon">
            <path
                id="Vector"
                d="M12.7164 28L12.2388 24.16C11.9801 24.06 11.7363 23.94 11.5075 23.8C11.2786 23.66 11.0547 23.51 10.8358 23.35L7.28358 24.85L4 19.15L7.07463 16.81C7.05473 16.67 7.04478 16.535 7.04478 16.405V15.595C7.04478 15.465 7.05473 15.33 7.07463 15.19L4 12.85L7.28358 7.15L10.8358 8.65C11.0547 8.49 11.2836 8.34 11.5224 8.2C11.7612 8.06 12 7.94 12.2388 7.84L12.7164 4H19.2836L19.7612 7.84C20.0199 7.94 20.2637 8.06 20.4925 8.2C20.7214 8.34 20.9453 8.49 21.1642 8.65L24.7164 7.15L28 12.85L24.9254 15.19C24.9453 15.33 24.9552 15.465 24.9552 15.595V16.405C24.9552 16.535 24.9353 16.67 24.8955 16.81L27.9701 19.15L24.6866 24.85L21.1642 23.35C20.9453 23.51 20.7164 23.66 20.4776 23.8C20.2388 23.94 20 24.06 19.7612 24.16L19.2836 28H12.7164ZM14.806 25.6H17.1642L17.5821 22.42C18.199 22.26 18.7711 22.025 19.2985 21.715C19.8259 21.405 20.3085 21.03 20.7463 20.59L23.7015 21.82L24.8657 19.78L22.2985 17.83C22.398 17.55 22.4677 17.255 22.5075 16.945C22.5473 16.635 22.5672 16.32 22.5672 16C22.5672 15.68 22.5473 15.365 22.5075 15.055C22.4677 14.745 22.398 14.45 22.2985 14.17L24.8657 12.22L23.7015 10.18L20.7463 11.44C20.3085 10.98 19.8259 10.595 19.2985 10.285C18.7711 9.975 18.199 9.74 17.5821 9.58L17.194 6.4H14.8358L14.4179 9.58C13.801 9.74 13.2289 9.975 12.7015 10.285C12.1741 10.595 11.6915 10.97 11.2537 11.41L8.29851 10.18L7.13433 12.22L9.70149 14.14C9.60199 14.44 9.53234 14.74 9.49254 15.04C9.45274 15.34 9.43284 15.66 9.43284 16C9.43284 16.32 9.45274 16.63 9.49254 16.93C9.53234 17.23 9.60199 17.53 9.70149 17.83L7.13433 19.78L8.29851 21.82L11.2537 20.56C11.6915 21.02 12.1741 21.405 12.7015 21.715C13.2289 22.025 13.801 22.26 14.4179 22.42L14.806 25.6ZM16.0597 20.2C17.2139 20.2 18.199 19.79 19.0149 18.97C19.8308 18.15 20.2388 17.16 20.2388 16C20.2388 14.84 19.8308 13.85 19.0149 13.03C18.199 12.21 17.2139 11.8 16.0597 11.8C14.8856 11.8 13.8955 12.21 13.0896 13.03C12.2836 13.85 11.8806 14.84 11.8806 16C11.8806 17.16 12.2836 18.15 13.0896 18.97C13.8955 19.79 14.8856 20.2 16.0597 20.2Z"
                fill={fill}
            />
        </g>
    </svg>
);

export default SettingsIcon32;
