import type { ChangeEvent } from "react";
import { DebounceInput } from "react-debounce-input";

import { SearchIcon24 } from "@/components/icons";

import { IconWrapper, SearchBarWrapperDiv } from "./search-bar.styles";

interface SearchBarProps {
    disabled?: boolean;
    debounceTimeout: number;
    placeholder: string;
    value: string | null;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    width?: string;
    backgroundColor?: string;
}

const SearchBar = ({
    disabled = false,
    debounceTimeout = 400,
    placeholder,
    value,
    onChange,
    width,
    backgroundColor,
    ...remainingProps
}: SearchBarProps) => {
    return (
        <SearchBarWrapperDiv width={width} backgroundColor={backgroundColor} {...remainingProps}>
            <IconWrapper>
                <SearchIcon24 width={24} height={24} />
            </IconWrapper>
            <DebounceInput
                disabled={disabled}
                debounceTimeout={debounceTimeout}
                value={value || ""}
                onChange={onChange}
                placeholder={placeholder}
                autoFocus
            />
        </SearchBarWrapperDiv>
    );
};

export default SearchBar;
