export const scrollToTop = (elementId: string) => {
    const htmlElement = document.getElementById(`${elementId}`);

    if (htmlElement) {
        htmlElement.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
};
