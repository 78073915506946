import styled from "styled-components";

import base from "@/themes/base/base";

export const OptionsBarWrapperDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 0.625rem;
`;

export const ButtonText = styled.span`
    ${base.typography.largeMedium};
`;
