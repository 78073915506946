import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { triggerAnalytics } from "@/api/sessions";

import ContentLoader from "@/components/content-loader/content-loader";
import Loader from "@/components/ui/loader";
import { ProgressBar } from "@/components/ui/progress-bar/progress-bar";
import Tooltip from "@/components/ui/tooltip/tooltip";

import type { Score } from "@/types/session-details";

import { Title, SubTitle } from "../../session-detail.styles";

import { usePolling } from "./utils/usePolling";

import {
    LandingScoreAndScoreBreakdownWrapper,
    LandingScoreWrapper,
    NoDataContent,
    NoDataWrapper,
    PerformanceScoreWrapper,
    ScoreBreakdownCard,
    ScoreBreakdownWrapper,
    SubtitleAndTooltipWrapper,
    ProgressBarAndSubtitleWrapper,
    BreakdownDivider,
    BreakdownDividerWrapper,
    LandingScoreDividerWrapper,
    LandingScoreDivider,
} from "./performance-score.styles";

export const PerformanceScore = () => {
    const [poll, setPoll] = useState(0);
    const { landingScore, breakdownData, simulationInstanceId } = usePolling(poll);

    const breakdownDataSorted: any = breakdownData?.sort((a, b) => a.position - b.position);

    useEffect(() => {
        if (breakdownData?.length === 0) {
            triggerAnalytics({ simulationInstanceId });
            setTimeout(() => {
                setPoll(poll + 1);
            }, 10000);
        }
    }, [breakdownData]);

    if (breakdownData === undefined) {
        return <ContentLoader height="21.0625rem" />;
    }

    return (
        <PerformanceScoreWrapper>
            {breakdownData.length === 0 ? (
                <NoDataWrapper>
                    <NoDataContent>
                        <Loader />
                        <Title>
                            <FormattedMessage id={"session-detail:data-loading"} />
                        </Title>
                    </NoDataContent>
                </NoDataWrapper>
            ) : (
                <>
                    <Title>{landingScore?.scoreTitle}</Title>

                    <LandingScoreAndScoreBreakdownWrapper>
                        {landingScore && (
                            <React.Fragment>
                                <LandingScoreWrapper>
                                    <ProgressBarAndSubtitleWrapper>
                                        <ProgressBar
                                            width={174}
                                            valueEnd={Math.round(landingScore.score)}
                                            scoreFontSize="3rem"
                                            totalScoreFontSize="1.5rem"
                                        />

                                        <SubtitleAndTooltipWrapper>
                                            <SubTitle>{landingScore.legend}</SubTitle>
                                            <Tooltip simpleText={landingScore.description} />
                                        </SubtitleAndTooltipWrapper>
                                    </ProgressBarAndSubtitleWrapper>
                                </LandingScoreWrapper>

                                <LandingScoreDividerWrapper>
                                    <LandingScoreDivider />
                                </LandingScoreDividerWrapper>
                            </React.Fragment>
                        )}

                        {breakdownData?.length !== 0 && (
                            <ScoreBreakdownWrapper>
                                {breakdownDataSorted?.map((score: Score, index: number) => {
                                    return (
                                        <ScoreBreakdownCard key={score.scoreTitle}>
                                            <ProgressBarAndSubtitleWrapper>
                                                <ProgressBar
                                                    width={87}
                                                    valueEnd={Math.trunc(score.score)}
                                                    scoreFontSize="2rem"
                                                />
                                                <SubTitle>{score.scoreTitle}</SubTitle>
                                            </ProgressBarAndSubtitleWrapper>

                                            {index < breakdownData.length - 1 && (
                                                <BreakdownDividerWrapper>
                                                    <BreakdownDivider />
                                                </BreakdownDividerWrapper>
                                            )}
                                        </ScoreBreakdownCard>
                                    );
                                })}
                            </ScoreBreakdownWrapper>
                        )}
                    </LandingScoreAndScoreBreakdownWrapper>
                </>
            )}
        </PerformanceScoreWrapper>
    );
};
