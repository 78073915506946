import type { IconProps } from "@/types/icon";

const MultipleFilterIcon24 = ({
    width = 24,
    height = 24,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} {...remainingProps}>
        <path d="M11 21V15H13V17H21V19H13V21H11ZM3 19V17H9V19H3ZM7 15V13H3V11H7V9H9V15H7ZM11 13V11H21V13H11ZM15 9V3H17V5H21V7H17V9H15ZM3 7V5H13V7H3Z" />
    </svg>
);

export default MultipleFilterIcon24;
