import type { CircuitScoreDataSet, LinearProgressBarDataSet } from "../scoring.types";

//this is the mock data that will be fetched for the scoring component
export const circuitScore: CircuitScoreDataSet[] = [
    {
        baseLine: "",
        xAxisInterval: 7,
        yAxisInterval: 10,
        datasets: [
            {
                scoreTitle: "Scoring",
                legend: "Avg. Circuits Score",
                score: 30,
                description: "legend tooltip",
            },
        ],
        gridView: {
            columnPosition: 0,
            rowPosition: 0,
            groupOrder: 0,
            groupSection: "Score",
        },
        legend: null,
        label: {
            currentMeasurementType: "Text",
            displayMeasurementType: "Text",
            displayMeasurementFormat: null,
            unit: "",
            maxDisplayValue: null,
            code: "Alias",
            description: "Alias",
        },
        value: {
            currentMeasurementType: "Numeric",
            displayMeasurementType: "Numeric",
            displayMeasurementFormat: null,
            unit: "",
            maxDisplayValue: null,
            code: "Score",
            description: "Score",
        },
        chartType: "ProgressBar",
        code: "circuit-score",
        description:
            "This is the landing score for the session and is based on the approach, touchdown, and stopping scores.",
    },
    {
        baseLine: "",
        xAxisInterval: 7,
        yAxisInterval: 10,
        datasets: [
            {
                name: "circuit1",
                label: "Circuit 1",
                result: 65,
            },
            {
                name: "circuit2",
                label: "Circuit 2",
                result: 54,
            },
            {
                name: "circuit3",
                label: "Circuit 3",
                result: 32,
            },
            {
                name: "circuit4",
                label: "Circuit 4",
                result: 95,
            },
            {
                name: "circuit5",
                label: "Circuit 5",
                result: 78,
            },
            {
                name: "circuit6",
                label: "Circuit 6",
                result: 28,
            },
            {
                name: "circuit7",
                label: "Circuit 7",
                result: 97,
            },
            {
                name: "circuit8",
                label: "Circuit 8",
                result: 62,
            },
            {
                name: "circuit9",
                label: "Circuit 9",
                result: 34,
            },
        ],
        gridView: {
            columnPosition: 0,
            rowPosition: 0,
            groupOrder: 0,
            groupSection: "Score",
        },
        legend: null,
        label: {
            currentMeasurementType: "Text",
            displayMeasurementType: "Text",
            displayMeasurementFormat: null,
            unit: "",
            maxDisplayValue: null,
            code: "Alias",
            description: "Alias",
        },
        value: {
            currentMeasurementType: "Numeric",
            displayMeasurementType: "Numeric",
            displayMeasurementFormat: null,
            unit: "",
            maxDisplayValue: null,
            code: "Score",
            description: "Score",
        },
        chartType: "LinearProgressBar",
        code: "circuit-score",
        description:
            "This is the landing score for the session and is based on the approach, touchdown, and stopping scores.",
    },
];

//this is the mock data that will be fetched for each individual circuit
export const circuitParameters: LinearProgressBarDataSet[] = [
    {
        name: "take-off",
        label: "Take-Off",
        result: 89,
    },
    {
        name: "upwind-turn",
        label: "Upwind Turn",
        result: 54,
    },
    {
        name: "downwind-leg",
        label: "Downwind Leg",
        result: 62,
    },
    {
        name: "final-turn",
        label: "Final Turn",
        result: 78,
    },
    {
        name: "final-approach",
        label: "Final Approach",
        result: 39,
    },
    {
        name: "landing",
        label: "Landing",
        result: 89,
    },
];
