import client from "@/client";

export const getDataPrivacy = async () => {
    const { data } = await client.get(`/v1/DataPrivacy`);

    return data;
};

export const dataPrivacyReview = async (version: string, accept: boolean) => {
    const { data } = await client.patch(
        `v1/DataPrivacy/user-history?version=${version}&accept=${accept}`,
    );

    return data;
};
