import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useIntl } from "react-intl";
import { QueryClient, QueryClientProvider, useInfiniteQuery } from "react-query";

import { getUsers } from "@/api/users";

import type { Option } from "@/pages/users/components/users-filter";

import { VRIntlProviderComponent } from "@/components/providers/intl-provider";
import Page, { PageLoader } from "@/components/ui/page";

import OptionsBar from "./components/options-bar/options-bar";
import UsersTable from "./components/users-table/users-table";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const queryClient = new QueryClient();

const Users = () => {
    const intl = useIntl();
    const pageTitle = intl.formatMessage({ id: "users:page-name" });

    const { ref: lastUserInRef, inView } = useInView();

    const [searchInputValue, setSearchInputValue] = useState<string | null>(null);
    const [selectedRole, setSelectedRole] = useState<Option[]>();
    const [selectedStatus, setSelectedStatus] = useState<string>();

    const {
        data: data,
        isError: isErrorInUsersData,
        fetchNextPage,
        hasNextPage: hasNextPage,
        isFetching: isFetchingUsersData,
        refetch: refetchUsersData,
    } = useInfiniteQuery(
        ["getUsers", searchInputValue, selectedRole, selectedStatus],
        //pageParam is skipToken
        ({ pageParam = null }) =>
            getUsers(pageParam, searchInputValue, selectedRole, selectedStatus),
        {
            getNextPageParam: (lastPage) => {
                return lastPage.hasNextPage ? lastPage.skipToken : undefined;
            },
            refetchOnWindowFocus: false,
            useErrorBoundary: false,
            refetchOnMount: true,
            enabled: true,
        },
    );

    useEffect(() => {
        if (inView) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, inView]);

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(event.target.value);
    };

    const usersData = data?.pages.flatMap((page) => page.items);

    useEffect(() => {
        queryClient.invalidateQueries(["getUsers"]);
        refetchUsersData();
    }, [searchInputValue, selectedRole, selectedStatus]);

    return (
        <Page
            title={pageTitle}
            counter={usersData?.length}
            rightContent={
                <OptionsBar
                    searchInputValue={searchInputValue}
                    onInputChange={handleSearchInputChange}
                    refetchUsersData={refetchUsersData}
                    onRoleChange={(roles) => {
                        setSelectedRole(roles);
                    }}
                    onStatusChange={(status) => {
                        setSelectedStatus(status);
                    }}
                />
            }
        >
            <UsersTable
                lastUserInRef={lastUserInRef}
                usersData={usersData}
                refetchUsersData={refetchUsersData}
                isFetchingUsersData={isFetchingUsersData}
                isErrorInUsersData={isErrorInUsersData}
            />
        </Page>
    );
};

const UsersPage = () => {
    return (
        <VRIntlProviderComponent localeFn={localeFn} id="trainees-page" fallback={<PageLoader />}>
            <QueryClientProvider client={queryClient}>
                <Users />
            </QueryClientProvider>
        </VRIntlProviderComponent>
    );
};

export default UsersPage;
