import { scaleLinear } from "d3-scale";
import { arc } from "d3-shape";

import { GaugeWrapper, Label } from "./gauge-styles";

interface GaugeProps {
    value: number;
    min?: number;
    max?: number;
    formatLabel?: any;
    colors: string[];
}

const Gauge = ({ value = 0, min = 0, max = 100, formatLabel, colors }: GaugeProps) => {
    const { label, color } = formatLabel();
    const backgroundArcGenerator = arc().cornerRadius(1);

    const backgroundArc1 =
        backgroundArcGenerator({
            startAngle: (-Math.PI * 2) / 3,
            endAngle: (-Math.PI * 2) / 9 - 0.01, // (0.01) is for gap between the arcs
            innerRadius: 0.98,
            outerRadius: 1.08,
        }) || "";

    const backgroundArc2 =
        backgroundArcGenerator({
            startAngle: (-Math.PI * 2) / 9 + 0.01,
            endAngle: (Math.PI * 2) / 9 - Math.PI / 180 + 0.01,
            innerRadius: 0.98,
            outerRadius: 1.08,
        }) || "";

    const backgroundArc3 =
        backgroundArcGenerator({
            startAngle: (Math.PI * 2) / 9 + 0.01,
            endAngle: (Math.PI * 2) / 3,
            innerRadius: 0.98,
            outerRadius: 1.08,
        }) || "";

    const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
    const percent = percentScale(value);

    const angleScale = scaleLinear()
        .domain([0, 1])
        .range([(-2 * Math.PI) / 3, (2 * Math.PI) / 3])
        .clamp(true);

    const angle = angleScale(percent);

    const markerLocation = getCoordsOnArc(angle, 1.04);

    return (
        <GaugeWrapper>
            <svg
                style={{ overflow: "visible" }}
                width="18.5rem"
                height="11.7rem"
                viewBox={[-1, -1.15, 1.98, 1.75].join(" ")}
            >
                <path d={backgroundArc1} fill={colors[0]} />
                <path d={backgroundArc2} fill={colors[1]} />
                <path d={backgroundArc3} fill={colors[2]} />

                <circle
                    cx={markerLocation[0]}
                    cy={markerLocation[1]}
                    r="0.07"
                    stroke={color}
                    strokeWidth="0.03"
                    fill={"#262626"}
                />
            </svg>

            <Label>{label}</Label>
        </GaugeWrapper>
    );
};

const getCoordsOnArc = (angle: number, offset = 10) => [
    Math.cos(angle - Math.PI / 2) * offset,
    Math.sin(angle - Math.PI / 2) * offset,
];

export default Gauge;

//source: https://2019.wattenberger.com/blog/gauge
